.Stats_stats__PB57m {
  padding: 8px 0;
  /* background-color: #f8f9fa; */
  position: relative;
  z-index: 0;
}
.styles_homepageContainer__cL6dC .styles_small-container__2O3eV {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: auto;
}
.Stats_stats__PB57m .Stats_statsWrapper__2AwcW {
  position: relative;
  width: 1080px;
}
.styles_homepageContainer__cL6dC .styles_row__1vzFc {
  display: flex;
  margin: 0 -12px;
  flex-wrap: wrap;
}
.tw-items-center {
  align-items: center;
}
.styles_homepageContainer__cL6dC .styles_col__c0uzR {
  padding: 0 12px;
}
.styles_homepageContainer__cL6dC .styles_col-6__3voLH {
  width: 50%;
}
.styles_homepageContainer__cL6dC .styles_col-6__3voLHG {
  width: 100%;
}
.tw-ml-auto {
  margin-left: 50px;
}
.tw-ml-auto2 {
  margin-left: 15px;
}
.styles_homepageContainer__cL6dC img,
.styles_homepageContainer__cL6dC video {
  max-width: 100%;
  vertical-align: middle;
}
.styles_homepageContainer__cL6dC img,
.styles_homepageContainer__cL6dC svg {
  display: inline-block;
}
.Stats_stats__PB57m .tw-ml-auto .Stats_heading__b4q5H {
  margin-bottom: 12px;
}
.Stats_stats__PB57m .Stats_heading__b4q5HY {
  margin-bottom: 12px;
  padding-left: 60px;
}
.styles_homepageContainer__cL6dC .styles_heading__21Gbs {
  font-size: 27px;
  line-height: 40px;
  /* font-weight: 600; */
  /* margin-bottom: 30px; */
  z-index: 2;
  position: relative;
}
.styles_homepageContainer__cL6dC h2 {
  font-size: 28px;
  line-height: 1.4;
  letter-spacing: normal;
}
.styles_homepageContainer__cL6dC
  .styles_stroke__3kKld.styles_stats-b2-stroke__1sdGv:before {
  top: 3px;
  left: 0;
}
.styles_homepageContainer__cL6dC .styles_stroke__3kKld:before {
  position: absolute;
  left: 0;
  right: 0;
  background-image: url(`images/banners/canada.png`);
  content: "";
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 0 100%;
  bottom: -6px;
  background-size: contain;
  z-index: -1;
}
.styles_homepageContainer__cL6dC .styles_orange__11u2T {
  color: #003366 !important;
}
.styles_homepageContainer__cL6dC li,
.styles_homepageContainer__cL6dC p {
  line-height: 1.6;
  font-size: 14px;
  font-weight: 400;
}
.styles_homepageContainer__cL6dC p {
  margin: 0 0 20px;
}
.styles_row__1vzFc {
  display: flex;
  margin: 0 -12px;
  flex-wrap: wrap;
}
.styles_homepageContainer__cL6dC .styles_col-6__3voLH {
  width: 100%;
}
.Stats_stats__PB57m .Stats_singleStatSection__2pvyG {
  /* background-color: #f8f9fa; */
  border-radius: 8px;
  min-height: 100%;
  width: 90%;
  margin-left: 60px;
  text-align: justify;
  transition: 0.3s ease;
  overflow: hidden;
  padding: 4px 0;
}
.Stats_stats__PB57m .Stats_singleStatSection__2pvKM {
  background-color: #f8f9fa;
  border-radius: 8px;
  min-height: 100%;
  width: 85%;
  margin-left: 65px;
  text-align: justify;
  transition: 0.3s ease;
  overflow: hidden;
  padding: 4px 0;
}
.Stats_stats__PB57m .Stats_singleStatSection__2pvyG h3 {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.6px;
}
.Stats_stats__PB57m .Stats_singleStatSection__2pvyG p {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}
.Stats_stats__PB57m .Stats_singleStatSection__2pvKM p {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}
.styles_homepageContainer__cL6dC .styles_col__c0uzR {
  padding: 0 12px;
}
.styles_homepageContainer__cL6dC .styles_col-6__3voLH {
  width: 50%;
}
.styles_homepageContainer__cL6dC .styles_col-6__3voLHG {
  width: 100%;
  margin: 14px auto;
}
.Stats_stats__PB57m .Stats_mapImg__ZD47G {
  position: relative;
  bottom: 0;
  margin-right: -50px;
  width: 500px;
  height: 200%;
}
.styles_homepageContainer__cL6dC img,
.styles_homepageContainer__cL6dC video {
  max-width: 100%;
  vertical-align: middle;
}
.styles_homepageContainer__cL6dC img,
.styles_homepageContainer__cL6dC svg {
  display: inline-block;
}
@media screen and (max-width: 768px) {
  .styles_homepageContainer__cL6dC {
    height: fit-content;
    padding: 20px auto;
  }
  .Stats_stats__PB57m {
      padding: 8px 0;
      background-color: #f8f9fa;
      position: relative;
      z-index: 0;
      
      .Stats_heading__b4q5HY {
        padding-left: 10px;
      }
        
      .Stats_singleStatSection__2pvKM {
        background-color: #f8f9fa;
        border-radius: 18px;
        min-height: 100%;
        margin: 10px auto;
        text-align: justify;
        transition: 0.3s ease;
        overflow: hidden;
      } 
      .Stats_statsCountContainer__1mvZI {
        /* margin: 0px; */
       }
       .styles_heading__21Gbs {
        font-size: 32px;
        line-height: 40px;
        font-weight: 600;
        /* margin-bottom: 30px; */
        z-index: 2;
        position: relative;
  
      }
  }
    
    }
    
    .styles_col-6__3voLHG {
      width: 100%;
      padding-left: 20px;
      /* margin: 30px auto; */
     
  }
    .styles_row__1vzFc{
      display: flex;
      /* margin: 0 12px; */
      flex-wrap: wrap;
        
    }

@media screen and (max-width: 768px) {
      .styles_homepageContainer__cL6dC {

      }
      .styles_row__1vzFc .styles_col__c0uzR {
        width: 100%;

        .Stats_statsCountContainer__1mvZI {
          display: grid;
          
        }
      }
      
      .Stats_stats__PB57m .Stats_mapImg__ZD47G {
        position: relative;
        bottom: 0;
        width: 360px;
      }
      .Stats_heading__b4q5H .styles_stroke__3kKld {
        font-size: 18px;
      }
    }

.Testimonials_testimonial-area-main__3eI_E {
  position: relative;
  z-index: 1;
  margin-top: 20px;
}
.Testimonials_testimonial-area-main__3eI_F {
  /* padding: 32px 0 60px; */
  /* background: #f8fbfe; */
  /* position: relative;
    z-index: 1; */
}
.styles_homepageContainer__cL6dC .styles_small-container__2O3eV {
  max-width: 1020px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.Testimonials_testimonial-area-main__3eI_E .Testimonials_quote-wrapper__27vRL {
  background-color: #f8f9fa;
  border: 1.5px solid #eceaea;
  border-radius: 16px;
  width: 95%;
  height: 210px;
  margin: 0 auto;
}
.Testimonials_testimonial-area-main__3eI_F .Testimonials_quote-wrapper__28vRL {
  /* border: 1.5px solid #eceaea; */
  /* border-radius: 16px; */
  height: fit-content;
  width: 95%;
  padding-top: 20px;
  padding-bottom: 30px;
  margin: 0 auto;
}
.Testimonials_testimonial-area-main__3eI_E
  .Testimonials_quote-wrapper__27vRL
  .Testimonials_quote-container__pi8Vw {
  display: flex;
  padding: 20px;
}
.Testimonials_testimonial-area-main__3eI_E
  .Testimonials_quote-wrapper__27vRL
  .Testimonials_quote-container__pi8Vw
  .Testimonials_video-cover-wrapper__19thV {
  position: relative;
  height: 400px;
  width: 200px;
}
.Testimonials_testimonial-area-main__3eI_E
  .Testimonials_quote-wrapper__27vRL
  .Testimonials_quote-container__pi8Vw
  .img {
  position: relative;
  height: 42%;
  width: 140px;
  margin-left: 40px;
}
.styles_homepageContainer__cL6dC img,
.styles_homepageContainer__cL6dC video {
  max-width: 100%;
  vertical-align: middle;
}
.styles_homepageContainer__cL6dC .styles_absolute-center__lwtjt {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.styles_homepageContainer__cL6dC .styles_play-button__1lRpC svg {
  display: inline-block;
}
.styles_homepageContainer__cL6dC img,
.styles_homepageContainer__cL6dC svg {
  display: inline-block;
}
.Testimonials_testimonial-area-main__3eI_E
  .Testimonials_quote-wrapper__27vRL
  .Testimonials_quote-container__pi8Vw
  .Testimonials_feedback-wrapper__1QGRM {
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  margin-left: 100px;
  margin-top: 35px;
  width: 50%;
  justify-content: space-evenly;
}
.Testimonials_testimonial-area-main__3eI_E
  .Testimonials_quote-wrapper__27vRL
  .Testimonials_quote-container__pi8Vw
  .Testimonials_feedback-wrapper__1QGRN {
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  margin-left: 100px;
  margin-top: 300px;
  width: 50%;
  justify-content: space-evenly;
}
.Testimonials_testimonial-area-main__3eI_E
  .Testimonials_quote-wrapper__27vRL
  .Testimonials_quote-container__pi8Vw
  .Testimonials_feedback-wrapper__1QGRM
  .Testimonials_quote-svg__1yz2h {
  width: 28px;
  height: 20px;
}
.styles_homepageContainer__cL6dC img,
.styles_homepageContainer__cL6dC video {
  max-width: 100%;
  vertical-align: middle;
}
.styles_homepageContainer__cL6dC img,
.styles_homepageContainer__cL6dC svg {
  display: inline-block;
}
.Testimonials_testimonial-area-main__3eI_E
  .Testimonials_quote-wrapper__27vRL
  .Testimonials_quote-container__pi8Vw
  .Testimonials_feedback-wrapper__1QGRM
  .Testimonials_quote__1XoDv {
  width: 760px;
  font-weight: 500;
  font-size: 36px;
  line-height: 39px;
  text-align: center;
  color: #003366;
  margin: 0;
}
.styles_homepageContainer__cL6dC li,
.styles_homepageContainer__cL6dC p {
  line-height: 1.6;
  font-size: 14px;
  font-weight: 400;
}
.styles_homepageContainer__cL6dC li,
.styles_homepageContainer__cL6dC p {
  line-height: 1.6;
  font-size: 14px;
  font-weight: 400;
}
.styles_homepageContainer__cL6dC p {
  margin: 0 0 20px;
}
.tw-items-center {
  align-items: center;
}
.tw-flex {
  display: flex;
}
.Testimonials_testimonial-area-main__3eI_E
  .Testimonials_quote-wrapper__27vRL
  .Testimonials_quote-container__pi8Vw
  .Testimonials_feedback-wrapper__1QGRM
  .Testimonials_testimonial-round-img__twEb8 {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}
.Testimonials_testimonial-area-main__3eI_E
  .Testimonials_quote-wrapper__27vRL
  .Testimonials_quote-container__pi8Vw
  .Testimonials_feedback-wrapper__1QGRM
  .Testimonials_details__157F3 {
  margin-left: 12px;
}
.Testimonials_testimonial-area-main__3eI_E
  .Testimonials_quote-wrapper__27vRL
  .Testimonials_quote-container__pi8Vw
  .Testimonials_feedback-wrapper__1QGRM
  .Testimonials_details__157F3
  h4 {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #01202b;
  position: absolute;
  padding-left: 600px;
}
.Testimonials_testimonial-area-main__3eI_E
  .Testimonials_quote-wrapper__27vRL
  .Testimonials_quote-container__pi8Vw
  .Testimonials_feedback-wrapper__1QGRM
  .Testimonials_details__157F3
  span {
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
.styles_absolute-center__lwtjt {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.styles_play-button__1lRpC {
  width: 55px;
  height: 48px;
  background-color: #fff;
  text-align: center;
  border-radius: 68% 56% 53% 53%;
  transition: 0.4s ease;
  z-index: 994;
  display: block;
}
.Testimonials_quote__1XoDv {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.45px;
  color: #003366;
  margin-top: 16px;
  text-align: justify;
  margin: 8px;
}
@media (min-width: 769px) {
  .NearByCentres_nearByCentresMainContainer__3DRfM {
    max-width: 1018px;
    margin: auto;
    padding: 40px 0;
  }
}
.NearByCentres_nearByCentresMainContainer__3DRfM
  .NearByCentres_nearByCentresHeading__2W9ti {
  font-size: 30px;
  /* font-weight: 600; */
  line-height: 40px;
  text-align: left;
  color: #20211c;
}
.LandingPage_vlcMainPageContainer__36IOy * {
  font-family: Poppins;
}
.NearByCentres_nearByCentresMainContainer__3DRfM
  .NearByCentres_nearByCentresHeading__2W9ti
  .NearByCentres_subHeading__2yOfj {
  color: #003366;
}
.NearByCentres_nearByCentresMainContainer__3DRfM
  .NearByCentres_nearByCentresContainer__2PtmG.NearByCentres_showMore__1ATve {
  max-height: unset;
}
.NearByCentres_nearByCentresMainContainer__3DRfM
  .NearByCentres_nearByCentresContainer__2PtmG {
  display: flex;
  display: -ms-flex;
  display: -o-flex;
  display: -moz-flex;
  align-items: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  flex-wrap: wrap;
  gap: 33px;
  margin-top: 40px;
  max-height: 390px;
  overflow-y: hidden;
  padding: 10px 0;
}
@media (min-width: 769px) {
  .NearByCentres_nearByCentresMainContainer__3DRfM
    .NearByCentres_nearByCentresContainer__2PtmG
    .NearByCentres_centreCard__3c8lc {
    flex-basis: 31.14%;
    height: fit-content;
  }
}
.NearByCentres_nearByCentresMainContainer__3DRfM
  .NearByCentres_nearByCentresContainer__2PtmG
  .NearByCentres_centreCard__3c8lc {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1215686275);
  border-radius: 8px;
  padding: 5px;
}
img,
video {
  max-width: 100%;
  height: auto;
}
.NearByCentres_nearByCentresMainContainer__3DRfM
  .NearByCentres_nearByCentresContainer__2PtmG
  .NearByCentres_centreCard__3c8lc
  .NearByCentres_centreDetails__7iiUm {
  padding: 15px;
}
.NearByCentres_nearByCentresMainContainer__3DRfM
  .NearByCentres_nearByCentresContainer__2PtmG
  .NearByCentres_centreCard__3c8lc
  .NearByCentres_centreDetails__7iiUm
  .NearByCentres_centreName__1MXLs {
  font-size: 16px;
  color: #01202b;
  font-weight: 600;
  text-align: center;
}
@media (min-width: 769px) {
  .NearByCentres_nearByCentresMainContainer__3DRfM
    .NearByCentres_nearByCentresContainer__2PtmG
    .NearByCentres_centreCard__3c8lc
    .NearByCentres_centreDetails__7iiUm
    .NearByCentres_divideLineMweb__3DDyw {
    display: none;
  }
}
.NearByCentres_nearByCentresMainContainer__3DRfM
  .NearByCentres_nearByCentresContainer__2PtmG
  .NearByCentres_centreCard__3c8lc
  .NearByCentres_centreDetails__7iiUm
  .NearByCentres_centreDescription__3gseW {
  display: flex;
  display: -ms-flex;
  display: -o-flex;
  display: -moz-flex;
  align-items: flex-start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  -o-align-items: flex-start;
  margin-top: 10px;
}
.NearByCentres_nearByCentresMainContainer__3DRfM
  .NearByCentres_nearByCentresContainer__2PtmG
  .NearByCentres_centreCard__3c8lc
  .NearByCentres_centreDetails__7iiUm
  .NearByCentres_centreDescription__3gseW
  .NearByCentres_locationImg__3F4iK {
  margin-right: 5px;
  margin-bottom: 18px;
  margin-top: 3px;
}
.NearByCentres_nearByCentresMainContainer__3DRfM
  .NearByCentres_nearByCentresContainer__2PtmG
  .NearByCentres_centreCard__3c8lc
  .NearByCentres_centreDetails__7iiUm
  .NearByCentres_centreDescription__3gseW
  .NearByCentres_address__3b-ip {
  color: #444;
  font-size: 14px;
  font-weight: 400;
  height: 65px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: -o-ellipsis-lastline;
  text-overflow: ellipsis;
  white-space: unset;
}
.NearByCentres_nearByCentresMainContainer__3DRfM
  .NearByCentres_nearByCentresContainer__2PtmG
  .NearByCentres_centreCard__3c8lc
  .NearByCentres_centreDetails__7iiUm
  .NearByCentres_divideLine__X5SEV,
.NearByCentres_nearByCentresMainContainer__3DRfM
  .NearByCentres_nearByCentresContainer__2PtmG
  .NearByCentres_centreCard__3c8lc
  .NearByCentres_centreDetails__7iiUm
  .NearByCentres_divideLineMweb__3DDyw {
  width: 100%;
  height: 2px;
  background-color: #f8f9fa;
  margin: 10px 0;
}
.NearByCentres_nearByCentresMainContainer__3DRfM
  .NearByCentres_nearByCentresContainer__2PtmG
  .NearByCentres_centreCard__3c8lc
  .NearByCentres_centreDetails__7iiUm
  .NearByCentres_visitCentreCta__zyo7J {
  box-shadow: 0 2px 0 0 #f35427;
  color: #fff;
  background-color: #ff693d;
  width: 100%;
  text-align: center;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  padding: 8px 0;
}
@media (min-width: 769px) {
  .NearByCentres_nearByCentresMainContainer__3DRfM
    .NearByCentres_nearByCentresContainer__2PtmG
    .NearByCentres_centreCard__3c8lc
    .NearByCentres_centreDetails__7iiUm
    .NearByCentres_visitCentreCta__zyo7J
    .NearByCentres_viconRight__2qGF3 {
    display: none;
  }
}
.Testimonials_testimonial-area-main__3eI_F .CourseDescription_heading__bOXwW {
  font-size: 30px;
  font-weight: 300;
  color: #20211c;
  font-style: normal;
  position: relative;
  width: -webkit-fit-content;
  /* width: -moz-fit-content; */
  /* width: fit-content; */
  text-align: center;
  margin: 10px;
}
.Testimonials_testimonial-area-main__3eI_F
  .CourseDescription_heading__bOXwW
  span {
  color: #003366;
  font-size: 30px;
}
/* slogan4 */
.homepage-layout .slogan4 {
  /* background-color: #f8f9fa; */
  height: fit-content;
}
.homepage-layout .slogan4 .text-one {
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.9px;
  text-align: center;
  color: #20211c;
  padding-top: 30px;
  margin-bottom: 8px;
}
.homepage-layout .slogan4 .text-two {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #262626;
}
.homepage-layout .slogan4 .text-two span {
  font-weight: normal;
  display: block;
}
.homepage-layout .slogan4 .row {
  display: flex;
  align-items: center;
}
.homepage-layout .slogan4 .row .text-one {
  text-align: left;
  margin-bottom: 22px;
}
.homepage-layout .slogan4 .row .text-two {
  text-align: left;
  max-width: 480px;
}
.homepage-layout .slogan4 .row .col {
  flex-basis: 100%;
  flex-grow: 1;
}
.homepage-layout .slogan4 .row .col:first-child {
  align-items: center;
}
.homepage-layout .slogan4 .row .col:first-child .img-wrapper {
  display: flex;
  width: 80%;
  margin-left: 90px;
}
.homepage-layout .slogan4 .row .col:first-child .img-wrapper .img-wrap {
  flex-grow: 1;
  flex-basis: 100%;
  margin: 24px auto;
  padding-left: 80px;
  overflow: hidden;
}
.homepage-layout .slogan4 .row .col:first-child .img-wrapper .img-wrap img {
  max-width: 100%;
  border-radius: 10px;
  width: 280px;
  height: 120px;
  object-fit: cover;
  border: solid 2.5px #003366;
}
.homepage-layout .slogan4 .row .content-wrap .text-top {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #20211c;
    margin-bottom: 10px;
    text-align: center;
  
}
.homepage-layout .section {
  /* padding: 48px 0; */
  background-color: #f8f9fa;
}
.homepage-layout .section .button-wrap {
  text-align: center;
  margin-top: 40px;
}
.homepage-layout .section .button-wrap a {
  display: inline-block;
  margin: 12px 0;
}
.homepage-layout .section .container {
  max-width: 1078px;
  margin: 0 auto;
}
.homepage-layout .section .slider-wrap {
  margin-top: 42px;
}
.homepage-layout .section .slider-wrap .slick-list {
  padding: 12px;
}
.homepage-layout .section .title-text {
  font-size: 30px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.85px;
  text-align: center;
  color: #20211c;
  margin-bottom: 4px;
}
.homepage-layout .section .title-text span {
  font-weight: bold;
}
.homepage-layout .section .sub-text {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: center;
  color: #20211c;
}
.homepage-layout .section .topics-covered {
  padding: 45px 0;
  max-width: 1040px;
  margin: 0 auto;
}
.homepage-layout .section .topics-covered .title-text {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #35353c;
  margin-bottom: 20px;
  text-align: left;
}
.homepage-layout .section .topics-covered .topics {
  padding: 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}
.homepage-layout .section .topics-covered .topics .topic {
  border-radius: 4px;
  border: solid 0.8px #dee2e6;
  background-color: #ffffff;
  padding: 18px 14px;
  position: relative;
}
.homepage-layout .section .topics-covered .topics .topic:before {
  content: "";
  width: 2px;
  height: 35px;
  border-radius: 1px;
  background-color: #063bff;
  position: absolute;
  left: -2px;
  top: 20px;
}
.homepage-layout .section .topics-covered .topics .topic .text-one {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.4px;
  color: #2b2b2b;
}
.homepage-layout .section .topics-covered .topics .topic .text-two {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.35px;
  color: #20211c;
}
.homepage-layout .section.one .container:first-child {
  margin-bottom: 80px;
}
.homepage-layout .section.two .container {
  max-width: 1080px;
  margin: 0 auto;
}
.homepage-layout .section.two .flex-wrap {
  display: flex;
  margin-top: 36px;
}
.homepage-layout .section.two .flex-wrap > div {
  flex-grow: 1;
  flex-basis: 100%;
}
.homepage-layout .section.two .flex-wrap > div:not(:last-child) {
  margin-right: 20px;
}
.homepage-layout .section.two .flex-wrap > div .img-wrap {
  height: 12px;
  opacity: 0.8;
  margin-bottom: 16px;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
}
.homepage-layout .section.two .flex-wrap > div .img-wrap .play {
  position: absolute;
  width: 31px;
  height: 31px;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
}
.homepage-layout .section.two .flex-wrap > div .img-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.homepage-layout .section.two .flex-wrap > div .content-wrap p {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.45px;
  color: #20211c;
}
.homepage-layout .section.two .flex-wrap > div:not(:last-child) {
  margin-right: 20px;
}
.homepage-layout .section.two .experts-wrap {
  margin: 10px auto;
}
.homepage-layout .section.two .experts-wrap .text {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: center;
  color: #20211c;
}
.homepage-layout .section.two .img-list {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.homepage-layout .section.two .img-list img {
  max-height: 46px;
}
.homepage-layout .section.two .img-list img:not(:last-child) {
  margin-right: 60px;
}

@media screen and (max-width: 768px) {
  .homepage-layout .section.two .experts-wrap .img-list {
    display: grid;
    margin-top: 30px;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
          align-items: start;
  }
  .homepage-layout .section.two .img-list img {
    margin: 10px !important;
    max-height: 82px !important;
    width: 120px;
  }
}

/* for mobile css  works in mobile only*/
@media screen and (max-width: 768px) {
  /* .homepage-layout .Testimonials_testimonial-area-main__3eI_E {
    width: 100%;
    height: fit-content;
  } */
  /* .homepage-layout .Testimonials_quote-container__pi8Vw {
  
      
  } */

  .homepage-layout .Testimonials_quote-wrapper__27vRL  {
    display: none;
  }
  .homepage-layout .Quotation_area_main__3I_E7 .Quotation_quote_wrapper__2BnL {
    background-color: #f8f9fa;
    border: 1.5px solid #eceaea;
    border-radius: 16px;
    height: fit-content;
    margin: 0 auto;
  }
  .homepage-layout .Quotation_area_main__3I_E7 .Quotation_quote_wrapper__2BnL .Quotation_quote_container__nl4Kw {
    padding: 16px;
    display: flex;
    display: -ms-flex;
    display: -o-flex;
    display: -moz-flex;
    flex-direction: column-reverse;
    margin: 10px auto;
    text-align: right;
}
.homepage-layout .Quotation_area_main__3I_E7 .Quotation_quote_wrapper__2BnL .Quotation_quote_container__nl4Kw .img {
  padding-top: 30px;
}
/* .homepage-layout .Quotation_area_main__3I_E7 .Quotation_quote_wrapper__2BnL .Quotation_quote_container__nl4Kw .Testimonials_video-cover-wrapper__19thV {
    position: relative;
    
  } */
  .homepage-layout .Quotation_area_main__3I_E7 .Quotation_quote_wrapper__2BnL .Quotation_quote_container__nl4Kw .Quotation_feed_wrap__1SpRN {
    padding: 0 1px;
    font-weight: 400;
  color: #344e5a;
  margin-top: 30px auto;
  text-align: right;
}
  .homepage-layout .Quotation_area_main__3I_E7 .Quotation_quote_wrapper__2BnL .Quotation_quote_container__nl4Kw .Quotation_feed_wrap__1SpRN .Quotation_quXozy {
    width: 100%;
    font-weight: 400;
    font-size: 25px;
    line-height: 25px;
    text-align: justify;
    color: #19191b;
    margin: 0;
  }
  .homepage-layout .Quotation_area_main__3I_E7 .Quotation_quote_wrapper__2BnL .Quotation_quote_container__nl4Kw .Quotation_feed_wrap__1SpRN .tw-flex {
    padding: 0 1px;
    font-weight: 40px;
    font-size: 30px;
  color: #344e5a;
  margin-top: 30px;
  text-align: right;
}

/* .homepage-layout .Quotation_area_main__3I_E7 .Quotation_quote_wrapper__2BnL .Quotation_quote_container__nl4Kw .tw-flex .Quotation_round_img__twEb8 {
  padding: 0;
  font-weight: 400;
  color: #344e5a;
  margin-top: 50px;
  text-align: right;
} */
.homepage-layout .Quotation_area_main__3I_E7 .Quotation_quote_wrapper__2BnL .Quotation_quote_container__nl4Kw .Quotation_details__157F3 {
  font-weight: 400;
  font-size: 20px;
  color: #344e5a;
  text-align: right;
}
/* .Testimonials_testimonial-area-main__3eI_E .Testimonials_quote-wrapper__27vRL .Testimonials_quote-container__pi8Vw .Testimonials_feedback-wrapper__1QGRM .Testimonials_quote__1XoDv .p {
  width: 803px;
  font-weight: 400;
  font-size: 10px;
  line-height: 9px;
  text-align: center;
  color: #19191b;
  margin: 0;
} */
}

@media screen and (min-width: 768px) {
  .homepage-layout .Quotation_area_main__3I_E7 {
    display: none;
  }
}
/* for mobile css  works in mobile only*/
@media screen and (max-width: 768px) {
  .homepage-layout .slogan4 {
    width: 100%;
    height: fit-content;
  }
  .text-one {
     align-items: center;
  }

  .homepage-layout .slogan4 .row {
    display:flex;
    margin: 0%;
  }

  .homepage-layout .slogan4 .row .col:first-child {
    margin: 5px auto;
  }
 .homepage-layout .slogan4 .row .col:first-child .img-wrapper {
  
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-left: 30px;
      }
  .homepage-layout .slogan4 .row .col:first-child .img-wrapper .img-wrap {
    margin: 10px auto;
    padding-left: 10px;
    overflow: hidden;
  }
  .homepage-layout .slogan4 .row .col:first-child .img-wrapper .img-wrap img {
    max-width: 100%;
    border-radius: 10px;
    border: solid 1.8px #003366;
    width: 180px;
    height: 90px;
    object-fit: cover;
  }
}
/* for desktop css works only desktop */
/* @media screen and (min-width: 768px) {
    .homepage-layout .sloganMobileView {
      display: none;
    }
  } */


  /* Faqss css */

  .Faq_mainContainer__KomTp {
    margin: 52px auto;
    max-width: 1224px;
    width: 100%;
    border-radius: 4px;
    background-color: #fff;
    padding: 0 20px;
}
.Faq_quesans__swHFs .FAQ_SetHeader__GhdP9 {
  font-size: 48px;
  text-align: center;
  font-weight: 600;
}
.Faq_mainContainer__KomTp .FAQ_SetHeader__GhdP9 {
  line-height: 1.33;
  color: #000;
  font-weight: 600;
  font-size: 32px;
  margin: 0 auto 40px;
}
