@import url(~antd/dist/antd.css);
@import url(~slick-carousel/slick/slick-theme.css);
@import url(~slick-carousel/slick/slick.css);
@import url("https://fonts.googleapis.com/css?family=Open+Sans:200,300,400,600,700,800&display=swap");
* {
  box-sizing: border-box; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  font-family: 'Open Sans', sans-serif; }
  html.fixed {
    overflow: hidden; }

body {
  font-family: 'Open Sans', sans-serif; }

.q-button {
  border-radius: 2px;
  border: 0;
  outline: none;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.16px;
  text-align: center;
  color: #3129ea;
  padding: 16px 50px;
  text-transform: uppercase;
  background: #fff;
  cursor: pointer; }
  .q-button.blue {
    background-image: linear-gradient(265deg, #341eea, #1e7eea);
    color: #ffffff; }
  .q-button.blue-bordered {
    border: solid 1px #3129ea; }

.disabled {
  opacity: .3;
  pointer-events: none !important; }

.mb-24 {
  margin-bottom: 24px; }

.mt-24 {
  margin-top: 24px; }

.ant-tooltip {
  z-index: 99; }

.error-message {
  color: #f71e1e;
  margin-top: 8px !important; }

.custom-tab {
  text-align: center; }
  .custom-tab .tabs {
    border-radius: 2px;
    background-color: #eff1f3;
    padding: 3px;
    display: inline-flex;
    position: relative; }
    .custom-tab .tabs .tab-background {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.05) 0px 1px 0px 0px;
      position: absolute;
      top: 3px;
      left: 3px;
      width: calc(50% - 3px);
      height: calc(100% - 6px);
      z-index: 0;
      background: white;
      transition: transform 0.5s cubic-bezier(0, 1, 0, 1) 0s; }
      .custom-tab .tabs .tab-background.trainers, .custom-tab .tabs .tab-background.Group {
        transform: translate(100%); }
    .custom-tab .tabs .tab {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #35353c;
      padding: 14px 32px;
      position: relative;
      margin: 0;
      cursor: pointer;
      display: flex;
      align-items: center; }
      .custom-tab .tabs .tab.active {
        border-radius: 2px;
        color: #063bff;
        padding: 12px 32px; }
  .custom-tab .tab-content {
    margin-top: 40px; }
    .custom-tab .tab-content.with-radio .ant-radio-group {
      display: block;
      width: 100%; }
    .custom-tab .tab-content.with-radio .ant-radio-wrapper {
      border-radius: 8px;
      background-color: #fafbfc;
      display: flex;
      width: 100%;
      margin-bottom: 10px;
      padding: 14px;
      justify-content: flex-start;
      align-items: center; }
      .custom-tab .tab-content.with-radio .ant-radio-wrapper.ant-radio-wrapper-checked {
        border: solid 1px #063bff;
        background-color: #f6f7ff; }
        .custom-tab .tab-content.with-radio .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-inner {
          border-color: #063bff; }
          .custom-tab .tab-content.with-radio .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-inner:after {
            background-color: #063bff; }
      .custom-tab .tab-content.with-radio .ant-radio-wrapper span:last-child {
        flex-grow: 1; }
    .custom-tab .tab-content.with-radio .flex-wrap {
      display: flex;
      align-items: center; }
      .custom-tab .tab-content.with-radio .flex-wrap .desc {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: #2f3a4f;
        padding: 0 10px;
        position: relative; }
        .custom-tab .tab-content.with-radio .flex-wrap .desc.with-border {
          padding-left: 0; }
          .custom-tab .tab-content.with-radio .flex-wrap .desc.with-border:after {
            content: '';
            position: absolute;
            right: 0;
            width: 1px;
            height: 10px;
            background: #d1d3e4;
            top: calc(50% - 5px); }
        .custom-tab .tab-content.with-radio .flex-wrap .desc span {
          display: inline-block;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          color: #7f8fa4; }
      .custom-tab .tab-content.with-radio .flex-wrap .price {
        flex-grow: 1;
        font-size: 18px;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ff6237;
        text-align: right; }

.fixed-info-bar {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  padding: 1rem;
  z-index: 9999;
  background-color: #ffe2c0;
  display: flex;
  align-items: center;
  justify-content: center; }
  .fixed-info-bar .text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2f3a4f; }
  .fixed-info-bar a {
    padding: 7px 36px 5px 38px;
    border-radius: 2px;
    background-color: #4139eb;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin-left: 2rem; }
  .fixed-info-bar .anticon {
    font-size: 1.6rem;
    cursor: pointer;
    color: #2f3a4f;
    right: -4rem;
    position: relative; }

.clickable {
  cursor: pointer; }

@media screen and (max-width: 768px) {
  .ant-tooltip {
    top: 10px !important;
    z-index: 99; }
  .fixed-info-bar a {
    font-size: 1rem;
    padding: 7px 2rem 5px 2rem; }
  .fixed-info-bar .anticon {
    right: 0;
    padding-left: 1rem; } }

/*==================================*/
/*==================================*/
.container {
  max-width: 1080px;
  margin: 0 auto; }

.component-loading {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  font-size: 19.8px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: 0.71px;
  text-align: center;
  color: #2f3a4f; }

.tokenised-header {
  display: flex;
  width: 100%; }
  .tokenised-header .container {
    display: flex;
    width: 100%;
    padding: 24px 0; }
  .tokenised-header .menu {
    display: none; }
  .tokenised-header .img-wrap {
    min-width: 100px; }
    .tokenised-header .img-wrap img {
      max-width: 96px; }
  .tokenised-header .menu-wrap {
    flex-grow: 1;
    text-align: right; }
    .tokenised-header .menu-wrap a {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2f3a50;
      display: inline-flex;
      align-items: center; }
    .tokenised-header .menu-wrap .dp {
      width: 43px;
      height: 43px;
      background-color: #c8ced9;
      border-radius: 100%;
      display: inline-block;
      margin: 0 4px; }
    .tokenised-header .menu-wrap .button {
      display: inline-block;
      font-size: 13px; }

.header {
  display: flex;
  margin-bottom: 32px;
  width: 100%; }
  .header .menu {
    display: none; }
  .header .img-wrap {
    min-width: 100px; }
    .header .img-wrap img {
      max-width: 96px; }
  .header .menu-wrap {
    flex-grow: 1;
    text-align: right; }
    .header .menu-wrap a {
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.23);
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.23);
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      margin-right: 24px;
      text-decoration: none; }
    .header .menu-wrap .button {
      display: inline-block;
      font-size: 13px; }

.footer {
  background-color: #003366;
  padding: 82px;
  text-align: center; }
  .footer .nav.with-margin {
    margin-top: 24px; }
  .footer .nav a {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.13;
    letter-spacing: 2px;
    text-align: center;
    color: #ffffff;
    margin-right: 24px;
    text-decoration: none; }
    .footer .nav a:hover {
      color: #ffffff;
      text-decoration: underline; }
  .footer .text-one {
    opacity: 0.91;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff; }
  .footer .text-two {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff; }
  .footer .social-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 62px 0 84px 0; }
    .footer .social-icons .fb,
    .footer .social-icons .insta,
    .footer .social-icons .linkedin {
      width: 56px;
      height: 56px;
      margin-right: 16px; }
    .footer .social-icons .fb {
      background: url(images/homepage/icon-fb.png) no-repeat;
      background-size: 100% 100%; }
      .footer .social-icons .fb:hover {
        background: url(images/landing/icon-fb-grey.svg) no-repeat;
        background-size: 100% 100%; }
    .footer .social-icons .insta {
      background: url(images/homepage/icon-instagram.png) no-repeat;
      background-size: 100% 100%; }
      .footer .social-icons .insta:hover {
        background: url(images/landing/icon-instagram.png) no-repeat;
        background-size: 100% 100%; }
    .footer .social-icons .linkedin {
      background: url(images/homepage/icon-linkedin.png) no-repeat;
      background-size: 100% 100%; }
      .footer .social-icons .linkedin:hover {
        background: url(images/landing/icon-linkedin.svg) no-repeat;
        background-size: 100% 100%; }
    .footer .social-icons img {
      width: 56px;
      height: 56px;
      object-fit: contain;
      margin-right: 16px; }

.mobile-nav {
  display: none; }

.privacy-policy p {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2f3a4f;
  margin-bottom: 12px; }

.privacy-policy .title-text {
  font-size: 36px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.9px;
  text-align: center;
  color: #2f3a4f;
  margin-bottom: 24px; }

.privacy-policy .header-text {
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #262626; }

.privacy-policy ul {
  padding: 0;
  margin: 0; }
  .privacy-policy ul li {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2f3a4f;
    margin: 0 0 12px 0; }

.authenticated-layout .authenticated-header {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  padding: 12px 20px;
  width: 100%;
  background-color: #ffffff;
  z-index: 99;
  border: solid 1px #f1f1f1; }
  .authenticated-layout .authenticated-header .manage-profile {
    font-size: 1.4rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262626;
    margin-right: 1.2rem; }
  .authenticated-layout .authenticated-header .container {
    align-items: center; }
  .authenticated-layout .authenticated-header .ant-dropdown-link {
    color: #2f3a4f; }
  .authenticated-layout .authenticated-header .logo-wrap {
    margin-right: 24px; }
    .authenticated-layout .authenticated-header .logo-wrap .logo {
      max-height: 50px; }
  .authenticated-layout .authenticated-header .container {
    display: flex;
    width: 100%;
    padding: 0;
    max-width: 100%; }
  .authenticated-layout .authenticated-header .dropdown {
    position: relative;
    margin-right: 3.6rem; }
    .authenticated-layout .authenticated-header .dropdown .ant-select.ant-select-focused .ant-select-selection {
      box-shadow: none; }
    .authenticated-layout .authenticated-header .dropdown .ant-select .ant-select-selection {
      border-radius: 0;
      border: 0;
      background-image: #fff;
      min-width: 18rem;
      max-width: 18rem;
      text-align: right; }
    .authenticated-layout .authenticated-header .dropdown .ant-select .anticon {
      color: #3031ea; }
    .authenticated-layout .authenticated-header .dropdown .ant-select .ant-select-selection-selected-value {
      font-size: 1.5rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: right;
      color: #262626;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .authenticated-layout .authenticated-header .dropdown .add-kid {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
      color: #063bff; }
  .authenticated-layout .authenticated-header .user-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end; }
    .authenticated-layout .authenticated-header .user-wrap .name {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2f3a50;
      margin-right: 12px; }
    .authenticated-layout .authenticated-header .user-wrap .dp {
      width: 43px;
      height: 43px;
      background-image: linear-gradient(135deg, #ff512f, #dd2476);
      border-radius: 100%;
      display: flex;
      margin: 0 4px;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      text-transform: uppercase; }
  .authenticated-layout .authenticated-header .nav-wrap {
    flex-grow: 1;
    justify-content: center;
    display: flex; }
    .authenticated-layout .authenticated-header .nav-wrap a {
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 3.07;
      letter-spacing: normal;
      text-align: center;
      color: #8492a2;
      position: relative; }
      .authenticated-layout .authenticated-header .nav-wrap a.active:after {
        content: '';
        height: 2px;
        background-color: #4139eb;
        position: absolute;
        bottom: -14px;
        left: 0;
        right: 0;
        width: 100%; }
      .authenticated-layout .authenticated-header .nav-wrap a:not(:last-child) {
        margin-right: 24px; }

.authenticated-layout .authenticated-wrapper {
  display: flex;
  width: 100%; }
  .authenticated-layout .authenticated-wrapper .content-wrapper {
    flex-grow: 1; }

.delete-wrap .text {
  font-size: 1.6rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: #525f78;
  opacity: 0.9;
  margin: 0;
  text-align: center;
  margin-bottom: 6rem; }

.delete-wrap .ant-select {
  width: 100%; }

.delete-wrap .buttons-wrap {
  margin-top: 6rem;
  padding: 0 2rem;
  text-align: center;
  display: flex; }
  .delete-wrap .buttons-wrap .q-button {
    flex-grow: 1;
    flex-basis: 100%;
    padding: 1rem 4rem; }
    .delete-wrap .buttons-wrap .q-button:last-child {
      margin-left: 2rem; }

@media screen and (max-width: 768px) {
  .tokenised-header {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.17);
    background-color: #ffffff; }
    .tokenised-header .container {
      padding: 12px 0; }
    .tokenised-header .img-wrap img {
      width: 55px; }
    .tokenised-header .menu-wrap {
      display: none; }
  .header .menu {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 22px;
    cursor: pointer;
    margin-left: 10px; }
  .header .img-wrap {
    margin-left: 12px; }
  .footer {
    padding: 50px 18px 50px 18px; }
    .footer .nav a {
      opacity: 0.91;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      margin-bottom: 12px;
      display: inline-block; }
    .footer .text-two {
      font-size: 10px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.44;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff; }
    .footer .social-icons {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 62px 0 84px 0; }
      .footer .social-icons .fb,
      .footer .social-icons .insta,
      .footer .social-icons .linkedin {
        width: 37px;
        height: 37px;
        margin-right: 10px; }
  .mobile-nav {
    width: 260px;
    box-shadow: 6px 0 14px 0 rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 99;
    height: 100vh;
    transform: translateX(-120%);
    transition: all .3s ease-out;
    text-align: left;
    display: block; }
    .mobile-nav.show {
      transform: translateX(0); }
    .mobile-nav .close {
      width: 64px;
      height: 33px;
      border-radius: 16.5px;
      background-color: #ffffff;
      position: absolute;
      right: -20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 10px;
      top: 18px;
      cursor: pointer; }
    .mobile-nav .img-wrap {
      margin: 0; }
      .mobile-nav .img-wrap img {
        margin: 25px 0 0px 0;
        max-width: 160px; }
    .mobile-nav a {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 3.13;
      letter-spacing: normal;
      color: #2f3a4f;
      letter-spacing: normal;
      padding: 0 20px;
      display: inline-block;
      text-align: left;
      text-decoration: none;
      display: block; }
      .mobile-nav a.active {
        border-left: 2px solid #3229ea; }
      .mobile-nav a .free {
        background: url(images/free-label-mobile.svg) no-repeat;
        width: 36px;
        height: 16px;
        padding-left: 4px;
        background-size: cover;
        background-position: center center;
        display: inline-block;
        font-size: 11px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        text-align: left;
        position: relative; }
    .mobile-nav .button {
      position: absolute;
      bottom: 20px;
      left: 20px;
      font-size: 11.7px !important;
      font-weight: bold !important;
      padding: 12px 10px !important;
      text-transform: uppercase;
      width: calc(100% - 40px); }
  .authenticated-layout {
    background-color: #ffffff; }
    .authenticated-layout .authenticated-wrapper {
      display: block; }
      .authenticated-layout .authenticated-wrapper .content-wrapper > .header-wrap {
        padding: 18px 0; }
        .authenticated-layout .authenticated-wrapper .content-wrapper > .header-wrap div {
          padding-right: 12px; }
          .authenticated-layout .authenticated-wrapper .content-wrapper > .header-wrap div .logo {
            max-width: 58px; }
          .authenticated-layout .authenticated-wrapper .content-wrapper > .header-wrap div .cross {
            max-width: 14px; }
          .authenticated-layout .authenticated-wrapper .content-wrapper > .header-wrap div:first-child {
            padding-left: 12px; }
    .authenticated-layout .authenticated-header {
      padding: 14px 0 11px 0; }
      .authenticated-layout .authenticated-header .dropdown {
        flex-grow: 1;
        text-align: right; }
      .authenticated-layout .authenticated-header .container {
        padding: 0 20px; }
        .authenticated-layout .authenticated-header .container .logo-wrap {
          display: flex;
          align-items: center; }
          .authenticated-layout .authenticated-header .container .logo-wrap img {
            margin-right: 8px;
            max-width: 22px; }
          .authenticated-layout .authenticated-header .container .logo-wrap a img {
            max-width: 55px; }
      .authenticated-layout .authenticated-header .user-wrap {
        display: none;
        flex-grow: 1; }
        .authenticated-layout .authenticated-header .user-wrap .name {
          font-size: 11.2px;
          font-weight: 500; }
        .authenticated-layout .authenticated-header .user-wrap .dp {
          width: 30px;
          height: 30px; }
  .authentication .action-wrapper .content p {
    display: none; }
  .authentication .action-wrapper .content > div {
    display: flex; }
    .authentication .action-wrapper .content > div .link,
    .authentication .action-wrapper .content > div .button {
      flex-basis: 100%;
      flex-grow: 1; }
    .authentication .action-wrapper .content > div .link {
      text-align: left;
      display: block; } }

.about-us .banner-wrapper {
  background: url(images/static/about-banner.webp) no-repeat;
  background-size: cover;
  height: 580px;
  position: relative;
  padding: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end; }
  .about-us .banner-wrapper .container {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end; }
  .about-us .banner-wrapper .text-wrapper {
    width: 571px;
    border-radius: 6px;
    background-image: linear-gradient(297deg, #700aad, #8a3ceb);
    padding: 40px 49px 45px 40px;
    margin-bottom: -60px; }
    .about-us .banner-wrapper .text-wrapper .text {
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      text-align: justify; }
      .about-us .banner-wrapper .text-wrapper .text span {
        font-size: 36px;
        font-weight: 300;
        display: block; }

.about-us .sections {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff); }
  .about-us .sections .text-one {
    font-size: 32px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.9px;
    text-align: center;
    color: #2f3a4f;
    margin-bottom: 8px; }
  .about-us .sections .text-two {
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #262626; }
    .about-us .sections .text-two span {
      font-weight: normal;
      display: block; }
  .about-us .sections .section-one {
    padding: 112px 0 152px 0; }
    .about-us .sections .section-one .text-one {
      margin-bottom: 9px; }
    .about-us .sections .section-one .text-two {
      margin-bottom: 68px;
      max-width: 888px;
      margin: 0 auto;
      margin-bottom: 68px; }
    .about-us .sections .section-one .row1 {
      display: flex; }
      .about-us .sections .section-one .row1 .col {
        flex-basis: 100%;
        flex-grow: 1;
        border-radius: 6px;
        box-shadow: 0 0 34px 0 rgba(0, 0, 0, 0.12);
        background-color: #ffffff;
        padding: 30px 30px 50px 25px;
        position: relative; }
        .about-us .sections .section-one .row1 .col img {
          width: 191px;
          height: 183px;
          object-fit: contain;
          margin-bottom: 24px; }
        .about-us .sections .section-one .row1 .col:after {
          content: '';
          opacity: 0.8;
          background-color: #8c2dfa;
          width: 2px;
          height: 183px;
          position: absolute;
          right: 0;
          top: 30px; }
        .about-us .sections .section-one .row1 .col .text-one {
          font-size: 16px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #262626;
          margin-bottom: 2px;
          text-align: left; }
        .about-us .sections .section-one .row1 .col .text-two {
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.58;
          letter-spacing: normal;
          color: #2f3a4f;
          margin-bottom: 24px;
          text-align: left; }
        .about-us .sections .section-one .row1 .col .text-three {
          font-size: 14.4px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #2f3a4f;
          text-align: justify; }
        .about-us .sections .section-one .row1 .col:not(:last-child) {
          margin-right: 23px; }
  .about-us .sections .section-two {
    margin-bottom: 150px; }
    .about-us .sections .section-two .row2 {
      display: flex;
      margin-left: 40px;
      align-items: center; }
      .about-us .sections .section-two .row2 .col {
        flex-basis: 100%;
        flex-grow: 1;
        border-radius: 6px;
        box-shadow: 0 0 34px 0 rgba(0, 0, 0, 0.12);
        background-color: #ffffff;
        padding: 30px 30px 50px 25px;
        position: relative; }
        .about-us .sections .section-two .row2 .col .text-one {
          text-align: center;
          margin-bottom: 22px; }
        .about-us .sections .section-two .row2 .col .text-two {
          text-align: justify;
          max-width: 100%; }
      .about-us .sections .section-two .row2 .col {
        flex-basis: 100%;
        flex-grow: 1; }
        .about-us .sections .section-two .row2 .col:first-child {
          margin-right: 58px; }
          .about-us .sections .section-two .row2 .col:first-child .img-wrapper {
            display: flex;
            align-items: center; }
            .about-us .sections .section-two .row2 .col:first-child .img-wrapper .img-wrap {
              flex-grow: 1;
              flex-basis: 100%;
              margin: 0 4px 4px 0;
              overflow: hidden; }
              .about-us .sections .section-two .row2 .col:first-child .img-wrapper .img-wrap img {
                max-width: 100%;
                width: 168px;
                height: 160px;
                object-fit: cover; }

.footer {
  padding-top: 200px; }

.register-wrapper {
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0 2px 44px 0 rgba(0, 0, 0, 0.18);
  background-image: linear-gradient(279deg, #dc7dde, #ba49f7);
  padding: 52px;
  max-width: 1080px;
  margin: 0 auto;
  position: relative;
  margin-bottom: -120px; }
  .register-wrapper .text-one {
    font-size: 45px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.18;
    letter-spacing: 1.05px;
    color: #ffffff; }
  .register-wrapper .text-two {
    font-size: 22px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.41;
    letter-spacing: normal;
    color: #ffffff; }
  .register-wrapper .button {
    background-color: #ffffff;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.16px;
    text-align: center;
    color: #341eea;
    padding: 16px 26px;
    position: absolute;
    right: 50px;
    border: 0;
    top: calc(50% - 26px);
    cursor: pointer; }

.select-dropdown {
  position: relative;
  border-radius: 2px;
  border: solid 1px #adb5c6;
  background-color: #ffffff;
  width: auto;
  max-width: 100%;
  width: 100%;
  margin-bottom: 16px; }
  .select-dropdown select {
    color: rgba(53, 64, 82, 0.9);
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    width: 100%;
    padding: 10px 15px;
    border: none;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }
    .select-dropdown select.light {
      color: rgba(53, 64, 82, 0.7); }
    .select-dropdown select option {
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.19;
      letter-spacing: normal;
      color: rgba(53, 64, 82, 0.9);
      padding: 8px 0; }
    .select-dropdown select:active, .select-dropdown select:focus {
      outline: none;
      box-shadow: none; }
  .select-dropdown:after {
    content: " ";
    position: absolute;
    top: 50%;
    margin-top: -2px;
    right: 8px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #3031ea; }

.switch-wrap {
  /**
  * 1. Adjust this to size
  */ }
  .switch-wrap .switch input {
    position: absolute;
    opacity: 0; }
  .switch-wrap .switch {
    display: inline-block;
    font-size: 20px;
    /* 1 */
    height: 1.1em;
    width: 2em;
    background: #002dff;
    border-radius: 1em;
    border: 1px solid #0020b7;
    box-sizing: border-box; }
  .switch-wrap .switch div {
    height: 1em;
    width: 1em;
    border-radius: 1em;
    background: #FFF;
    box-shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.3);
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    transition: all 300ms; }
  .switch-wrap .switch input:checked + div {
    -webkit-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }

@media screen and (max-width: 768px) {
  .about-us .banner-wrapper {
    background: url(images/static/about-banner.webp) no-repeat;
    background-size: cover;
    height: 480px;
    position: relative;
    padding: 16px 12px;
    display: flex;
    align-items: flex-end;
    margin-top: -24px; }
    .about-us .banner-wrapper .text-wrapper {
      margin-bottom: -40px;
      padding: 28px 30px 40px 16px; }
      .about-us .banner-wrapper .text-wrapper .text {
        font-size: 13.2px; }
        .about-us .banner-wrapper .text-wrapper .text span {
          font-size: 20.4px;
          font-weight: 300; }
  .about-us .sections .text-one {
    font-size: 22px; }
  .about-us .sections .text-two {
    font-size: 10px; }
  .about-us .sections .section-one {
    padding: 10px 0 10px 0; }
    .about-us .sections .section-one .text-one,
    .about-us .sections .section-one .text-two {
      padding: 10 10px; }
    .about-us .sections .section-one .text-two {
      margin-bottom: 32px; }
    .about-us .sections .section-one .slick-track {
      display: flex !important;
      padding: 12px 0; }
    .about-us .sections .section-one .slick-slide {
      height: inherit !important;
      margin-left: 12px; }
    .about-us .sections .section-one .row .col {
      flex-basis: 100%;
      flex-grow: 1;
      border-radius: 6px;
      box-shadow: 0 0 34px 0 rgba(0, 0, 0, 0.12);
      background-color: #ffffff;
      padding: 30px 30px 50px 25px;
      position: relative; }
      .about-us .sections .section-one .row .col::after {
        height: 109.8px;
        top: 15px; }
      .about-us .sections .section-one .row .col img {
        width: 114.6px;
        height: 109.8px; }
      .about-us .sections .section-one .row .col .text-one {
        font-size: 13.2px;
        padding: 0; }
      .about-us .sections .section-one .row .col .text-two {
        font-size: 10.8px;
        margin-bottom: 4px;
        padding: 0; }
      .about-us .sections .section-one .row .col .text-three {
        font-size: 14px;
        padding-left: 30px;
        text-align: justify;
        max-width: 100%; }
  .about-us .sections .section-two {
    margin-bottom: 85px; }
    .about-us .sections .section-two .row {
      flex-direction: column; }
      .about-us .sections .section-two .row .col:first-child {
        order: 2;
        margin: 4px; }
        .about-us .sections .section-two .row .col:first-child .img-wrapper {
          align-items: flex-start; }
          .about-us .sections .section-two .row .col:first-child .img-wrapper .img-wrap {
            max-height: 160px;
            overflow: hidden; }
            .about-us .sections .section-two .row .col:first-child .img-wrapper .img-wrap:last-child {
              display: none; }
            .about-us .sections .section-two .row .col:first-child .img-wrapper .img-wrap img {
              width: 100%; }
      .about-us .sections .section-two .row .col:last-child {
        margin-bottom: 24px;
        border: 0; }
      .about-us .sections .section-two .row .col .text-one,
      .about-us .sections .section-two .row .col .text-two {
        text-align: center; }
      .about-us .sections .section-two .row .col .text-wrapper {
        padding: 0 15px; }
  .about-us .global-container {
    width: 100%; }
    .about-us .global-container .section {
      height: fit-content; }
      .about-us .global-container .section .left_head {
        width: 100%; }
        .about-us .global-container .section .left_head .text-24 {
          font-size: 20px; }
        .about-us .global-container .section .left_head .inner {
          font-size: 15px; }
  .register-wrapper {
    padding: 32px 36px;
    text-align: center;
    max-width: calc(100% - 24px); }
    .register-wrapper .text-one {
      font-size: 28px; }
    .register-wrapper .text-two {
      font-size: 13.2px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 2.41; }
    .register-wrapper .button {
      position: relative;
      margin: 14px auto auto;
      left: auto;
      right: auto;
      font-size: 12px; }
  .select-dropdown select {
    font-size: 11.6px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.19;
    letter-spacing: normal;
    color: rgba(53, 64, 82, 0.9); }
    .select-dropdown select option {
      color: rgba(53, 64, 82, 0.9); } }

.contact-us .banner-wrapper {
  background: url(images/static/banner-three.webp) no-repeat;
  background-size: cover;
  height: 580px;
  position: relative;
  padding: 0 100px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end; }
  .contact-us .banner-wrapper .container {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end; }
  .contact-us .banner-wrapper .text-wrapper {
    width: 571px;
    border-radius: 6px;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(120deg, #00d2ff, #3a7bd5);
    padding: 65px 55px 50px 50px;
    margin-bottom: -60px; }
    .contact-us .banner-wrapper .text-wrapper .text {
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.56;
      letter-spacing: normal;
      color: #ffffff; }
      .contact-us .banner-wrapper .text-wrapper .text span {
        font-size: 36px;
        font-weight: 300;
        display: block; }

.contact-us .sections {
  max-width: 710px;
  margin: 140px auto auto; }
  .contact-us .sections .text-one {
    font-size: 36px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.9px;
    text-align: center;
    color: #2f3a4f; }
  .contact-us .sections .text-two {
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #262626; }
    .contact-us .sections .text-two span {
      font-weight: normal;
      display: block; }
  .contact-us .sections .form-wrapper {
    margin: 40px auto 160px auto; }
    .contact-us .sections .form-wrapper .row {
      display: flex; }
      .contact-us .sections .form-wrapper .row.tnc {
        display: block; }
      .contact-us .sections .form-wrapper .row.center {
        justify-content: center; }
      .contact-us .sections .form-wrapper .row .col {
        flex-grow: 1;
        flex-basis: 100%;
        margin-bottom: 16px;
        position: relative; }
        .contact-us .sections .form-wrapper .row .col .ant-tooltip {
          position: absolute;
          right: 12px;
          top: 12px; }
        .contact-us .sections .form-wrapper .row .col:not(:last-child) {
          margin-right: 20px; }
        .contact-us .sections .form-wrapper .row .col input,
        .contact-us .sections .form-wrapper .row .col textarea {
          border-radius: 2px;
          border: solid 1px #adb5c6;
          background-color: #ffffff;
          opacity: 0.9;
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.19;
          letter-spacing: normal;
          color: rgba(53, 64, 82, 0.9);
          width: 100%;
          outline: none;
          padding: 12px 15px; }
          .contact-us .sections .form-wrapper .row .col input.error,
          .contact-us .sections .form-wrapper .row .col textarea.error {
            border: solid 1px red; }
          .contact-us .sections .form-wrapper .row .col input ::-webkit-input-placeholder,
          .contact-us .sections .form-wrapper .row .col textarea ::-webkit-input-placeholder {
            /* Edge */
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.19;
            letter-spacing: normal;
            color: rgba(53, 64, 82, 0.9); }
          .contact-us .sections .form-wrapper .row .col input :-ms-input-placeholder,
          .contact-us .sections .form-wrapper .row .col textarea :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.19;
            letter-spacing: normal;
            color: rgba(53, 64, 82, 0.9); }
          .contact-us .sections .form-wrapper .row .col input ::placeholder,
          .contact-us .sections .form-wrapper .row .col textarea ::placeholder {
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.19;
            letter-spacing: normal;
            color: rgba(53, 64, 82, 0.9); }
          .contact-us .sections .form-wrapper .row .col input:focus,
          .contact-us .sections .form-wrapper .row .col textarea:focus {
            border-radius: 2px;
            border: solid 1px #3228ea;
            background-color: #ffffff; }
        .contact-us .sections .form-wrapper .row .col textarea {
          min-height: 115px; }
    .contact-us .sections .form-wrapper .button {
      border-radius: 2px;
      padding: 16px 68px; }

.contact-us .info-wrapper {
  border-radius: 8px;
  box-shadow: 0 2px 44px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(106deg, #9ab2f9, #5075f9);
  padding: 30px;
  max-width: 1080px;
  margin: 0 auto;
  position: relative;
  margin-bottom: -80px; }
  .contact-us .info-wrapper .row {
    display: flex;
    align-items: flex-start; }
    .contact-us .info-wrapper .row .col {
      text-align: center; }
      .contact-us .info-wrapper .row .col:not(:last-child) {
        margin-right: 32px; }
      .contact-us .info-wrapper .row .col:first-child {
        border-right: solid 2px #bb4039;
        padding: 12px 20px 12px 0;
        min-width: 322px; }
      .contact-us .info-wrapper .row .col > div {
        height: 40px;
        margin-bottom: 22px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .contact-us .info-wrapper .row .col > div img {
          max-height: 100%; }
      .contact-us .info-wrapper .row .col .text-one {
        font-size: 30px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.75px;
        color: #ffffff;
        text-align: left; }
      .contact-us .info-wrapper .row .col .text-two {
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.69;
        letter-spacing: normal;
        color: #ffffff; }
        .contact-us .info-wrapper .row .col .text-two span {
          display: block; }
      .contact-us .info-wrapper .row .col a {
        color: inherit; }

.contact-us .footer {
  padding-top: 200px; }

.tnc .ant-checkbox-wrapper {
  display: flex;
  align-items: flex-start; }
  .tnc .ant-checkbox-wrapper .ant-checkbox + span {
    line-height: 1.2;
    text-align: left; }

@media screen and (max-width: 768px) {
  .contact-us .banner-wrapper {
    background: url(images/static/banner-three-mobile.webp) no-repeat;
    background-size: 100% 100%;
    height: 480px;
    position: relative;
    padding: 16px 12px;
    display: flex;
    align-items: flex-end;
    margin-top: 20px; }
    .contact-us .banner-wrapper .text-wrapper {
      margin-bottom: -40px;
      padding: 28px 30px 40px 16px;
      border-radius: 1.1px; }
      .contact-us .banner-wrapper .text-wrapper .text {
        font-size: 13.2px; }
        .contact-us .banner-wrapper .text-wrapper .text span {
          font-size: 20.4px;
          font-weight: 300; }
  .contact-us .sections {
    max-width: 100%;
    margin: 60px auto auto;
    padding: 0 22px; }
    .contact-us .sections .text-one {
      font-size: 22px; }
    .contact-us .sections .text-two {
      font-size: 10px; }
    .contact-us .sections .form-wrapper {
      margin: 40px auto 48px auto; }
      .contact-us .sections .form-wrapper .row {
        flex-direction: column; }
        .contact-us .sections .form-wrapper .row .col:not(:last-child) {
          margin-right: 0; }
        .contact-us .sections .form-wrapper .row .col input,
        .contact-us .sections .form-wrapper .row .col textarea {
          font-size: 11.6px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.19;
          letter-spacing: normal;
          color: #354052; }
        .contact-us .sections .form-wrapper .row .col textarea {
          min-height: 80px; }
      .contact-us .sections .form-wrapper .select-dropdown select {
        font-size: 11.6px; }
  .contact-us .info-wrapper {
    padding: 36px;
    object-fit: contain;
    border-radius: 4.8px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.3);
    background-image: linear-gradient(153deg, #fba649, #f2534c);
    max-width: calc(100% - 36px); }
    .contact-us .info-wrapper .row {
      flex-direction: column; }
      .contact-us .info-wrapper .row .col {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        width: 100%; }
        .contact-us .info-wrapper .row .col > div {
          margin-bottom: 0; }
        .contact-us .info-wrapper .row .col img {
          max-width: 22px;
          margin: 0 14px 0 0; }
        .contact-us .info-wrapper .row .col:not(:last-child) {
          margin-right: 0; }
        .contact-us .info-wrapper .row .col:first-child {
          border-right: 0;
          padding: 0;
          justify-content: center; }
        .contact-us .info-wrapper .row .col .text-one {
          font-size: 18px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.64px;
          text-align: center;
          color: #ffffff; }
        .contact-us .info-wrapper .row .col .text-two {
          font-size: 12px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 2.41;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff; } }

.codeq .sticky {
  z-index: 999; }

.codeq .banner-wrapper {
  background: url(images/static/banner-four.png) no-repeat;
  background-size: 100% 100%;
  height: 660px;
  position: relative;
  padding: 80px 0 140px 0;
  text-align: center; }
  .codeq .banner-wrapper .inline-img-wrap {
    display: inline-block;
    text-align: center;
    margin: 0 auto 24px auto; }
    .codeq .banner-wrapper .inline-img-wrap img {
      display: inline-block;
      vertical-align: middle; }
      .codeq .banner-wrapper .inline-img-wrap img:not(:last-child) {
        margin-right: 26px; }
      .codeq .banner-wrapper .inline-img-wrap img.ola {
        max-width: 200px; }
      .codeq .banner-wrapper .inline-img-wrap img.paytm {
        max-width: 160px; }
      .codeq .banner-wrapper .inline-img-wrap img.flipkart {
        max-width: 58px; }
  .codeq .banner-wrapper .codeq-logo {
    margin: 22px auto 44px auto; }
  .codeq .banner-wrapper .inline-text-wrapper {
    display: flex;
    align-items: center; }
    .codeq .banner-wrapper .inline-text-wrapper > div {
      flex-basis: 100%;
      flex-grow: 1;
      padding: 0 50px; }
      .codeq .banner-wrapper .inline-text-wrapper > div:first-child {
        border-right: solid 3px rgba(29, 99, 96, 0.26); }
      .codeq .banner-wrapper .inline-text-wrapper > div .text span {
        display: block;
        font-weight: normal; }
  .codeq .banner-wrapper .form-wrapper {
    border-radius: 8px;
    box-shadow: 0 0 44px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 40px 24px;
    display: flex;
    align-items: center;
    margin-top: 72px; }
    .codeq .banner-wrapper .form-wrapper p {
      font-size: 26px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.93px;
      color: #262626;
      margin-right: 15px; }
    .codeq .banner-wrapper .form-wrapper > div {
      position: relative; }
      .codeq .banner-wrapper .form-wrapper > div .ant-tooltip {
        position: absolute;
        right: 28px;
        top: 16px; }
    .codeq .banner-wrapper .form-wrapper input {
      border-radius: 2px;
      border: solid 1px #adb5c6;
      background-color: #ffffff;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.19;
      letter-spacing: normal;
      color: #354052;
      padding: 14px;
      margin-right: 20px;
      min-width: 300px;
      outline: none; }
      .codeq .banner-wrapper .form-wrapper input.error {
        border: 1px solid red; }
    .codeq .banner-wrapper .form-wrapper button {
      padding: 16px 36px;
      border: 0; }
  .codeq .banner-wrapper .title-text {
    margin-bottom: 26px; }

.codeq .sections button {
  border-radius: 2px;
  padding: 15px 52px;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.16px;
  text-align: center;
  color: #ffffff;
  outline: none;
  cursor: pointer;
  border: 0; }

.codeq .sections .title-text {
  font-size: 36px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.9px;
  text-align: center;
  color: #ffffff; }

.codeq .sections .sub-text {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff; }

.codeq .sections .text {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff; }

.codeq .sections .section-one {
  text-align: center;
  padding: 120px 0 90px 0; }
  .codeq .sections .section-one .title-text {
    color: #2f3a4f;
    margin-bottom: 20px; }
  .codeq .sections .section-one .sub-text {
    color: #282828; }
  .codeq .sections .section-one .text {
    color: #2f3a4f; }
  .codeq .sections .section-one .row {
    margin: 90px 0 46px 0;
    display: flex;
    align-items: flex-start; }
    .codeq .sections .section-one .row .col {
      flex-grow: 1;
      flex-basis: 100%; }
      .codeq .sections .section-one .row .col:not(:last-child) {
        margin-right: 54px; }
      .codeq .sections .section-one .row .col .text {
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: #2f3a4f; }
      .codeq .sections .section-one .row .col > div {
        height: 220px;
        margin-bottom: 50px; }
      .codeq .sections .section-one .row .col img {
        max-height: 100%; }
      .codeq .sections .section-one .row .col .sub-text {
        margin-bottom: 11px; }

.codeq .sections .sub-menu-wrap {
  border-radius: 8px;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center; }
  .codeq .sections .sub-menu-wrap a {
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #262626;
    text-decoration: none;
    padding: 28px 0 24px 0;
    position: relative; }
    .codeq .sections .sub-menu-wrap a:not(:last-child) {
      margin-right: 32px; }
    .codeq .sections .sub-menu-wrap a.active {
      color: #2a00e8; }
      .codeq .sections .sub-menu-wrap a.active:after {
        content: '';
        height: 3px;
        border-radius: 1.5px;
        background-color: #2c1fe4;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%; }

.codeq .sections .section-two {
  background-image: linear-gradient(to top, #ffffff, rgba(239, 241, 245, 0.74));
  padding: 67px 0 113px 0; }
  .codeq .sections .section-two .title-text {
    color: #2e2e2e;
    margin-bottom: 12px; }
  .codeq .sections .section-two .sub-text {
    color: #282828; }
  .codeq .sections .section-two .text {
    color: #2f3a4f; }
  .codeq .sections .section-two .row {
    display: flex; }
    .codeq .sections .section-two .row.one {
      margin: 40px 0 80px 0; }
      .codeq .sections .section-two .row.one .col {
        flex-basis: 100%;
        flex-grow: 1;
        border-radius: 5px;
        box-shadow: 0 2px 37px 0 rgba(0, 0, 0, 0.05);
        background-color: #ffffff;
        padding: 40px 20px 24px 16px;
        position: relative; }
        .codeq .sections .section-two .row.one .col img {
          width: 112.6px;
          height: 107.5px;
          object-fit: cover;
          border-radius: 3.4px;
          margin-bottom: 12px; }
        .codeq .sections .section-two .row.one .col:after {
          content: '';
          opacity: 0.8;
          background-color: #f64b89;
          width: 2px;
          height: 107.5px;
          position: absolute;
          right: 0;
          top: 40px; }
        .codeq .sections .section-two .row.one .col .sub-text {
          text-align: left;
          font-size: 16px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #262626; }
        .codeq .sections .section-two .row.one .col .text {
          text-align: left;
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.38;
          letter-spacing: normal;
          color: #2f3a4f; }
        .codeq .sections .section-two .row.one .col:not(:last-child) {
          margin-right: 20px; }
    .codeq .sections .section-two .row.two .col {
      flex-grow: 1;
      flex-basis: 100%;
      padding: 0 60px;
      text-align: center; }
      .codeq .sections .section-two .row.two .col .img-wrap {
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px; }

.codeq .sections .section-three {
  background-color: #2f3a4f;
  padding: 67px 0 0 0; }
  .codeq .sections .section-three .carousel-wrapper .slick-track {
    display: flex !important; }
  .codeq .sections .section-three .carousel-wrapper .slick-slide {
    height: inherit !important; }
  .codeq .sections .section-three .carousel-wrapper button.slick-arrow {
    border-radius: 0;
    border: 0;
    background: #2f3a4f url(images/static/right-arrow.svg) no-repeat;
    color: transparent;
    padding: 0;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.16px;
    text-align: center;
    outline: none;
    cursor: pointer;
    width: 58px;
    height: 58px;
    position: absolute;
    right: 0;
    bottom: 24px;
    background-size: 99% 99%;
    top: auto;
    left: auto; }
    .codeq .sections .section-three .carousel-wrapper button.slick-arrow:before {
      display: none; }
  .codeq .sections .section-three .carousel-wrapper button.slick-prev {
    right: 60px;
    background: #2f3a4f url(images/static/left-arrow.svg) no-repeat;
    background-size: 99% 99%; }
  .codeq .sections .section-three .title-text {
    margin-bottom: 60px; }
  .codeq .sections .section-three .row {
    display: flex;
    align-items: center; }
    .codeq .sections .section-three .row .col {
      flex-grow: 1;
      flex-basis: 100%; }
      .codeq .sections .section-three .row .col:first-child {
        margin-right: 95px;
        text-align: right; }
        .codeq .sections .section-three .row .col:first-child img {
          max-width: 100%;
          display: inline-block; }
      .codeq .sections .section-three .row .col.explorer img {
        max-width: 380px; }
      .codeq .sections .section-three .row .col .text-one {
        font-size: 36px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.9px;
        color: #ffffff;
        margin-bottom: 16px; }
      .codeq .sections .section-three .row .col .text-two {
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.56;
        letter-spacing: normal;
        color: #ffffff;
        margin-bottom: 32px; }
        .codeq .sections .section-three .row .col .text-two span {
          display: block;
          font-weight: bold; }
      .codeq .sections .section-three .row .col button {
        border-radius: 2px;
        background-color: #ffffff;
        background-image: none;
        padding: 16px 66px;
        font-size: 15px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 1.16px;
        text-align: center;
        color: #002dff; }

.codeq .sections .section-four {
  padding: 70px 0; }
  .codeq .sections .section-four .title-text {
    color: #2f3a4f;
    margin-bottom: 18px; }
  .codeq .sections .section-four .sub-text {
    color: #323232; }
  .codeq .sections .section-four .course-selection {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 28px; }
    .codeq .sections .section-four .course-selection p {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #7f8fa4;
      margin-right: 14px; }
    .codeq .sections .section-four .course-selection .select-dropdown {
      max-width: 244px;
      margin: 0; }
  .codeq .sections .section-four .row {
    display: flex;
    margin: 63px 0 83px 0; }
    .codeq .sections .section-four .row .col {
      border-radius: 6px;
      box-shadow: 0 0 26px 0 rgba(0, 0, 0, 0.06);
      background-color: #ffffff;
      flex-grow: 1;
      flex-basis: 100%;
      padding: 36px 24px 26px 24px; }
      .codeq .sections .section-four .row .col.green {
        border-top: 5px solid #06be8e; }
      .codeq .sections .section-four .row .col.purple {
        border-top: 5px solid #7500e8; }
      .codeq .sections .section-four .row .col.red {
        border-top: 5px solid #f71976; }
      .codeq .sections .section-four .row .col.popular {
        background: #063bff;
        transform: scale(1.05); }
        .codeq .sections .section-four .row .col.popular .text-one,
        .codeq .sections .section-four .row .col.popular .text-two,
        .codeq .sections .section-four .row .col.popular .text-three,
        .codeq .sections .section-four .row .col.popular .text-four {
          color: #fff; }
          .codeq .sections .section-four .row .col.popular .text-one span,
          .codeq .sections .section-four .row .col.popular .text-two span,
          .codeq .sections .section-four .row .col.popular .text-three span,
          .codeq .sections .section-four .row .col.popular .text-four span {
            color: #fff; }
      .codeq .sections .section-four .row .col .mb-one {
        margin-bottom: 13px; }
      .codeq .sections .section-four .row .col .mb-two {
        margin-bottom: 16px; }
      .codeq .sections .section-four .row .col .mb-three {
        margin-bottom: 8px; }
      .codeq .sections .section-four .row .col .mb-four {
        margin-bottom: 12px; }
      .codeq .sections .section-four .row .col .text-one {
        font-size: 16px;
        font-weight: 800;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.4px;
        text-align: center;
        color: #2c2c2c;
        text-transform: uppercase; }
      .codeq .sections .section-four .row .col .text-two {
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.4px;
        text-align: center;
        color: #2f3a4f; }
      .codeq .sections .section-four .row .col .text-three {
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.4px;
        text-align: center;
        color: #2f3a4f; }
        .codeq .sections .section-four .row .col .text-three span {
          font-size: 44px;
          font-weight: 300;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 1.25px;
          text-align: center;
          color: #2c1fe4;
          display: block; }
      .codeq .sections .section-four .row .col .text-four {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.35px;
        text-align: center;
        color: #2f3a4f; }
      .codeq .sections .section-four .row .col:not(:last-child) {
        margin-right: 20px; }
  .codeq .sections .section-four button {
    margin: 0 auto;
    display: flex; }

.codeq .sections .section-five {
  padding: 67px 0 60px 0; }
  .codeq .sections .section-five .title-text {
    color: #2f3a4f;
    margin-bottom: 17px; }
  .codeq .sections .section-five .sub-text {
    color: #323232; }
  .codeq .sections .section-five .container {
    max-width: 920px;
    margin: 0 auto; }
  .codeq .sections .section-five .carousel-wrapper .slick-track {
    display: flex !important; }
  .codeq .sections .section-five .carousel-wrapper .slick-slide {
    height: inherit !important; }
  .codeq .sections .section-five .carousel-wrapper button.slick-arrow {
    border-radius: 0;
    border: 0;
    background: url(images/static/arrow-right.svg) no-repeat;
    color: transparent;
    padding: 0;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.16px;
    text-align: center;
    outline: none;
    cursor: pointer;
    width: 104px;
    height: 104px;
    position: absolute;
    right: -100px;
    top: calc(50% - 50px);
    background-size: 99% 99%; }
    .codeq .sections .section-five .carousel-wrapper button.slick-arrow:before {
      display: none; }
  .codeq .sections .section-five .carousel-wrapper button.slick-prev {
    left: -100px;
    background: url(images/static/arrow-left.svg) no-repeat;
    background-size: 99% 99%; }
  .codeq .sections .section-five .row {
    display: flex;
    margin: 0 auto; }
    .codeq .sections .section-five .row .col {
      flex-grow: 1;
      flex-basis: 100%;
      margin-top: 83px; }
      .codeq .sections .section-five .row .col img {
        max-width: 420px;
        display: inline-block; }
      .codeq .sections .section-five .row .col:first-child {
        margin-right: 44px;
        text-align: right; }
      .codeq .sections .section-five .row .col .text-one {
        font-size: 26px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.6px;
        color: #262626;
        padding-left: 42px;
        background: url(images/static/quote.svg) no-repeat;
        background-position: 0 10px;
        margin-bottom: 20px;
        background-size: 20px 20px; }
      .codeq .sections .section-five .row .col .text-two {
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #2f3a4f; }

@media screen and (max-width: 768px) {
  .codeq .sections button {
    font-size: 10.6px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.82px;
    text-align: center;
    color: #ffffff;
    padding: 11px 36px; }
  .codeq .sections .title-text {
    font-size: 24px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.6px;
    text-align: center;
    color: #ffffff; }
  .codeq .sections .sub-text {
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff; }
  .codeq .sections .text {
    font-size: 13.2px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: center;
    color: #2f3a4f; }
  .codeq .sections .banner-wrapper {
    height: auto;
    padding: 40px 5px 80px 5px;
    position: relative; }
    .codeq .sections .banner-wrapper .title-text,
    .codeq .sections .banner-wrapper .sub-text,
    .codeq .sections .banner-wrapper .text {
      color: #fff; }
    .codeq .sections .banner-wrapper .title-text {
      margin-bottom: 0; }
    .codeq .sections .banner-wrapper .inline-img-wrap {
      margin-bottom: 12px; }
      .codeq .sections .banner-wrapper .inline-img-wrap img {
        margin-right: 8px; }
      .codeq .sections .banner-wrapper .inline-img-wrap .ola {
        width: 92px; }
      .codeq .sections .banner-wrapper .inline-img-wrap .paytm {
        width: 74.7px; }
      .codeq .sections .banner-wrapper .inline-img-wrap .flipkart {
        width: 26.2px; }
    .codeq .sections .banner-wrapper .codeq-logo {
      width: 252px; }
    .codeq .sections .banner-wrapper .inline-text-wrapper {
      flex-direction: column; }
      .codeq .sections .banner-wrapper .inline-text-wrapper > div {
        padding: 0 12px; }
        .codeq .sections .banner-wrapper .inline-text-wrapper > div:first-child {
          border: 0;
          margin-bottom: 24px; }
        .codeq .sections .banner-wrapper .inline-text-wrapper > div .text {
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff; }
    .codeq .sections .banner-wrapper .form-wrapper {
      flex-direction: column;
      padding: 32px 22px;
      margin: auto;
      position: absolute;
      bottom: -200px;
      width: calc(100% - 10px); }
      .codeq .sections .banner-wrapper .form-wrapper > div {
        width: 100%; }
      .codeq .sections .banner-wrapper .form-wrapper p {
        margin: 0 0 20px 0;
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.57px;
        color: #262626; }
      .codeq .sections .banner-wrapper .form-wrapper input {
        margin: 0 0 10px 0;
        min-width: 100%;
        font-size: 12.8px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.19;
        letter-spacing: normal;
        color: #354052;
        text-align: center;
        padding: 10px;
        width: 100%; }
  .codeq .sections .section-one {
    padding: 250px 18px 40px 18px; }
    .codeq .sections .section-one.milkbasket {
      padding: 160px 18px 40px 18px; }
    .codeq .sections .section-one .row {
      flex-direction: column;
      margin: 90px 0 46px 0; }
      .codeq .sections .section-one .row .col:not(:last-child) {
        margin-right: 0;
        margin-bottom: 32px; }
      .codeq .sections .section-one .row .col:first-child img {
        max-width: 138px; }
      .codeq .sections .section-one .row .col:nth-child(2) img {
        max-width: 89px; }
      .codeq .sections .section-one .row .col:nth-child(3) img {
        max-width: 153px; }
  .codeq .sections .sub-menu-wrap {
    display: none; }
  .codeq .sections .section-two {
    padding: 67px 20px; }
    .codeq .sections .section-two .sub-text br {
      display: none; }
    .codeq .sections .section-two .carousel-wrapper .slick-track {
      display: flex !important; }
    .codeq .sections .section-two .carousel-wrapper .slick-slide {
      height: inherit !important;
      height: inherit !important;
      margin-right: 12px; }
    .codeq .sections .section-two .row.one {
      margin: 23px 0 64px 0; }
      .codeq .sections .section-two .row.one .col {
        padding: 35px 15px 25px 15px;
        box-shadow: none;
        min-height: 215px; }
        .codeq .sections .section-two .row.one .col:after {
          height: 89px;
          top: 35px; }
        .codeq .sections .section-two .row.one .col .sub-text {
          font-size: 16px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #262626; }
        .codeq .sections .section-two .row.one .col .text {
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.28;
          letter-spacing: normal;
          color: #2f3a4f; }
        .codeq .sections .section-two .row.one .col img {
          width: 93.8px;
          height: 89.6px;
          object-fit: cover;
          border-radius: 2.8px; }
    .codeq .sections .section-two .row.two {
      flex-direction: column; }
      .codeq .sections .section-two .row.two .col {
        margin-bottom: 0;
        padding: 0 20px; }
        .codeq .sections .section-two .row.two .col:first-child {
          margin-bottom: 48px; }
        .codeq .sections .section-two .row.two .col .img-wrap {
          height: auto; }
          .codeq .sections .section-two .row.two .col .img-wrap img {
            max-width: 100%; }
  .codeq .sections .section-three {
    padding-bottom: 120px; }
    .codeq .sections .section-three .title-text {
      margin-bottom: 27px; }
    .codeq .sections .section-three .carousel-wrapper .slick-track {
      display: block !important; }
    .codeq .sections .section-three .carousel-wrapper .slick-slide {
      height: auto !important; }
    .codeq .sections .section-three .carousel-wrapper button.slick-arrow {
      width: 47px;
      height: 47px;
      background-size: 99% 99%;
      right: auto;
      left: 50%;
      margin: auto;
      bottom: -80px;
      top: auto;
      right: auto; }
    .codeq .sections .section-three .carousel-wrapper button.slick-prev {
      background: #2f3a4f url(images/static/left-arrow.svg) no-repeat;
      background-size: 99% 99%;
      left: calc(50% - 50px);
      top: auto;
      right: auto; }
    .codeq .sections .section-three .row {
      flex-direction: column;
      padding: 0 12px; }
      .codeq .sections .section-three .row .col:first-child {
        margin-right: 0;
        margin-bottom: 35px; }
      .codeq .sections .section-three .row .col.explorer img {
        max-width: 100%; }
      .codeq .sections .section-three .row .col .text-one {
        margin-bottom: 12px;
        font-size: 20.8px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.52px;
        color: #ffffff; }
      .codeq .sections .section-three .row .col .text-two {
        font-size: 13.9px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.36;
        letter-spacing: normal;
        color: #ffffff; }
      .codeq .sections .section-three .row .col button {
        font-size: 12px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.93px;
        text-align: center;
        color: #002dff;
        display: flex;
        margin: 0 auto;
        padding: 9px 25px; }
  .codeq .sections .section-four {
    padding: 52px 20px; }
    .codeq .sections .section-four .carousel-wrapper .slick-track {
      display: flex !important; }
    .codeq .sections .section-four .carousel-wrapper .slick-slide {
      height: inherit !important;
      height: inherit !important;
      margin-right: 12px; }
    .codeq .sections .section-four .course-selection {
      margin-top: 18px; }
      .codeq .sections .section-four .course-selection p {
        font-size: 11.9px;
        margin-right: 11.8px; }
      .codeq .sections .section-four .course-selection .select-dropdown {
        max-width: 210px;
        margin: 0; }
    .codeq .sections .section-four .row {
      margin: 31px 0; }
      .codeq .sections .section-four .row .col {
        min-height: 200px; }
        .codeq .sections .section-four .row .col.popular {
          transform: scale(1); }
        .codeq .sections .section-four .row .col .text-one {
          font-size: 13.2px;
          font-weight: 800;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.33px;
          text-align: center;
          color: #2c2c2c; }
        .codeq .sections .section-four .row .col .text-two {
          font-size: 10.8px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.27px;
          text-align: center;
          color: #2f3a4f; }
        .codeq .sections .section-four .row .col .text-three {
          font-size: 10.8px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.27px;
          text-align: center;
          color: #2f3a4f; }
          .codeq .sections .section-four .row .col .text-three span {
            font-size: 30px;
            font-weight: 300;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.75px;
            text-align: center;
            color: #2c1fe4; }
        .codeq .sections .section-four .row .col .text-four {
          font-size: 10.8px; }
        .codeq .sections .section-four .row .col .mb-one {
          margin-bottom: 13px; }
        .codeq .sections .section-four .row .col .mb-two {
          margin-bottom: 10px; }
        .codeq .sections .section-four .row .col .mb-four {
          margin-bottom: 8px; }
  .codeq .sections .section-five {
    padding: 32px 0 40px 0; }
    .codeq .sections .section-five .container {
      max-width: 100%; }
    .codeq .sections .section-five .carousel-wrapper .slick-track {
      display: flex !important; }
    .codeq .sections .section-five .carousel-wrapper .slick-slide {
      height: inherit !important; }
    .codeq .sections .section-five .carousel-wrapper button.slick-arrow {
      right: 0;
      top: 180px;
      bottom: auto;
      left: auto;
      z-index: 9;
      bottom: auto; }
      .codeq .sections .section-five .carousel-wrapper button.slick-arrow:before {
        display: none; }
    .codeq .sections .section-five .carousel-wrapper button.slick-prev {
      left: 0;
      right: auto; }
    .codeq .sections .section-five .row {
      flex-direction: column;
      padding: 0 20px;
      margin-top: 18px; }
      .codeq .sections .section-five .row .col {
        margin: 0; }
        .codeq .sections .section-five .row .col img {
          max-width: 100%;
          width: 100%; }
        .codeq .sections .section-five .row .col:first-child {
          margin: 0 0 22px 0; }
        .codeq .sections .section-five .row .col .text-one {
          font-size: 16.8px;
          font-weight: 300;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.39px;
          color: #262626; }
        .codeq .sections .section-five .row .col .text-two {
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #2f3a4f; } }

.lingoq .sticky {
  z-index: 999; }

.lingoq .banner-wrapper {
  background: url(images/lingoq/banner-old.png) no-repeat;
  background-size: cover;
  height: 630px;
  position: relative;
  padding: 0;
  text-align: center; }
  .lingoq .banner-wrapper.study {
    background: url(images/lingoq/banner.png) no-repeat;
    background-size: cover;
    height: 630px;
    position: relative;
    padding: 0;
    text-align: center; }
    .lingoq .banner-wrapper.study .content-wrapper .text-one {
      font-size: 40px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.23;
      letter-spacing: normal;
      color: #1e174a;
      margin-bottom: 12px; }
  .lingoq .banner-wrapper .list-wrap {
    border-radius: 6px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 48px 74px;
    position: relative;
    display: flex;
    align-items: flex-start;
    top: 18px; }
    .lingoq .banner-wrapper .list-wrap .list {
      flex-grow: 1;
      flex-basis: 100%;
      text-align: center; }
      .lingoq .banner-wrapper .list-wrap .list:not(:last-child) {
        margin-right: 60px; }
      .lingoq .banner-wrapper .list-wrap .list img {
        margin-bottom: 24px; }
      .lingoq .banner-wrapper .list-wrap .list .text-one {
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.32;
        letter-spacing: normal;
        color: #262626;
        margin-bottom: 7px; }
      .lingoq .banner-wrapper .list-wrap .list .text-two {
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: #2f3a4f; }
  .lingoq .banner-wrapper .container {
    width: 100%;
    height: 100%; }
  .lingoq .banner-wrapper .courses-wrapper .text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.79;
    letter-spacing: normal;
    color: #1e174a;
    text-align: left;
    margin-bottom: 6px; }
  .lingoq .banner-wrapper .courses-wrapper .row {
    display: flex; }
    .lingoq .banner-wrapper .courses-wrapper .row:last-child {
      margin-bottom: 16px; }
    .lingoq .banner-wrapper .courses-wrapper .row:not(:last-child) {
      margin-bottom: 10px; }
    .lingoq .banner-wrapper .courses-wrapper .row .col {
      flex-grow: 1;
      flex-basis: 100%;
      border-radius: 4px;
      box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.04);
      background-color: #ffffff;
      padding: 16px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: center;
      color: #1e174a;
      max-width: 172px; }
      .lingoq .banner-wrapper .courses-wrapper .row .col:not(:last-child) {
        margin-right: 10px; }
  .lingoq .banner-wrapper .content-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: calc(100% - 60px); }
    .lingoq .banner-wrapper .content-wrapper > div {
      display: inline-block;
      max-width: 520px;
      text-align: left; }
    .lingoq .banner-wrapper .content-wrapper .label-text {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.79;
      letter-spacing: normal;
      color: #1e174a; }
    .lingoq .banner-wrapper .content-wrapper .button {
      border-radius: 1.4px;
      box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.15);
      background-color: #4922f3;
      background-image: none;
      margin-top: 18px; }
    .lingoq .banner-wrapper .content-wrapper .text-one {
      font-size: 40px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.23;
      letter-spacing: normal;
      color: #fff;
      margin-bottom: 12px; }
    .lingoq .banner-wrapper .content-wrapper img {
      max-width: 100%;
      margin: 12px 0; }
    .lingoq .banner-wrapper .content-wrapper .text-two {
      font-size: 22px;
      font-weight: 400;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff; }
    .lingoq .banner-wrapper .content-wrapper .desc-wrap {
      border-radius: 4px;
      background-color: rgba(34, 43, 61, 0.44);
      display: flex;
      padding: 28px 0; }
      .lingoq .banner-wrapper .content-wrapper .desc-wrap > div {
        flex-grow: 1;
        flex-basis: 100%;
        text-align: center;
        padding: 0 32px; }
        .lingoq .banner-wrapper .content-wrapper .desc-wrap > div:first-child {
          border-right: solid 1px rgba(22, 29, 74, 0.46); }
        .lingoq .banner-wrapper .content-wrapper .desc-wrap > div .button {
          border-radius: 12.4px;
          background-color: #ff6237;
          padding: 4px 15px;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff;
          margin-top: 12px;
          display: inline-block;
          cursor: default; }
        .lingoq .banner-wrapper .content-wrapper .desc-wrap > div p {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff; }
        .lingoq .banner-wrapper .content-wrapper .desc-wrap > div p.bold {
          font-size: 22px;
          font-weight: 800;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff;
          line-height: 1; }
          .lingoq .banner-wrapper .content-wrapper .desc-wrap > div p.bold.large {
            font-size: 30px; }
        .lingoq .banner-wrapper .content-wrapper .desc-wrap > div .mt-4 {
          margin-top: 4px; }
        .lingoq .banner-wrapper .content-wrapper .desc-wrap > div .inline-flex {
          display: inline-flex;
          align-items: flex-start; }
          .lingoq .banner-wrapper .content-wrapper .desc-wrap > div .inline-flex.bottom {
            align-items: flex-end; }
        .lingoq .banner-wrapper .content-wrapper .desc-wrap > div .cancelled {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff;
          text-decoration: line-through; }
        .lingoq .banner-wrapper .content-wrapper .desc-wrap > div .denomination {
          font-size: 12px;
          font-weight: normal !important;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff;
          margin-right: 2px; }
  .lingoq .banner-wrapper .form-wrapper {
    border-radius: 8px;
    box-shadow: 0 0 44px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 40px 24px;
    display: flex;
    align-items: center;
    margin-top: 0; }
    .lingoq .banner-wrapper .form-wrapper p {
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.64px;
      color: #262626;
      margin-right: 12px; }
    .lingoq .banner-wrapper .form-wrapper .form-elements {
      display: flex;
      align-items: center;
      flex-grow: 1; }
      .lingoq .banner-wrapper .form-wrapper .form-elements > div {
        position: relative;
        flex-grow: 1;
        flex-basis: 100%; }
        .lingoq .banner-wrapper .form-wrapper .form-elements > div:not(:last-child) input {
          border-right: 0; }
        .lingoq .banner-wrapper .form-wrapper .form-elements > div .ant-tooltip {
          position: absolute;
          right: 12px;
          top: 12px; }
      .lingoq .banner-wrapper .form-wrapper .form-elements input {
        width: 100%;
        outline: none; }
    .lingoq .banner-wrapper .form-wrapper input {
      border-radius: 2px;
      border: solid 1px #adb5c6;
      background-color: #ffffff;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.19;
      letter-spacing: normal;
      color: #354052;
      padding: 14px;
      margin-right: 0;
      min-width: 200px; }
      .lingoq .banner-wrapper .form-wrapper input.error {
        border: 1px solid red !important; }
    .lingoq .banner-wrapper .form-wrapper button {
      padding: 16px 36px;
      border: 0;
      margin-left: 12px; }
  .lingoq .banner-wrapper .title-text {
    margin-bottom: 26px; }

.lingoq .sections button {
  border-radius: 2px;
  padding: 15px 52px;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.16px;
  text-align: center;
  color: #ffffff;
  outline: none;
  cursor: pointer;
  border: 0; }

.lingoq .sections .title-text {
  font-size: 36px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.9px;
  text-align: center;
  color: #2e2e2e; }

.lingoq .sections .sub-text {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2e2e2e; }

.lingoq .sections .text {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2e2e2e; }

.lingoq .sections .section-one {
  text-align: center;
  padding: 140px 0 80px 0; }
  .lingoq .sections .section-one.study {
    padding: 240px 0 80px 0; }
  .lingoq .sections .section-one .title-text {
    color: #2f3a4f;
    margin-bottom: 20px; }
  .lingoq .sections .section-one .sub-text {
    color: #282828; }
  .lingoq .sections .section-one .text {
    color: #2f3a4f; }
  .lingoq .sections .section-one .row {
    margin: 90px 0 46px 0;
    display: flex;
    align-items: flex-start; }
    .lingoq .sections .section-one .row .col {
      flex-grow: 1;
      flex-basis: 100%; }
      .lingoq .sections .section-one .row .col:not(:last-child) {
        margin-right: 20px; }
      .lingoq .sections .section-one .row .col .text {
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #2f3a4f; }
      .lingoq .sections .section-one .row .col img {
        max-width: 100%;
        margin-bottom: 18px; }
      .lingoq .sections .section-one .row .col .sub-text {
        margin-bottom: 11px; }

.lingoq .sections .sub-menu-wrap {
  border-radius: 8px;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center; }
  .lingoq .sections .sub-menu-wrap a {
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #262626;
    text-decoration: none;
    padding: 28px 0 24px 0;
    position: relative; }
    .lingoq .sections .sub-menu-wrap a:not(:last-child) {
      margin-right: 32px; }
    .lingoq .sections .sub-menu-wrap a.active {
      color: #2a00e8; }
      .lingoq .sections .sub-menu-wrap a.active:after {
        content: '';
        height: 3px;
        border-radius: 1.5px;
        background-color: #2c1fe4;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%; }

.lingoq .sections .section-two {
  padding: 48px 0 0 0; }
  .lingoq .sections .section-two .row {
    display: flex; }
    .lingoq .sections .section-two .row:first-child {
      margin-top: 56px; }
    .lingoq .sections .section-two .row:last-child {
      margin-top: 24px;
      justify-content: center; }
    .lingoq .sections .section-two .row .col {
      flex-grow: 1;
      flex-basis: 100%; }
      .lingoq .sections .section-two .row .col:not(:last-child) {
        margin-right: 24px; }
      .lingoq .sections .section-two .row .col .img-wrap {
        max-height: 90px;
        text-align: center;
        margin-bottom: 18px; }
        .lingoq .sections .section-two .row .col .img-wrap img {
          max-height: 100%; }
      .lingoq .sections .section-two .row .col .text-one {
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #2f3a4f;
        margin-bottom: 5px; }
      .lingoq .sections .section-two .row .col .text-two {
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #2f3a4f; }

.lingoq .sections .baloon-section {
  background: url(images/lingoq/img-five.png) no-repeat;
  background-size: 100% 100%;
  padding: 67px 0 0 0;
  height: 680px;
  width: 100%;
  margin-top: 56px; }
  .lingoq .sections .baloon-section .baloon-row {
    display: flex;
    max-width: 780px;
    width: 100%;
    margin: 60px auto auto; }
    .lingoq .sections .baloon-section .baloon-row .text {
      font-size: 13px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      text-transform: uppercase; }
    .lingoq .sections .baloon-section .baloon-row .col {
      position: relative; }
      .lingoq .sections .baloon-section .baloon-row .col .text {
        position: absolute;
        top: 50px;
        margin: 0 auto; }
      .lingoq .sections .baloon-section .baloon-row .col:nth-child(1) {
        margin-right: 90px;
        top: 80px; }
        .lingoq .sections .baloon-section .baloon-row .col:nth-child(1) img {
          width: 140px;
          object-fit: contain; }
      .lingoq .sections .baloon-section .baloon-row .col:nth-child(2) {
        top: 40px;
        margin-right: 150px; }
        .lingoq .sections .baloon-section .baloon-row .col:nth-child(2) img {
          width: 155px;
          object-fit: contain; }
      .lingoq .sections .baloon-section .baloon-row .col:nth-child(3) {
        top: 100px;
        margin-right: 0; }
        .lingoq .sections .baloon-section .baloon-row .col:nth-child(3) .text {
          left: 20px; }
        .lingoq .sections .baloon-section .baloon-row .col:nth-child(3) img {
          width: 126px;
          object-fit: contain; }
      .lingoq .sections .baloon-section .baloon-row .col:nth-child(4) {
        top: 200px; }
        .lingoq .sections .baloon-section .baloon-row .col:nth-child(4) .text {
          left: 28px; }
        .lingoq .sections .baloon-section .baloon-row .col:nth-child(4) img {
          width: 126px;
          object-fit: contain; }

.lingoq .sections .section-three {
  padding: 100px 0; }
  .lingoq .sections .section-three .row {
    display: flex;
    margin: 63px 0 0 0;
    justify-content: center; }
    .lingoq .sections .section-three .row .col {
      border-radius: 5.1px;
      box-shadow: 0 0 22px 0 rgba(0, 0, 0, 0.06);
      background-color: #ffffff;
      max-width: 460px;
      padding: 46px 40px 60px 40px; }
      .lingoq .sections .section-three .row .col .text-one {
        font-size: 22px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.09;
        letter-spacing: normal;
        text-align: center;
        color: #2f3a4f;
        margin-bottom: 35px; }
      .lingoq .sections .section-three .row .col img {
        max-width: 200px;
        display: block;
        margin: 0 auto; }
      .lingoq .sections .section-three .row .col:not(:last-child) {
        margin-right: 32px; }
      .lingoq .sections .section-three .row .col.red .content-wrapper .key-value:nth-child(1) .value .line .percent {
        width: 40%; }
      .lingoq .sections .section-three .row .col.red .content-wrapper .key-value:nth-child(2) .value .line .percent {
        width: 35%; }
      .lingoq .sections .section-three .row .col.red .content-wrapper .key-value:nth-child(3) .value .line .percent {
        width: 20%; }
      .lingoq .sections .section-three .row .col.red .content-wrapper .key-value:nth-child(4) .value .line .percent {
        width: 25%; }
      .lingoq .sections .section-three .row .col.red .content-wrapper .key-value:nth-child(5) .value .line .percent {
        width: 10%; }
      .lingoq .sections .section-three .row .col.red .content-wrapper .key-value:nth-child(6) .value .line .percent {
        width: 45%; }
      .lingoq .sections .section-three .row .col.red .content-wrapper .key-value .value .line .percent {
        background-image: linear-gradient(to left, #ff512f, #dd2457); }
      .lingoq .sections .section-three .row .col.green .content-wrapper .key-value:nth-child(1) .value .line .percent {
        width: 100%; }
      .lingoq .sections .section-three .row .col.green .content-wrapper .key-value:nth-child(2) .value .line .percent {
        width: 100%; }
      .lingoq .sections .section-three .row .col.green .content-wrapper .key-value:nth-child(3) .value .line .percent {
        width: 100%; }
      .lingoq .sections .section-three .row .col.green .content-wrapper .key-value:nth-child(4) .value .line .percent {
        width: 100%; }
      .lingoq .sections .section-three .row .col.green .content-wrapper .key-value:nth-child(5) .value .line .percent {
        width: 100%; }
      .lingoq .sections .section-three .row .col.green .content-wrapper .key-value:nth-child(6) .value .line .percent {
        width: 100%; }
      .lingoq .sections .section-three .row .col.green .content-wrapper .key-value .value .line .percent {
        background-image: linear-gradient(to left, #06be8e, #2acf18);
        display: block; }
      .lingoq .sections .section-three .row .col .content-wrapper {
        margin-top: 52px; }
        .lingoq .sections .section-three .row .col .content-wrapper .key-value {
          display: flex;
          align-items: center;
          margin-bottom: 18px; }
          .lingoq .sections .section-three .row .col .content-wrapper .key-value .key {
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.4px;
            color: #2f3a4f;
            flex-grow: 1; }
          .lingoq .sections .section-three .row .col .content-wrapper .key-value .value {
            flex-grow: 1;
            min-width: 235px;
            max-width: 235px;
            margin-left: 24px; }
            .lingoq .sections .section-three .row .col .content-wrapper .key-value .value .line {
              position: relative;
              height: 8px;
              border-radius: 8px;
              background-color: #eeeeee; }
              .lingoq .sections .section-three .row .col .content-wrapper .key-value .value .line .percent {
                height: 8px;
                border-radius: 8px;
                width: 40%;
                display: block; }
  .lingoq .sections .section-three button {
    margin: 0 auto;
    display: flex; }

.lingoq .sections .section-four {
  background-color: #2f3a4f;
  padding: 44px 0 56px 0; }
  .lingoq .sections .section-four .title-text {
    color: #fff; }
  .lingoq .sections .section-four button {
    border-radius: 2px;
    background-color: #ffffff;
    padding: 16px 67px;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.16px;
    text-align: center;
    color: #002dff;
    border: 0;
    outline: none;
    background-image: none;
    margin: 62px auto auto;
    display: flex; }
  .lingoq .sections .section-four .row {
    display: flex;
    margin-top: 55px; }
    .lingoq .sections .section-four .row .col {
      flex-grow: 1;
      flex-basis: 100%; }
      .lingoq .sections .section-four .row .col:not(:last-child) {
        margin-right: 50px; }
      .lingoq .sections .section-four .row .col .img-wrap {
        height: 270px;
        text-align: center;
        margin-bottom: 24px; }
        .lingoq .sections .section-four .row .col .img-wrap img {
          max-height: 100%; }
      .lingoq .sections .section-four .row .col .text-one {
        font-size: 20px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.32;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        margin-bottom: 14px; }
      .lingoq .sections .section-four .row .col .text-two {
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff; }

.lingoq .sections .section-five {
  padding: 70px 0; }
  .lingoq .sections .section-five .title-text {
    color: #2f3a4f;
    margin-bottom: 38px; }
  .lingoq .sections .section-five .sub-text {
    color: #323232; }
  .lingoq .sections .section-five .course-selection {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 28px; }
    .lingoq .sections .section-five .course-selection p {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #7f8fa4;
      margin-right: 14px; }
    .lingoq .sections .section-five .course-selection .select-dropdown {
      max-width: 244px;
      margin: 0; }
  .lingoq .sections .section-five .sub-text {
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #2e2e2e;
    display: flex;
    justify-content: center; }
    .lingoq .sections .section-five .sub-text .switch-wrap {
      margin: 0 8px;
      display: inline-flex; }
      .lingoq .sections .section-five .sub-text .switch-wrap > div {
        border-radius: 2px;
        border: solid 1px #ced6e4;
        background-color: #ffffff;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #2e2e2e;
        padding: 9px 24px;
        cursor: pointer; }
        .lingoq .sections .section-five .sub-text .switch-wrap > div.active {
          border-radius: 2px;
          box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.28);
          background-color: #002dff;
          color: #ffffff; }
  .lingoq .sections .section-five .row {
    display: flex;
    justify-content: center;
    margin: 20px 0 83px 0; }
    .lingoq .sections .section-five .row .col {
      border-radius: 5.1px;
      box-shadow: 0 0 22px 0 rgba(0, 0, 0, 0.06);
      background-color: #ffffff;
      flex-grow: 1;
      flex-basis: 100%;
      padding: 36px 24px 26px 24px;
      max-width: 260px;
      position: relative;
      z-index: 2;
      text-align: center; }
      .lingoq .sections .section-five .row .col.popular {
        background: #063bff;
        transform: scale(1.05); }
        .lingoq .sections .section-five .row .col.popular .text-one,
        .lingoq .sections .section-five .row .col.popular .text-two,
        .lingoq .sections .section-five .row .col.popular .text-three,
        .lingoq .sections .section-five .row .col.popular .text-four {
          color: #fff; }
          .lingoq .sections .section-five .row .col.popular .text-one span,
          .lingoq .sections .section-five .row .col.popular .text-two span,
          .lingoq .sections .section-five .row .col.popular .text-three span,
          .lingoq .sections .section-five .row .col.popular .text-four span {
            color: #fff; }
      .lingoq .sections .section-five .row .col .mb-one {
        margin-bottom: 13px; }
      .lingoq .sections .section-five .row .col .mb-two {
        margin-bottom: 16px; }
      .lingoq .sections .section-five .row .col .mb-three {
        margin-bottom: 8px; }
      .lingoq .sections .section-five .row .col .mb-four {
        margin-bottom: 12px; }
      .lingoq .sections .section-five .row .col .text-one {
        font-size: 22px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.55px;
        text-align: center;
        color: #2f3a4f; }
      .lingoq .sections .section-five .row .col .text-two {
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.4px;
        text-align: center;
        color: #2f3a4f; }
      .lingoq .sections .section-five .row .col .text-three {
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: 0.4px;
        text-align: center;
        color: #2f3a4f;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        line-height: 1; }
        .lingoq .sections .section-five .row .col .text-three span {
          font-size: 36px;
          font-style: normal;
          font-stretch: normal;
          letter-spacing: 1.25px;
          text-align: center;
          color: #2c1fe4;
          display: block;
          line-height: 1; }
        .lingoq .sections .section-five .row .col .text-three .denomination {
          font-size: 16px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: 0.4px;
          text-align: center;
          color: #2c1fe4;
          display: inline-block;
          line-height: 1;
          margin-top: 4px; }
      .lingoq .sections .section-five .row .col .text-four {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.35px;
        text-align: center;
        color: #2f3a4f; }
      .lingoq .sections .section-five .row .col .text-five {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.35px;
        text-align: center;
        color: #2c2c2c; }
      .lingoq .sections .section-five .row .col .text-six {
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #2c2c2c;
        text-decoration: line-through;
        display: flex;
        align-items: flex-start;
        justify-content: center; }
        .lingoq .sections .section-five .row .col .text-six .denomination {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #2c2c2c;
          margin: 2px 2px 0 0; }
      .lingoq .sections .section-five .row .col:not(:last-child) {
        margin-right: 20px; }
      .lingoq .sections .section-five .row .col .button {
        border-radius: 12.4px;
        background-color: #ff6237;
        padding: 4px 15px;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        margin-bottom: 9px;
        display: inline-block;
        cursor: default; }
      .lingoq .sections .section-five .row .col .line {
        border-bottom: solid 1px rgba(82, 95, 120, 0.1);
        margin: 12px auto 10px auto;
        width: calc(100% - 60px); }
  .lingoq .sections .section-five button {
    margin: 0 auto;
    display: flex; }

@media screen and (max-width: 768px) {
  .lingoq .sections button {
    font-size: 10.6px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.82px;
    text-align: center;
    color: #ffffff;
    padding: 11px 36px; }
  .lingoq .sections .title-text {
    font-size: 24px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.6px;
    text-align: center; }
  .lingoq .sections .sub-text {
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center; }
  .lingoq .sections .text {
    font-size: 13.2px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: center;
    color: #2f3a4f; }
  .lingoq .sections .banner-wrapper {
    height: auto;
    padding: 30px 15px 60px 15px;
    position: relative;
    background: url(images/lingoq/banner-mobile-old.png) no-repeat;
    background-size: 100% 100%;
    background-position: center -24px;
    text-align: left; }
    .lingoq .sections .banner-wrapper.study {
      height: auto;
      padding: 30px 15px 60px 15px;
      position: relative;
      background: url(images/lingoq/banner-mobile.png) no-repeat;
      background-size: 100% 100%;
      background-position: center -24px;
      text-align: left; }
      .lingoq .sections .banner-wrapper.study .container .content-wrapper > div {
        text-align: left; }
      .lingoq .sections .banner-wrapper.study .container .content-wrapper .text-one {
        text-align: left; }
    .lingoq .sections .banner-wrapper .courses-wrapper .row .col {
      font-size: 10.1px;
      line-height: 1.43;
      padding: 12px;
      max-width: 124px; }
    .lingoq .sections .banner-wrapper .list-wrap {
      flex-direction: column;
      padding: 26px 30px;
      position: absolute;
      top: 90%;
      margin: auto;
      left: 6px;
      right: 6px; }
      .lingoq .sections .banner-wrapper .list-wrap .list {
        display: flex;
        justify-content: center;
        text-align: left;
        width: 100%; }
        .lingoq .sections .banner-wrapper .list-wrap .list img {
          margin-right: 15px;
          width: 26px;
          height: 24px;
          object-fit: contain; }
        .lingoq .sections .banner-wrapper .list-wrap .list > div {
          flex-grow: 1; }
          .lingoq .sections .banner-wrapper .list-wrap .list > div .text-one {
            font-size: 14px;
            font-weight: 500;
            text-align: left;
            margin-bottom: 5px; }
          .lingoq .sections .banner-wrapper .list-wrap .list > div .text-two {
            font-size: 11.2px;
            font-weight: normal;
            text-align: left; }
        .lingoq .sections .banner-wrapper .list-wrap .list:not(:last-child) {
          margin: 0 0 16px 0; }
    .lingoq .sections .banner-wrapper .container .content-wrapper > div {
      text-align: center; }
    .lingoq .sections .banner-wrapper .container .content-wrapper .text-one {
      font-size: 24px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.6px;
      text-align: center;
      color: #ffffff; }
    .lingoq .sections .banner-wrapper .container .content-wrapper .text-two {
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff; }
    .lingoq .sections .banner-wrapper .container .content-wrapper .text {
      font-size: 10px; }
    .lingoq .sections .banner-wrapper .container .content-wrapper img {
      max-width: 240px; }
    .lingoq .sections .banner-wrapper .container .content-wrapper .desc-wrap {
      border-radius: 3.2px;
      padding: 20px 0;
      margin-bottom: 28px; }
      .lingoq .sections .banner-wrapper .container .content-wrapper .desc-wrap > div {
        padding: 0 24px; }
        .lingoq .sections .banner-wrapper .container .content-wrapper .desc-wrap > div:first-child {
          border: 0; }
        .lingoq .sections .banner-wrapper .container .content-wrapper .desc-wrap > div p {
          font-size: 12px; }
        .lingoq .sections .banner-wrapper .container .content-wrapper .desc-wrap > div p.bold {
          font-size: 14px; }
          .lingoq .sections .banner-wrapper .container .content-wrapper .desc-wrap > div p.bold.large {
            font-size: 24px; }
        .lingoq .sections .banner-wrapper .container .content-wrapper .desc-wrap > div .denomination {
          font-size: 9.6px; }
        .lingoq .sections .banner-wrapper .container .content-wrapper .desc-wrap > div .cancelled {
          font-size: 12.8px; }
    .lingoq .sections .banner-wrapper .content-wrapper {
      display: block;
      height: auto; }
    .lingoq .sections .banner-wrapper .label-text {
      font-size: 10px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      color: #ffffff;
      text-align: left; }
    .lingoq .sections .banner-wrapper .title-text,
    .lingoq .sections .banner-wrapper .sub-text,
    .lingoq .sections .banner-wrapper .text {
      color: #fff; }
    .lingoq .sections .banner-wrapper .title-text {
      margin-bottom: 0; }
    .lingoq .sections .banner-wrapper .form-wrapper {
      flex-direction: column;
      padding: 32px 22px;
      margin: auto;
      position: absolute;
      width: calc(100% - 20px);
      left: 10px; }
      .lingoq .sections .banner-wrapper .form-wrapper p {
        margin: 0 0 20px 0;
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.57px;
        color: #262626; }
      .lingoq .sections .banner-wrapper .form-wrapper .form-elements {
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin: 0;
        border: 0; }
        .lingoq .sections .banner-wrapper .form-wrapper .form-elements > div {
          width: 100%;
          margin-bottom: 12px; }
        .lingoq .sections .banner-wrapper .form-wrapper .form-elements:not(:last-child) div input {
          border: solid 0.8px #adb5c6; }
        .lingoq .sections .banner-wrapper .form-wrapper .form-elements input {
          width: 100%;
          border-radius: 1.6px;
          border: solid 0.8px #adb5c6; }
      .lingoq .sections .banner-wrapper .form-wrapper input {
        margin: 0 0 10px 0;
        min-width: 100%;
        font-size: 12.8px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.19;
        letter-spacing: normal;
        color: #354052;
        text-align: center;
        padding: 10px;
        margin-bottom: 1px; }
      .lingoq .sections .banner-wrapper .form-wrapper button {
        margin-top: 18px; }
  .lingoq .sections .section-one {
    padding: 280px 18px 40px 18px; }
    .lingoq .sections .section-one.study {
      padding: 250px 18px 40px 18px; }
    .lingoq .sections .section-one br {
      display: none; }
    .lingoq .sections .section-one .row {
      flex-direction: column;
      margin: 90px 0 46px 0; }
      .lingoq .sections .section-one .row .col:not(:last-child) {
        margin-right: 0;
        margin-bottom: 32px; }
    .lingoq .sections .section-one button {
      width: 100%;
      font-size: 15px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1.16px;
      text-align: center;
      color: #ffffff;
      padding: 16px; }
  .lingoq .sections .sub-menu-wrap {
    display: none; }
  .lingoq .sections .section-two {
    padding: 0 0 67px 0; }
    .lingoq .sections .section-two .sub-text br {
      display: none; }
    .lingoq .sections .section-two .title-text,
    .lingoq .sections .section-two .sub-text {
      padding: 0 24px; }
    .lingoq .sections .section-two .row {
      flex-direction: column;
      padding: 0 20px; }
      .lingoq .sections .section-two .row .col {
        display: flex;
        align-items: flex-start;
        margin-bottom: 26px; }
        .lingoq .sections .section-two .row .col:nth-child(2) .img-wrap, .lingoq .sections .section-two .row .col:nth-child(4) .img-wrap {
          order: 2;
          margin: 0 0 0 24px; }
        .lingoq .sections .section-two .row .col:nth-child(2) .text-wrap .text-one,
        .lingoq .sections .section-two .row .col:nth-child(2) .text-wrap .text-two, .lingoq .sections .section-two .row .col:nth-child(4) .text-wrap .text-one,
        .lingoq .sections .section-two .row .col:nth-child(4) .text-wrap .text-two {
          text-align: right; }
        .lingoq .sections .section-two .row .col .img-wrap {
          margin-right: 21px;
          margin-bottom: 0;
          height: 60px; }
        .lingoq .sections .section-two .row .col .text-wrap {
          text-align: left; }
          .lingoq .sections .section-two .row .col .text-wrap .text-one,
          .lingoq .sections .section-two .row .col .text-wrap .text-two {
            text-align: left; }
        .lingoq .sections .section-two .row .col:not(:last-child) {
          margin-right: 0; }
    .lingoq .sections .section-two .baloon-section {
      height: 474px;
      background-size: cover;
      background-position: center center; }
      .lingoq .sections .section-two .baloon-section .baloon-row .col {
        margin: 0;
        position: relative; }
        .lingoq .sections .section-two .baloon-section .baloon-row .col .text {
          font-size: 7px;
          position: absolute;
          top: 30px;
          margin: 0 auto; }
        .lingoq .sections .section-two .baloon-section .baloon-row .col:nth-child(1) {
          margin-right: 10px;
          top: 0; }
          .lingoq .sections .section-two .baloon-section .baloon-row .col:nth-child(1) img {
            width: 70px;
            object-fit: contain; }
        .lingoq .sections .section-two .baloon-section .baloon-row .col:nth-child(2) {
          top: 60px;
          margin-right: 10px; }
          .lingoq .sections .section-two .baloon-section .baloon-row .col:nth-child(2) img {
            width: 70px;
            object-fit: contain; }
        .lingoq .sections .section-two .baloon-section .baloon-row .col:nth-child(3) {
          top: 40px;
          margin-right: 10px; }
          .lingoq .sections .section-two .baloon-section .baloon-row .col:nth-child(3) .text {
            left: 11px; }
          .lingoq .sections .section-two .baloon-section .baloon-row .col:nth-child(3) img {
            width: 70px;
            object-fit: contain; }
        .lingoq .sections .section-two .baloon-section .baloon-row .col:nth-child(4) {
          top: 90px; }
          .lingoq .sections .section-two .baloon-section .baloon-row .col:nth-child(4) .text {
            left: 16px; }
          .lingoq .sections .section-two .baloon-section .baloon-row .col:nth-child(4) img {
            width: 70px;
            object-fit: contain; }
  .lingoq .sections .section-three {
    padding: 0 0 44px 0; }
    .lingoq .sections .section-three .title-text {
      margin-bottom: 10px; }
    .lingoq .sections .section-three .row {
      flex-direction: column;
      padding: 0 12px; }
      .lingoq .sections .section-three .row .col {
        padding: 30px 25px 35px 25px;
        border-radius: 3.2px;
        box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.06);
        background-color: #ffffff; }
        .lingoq .sections .section-three .row .col:first-child {
          margin-right: 0;
          margin-bottom: 20px; }
        .lingoq .sections .section-three .row .col .text-one {
          font-size: 13.9px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.09;
          letter-spacing: normal;
          text-align: center;
          color: #2f3a4f;
          margin-bottom: 20px; }
        .lingoq .sections .section-three .row .col img {
          max-width: 128px; }
        .lingoq .sections .section-three .row .col .content-wrapper .key-value .key {
          font-size: 10.1px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.25px;
          color: #2f3a4f;
          min-width: 70px;
          max-width: 70px; }
        .lingoq .sections .section-three .row .col .content-wrapper .key-value .value {
          min-width: auto;
          max-width: 100%;
          flex-grow: 1; }
          .lingoq .sections .section-three .row .col .content-wrapper .key-value .value .line {
            height: 5px; }
            .lingoq .sections .section-three .row .col .content-wrapper .key-value .value .line .percent {
              height: 100%; }
  .lingoq .sections .section-four {
    padding: 52px 20px; }
    .lingoq .sections .section-four .row {
      margin: 31px 0;
      flex-direction: column; }
      .lingoq .sections .section-four .row .col:not(:last-child) {
        margin: 0 0 32px 0; }
      .lingoq .sections .section-four .row .col .img-wrap {
        margin-bottom: 18px;
        height: auto; }
      .lingoq .sections .section-four .row .col .text-one {
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.32;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        margin-bottom: 10px; }
      .lingoq .sections .section-four .row .col .text-two {
        font-size: 12.8px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff; }
      .lingoq .sections .section-four .row .col .text-three {
        font-size: 10.8px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.27px;
        text-align: center;
        color: #2f3a4f; }
        .lingoq .sections .section-four .row .col .text-three span {
          font-size: 30px;
          font-weight: 300;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.75px;
          text-align: center;
          color: #2c1fe4; }
      .lingoq .sections .section-four .row .col .text-four {
        font-size: 10.8px; }
      .lingoq .sections .section-four .row .col .mb-one {
        margin-bottom: 13px; }
      .lingoq .sections .section-four .row .col .mb-two {
        margin-bottom: 10px; }
      .lingoq .sections .section-four .row .col .mb-four {
        margin-bottom: 8px; }
    .lingoq .sections .section-four button {
      display: none; }
  .lingoq .sections .section-five {
    padding: 52px 20px; }
    .lingoq .sections .section-five .carousel-wrapper .slick-track {
      display: flex !important; }
    .lingoq .sections .section-five .carousel-wrapper .slick-list {
      padding-bottom: 12px; }
    .lingoq .sections .section-five .carousel-wrapper .slick-slide {
      height: inherit !important;
      height: inherit !important;
      margin-right: 12px; }
    .lingoq .sections .section-five .course-selection {
      margin-top: 18px; }
      .lingoq .sections .section-five .course-selection p {
        font-size: 11.9px;
        margin-right: 11.8px; }
      .lingoq .sections .section-five .course-selection .select-dropdown {
        max-width: 210px;
        margin: 0; }
    .lingoq .sections .section-five .row {
      margin: 31px 0; }
      .lingoq .sections .section-five .row .col {
        min-height: 240px; }
        .lingoq .sections .section-five .row .col .text-one {
          font-size: 16.8px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.42px; }
        .lingoq .sections .section-five .row .col .text-two {
          font-size: 10.8px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.27px;
          text-align: center;
          color: #2f3a4f; }
        .lingoq .sections .section-five .row .col .text-three {
          font-size: 10.8px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.27px;
          text-align: center;
          color: #2f3a4f; }
          .lingoq .sections .section-five .row .col .text-three .denomination {
            margin-top: 8px; }
          .lingoq .sections .section-five .row .col .text-three span {
            font-size: 30px;
            font-weight: 300;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.75px;
            text-align: center;
            color: #2c1fe4; }
        .lingoq .sections .section-five .row .col .text-four {
          font-size: 10.8px; }
        .lingoq .sections .section-five .row .col .text-five {
          font-size: 10.7px; }
        .lingoq .sections .section-five .row .col .text-six {
          font-size: 13.8px; }
        .lingoq .sections .section-five .row .col .mb-one {
          margin-bottom: 13px; }
        .lingoq .sections .section-five .row .col .mb-two {
          margin-bottom: 10px; }
        .lingoq .sections .section-five .row .col .mb-four {
          margin-bottom: 8px; } }

.modal-window {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  transition: all 0.3s;
  display: flex;
  overflow-y: auto; }
  .modal-window:target {
    visibility: visible;
    opacity: 1;
    pointer-events: auto; }
  .modal-window .update-number-otp {
    width: 500px !important; }
  .modal-window .confirm-booking {
    width: 500px !important;
    padding: 60px 60px 30px 9px !important; }
    .modal-window .confirm-booking .modal-header-update .phone-title-text {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #262626;
      margin-left: 193px; }
    .modal-window .confirm-booking .btn {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-end; }
      .modal-window .confirm-booking .btn .btn-white button {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        margin-right: 20px;
        padding: 0.6rem 1.2rem;
        border-radius: 2px;
        background-color: #ffffff;
        color: #4139eb; }
      .modal-window .confirm-booking .btn .btn-blue button {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        margin-right: 20px;
        padding: 0.6rem 1.2rem;
        border-radius: 2px;
        color: #ffffff;
        background-color: #4139eb; }
    .modal-window .confirm-booking .booking-confirm-card {
      position: relative;
      max-width: 56rem;
      background-color: #ffffff;
      color: #7f8fa4;
      border-radius: 0.8rem;
      padding: 20px 78px 20px 19px; }
      .modal-window .confirm-booking .booking-confirm-card .classTime-card-container {
        display: flex; }
        .modal-window .confirm-booking .booking-confirm-card .classTime-card-container .classTime-card {
          margin: 7px 10px 6px 0px;
          padding: 8px 13px 9px 10px;
          border-radius: 2px;
          border: solid 1px #d1d8e1;
          background-color: #ffffff; }
          .modal-window .confirm-booking .booking-confirm-card .classTime-card-container .classTime-card .header-text {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #7f8fa4; }
      .modal-window .confirm-booking .booking-confirm-card .class-selcted {
        display: flex;
        flex-direction: column;
        justify-content: center; }
        .modal-window .confirm-booking .booking-confirm-card .class-selcted .classSelected-text {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #2f3a4f;
          margin-top: 19px; }
      .modal-window .confirm-booking .booking-confirm-card .heading-selcted {
        display: flex;
        flex-direction: column;
        justify-content: center; }
        .modal-window .confirm-booking .booking-confirm-card .heading-selcted .selected-text {
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #2f3a4f;
          font-size: 12px;
          margin-bottom: 7px; }
      .modal-window .confirm-booking .booking-confirm-card .name-header {
        display: flex;
        margin-bottom: 18px;
        align-items: center; }
        .modal-window .confirm-booking .booking-confirm-card .name-header .heading-name {
          display: flex;
          flex-direction: column;
          justify-content: center; }
          .modal-window .confirm-booking .booking-confirm-card .name-header .heading-name .header-text {
            margin-left: 6px;
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            margin-bottom: 2px; }
  .modal-window .update-number {
    width: 500px !important; }
  .modal-window .otp-container {
    margin-top: 26px; }
    .modal-window .otp-container .inline-error {
      display: inline-block;
      margin-top: 4px;
      color: #ed1c24; }
    .modal-window .otp-container .varify-label {
      font-size: 10px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #627386;
      margin-bottom: 6px; }
    .modal-window .otp-container .q-button {
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.16px;
      text-align: center;
      cursor: pointer;
      background-image: linear-gradient(265deg, #341eea, #1e7eea);
      color: #fff;
      text-transform: uppercase;
      outline: none;
      border-radius: 2px;
      margin: 0 auto;
      display: block; }
    .modal-window .otp-container input {
      border-radius: 2px;
      border: 1px solid #7c8390;
      background-color: #fff;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
      color: #363e4c;
      width: 100%;
      outline: none;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      padding: 12px 15px;
      margin-top: 6px; }
      .modal-window .otp-container input.error {
        border: 1px solid #ed1c24;
        color: #ed1c24; }
    .modal-window .otp-container .helper-text {
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #262626;
      font-size: 12px;
      margin: 9px 0 31px; }
      .modal-window .otp-container .helper-text span {
        color: #4139eb;
        cursor: pointer; }
  .modal-window .input-container {
    margin-top: 26px; }
    .modal-window .input-container .inline-error {
      display: inline-block;
      margin-top: 4px;
      color: #ed1c24; }
    .modal-window .input-container .helper-text {
      margin: 10px 0 0;
      font-size: 10px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #627386; }
    .modal-window .input-container button {
      position: absolute;
      border-radius: 5px;
      right: 34px;
      z-index: 2;
      border: none;
      top: 107px;
      cursor: pointer;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4139eb; }
      .modal-window .input-container button.disabled {
        opacity: 0.3; }
    .modal-window .input-container .input-with-flag {
      position: relative; }
      .modal-window .input-container .input-with-flag span {
        position: absolute;
        left: 12px;
        top: 13px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: normal;
        color: #363e4c;
        padding-right: 4px;
        border-right: 1px solid #adb5c6; }
        .modal-window .input-container .input-with-flag span img {
          width: 22px;
          margin-right: 6px; }
      .modal-window .input-container .input-with-flag input {
        border-radius: 2px;
        border: 1px solid #7c8390;
        background-color: #fff;
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: normal;
        color: #363e4c;
        padding: 15px;
        width: 100%;
        outline: none;
        padding-left: 76px;
        margin-top: 1.6px; }
        .modal-window .input-container .input-with-flag input.error {
          border: 1px solid #ed1c24;
          color: #ed1c24; }
        .modal-window .input-container .input-with-flag input:focus {
          border: solid 1px #7c8390;
          color: #363e4c; }
  .modal-window .modal-header-update {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9;
    display: flex;
    align-items: center;
    /* position: relative; */
    padding: 19px 14px 18px 14px;
    border-radius: 4px;
    box-shadow: 0 1px 0 0 #f2f4f6;
    background-color: #ffffff;
    border: solid 1px #d4dbe3; }
    .modal-window .modal-header-update .phone-title-text {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #262626; }
  .modal-window .modal-content {
    border-radius: 2px;
    box-shadow: 0 0 44px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    position: relative;
    width: 500px;
    height: auto;
    padding: 60px 60px 30px 60px;
    background: #ffffff;
    z-index: 1;
    margin: auto; }
    .modal-window .modal-content.card-detail-view {
      padding: 20px 20px 20px 20px;
      width: 700px;
      background: transparent; }
      .modal-window .modal-content.card-detail-view .modal-close {
        right: 40px;
        top: 40px;
        z-index: 9; }
      .modal-window .modal-content.card-detail-view .modal-body {
        border-radius: 2px;
        background-color: #f5f4f8; }
    .modal-window .modal-content .buttons-wrap {
      display: flex;
      justify-content: center;
      margin-top: 30px; }
      .modal-window .modal-content .buttons-wrap button {
        padding: 8px 30px;
        font-weight: 400;
        font-size: 12px; }
        .modal-window .modal-content .buttons-wrap button:not(:last-child) {
          margin-right: 7px; }
    .modal-window .modal-content .sub-text {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #262626; }
    .modal-window .modal-content .link-type {
      text-align: center;
      display: block;
      margin-top: 5px;
      font-size: 13px; }
    .modal-window .modal-content .content {
      padding-bottom: 32px; }
  .modal-window .calendar-popup {
    max-width: 300px;
    padding: 24px 12px; }
    .modal-window .calendar-popup .content {
      padding: 0; }
    .modal-window .calendar-popup .name,
    .modal-window .calendar-popup .email,
    .modal-window .calendar-popup .timing {
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.32;
      letter-spacing: normal;
      text-align: center;
      color: #262626;
      text-align: left;
      margin-bottom: 4px;
      display: flex; }
      .modal-window .calendar-popup .name span,
      .modal-window .calendar-popup .email span,
      .modal-window .calendar-popup .timing span {
        font-weight: 300;
        display: inline-block;
        margin-right: 4px;
        margin-left: 8px; }
    .modal-window .calendar-popup .footer-wrap {
      display: flex;
      margin-top: 24px; }
      .modal-window .calendar-popup .footer-wrap li {
        flex-grow: 1;
        flex-basis: 100%; }
        .modal-window .calendar-popup .footer-wrap li .link {
          border-radius: 2px;
          background: #3129ea;
          font-size: 10px;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 1.16px;
          text-align: center;
          color: #ffffff;
          padding: 8px 12px;
          border: 0;
          cursor: pointer; }
        .modal-window .calendar-popup .footer-wrap li:first-child {
          margin-right: 12px; }
          .modal-window .calendar-popup .footer-wrap li:first-child .link {
            background: #fff;
            border: 1px solid #3129ea;
            color: #3129ea; }
  .modal-window .tnc {
    text-align: center;
    max-width: 100%; }
    .modal-window .tnc .error-message {
      text-align: left; }
    .modal-window .tnc .button {
      margin-top: 32px; }
    .modal-window .tnc .sub-text {
      font-weight: normal;
      margin-top: 8px; }
      .modal-window .tnc .sub-text.small {
        font-size: 14px; }
  .modal-window .thankyou-modal {
    width: 500px;
    height: 300px; }
    .modal-window .thankyou-modal > div {
      text-align: center;
      width: 100%; }
    .modal-window .thankyou-modal .title-text {
      font-size: 45px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1.13px;
      text-align: center;
      color: #2e2e2e; }
    .modal-window .thankyou-modal .sub-text {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #262626;
      margin-top: 12px; }
    .modal-window .thankyou-modal button {
      margin-top: 32px; }
  .modal-window .video-player,
  .modal-window .teaching-video {
    width: 75%;
    background: transparent;
    box-shadow: none; }
    .modal-window .video-player .video-container,
    .modal-window .teaching-video .video-container {
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 30px;
      height: 0;
      overflow: hidden;
      width: 100%; }
    .modal-window .video-player .video-container iframe,
    .modal-window .video-player .video-container object,
    .modal-window .video-player .video-container embed,
    .modal-window .teaching-video .video-container iframe,
    .modal-window .teaching-video .video-container object,
    .modal-window .teaching-video .video-container embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  .modal-window .book-demo {
    width: 960px;
    padding: 100px 58px 136px 58px; }
    .modal-window .book-demo .content-wrapper {
      display: flex;
      align-items: center;
      width: 100%; }
      .modal-window .book-demo .content-wrapper .course {
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px; }
        .modal-window .book-demo .content-wrapper .course span {
          font-weight: 500;
          color: #000;
          display: block;
          margin-bottom: 8px; }
      .modal-window .book-demo .content-wrapper button {
        border-radius: 2px;
        background-image: linear-gradient(266deg, #341eea, #1e7eea);
        font-size: 15px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 1.16px;
        text-align: center;
        color: #ffffff;
        width: 100%; }
      .modal-window .book-demo .content-wrapper > div {
        flex-grow: 1;
        flex-basis: 100%; }
        .modal-window .book-demo .content-wrapper > div:first-child {
          margin-right: 80px; }
          .modal-window .book-demo .content-wrapper > div:first-child .col {
            margin-bottom: 16px;
            position: relative; }
            .modal-window .book-demo .content-wrapper > div:first-child .col .ant-tooltip {
              position: absolute;
              right: 12px;
              top: 12px; }
        .modal-window .book-demo .content-wrapper > div .title-text {
          font-size: 36px;
          font-weight: 300;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.9px;
          text-align: center;
          color: #2f3a4f;
          margin-bottom: 24px; }
        .modal-window .book-demo .content-wrapper > div ::-webkit-input-placeholder {
          /* Edge */
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.19;
          letter-spacing: normal;
          color: rgba(53, 64, 82, 0.9); }
        .modal-window .book-demo .content-wrapper > div :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.19;
          letter-spacing: normal;
          color: rgba(53, 64, 82, 0.9); }
        .modal-window .book-demo .content-wrapper > div ::placeholder {
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.19;
          letter-spacing: normal;
          color: rgba(53, 64, 82, 0.9); }
        .modal-window .book-demo .content-wrapper > div input {
          border-radius: 2px;
          border: solid 1px #adb5c6;
          background-color: #ffffff;
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.19;
          letter-spacing: normal;
          color: rgba(53, 64, 82, 0.9);
          padding: 12px 15px 12px 15px;
          width: 100%;
          outline: none; }
          .modal-window .book-demo .content-wrapper > div input.error {
            border: solid 1px red; }
        .modal-window .book-demo .content-wrapper > div:last-child img {
          max-width: 420px; }
  .modal-window .info-modal {
    width: 500px;
    min-height: 300px;
    text-align: center;
    justify-content: center; }
    .modal-window .info-modal .green-tick {
      margin-bottom: 26px;
      width: 48px;
      height: 48px; }
    .modal-window .info-modal .lingoq-logo {
      margin-bottom: 26px;
      width: 199px;
      height: 99px; }
    .modal-window .info-modal > div {
      display: inline-block; }
    .modal-window .info-modal .text-one {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #262626;
      margin-bottom: 32px; }
    .modal-window .info-modal .info-bar {
      display: inline-block;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #262626;
      padding: 9px 25px; }
      .modal-window .info-modal .info-bar span {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #262626;
        display: block;
        margin-top: 8px; }
    .modal-window .info-modal .button {
      border-radius: 2px;
      background-image: linear-gradient(265deg, #341eea 99%, #1e7eea -8%);
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.16px;
      text-align: center;
      color: #ffffff;
      outline: none;
      text-transform: uppercase;
      padding: 16px 94px;
      margin-top: 70px;
      display: inline-block; }
  .modal-window .text-center {
    text-align: center;
    width: 100%; }
  .modal-window .info-modal-lingoq {
    width: 800px;
    height: 500px;
    min-height: 300px;
    justify-content: center;
    background: url(images/backGroundPage.png) no-repeat;
    background-repeat: repeat;
    position: relative;
    background-size: cover; }
    .modal-window .info-modal-lingoq .green-tick {
      margin-bottom: 26px;
      width: 48px;
      height: 48px; }
    .modal-window .info-modal-lingoq .lingoq-logo {
      margin-bottom: 26px;
      width: 199px;
      height: 99px; }
    .modal-window .info-modal-lingoq > div {
      display: inline-block; }
    .modal-window .info-modal-lingoq .text-one {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #2E2E319C;
      margin-bottom: 42px; }
    .modal-window .info-modal-lingoq .info-bar {
      display: inline-block;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #262626;
      padding: 9px 25px; }
      .modal-window .info-modal-lingoq .info-bar span {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #262626;
        display: block;
        margin-top: 8px; }
    .modal-window .info-modal-lingoq .button {
      border-radius: 2px;
      background-image: linear-gradient(265deg, #341eea 99%, #1e7eea -8%);
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.16px;
      text-align: center;
      color: #ffffff;
      outline: none;
      text-transform: uppercase;
      padding: 16px 94px;
      margin-top: 70px;
      display: inline-block; }
    .modal-window .info-modal-lingoq .orange {
      background: #ff5b13;
      color: #ffffff;
      border: solid 1px #ff5b13;
      margin-bottom: 10px; }
  .modal-window .reschedule-modal {
    width: 760px; }
    .modal-window .reschedule-modal > div {
      width: 100%; }
    .modal-window .reschedule-modal .content {
      width: 100%; }
      .modal-window .reschedule-modal .content .datepick {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px; }
      .modal-window .reschedule-modal .content .form-field {
        margin-bottom: 20px; }
        .modal-window .reschedule-modal .content .form-field .ant-select-selection {
          height: auto;
          padding: 0.4rem 0;
          border: solid 1px #ced0da;
          background-image: linear-gradient(to top, #f2f4f7, #ffffff); }
        .modal-window .reschedule-modal .content .form-field .ant-select {
          min-width: 50%; }
        .modal-window .reschedule-modal .content .form-field label {
          display: block;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #262626;
          margin-bottom: 20px; }
    .modal-window .reschedule-modal .text {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #262626;
      margin-bottom: 24px; }
    .modal-window .reschedule-modal .ant-calendar-picker {
      min-width: 50%;
      margin-bottom: 24px; }
      .modal-window .reschedule-modal .ant-calendar-picker:hover input {
        border: solid 1px #ced0da; }
      .modal-window .reschedule-modal .ant-calendar-picker input {
        border-radius: 4px;
        border: solid 1px #ced0da;
        background-image: linear-gradient(to top, #f2f4f7, #ffffff);
        padding: 10px 12px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #262626;
        width: 100%;
        outline: none;
        height: auto; }
        .modal-window .reschedule-modal .ant-calendar-picker input:focus {
          box-shadow: none; }
    .modal-window .reschedule-modal .available-slots-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 20px; }
    .modal-window .reschedule-modal .time-slot {
      border-radius: 2px;
      border: solid 1px #adb5c6;
      background-color: #ffffff;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #262626;
      padding: 10px;
      cursor: pointer; }
      .modal-window .reschedule-modal .time-slot.selected {
        border: solid 1px #363ed6;
        background-color: #edeef7;
        color: #363ed6; }
    .modal-window .reschedule-modal .label {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #7f8fa4;
      margin-bottom: 6px; }
      .modal-window .reschedule-modal .label.error {
        color: #f53636; }
      .modal-window .reschedule-modal .label span {
        color: #f53636; }
    .modal-window .reschedule-modal .sub-text {
      text-align: left;
      margin-bottom: 20px; }
    .modal-window .reschedule-modal .info-text {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.58;
      letter-spacing: normal;
      color: #525f78;
      margin-top: 12px;
      text-align: center; }
    .modal-window .reschedule-modal .buttons-wrap {
      margin-top: 50px; }
    .modal-window .reschedule-modal .info-wrap {
      border-radius: 2px;
      background-color: #fefacd;
      padding: 16px 14px;
      margin-top: 20px; }
      .modal-window .reschedule-modal .info-wrap .text {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: normal;
        color: #525f78;
        opacity: 0.9; }
        .modal-window .reschedule-modal .info-wrap .text span {
          font-weight: bold;
          color: #2f3a4f; }
    .modal-window .reschedule-modal ::-webkit-input-placeholder {
      /* Edge */
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
      color: #525f78;
      opacity: 0.9; }
    .modal-window .reschedule-modal :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
      color: #525f78;
      opacity: 0.9; }
    .modal-window .reschedule-modal ::placeholder {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
      color: #525f78;
      opacity: 0.9; }
  .modal-window .date-range-selector {
    max-width: 400px;
    padding: 25px 40px 40px 40px; }
    .modal-window .date-range-selector .title-text {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #262626;
      margin-bottom: 50px; }
    .modal-window .date-range-selector .form-wrapper .button {
      padding: 10px 12px;
      width: auto;
      margin: 0 auto;
      display: inherit; }
    .modal-window .date-range-selector .form-wrapper .no-margin {
      margin: 0; }
  .modal-window .event-confirmation {
    max-width: 480px;
    padding: 25px 40px 40px 40px; }
    .modal-window .event-confirmation.modal-content {
      max-height: initial; }
    .modal-window .event-confirmation .title-text {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #262626;
      margin-bottom: 24px; }
    .modal-window .event-confirmation .events-wrapper {
      max-height: 80vh;
      height: 100%;
      overflow-y: auto;
      padding: 12px; }
    .modal-window .event-confirmation .event {
      border-radius: 3.4px;
      border: solid 0.9px #363ed6;
      background-color: #edeef7;
      padding: 8px;
      margin-bottom: 12px; }
      .modal-window .event-confirmation .event .text-one {
        font-size: 12px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #525f78;
        line-height: 1.6; }
      .modal-window .event-confirmation .event .buttons-wrap {
        display: flex;
        justify-content: flex-end; }
        .modal-window .event-confirmation .event .buttons-wrap button {
          border-radius: 2px;
          padding: 8px 20px;
          font-size: 12px;
          outline: none;
          cursor: pointer; }
        .modal-window .event-confirmation .event .buttons-wrap .accept {
          background-image: linear-gradient(265deg, #341eea, #1e7eea);
          color: #ffffff;
          border: solid 1px #3129ea; }
        .modal-window .event-confirmation .event .buttons-wrap .reject {
          border: solid 1px #3129ea;
          color: #333; }
  .modal-window .survey-view {
    width: 768px; }
    .modal-window .survey-view .modal-close {
      right: 16px;
      top: 20px; }
    .modal-window .survey-view.modal-content {
      padding: 0;
      max-height: 80%;
      overflow-y: auto; }
    .modal-window .survey-view .title-text {
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.83;
      letter-spacing: normal;
      color: #22272e; }
    .modal-window .survey-view .modal-header {
      display: flex;
      align-items: center;
      position: relative;
      padding: 22px 14px 18px 14px;
      border-radius: 4px;
      box-shadow: 0 1px 0 0 #f2f4f6;
      background-color: #ffffff; }
    .modal-window .survey-view .questions .question-wrapper {
      padding: 14px; }
      .modal-window .survey-view .questions .question-wrapper .q-wrap {
        border-bottom: solid 1px #f4f7fc; }
        .modal-window .survey-view .questions .question-wrapper .q-wrap .question {
          display: flex; }
          .modal-window .survey-view .questions .question-wrapper .q-wrap .question .label {
            width: 16px;
            height: 16px;
            border-radius: 2px;
            border: solid 1px #6674a6;
            background-color: #ffffff;
            font-size: 10px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #2e394f;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 8px; }
          .modal-window .survey-view .questions .question-wrapper .q-wrap .question .text {
            font-size: 12px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #232d42; }
      .modal-window .survey-view .questions .question-wrapper .answer-list {
        margin: 8px 0 16px 0; }
        .modal-window .survey-view .questions .question-wrapper .answer-list .answer {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: #2d3852; }
    .modal-window .survey-view .list-wrap {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      padding: 14px;
      cursor: pointer; }
      .modal-window .survey-view .list-wrap.selected {
        background-color: #f2f4f6; }
      .modal-window .survey-view .list-wrap > div:not(:last-child) {
        margin-right: 24px; }
      .modal-window .survey-view .list-wrap .text-one {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #525f78;
        margin-bottom: 6px; }
      .modal-window .survey-view .list-wrap .text-two {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #2f3a4f; }
      .modal-window .survey-view .list-wrap .view {
        flex-grow: 1;
        text-align: right;
        cursor: pointer; }
        .modal-window .survey-view .list-wrap .view span {
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #363ed6; }

.modal-close {
  width: 12px;
  height: 12px;
  object-fit: contain;
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer; }
  .modal-close:hover {
    opacity: 0.9; }

.survey-modal {
  width: 720px !important; }
  .survey-modal .ant-list-item p {
    margin: 0 12px 0 0; }
    .survey-modal .ant-list-item p:last-child {
      text-align: right;
      flex-grow: 1;
      margin: 0; }
    .survey-modal .ant-list-item p span {
      display: block;
      margin-bottom: 4px;
      font-size: 12px;
      columns: #2f3a4f;
      font-weight: 500; }

@media screen and (max-width: 768px) {
  .modal-window.dark {
    background: #000; }
  .modal-window .update-number-otp {
    width: 355px !important; }
  .modal-window .update-number {
    width: 355px !important; }
  .modal-window .input-container button {
    right: 0px; }
  .modal-window .event-confirmation {
    max-width: 100%; }
    .modal-window .event-confirmation .events-wrapper {
      max-height: calc(100vh - 120px); }
    .modal-window .event-confirmation.modal-content {
      padding: 40px 12px 12px; }
  .modal-window .info-modal .text-one {
    font-size: 14px;
    margin-bottom: 12px; }
  .modal-window .info-modal .button {
    font-size: 11.2px;
    padding: 8px; }
  .modal-window .info-modal .info-bar {
    padding: 0;
    font-size: 12px; }
    .modal-window .info-modal .info-bar span {
      font-size: 12px; }
  .modal-window .info-modal-lingoq .text-one {
    font-size: 14px;
    margin-bottom: 12px; }
  .modal-window .info-modal-lingoq .button {
    font-size: 11.2px;
    padding: 8px; }
  .modal-window .info-modal-lingoq .info-bar {
    padding: 0;
    font-size: 12px; }
    .modal-window .info-modal-lingoq .info-bar span {
      font-size: 12px; }
  .modal-window .confirm-booking {
    margin: auto 20px;
    padding: 60px 10px 30px 10px !important; }
    .modal-window .confirm-booking .modal-header-update {
      display: flex;
      justify-content: center; }
      .modal-window .confirm-booking .modal-header-update .phone-title-text {
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #262626;
        margin-left: 0px; }
    .modal-window .confirm-booking .btn {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-end; }
      .modal-window .confirm-booking .btn .btn-white button {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        margin-right: 20px;
        padding: 0.6rem 1.2rem;
        border-radius: 2px;
        background-color: #ffffff;
        color: #4139eb; }
      .modal-window .confirm-booking .btn .btn-blue button {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        margin-right: 20px;
        padding: 0.6rem 1.2rem;
        border-radius: 2px;
        color: #ffffff;
        background-color: #4139eb; }
    .modal-window .confirm-booking .booking-confirm-card {
      position: relative;
      max-width: 56rem;
      background-color: #ffffff;
      color: #7f8fa4;
      border-radius: 0.8rem;
      padding: 20px 78px 20px 19px; }
      .modal-window .confirm-booking .booking-confirm-card .classTime-card-container {
        display: flex;
        flex-wrap: wrap; }
        .modal-window .confirm-booking .booking-confirm-card .classTime-card-container .classTime-card {
          margin: 7px 10px 6px 0px;
          padding: 8px 13px 9px 10px;
          border-radius: 2px;
          border: solid 1px #d1d8e1;
          background-color: #ffffff; }
          .modal-window .confirm-booking .booking-confirm-card .classTime-card-container .classTime-card .header-text {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #7f8fa4; }
      .modal-window .confirm-booking .booking-confirm-card .class-selcted {
        display: flex;
        flex-direction: column;
        justify-content: center; }
        .modal-window .confirm-booking .booking-confirm-card .class-selcted .classSelected-text {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #2f3a4f;
          margin-top: 19px; }
      .modal-window .confirm-booking .booking-confirm-card .heading-selcted {
        display: flex;
        flex-direction: column;
        justify-content: center; }
        .modal-window .confirm-booking .booking-confirm-card .heading-selcted .selected-text {
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #2f3a4f;
          font-size: 12px;
          margin-bottom: 7px; }
      .modal-window .confirm-booking .booking-confirm-card .name-header {
        display: flex;
        margin-bottom: 18px;
        align-items: center; }
        .modal-window .confirm-booking .booking-confirm-card .name-header .heading-name {
          display: flex;
          flex-direction: column;
          justify-content: center; }
          .modal-window .confirm-booking .booking-confirm-card .name-header .heading-name .header-text {
            margin-left: 6px;
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            margin-bottom: 2px; }
  .modal-window .modal-content {
    max-height: 90%;
    overflow: auto;
    padding: 60px 30px 30px; }
    .modal-window .modal-content.card-detail-view {
      padding: 20px 0;
      width: 100%;
      background: transparent;
      border-radius: 2px;
      background-color: #f5f4f8;
      max-height: 100%; }
      .modal-window .modal-content.card-detail-view .modal-close {
        right: 24px;
        top: 24px;
        z-index: 9; }
      .modal-window .modal-content.card-detail-view .modal-body {
        border-radius: 0;
        background-color: #f5f4f8; }
  .modal-window .thankyou-modal {
    width: 100%;
    max-width: calc(100% - 24px); }
    .modal-window .thankyou-modal .title-text {
      font-size: 32px; }
  .modal-window .video-player,
  .modal-window .teaching-video {
    width: 100%;
    height: auto;
    padding: 24px 0 0; }
    .modal-window .video-player .modal-close,
    .modal-window .teaching-video .modal-close {
      top: 0;
      right: 0;
      width: 24px;
      height: 24px; }
  .modal-window .survey-view.modal-content {
    padding-top: 50px;
    height: 100%;
    max-height: 100%; }
  .modal-window .survey-view .modal-header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9; }
  .modal-window .info-modal {
    width: calc(100% - 24px);
    padding: 50px 24px 60px 24px; }
  .modal-window .info-modal-lingoq {
    width: calc(100% - 24px);
    padding: 50px 24px 60px 24px; }
  .modal-window .book-demo {
    width: calc(100% - 24px);
    padding: 50px 24px 60px 24px; }
    .modal-window .book-demo .content-wrapper {
      flex-direction: column;
      width: 100%; }
      .modal-window .book-demo .content-wrapper > div .title-text {
        font-size: 16px; }
      .modal-window .book-demo .content-wrapper > div input {
        padding: 12px;
        width: 100%; }
      .modal-window .book-demo .content-wrapper > div:first-child {
        margin-right: 0;
        min-width: 100%; }
      .modal-window .book-demo .content-wrapper > div:last-child {
        display: none; }
        .modal-window .book-demo .content-wrapper > div:last-child img {
          max-width: 100%; }
  .modal-window .reschedule-modal {
    max-width: 100%; }
    .modal-window .reschedule-modal .ant-calendar-picker {
      width: 100%; }
    .modal-window .reschedule-modal .available-slots-wrapper {
      display: block; }
      .modal-window .reschedule-modal .available-slots-wrapper .time-slot {
        margin-bottom: 12px; }
    .modal-window .reschedule-modal.modal-content {
      padding: 60px 24px 30px 24px; }
      .modal-window .reschedule-modal.modal-content .sub-text {
        font-size: 14px; } }

.book-demo {
  padding-top: 120px; }
  .book-demo .header {
    position: fixed;
    box-shadow: 0 0 22px 0 rgba(0, 0, 0, 0.06);
    z-index: 1000; }
    .book-demo .header .menu-wrap {
      display: none; }
  .book-demo p {
    margin: 0; }
  .book-demo .container {
    max-width: 756px;
    margin: 0 auto; }
  .book-demo .title-text {
    font-size: 36px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.9px;
    color: #2f3a4f;
    margin-bottom: 8px; }
  .book-demo .sub-text {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #262626; }
  .book-demo .details-section {
    border-radius: 2px;
    box-shadow: 0 0 22px 0 rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    border-left: 2px solid #f71976;
    padding: 22px 48px 24px 12px;
    margin: 20px 0 50px 0;
    display: inline-block; }
    .book-demo .details-section > div {
      display: inline-block;
      vertical-align: middle; }
      .book-demo .details-section > div:not(:last-child) {
        margin-right: 40px; }
    .book-demo .details-section .text {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #262626; }
      .book-demo .details-section .text span {
        font-weight: normal; }
  .book-demo .form-wrapper {
    margin-top: 32px;
    padding-bottom: 160px; }
    .book-demo .form-wrapper .radio-group {
      margin: 4px 0 12px 0; }
      .book-demo .form-wrapper .radio-group .ant-radio-checked:after {
        border: 1px solid #363ed6; }
      .book-demo .form-wrapper .radio-group .ant-radio-checked .ant-radio-inner {
        border-color: #363ed6; }
        .book-demo .form-wrapper .radio-group .ant-radio-checked .ant-radio-inner:after {
          background-color: #363ed6; }
    .book-demo .form-wrapper .ant-calendar-picker:hover input {
      border: solid 1px #adb5c6; }
    .book-demo .form-wrapper .ant-calendar-picker input {
      border-radius: 2px;
      border: solid 1px #adb5c6;
      background-color: #ffffff;
      padding: 10px 12px;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #262626;
      width: 100%;
      outline: none;
      height: auto; }
      .book-demo .form-wrapper .ant-calendar-picker input:focus {
        box-shadow: none; }
    .book-demo .form-wrapper .available-slots-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 20px; }
    .book-demo .form-wrapper .time-slot {
      border-radius: 2px;
      border: solid 1px #adb5c6;
      background-color: #ffffff;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #262626;
      padding: 10px;
      cursor: pointer; }
      .book-demo .form-wrapper .time-slot.selected {
        border: solid 1px #363ed6;
        background-color: #edeef7;
        color: #363ed6; }
    .book-demo .form-wrapper .label {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #7f8fa4;
      margin-bottom: 6px; }
      .book-demo .form-wrapper .label.error {
        color: #f53636; }
      .book-demo .form-wrapper .label span {
        color: #f53636; }
    .book-demo .form-wrapper ::-webkit-input-placeholder {
      /* Edge */
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
      color: #525f78;
      opacity: 0.9; }
    .book-demo .form-wrapper :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
      color: #525f78;
      opacity: 0.9; }
    .book-demo .form-wrapper ::placeholder {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
      color: #525f78;
      opacity: 0.9; }
    .book-demo .form-wrapper input.input {
      border-radius: 2px;
      border: solid 1px #adb5c6;
      background-color: #ffffff;
      padding: 10px 12px;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #262626;
      width: 100%;
      outline: none; }
      .book-demo .form-wrapper input.input.error {
        border: solid 1px #f53636; }
    .book-demo .form-wrapper .select-dropdown {
      margin-bottom: 0; }
      .book-demo .form-wrapper .select-dropdown.error {
        border: solid 1px #f53636; }
      .book-demo .form-wrapper .select-dropdown select {
        padding: 10px 12px;
        font-size: 14px; }
    .book-demo .form-wrapper .row {
      display: flex;
      align-content: flex-end;
      margin-bottom: 20px; }
      .book-demo .form-wrapper .row .col {
        flex-grow: 1;
        flex-basis: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end; }
        .book-demo .form-wrapper .row .col:first-child {
          margin-right: 20px; }
  .book-demo .blue-button {
    border-radius: 2px;
    background-image: linear-gradient(265deg, #341eea 99%, #1e7eea -8%);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.16px;
    text-align: center;
    color: #ffffff;
    padding: 16px 100px;
    outline: none;
    border: 0;
    text-transform: uppercase;
    cursor: pointer; }
    .book-demo .blue-button.disabled {
      opacity: 0.3;
      pointer-events: none; }
  .book-demo .button-wrap {
    margin-top: 60px;
    text-align: center; }
    .book-demo .button-wrap .helper-text {
      margin-top: 12px; }
  .book-demo .helper-text {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.58;
    letter-spacing: normal;
    color: #525f78; }
  .book-demo .info-wrap {
    border-radius: 2px;
    background-color: #fefacd;
    padding: 16px 14px;
    margin-top: 20px; }
    .book-demo .info-wrap .text {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
      color: #525f78;
      opacity: 0.9; }
      .book-demo .info-wrap .text span {
        font-weight: bold;
        color: #2f3a4f; }
  .book-demo .info-wrapper {
    border-radius: 8px;
    box-shadow: 0 2px 44px 0 rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(106deg, #fba649, #f2534c);
    padding: 30px;
    max-width: 1080px;
    margin: 0 auto;
    position: relative;
    margin-bottom: -80px; }
    .book-demo .info-wrapper .row {
      display: flex;
      align-items: flex-start; }
      .book-demo .info-wrapper .row .col {
        text-align: center; }
        .book-demo .info-wrapper .row .col:not(:last-child) {
          margin-right: 32px; }
        .book-demo .info-wrapper .row .col:first-child {
          border-right: solid 2px #bb4039;
          padding: 12px 20px 12px 0;
          min-width: 322px; }
        .book-demo .info-wrapper .row .col > div {
          height: 40px;
          margin-bottom: 22px;
          display: flex;
          align-items: center;
          justify-content: center; }
          .book-demo .info-wrapper .row .col > div img {
            max-height: 100%; }
        .book-demo .info-wrapper .row .col .text-one {
          font-size: 30px;
          font-weight: 300;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.75px;
          color: #ffffff;
          text-align: left; }
        .book-demo .info-wrapper .row .col .text-two {
          font-size: 16px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.69;
          letter-spacing: normal;
          color: #ffffff; }
        .book-demo .info-wrapper .row .col a {
          color: inherit; }
  .book-demo .footer {
    padding-top: 200px; }

.ant-calendar-selected-day .ant-calendar-date {
  color: #fff !important;
  border-color: transparent !important;
  background: #363ed6 !important;
  border-radius: 100% !important; }

.book-demo-header {
  height: 90px;
  background-color: #ffffff;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  padding: 8px 90px 12px 70px;
  display: flex;
  align-items: center;
  z-index: 100;
  box-shadow: 0 0 22px 0 rgba(0, 0, 0, 0.06); }
  .book-demo-header .menu {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end; }
    .book-demo-header .menu p {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2f3a50; }
    .book-demo-header .menu .dp {
      width: 43px;
      height: 43px; }
      .book-demo-header .menu .dp img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100%;
        margin-left: 12px; }

@media screen and (max-width: 768px) {
  .book-demo {
    padding-top: 0; }
    .book-demo.calendar-wrapper {
      padding-top: 0; }
    .book-demo .header {
      position: fixed; }
    .book-demo .container {
      padding: 32px 15px;
      margin-top: 0; }
      .book-demo .container .title-text {
        text-align: center;
        font-size: 22px; }
      .book-demo .container .sub-text {
        text-align: center;
        font-size: 10px; }
      .book-demo .container .form-wrapper .row {
        flex-direction: column;
        margin-bottom: 0; }
        .book-demo .container .form-wrapper .row .sub-text {
          margin-bottom: 12px; }
        .book-demo .container .form-wrapper .row .col {
          margin-bottom: 15px; }
          .book-demo .container .form-wrapper .row .col:first-child {
            margin-right: 0; }
      .book-demo .container .form-wrapper .available-slots-wrapper {
        display: block; }
        .book-demo .container .form-wrapper .available-slots-wrapper .time-slot {
          max-width: 222px;
          margin-bottom: 10px; }
    .book-demo .info-wrap p {
      text-align: center; }
      .book-demo .info-wrap p span {
        display: block; }
    .book-demo .info-wrapper {
      padding: 36px;
      object-fit: contain;
      border-radius: 4.8px;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.3);
      background-image: linear-gradient(153deg, #fba649, #f2534c);
      max-width: calc(100% - 36px); }
      .book-demo .info-wrapper .row {
        flex-direction: column; }
        .book-demo .info-wrapper .row .col {
          margin-bottom: 30px;
          display: flex;
          align-items: center;
          width: 100%; }
          .book-demo .info-wrapper .row .col > div {
            margin-bottom: 0; }
          .book-demo .info-wrapper .row .col img {
            max-width: 22px;
            margin: 0 14px 0 0; }
          .book-demo .info-wrapper .row .col:not(:last-child) {
            margin-right: 0; }
          .book-demo .info-wrapper .row .col:first-child {
            border-right: 0;
            padding: 0;
            justify-content: center; }
          .book-demo .info-wrapper .row .col .text-one {
            font-size: 18px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.64px;
            text-align: center;
            color: #ffffff; }
          .book-demo .info-wrapper .row .col .text-two {
            font-size: 12px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 2.41;
            letter-spacing: normal;
            text-align: left;
            color: #ffffff; }
            .book-demo .info-wrapper .row .col .text-two span {
              display: block; }
          .book-demo .info-wrapper .row .col a {
            color: inherit; }
  .book-demo-header {
    height: auto;
    padding-left: 32px; }
    .book-demo-header .logo-wrap {
      max-width: 55px; }
      .book-demo-header .logo-wrap img {
        max-width: 100%; }
    .book-demo-header .menu {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end; }
      .book-demo-header .menu p {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #2f3a50; }
      .book-demo-header .menu .dp {
        width: 43px;
        height: 43px; }
        .book-demo-header .menu .dp img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 100%;
          margin-left: 12px; } }

/*==================================*/
/*==================================*/
html {
  font-size: 10px; }

.no-events {
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: normal;
  text-align: center;
  color: #262626;
  padding: 12px;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center; }

.mobile-events .event {
  border-radius: 2px;
  border: solid 1px #dfe2e5;
  background-color: #ffffff;
  padding: 24px 20px 24px 20px;
  display: flex;
  align-items: center;
  margin-bottom: 30px; }
  .mobile-events .event.old {
    border-top: 2px solid darkorange; }
  .mobile-events .event.new {
    border-top: 2px solid #5e01ba; }
  .mobile-events .event.today {
    border-top: 2px solid #f71976; }
  .mobile-events .event > div {
    flex-grow: 1;
    flex-basis: 100%;
    max-width: 130px;
    min-width: 130px; }
    .mobile-events .event > div:not(:last-child) {
      margin-right: 32px; }
    .mobile-events .event > div:last-child {
      max-width: initial;
      min-width: auto; }
  .mobile-events .event.orange {
    border-top: 2px solid orangered; }
  .mobile-events .event .helper-text {
    margin: 1.2rem 0;
    font-size: 1.4rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: orangered;
    margin-bottom: 2px; }
  .mobile-events .event .call-mask {
    margin-top: 12px; }
    .mobile-events .event .call-mask.link {
      display: inline-block;
      padding: 6px 12px;
      border: 1px solid #1890ff;
      cursor: pointer;
      border-radius: 4px;
      font-size: 14px;
      margin-bottom: 12px; }
  .mobile-events .event:not(:last-child) {
    margin-bottom: 12px; }
  .mobile-events .event .text-one {
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262626; }
  .mobile-events .event .text-two {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #262626;
    margin-bottom: 8px;
    opacity: .8; }
  .mobile-events .event .text-three {
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262626; }
    .mobile-events .event .text-three span {
      display: block; }
  .mobile-events .event .link-wrap {
    display: inline-block;
    position: relative;
    right: auto;
    bottom: auto;
    text-align: right; }
    .mobile-events .event .link-wrap .link {
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4139eb;
      margin-left: 24px;
      cursor: pointer;
      display: inline-block; }
      .mobile-events .event .link-wrap .link.orange {
        color: #FF8F00; }

.calendar-wrapper {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  position: relative;
  padding-top: 90px;
  border-radius: 2px;
  border: solid 1px #dfe2e5;
  background-color: #ffffff;
  /*Turn pointer events back on*/ }
  .calendar-wrapper .container {
    max-width: 980px;
    margin: 46px auto; }
  .calendar-wrapper .title-text {
    font-size: 36px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.9px;
    color: #2f3a4f;
    margin-bottom: 8px; }
  .calendar-wrapper .sub-text {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #262626; }
  .calendar-wrapper .details-section {
    border-radius: 2px;
    box-shadow: 0 0 22px 0 rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    border-left: 2px solid #f71976;
    padding: 22px 48px 24px 12px;
    margin: 20px 0 40px 0;
    display: inline-block; }
    .calendar-wrapper .details-section > div {
      display: inline-block;
      vertical-align: middle; }
      .calendar-wrapper .details-section > div:not(:last-child) {
        margin-right: 40px; }
    .calendar-wrapper .details-section .text {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #262626; }
      .calendar-wrapper .details-section .text span {
        font-weight: normal; }
  .calendar-wrapper .fc-time-grid .fc-slats td {
    white-space: nowrap;
    overflow: hidden;
    height: 1em;
    padding: 2px .8rem; }
  .calendar-wrapper .fc-time-grid .fc-slats tr:not(:first-child) .fc-axis.fc-time.fc-widget-content {
    top: -1rem !important; }
  .calendar-wrapper .calendar-view {
    position: relative; }
    .calendar-wrapper .calendar-view .header-wrap {
      display: block;
      margin-bottom: 44px;
      position: relative;
      text-align: left; }
    .calendar-wrapper .calendar-view .right-action {
      display: inline-block;
      position: absolute;
      left: 0;
      z-index: 99;
      top: -24px; }
      .calendar-wrapper .calendar-view .right-action .link {
        font-size: 1.4rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #4139eb;
        cursor: pointer; }
    .calendar-wrapper .calendar-view .action-wrap {
      position: absolute;
      right: 10rem;
      top: 8px;
      z-index: 99; }
      .calendar-wrapper .calendar-view .action-wrap .button {
        margin: 0;
        padding: 8px 24px;
        font-size: 12px; }
    .calendar-wrapper .calendar-view .month-dropdown {
      position: relative;
      top: 8px;
      z-index: 10;
      display: inline-flex;
      align-items: center;
      margin: 0 auto;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #262626;
      justify-content: center; }
      .calendar-wrapper .calendar-view .month-dropdown img:first-child {
        margin-right: 12px; }
      .calendar-wrapper .calendar-view .month-dropdown .arrow {
        display: inline-block;
        margin-left: 12px; }
  .calendar-wrapper .more {
    font-size: 1.4rem;
    font-weight: 500;
    color: #eb3e55;
    padding: 0 1rem;
    cursor: pointer; }
  .calendar-wrapper .no-assigned-groups {
    font-size: 1.4rem;
    font-weight: 500;
    color: #2f3a4f;
    padding: 0; }
  .calendar-wrapper .calendar-container {
    display: inline-block;
    width: calc(100% - 24rem);
    vertical-align: top;
    overflow-y: auto;
    background: #fff;
    position: relative; }
  .calendar-wrapper .calendar-popup {
    width: 21rem;
    background-color: white;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.23);
    position: absolute;
    z-index: 9;
    margin: 0 auto;
    padding: 1rem;
    display: none; }
    .calendar-wrapper .calendar-popup .cancel {
      position: absolute;
      right: 1rem;
      top: 1rem;
      cursor: pointer; }
    .calendar-wrapper .calendar-popup .initials-wrap {
      width: 3rem;
      height: 3rem;
      background-color: #1d80d3;
      border-radius: 100%;
      text-align: center;
      display: inline-block;
      vertical-align: top; }
      .calendar-wrapper .calendar-popup .initials-wrap .initial {
        margin: 0;
        font-size: 1.2rem;
        font-weight: 500;
        color: #fcfcfc;
        text-transform: uppercase;
        position: relative;
        top: 50%;
        transform: translateY(-50%); }
    .calendar-wrapper .calendar-popup .content-wrap {
      display: inline-block;
      vertical-align: top;
      padding-left: 1rem; }
      .calendar-wrapper .calendar-popup .content-wrap p {
        margin: 0;
        margin-bottom: .5rem; }
      .calendar-wrapper .calendar-popup .content-wrap .name {
        font-size: 14px;
        font-weight: 500;
        color: #2f3a4f;
        padding-right: 1.5rem;
        margin: 0 0 8px; }
      .calendar-wrapper .calendar-popup .content-wrap .email {
        font-size: 12px;
        font-weight: normal;
        color: #525f78;
        margin: 0 0 8px; }
      .calendar-wrapper .calendar-popup .content-wrap .timing {
        font-size: 12px;
        font-weight: normal;
        color: #525f78; }
      .calendar-wrapper .calendar-popup .content-wrap .students {
        font-size: 1.2rem;
        color: #525f78;
        margin-bottom: 1rem; }
      .calendar-wrapper .calendar-popup .content-wrap .managed-by {
        font-size: 1.2rem;
        color: #525f78; }
      .calendar-wrapper .calendar-popup .content-wrap .key-value {
        display: flex; }
        .calendar-wrapper .calendar-popup .content-wrap .key-value.block {
          display: block; }
        .calendar-wrapper .calendar-popup .content-wrap .key-value .key {
          font-size: 1.2rem;
          color: #425f78;
          margin-right: 8px; }
        .calendar-wrapper .calendar-popup .content-wrap .key-value .value {
          font-size: 1.2rem;
          color: #525f78; }
      .calendar-wrapper .calendar-popup .content-wrap .img-wrap {
        width: 2rem;
        height: 2rem;
        display: inline-block;
        margin-right: .5rem; }
        .calendar-wrapper .calendar-popup .content-wrap .img-wrap img {
          width: 100%;
          height: 100%;
          -o-object-fit: cover;
          object-fit: cover;
          border-radius: 100%; }
    .calendar-wrapper .calendar-popup .footer-wrap {
      width: 100%;
      text-align: right;
      margin-top: 1.5rem;
      display: flex; }
      .calendar-wrapper .calendar-popup .footer-wrap li {
        padding: 0 .5rem; }
        .calendar-wrapper .calendar-popup .footer-wrap li:last-child {
          padding-right: 0; }
        .calendar-wrapper .calendar-popup .footer-wrap li .link {
          font-size: 12px;
          font-weight: 500;
          color: #363ed6;
          cursor: pointer;
          margin: 0; }
  .calendar-wrapper .select-dropdown {
    width: 20rem;
    background-color: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    position: absolute;
    z-index: 99;
    margin: auto;
    text-align: left;
    display: none; }
    .calendar-wrapper .select-dropdown p {
      margin: 0;
      padding: 1rem;
      cursor: pointer;
      font-size: 1.4rem;
      color: #2e394e;
      border-bottom: solid 1px rgba(151, 179, 206, 0.14); }
      .calendar-wrapper .select-dropdown p:hover {
        background: #FAFAFA; }
      .calendar-wrapper .select-dropdown p:last-child {
        border-bottom: 0; }
  .calendar-wrapper .fc-header-toolbar {
    padding: 8px 0;
    background-color: #fff;
    box-shadow: none;
    margin: 0;
    position: relative;
    z-index: 9; }
    .calendar-wrapper .fc-header-toolbar .fc-addClass-button,
    .calendar-wrapper .fc-header-toolbar .fc-updateSchedule-button {
      padding: 0;
      margin: 0;
      background: transparent;
      border: 0;
      border-radius: 2px;
      background-color: #eb3e55;
      cursor: pointer;
      font-size: 10px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.84px;
      text-align: center;
      color: #ffffff;
      padding: 6px;
      max-width: auto;
      margin-left: 12px; }
    .calendar-wrapper .fc-header-toolbar .fc-left {
      height: 100%;
      text-align: left;
      display: inline-block;
      vertical-align: middle; }
      .calendar-wrapper .fc-header-toolbar .fc-left h2 {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #262626;
        padding-left: 24px; }
    .calendar-wrapper .fc-header-toolbar .fc-right {
      height: 100%;
      justify-content: flex-end;
      display: flex;
      align-items: center;
      min-width: 360px; }
      .calendar-wrapper .fc-header-toolbar .fc-right h2 {
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #444c63;
        margin: 0 8px;
        padding: 0;
        min-width: 120px; }
    .calendar-wrapper .fc-header-toolbar .fc-center {
      display: flex;
      display: none; }
      .calendar-wrapper .fc-header-toolbar .fc-center button {
        background: transparent;
        padding: 0;
        margin: 0;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #525f78;
        border: 0;
        text-transform: capitalize;
        outline: none;
        border-radius: 0;
        line-height: 1.6;
        box-shadow: none !important; }
        .calendar-wrapper .fc-header-toolbar .fc-center button:not(last-child) {
          margin-right: 12px; }
        .calendar-wrapper .fc-header-toolbar .fc-center button.fc-button-active {
          border: 0;
          border-bottom: 1px solid #eb3e55;
          outline: none; }
          .calendar-wrapper .fc-header-toolbar .fc-center button.fc-button-active:focus, .calendar-wrapper .fc-header-toolbar .fc-center button.fc-button-active:active {
            box-shadow: none; }
        .calendar-wrapper .fc-header-toolbar .fc-center button:focus {
          border: 0;
          border-bottom: 1px solid #eb3e55;
          outline: none; }
    .calendar-wrapper .fc-header-toolbar .fc-next-button {
      margin-right: 8px !important; }
    .calendar-wrapper .fc-header-toolbar .fc-prev-button,
    .calendar-wrapper .fc-header-toolbar .fc-next-button {
      background: transparent;
      padding: 0;
      margin: 0;
      min-width: auto;
      width: auto;
      border: 0;
      outline: none;
      box-shadow: none !important; }
      .calendar-wrapper .fc-header-toolbar .fc-prev-button:focus, .calendar-wrapper .fc-header-toolbar .fc-prev-button:active,
      .calendar-wrapper .fc-header-toolbar .fc-next-button:focus,
      .calendar-wrapper .fc-header-toolbar .fc-next-button:active {
        background: none; }
      .calendar-wrapper .fc-header-toolbar .fc-prev-button span,
      .calendar-wrapper .fc-header-toolbar .fc-next-button span {
        position: relative;
        display: inline-block;
        background: url(images/platform/arrow-left.png) no-repeat;
        background-size: contain;
        width: 32px;
        height: 32px; }
        .calendar-wrapper .fc-header-toolbar .fc-prev-button span:after,
        .calendar-wrapper .fc-header-toolbar .fc-next-button span:after {
          display: none; }
    .calendar-wrapper .fc-header-toolbar .fc-next-button {
      margin-left: 8px; }
      .calendar-wrapper .fc-header-toolbar .fc-next-button span {
        background: url(images/platform/arrow-right.png) no-repeat;
        background-size: contain;
        width: 32px;
        height: 32px; }
        .calendar-wrapper .fc-header-toolbar .fc-next-button span:after {
          display: none; }
    .calendar-wrapper .fc-header-toolbar .fc-state-default {
      top: auto;
      transform: initial; }
  .calendar-wrapper .fc .fc-axis {
    padding: 1rem .8rem;
    font-size: 1.2rem;
    text-align: right;
    color: #8c97b2;
    border-top: 0;
    text-transform: uppercase;
    position: relative; }
  .calendar-wrapper .fc .fc-widget-header span {
    display: inline-block;
    padding: 1rem;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #525f78;
    text-transform: uppercase; }
  .calendar-wrapper .fc-event.navy-blue {
    color: #00bfdd;
    border-top: 4px solid #00bfdd; }
  .calendar-wrapper .fc-event.green {
    color: #16cf50;
    border-top: 4px solid #16cf50; }
  .calendar-wrapper .fc-event.dark-green {
    color: #0fbdce;
    border-top: 4px solid #0fbdce; }
  .calendar-wrapper .fc-event.purple {
    color: #fff;
    border-top: 4px solid #9012fe;
    background: rgba(144, 18, 254, 0.4); }
  .calendar-wrapper .fc-event.pink {
    color: #fff;
    border-top: 4px solid #eb3e55;
    background: rgba(235, 62, 85, 0.4); }
  .calendar-wrapper .fc-event.yellow {
    color: #fff;
    border-top: 4px solid #FFEB3B;
    background: rgba(255, 235, 59, 0.4); }
  .calendar-wrapper .fc-event.orange {
    color: #fff;
    border-top: 4px solid #ff6237;
    background: rgba(255, 98, 55, 0.4); }
  .calendar-wrapper .fc-event.blue {
    color: #1d80d3;
    border-top: 4px solid #1d80d3;
    background: rgba(29, 128, 211, 0.4); }
  .calendar-wrapper .fc-event,
  .calendar-wrapper .fc-event-dot {
    background-color: #f8f9fa;
    border-left: 1px solid rgba(218, 223, 234, 0.69) !important;
    border-right: 1px solid rgba(218, 223, 234, 0.69) !important;
    border-bottom: 1px solid rgba(218, 223, 234, 0.69) !important;
    border-top: 2px solid #16cf50;
    border-radius: 0;
    background: #f8f9fa; }
  .calendar-wrapper tr:first-child > td > .fc-day-grid-event {
    margin-top: 0; }
  .calendar-wrapper .fc-time-grid .fc-slats .fc-minor td {
    border-top-style: dashed; }
    .calendar-wrapper .fc-time-grid .fc-slats .fc-minor td.fc-time {
      border-top: 0; }
  .calendar-wrapper .fc-time-grid-event .fc-time {
    padding: 0;
    width: 100%; }
  .calendar-wrapper .fc-time-grid-event.fc-allow-mouse-resize .fc-resizer:after {
    content: "=";
    color: #dadfea;
    font-size: 1.6rem; }
  .calendar-wrapper .fc-time-grid-event.fc-selected .fc-resizer {
    bottom: 1rem; }
  .calendar-wrapper .fc-unthemed td.fc-today {
    background-color: rgba(0, 122, 255, 0.05); }
  .calendar-wrapper .fc-state-default {
    background-color: transparent;
    background-image: none;
    border: 0;
    color: #525f78;
    text-shadow: none;
    box-shadow: none;
    border-radius: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.4rem;
    text-transform: capitalize !important;
    height: 100%;
    z-index: 1; }
    .calendar-wrapper .fc-state-default.fc-state-active {
      color: #eb3e55;
      border-bottom: 1px solid #eb3e55 !important; }
    .calendar-wrapper .fc-state-default.fc-settingsButton-button {
      padding-right: 3rem; }
    .calendar-wrapper .fc-state-default.fc-printButton-button {
      padding-right: 3rem; }
    .calendar-wrapper .fc-state-default.fc-filterButton-button {
      padding-right: 3rem;
      display: none !important; }
  .calendar-wrapper .fc-day-grid-event,
  .calendar-wrapper .fc-time-grid-event {
    padding: .2rem .5rem .2rem .5rem;
    margin: 0; }
    .calendar-wrapper .fc-day-grid-event.today,
    .calendar-wrapper .fc-time-grid-event.today {
      background: white;
      box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.23); }
  .calendar-wrapper .fc-ltr .fc-time-grid .fc-event-container {
    margin: 0; }
  .calendar-wrapper .fa-content {
    position: relative; }
  .calendar-wrapper .fc-agendaWeek-view .fc-time-grid-event .fc-content .fc-time {
    display: block; }
  .calendar-wrapper .fc-month-view .fc-event .fc-content .fc-time {
    display: none; }
  .calendar-wrapper .fc-time-grid .fc-slats td {
    height: 16px; }
  .calendar-wrapper .fc-time-grid-event .fc-time,
  .calendar-wrapper .fc-content .fc-time {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2f3a4f;
    display: block; }
    .calendar-wrapper .fc-time-grid-event .fc-time p,
    .calendar-wrapper .fc-content .fc-time p {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #525f78; }
      .calendar-wrapper .fc-time-grid-event .fc-time p.event-helper-text,
      .calendar-wrapper .fc-content .fc-time p.event-helper-text {
        font-size: 1rem;
        position: absolute;
        color: #fff;
        bottom: 0;
        font-weight: 500;
        text-align: center;
        width: 100%; }
  .calendar-wrapper .fc-time-grid-event .fc-title,
  .calendar-wrapper .fc-content .fc-title {
    font-size: 1rem;
    font-weight: 500;
    color: inherit;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: none; }
  .calendar-wrapper .fc-time-grid-event .fc-content-image,
  .calendar-wrapper .fc-content .fc-content-image {
    max-width: 1.8rem;
    height: 1.8rem;
    border-radius: 100%;
    width: 100%;
    display: inline-block;
    vertical-align: top;
    position: absolute;
    top: 0;
    right: 0; }
  .calendar-wrapper .fc-event .fc-content {
    display: flex;
    flex-flow: column;
    height: 100%; }
    .calendar-wrapper .fc-event .fc-content .fc-time {
      order: 2; }
    .calendar-wrapper .fc-event .fc-content .fc-title {
      order: 1; }
  .calendar-wrapper .fc-unthemed th,
  .calendar-wrapper .fc-unthemed td,
  .calendar-wrapper .fc-unthemed thead,
  .calendar-wrapper .fc-unthemed tbody,
  .calendar-wrapper .fc-unthemed .fc-divider,
  .calendar-wrapper .fc-unthemed .fc-row,
  .calendar-wrapper .fc-unthemed .fc-content,
  .calendar-wrapper .fc-unthemed .fc-popover,
  .calendar-wrapper .fc-unthemed .fc-list-view,
  .calendar-wrapper .fc-unthemed .fc-list-heading td {
    border-color: #dadfea; }
  .calendar-wrapper .fc-now-indicator {
    border: 0 solid #0072ff; }
  .calendar-wrapper .fc-time-grid .fc-now-indicator-line {
    border-top: 1px solid #0072ff; }
  .calendar-wrapper .fc-highlight {
    opacity: 0.4;
    background-color: #007aff; }
  .calendar-wrapper .fc-slats tr:not(:first-child) .fc-axis.fc-time.fc-widget-content {
    position: relative;
    top: -1.8rem; }
  .calendar-wrapper .fc-slats tr:first-child .fc-axis.fc-time.fc-widget-content {
    position: relative;
    top: -1rem; }
  .calendar-wrapper .fc-nonbusiness.fc-bgevent {
    background: #dadfea; }
  .calendar-wrapper .fc-month-view .fc-day:hover {
    background: rgba(0, 122, 255, 0.05); }
  .calendar-wrapper .fc-bgevent,
  .calendar-wrapper .fc-event-container {
    pointer-events: auto;
    /*events*/ }
  .calendar-wrapper .disabled-calendar {
    opacity: .9;
    position: absolute;
    width: 100%;
    height: calc(100% - 9rem);
    left: 0;
    top: -2rem;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 9; }

.month-list {
  width: 240px; }
  .month-list ul {
    max-height: 200px;
    overflow: auto; }
    .month-list ul li {
      padding: 8px 12px; }
      .month-list ul li.active {
        background: #dfe3e9 url(images/tick.svg) no-repeat;
        background-position: 95% center; }
      .month-list ul li:not(:last-child) {
        box-shadow: inset 0 -1px 0 0 #dfe3e9; }

.all-loader-wrap {
  position: fixed;
  right: 0;
  left: 0;
  top: 5rem;
  width: 100%;
  height: calc(100% - 5rem);
  z-index: 9;
  background: #fff;
  opacity: .8; }

.calendar-right-panel {
  width: 32.5rem;
  height: calc(100% - 9rem);
  background-color: #f8f9fa;
  box-shadow: -5px 0 7px 0 rgba(25, 30, 40, 0.16);
  position: fixed;
  right: 0;
  bottom: 0;
  top: 7.2rem;
  z-index: 10;
  padding: 1rem;
  overflow-y: auto; }
  .calendar-right-panel.normal-wrapper {
    top: 7.1rem;
    height: calc(100% - 7.1rem); }
  .calendar-right-panel .attendance-view .header-wrap .cancel {
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer; }
  .calendar-right-panel .attendance-view .header-wrap .title {
    font-size: 1.6rem;
    color: #2f3a4f;
    margin-bottom: 3rem; }
  .calendar-right-panel .attendance-view .header-wrap .initials-wrap {
    width: 3rem;
    height: 3rem;
    background-color: #1d80d3;
    border-radius: 100%;
    text-align: center;
    display: inline-block;
    vertical-align: top; }
    .calendar-right-panel .attendance-view .header-wrap .initials-wrap .initial {
      margin: 0;
      font-size: 1.2rem;
      font-weight: 500;
      color: #fcfcfc;
      text-transform: uppercase;
      position: relative;
      top: 50%;
      transform: translateY(-50%); }
  .calendar-right-panel .attendance-view .header-wrap .content-wrap {
    display: inline-block;
    width: calc(100% - 10rem);
    vertical-align: top;
    padding-left: 1rem; }
    .calendar-right-panel .attendance-view .header-wrap .content-wrap p {
      margin: 0;
      margin-bottom: .5rem; }
    .calendar-right-panel .attendance-view .header-wrap .content-wrap .name {
      font-size: 1.4rem;
      font-weight: 500;
      color: #1d80d3; }
    .calendar-right-panel .attendance-view .header-wrap .content-wrap .timing {
      font-size: 1.2rem;
      color: #525f78; }
    .calendar-right-panel .attendance-view .header-wrap .content-wrap .students {
      font-size: 1.2rem;
      color: #525f78;
      margin-bottom: 1.5rem; }
    .calendar-right-panel .attendance-view .header-wrap .content-wrap .managed-by {
      font-size: .8rem;
      color: #525f78; }
    .calendar-right-panel .attendance-view .header-wrap .content-wrap .img-wrap {
      width: 2rem;
      height: 2rem;
      display: inline-block;
      margin-right: .5rem; }
      .calendar-right-panel .attendance-view .header-wrap .content-wrap .img-wrap img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        border-radius: 100%; }
  .calendar-right-panel .attendance-view .header-wrap .attendance-details {
    display: inline-block; }
    .calendar-right-panel .attendance-view .header-wrap .attendance-details .text {
      font-size: 1.2rem;
      color: #525f78;
      margin-bottom: .5rem; }
    .calendar-right-panel .attendance-view .header-wrap .attendance-details .btn-cont {
      border-radius: 2px;
      border: solid 1px #acb4c4;
      font-size: 12px;
      text-align: center;
      color: #525f78;
      padding: .5rem;
      background: white; }
  .calendar-right-panel .attendance-view .card {
    width: calc(100% - 1rem);
    min-height: 8rem;
    background-color: white;
    border: solid 1px rgba(200, 206, 218, 0.36);
    margin: .5rem;
    padding: 2rem 1rem;
    position: relative; }
    .calendar-right-panel .attendance-view .card .abs-cancel {
      position: absolute;
      right: 1rem;
      top: 1rem;
      width: initial;
      max-width: 1.2rem;
      cursor: pointer; }
    .calendar-right-panel .attendance-view .card.selected {
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1); }
      .calendar-right-panel .attendance-view .card.selected .toggle-select {
        border: solid 1px #50e3c2;
        background-position: center; }
    .calendar-right-panel .attendance-view .card.disabled {
      pointer-events: none;
      opacity: .3; }
    .calendar-right-panel .attendance-view .card .toggle-select {
      position: absolute;
      right: 3rem;
      top: 2.6rem;
      max-width: 2.8rem;
      cursor: pointer;
      padding: 1rem;
      background-color: #f8f9fa;
      border: solid 1px #8790a1;
      width: 2.8rem;
      height: 2.8rem;
      border-radius: 100%; }
    .calendar-right-panel .attendance-view .card .img-wrap {
      width: 4rem;
      height: 4rem;
      display: inline-block;
      margin-right: 1rem;
      vertical-align: middle; }
      .calendar-right-panel .attendance-view .card .img-wrap.default {
        background-color: #453b85;
        border-radius: 100%; }
        .calendar-right-panel .attendance-view .card .img-wrap.default p {
          font-size: 1.6rem;
          text-transform: uppercase;
          text-align: center;
          text-align: center;
          position: relative;
          top: 50%;
          transform: translateY(-50%);
          color: white; }
      .calendar-right-panel .attendance-view .card .img-wrap img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        border-radius: 100%; }
    .calendar-right-panel .attendance-view .card .details-wrap {
      display: inline-block;
      width: calc(100% - 9.5rem);
      vertical-align: middle; }
      .calendar-right-panel .attendance-view .card .details-wrap p {
        margin: 0;
        text-transform: capitalize;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 3rem; }
      .calendar-right-panel .attendance-view .card .details-wrap .name {
        font-size: 1.6rem;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4a4a4a;
        padding-right: 3rem; }
      .calendar-right-panel .attendance-view .card .details-wrap .sub-text {
        font-size: 1.2rem;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4a4a4a;
        opacity: .80; }
    .calendar-right-panel .attendance-view .card .remark-wrap {
      height: 12.8rem;
      border-radius: 2px;
      background-color: white;
      border: solid 0.5px #bebebe;
      width: 100%;
      margin-top: 1rem;
      font-size: 1.4rem; }
      .calendar-right-panel .attendance-view .card .remark-wrap textarea {
        border: 0;
        outline: none;
        resize: none;
        width: 100%;
        height: calc(100% - 3rem);
        padding: 1rem; }
      .calendar-right-panel .attendance-view .card .remark-wrap .footer-wrap {
        text-align: right;
        padding: 0 1rem; }
  .calendar-right-panel .done {
    width: 32.5rem;
    height: 5rem;
    background-color: #eb3e55;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    color: white;
    bottom: 0;
    right: 0;
    position: fixed; }
  .calendar-right-panel .attendance-view {
    padding-bottom: 5rem; }

.calendar-settings .error-box {
  border: 1px solid #eb3e55; }

.calendar-settings .dropdown.error {
  border: 1px solid #eb3e55 !important; }

.calendar-settings .groups-form-wrap {
  padding: 1rem 2rem; }
  .calendar-settings .groups-form-wrap .ui.selection.dropdown {
    width: 100%;
    background-position: calc(100% - 1rem) center;
    padding-right: 3rem; }
    .calendar-settings .groups-form-wrap .ui.selection.dropdown .menu > .item {
      padding: 1rem !important; }
    .calendar-settings .groups-form-wrap .ui.selection.dropdown .icon {
      display: none; }
    .calendar-settings .groups-form-wrap .ui.selection.dropdown .text {
      verical-align: middle;
      margin-top: .5rem;
      padding-left: .5rem; }
  .calendar-settings .groups-form-wrap .title {
    font-size: 1.6rem;
    font-weight: 500;
    color: #2f3a4f;
    margin: 0 0 2rem 0;
    position: relative; }
    .calendar-settings .groups-form-wrap .title span {
      position: absolute;
      right: 0;
      color: #eb3e55;
      font-size: 1.4rem;
      font-weight: 400;
      cursor: pointer;
      right: 1rem; }
  .calendar-settings .groups-form-wrap .left-wrap {
    width: 16rem; }
  .calendar-settings .groups-form-wrap .right-wrap {
    width: calc(100% - 16rem); }
  .calendar-settings .groups-form-wrap .grid {
    margin-bottom: 1.5rem; }
  .calendar-settings .groups-form-wrap .two-grid-wrap .grid {
    width: calc(100% / 2 - 1rem); }
  .calendar-settings .groups-form-wrap .two-grid-wrap .grid:nth-child(even) label.key {
    text-align: center; }

.calendar-settings .line {
  border-top: solid 1px rgba(190, 190, 190, 0.52) !important;
  height: 0; }

.calendar-settings .add-new {
  margin: 0;
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
  color: #eb3e55;
  cursor: pointer;
  padding: 1rem;
  max-width: 40rem;
  margin: 0 auto; }

.menu-dd-wrapper {
  position: absolute;
  top: 3rem;
  right: 0;
  width: 3rem;
  height: 2rem;
  border-radius: 4px;
  background-color: #ffffff;
  cursor: pointer;
  text-align: center; }
  .menu-dd-wrapper img {
    width: 4px;
    height: 2rem; }
  .menu-dd-wrapper .menu-dropdown {
    width: 15.6rem;
    position: absolute;
    top: 2.5rem;
    right: 1rem;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.39);
    border: solid 0.5px rgba(52, 67, 86, 0.13);
    z-index: 9; }
    .menu-dd-wrapper .menu-dropdown ul {
      background: white;
      width: 100%; }
      .menu-dd-wrapper .menu-dropdown ul li {
        color: #4a4a4a;
        padding: 1rem;
        font-size: 1.2rem;
        text-align: left;
        border-bottom: solid 1px rgba(193, 193, 193, 0.43); }
        .menu-dd-wrapper .menu-dropdown ul li:hover {
          background: #FAFAFA; }
        .menu-dd-wrapper .menu-dropdown ul li:last-child {
          border-bottom: 0; }

.header-button-wrap {
  text-align: center;
  margin-bottom: 5rem; }
  .header-button-wrap button {
    display: inline-block;
    width: 20rem;
    height: 4rem;
    font-size: 1.4rem;
    line-height: 1.29;
    text-align: center;
    color: #2f3a4f;
    background: white;
    border: solid 1px #acb4c4 !important;
    text-transform: initial !important;
    box-shadow: none; }
    .header-button-wrap button:first-child {
      border-right: 0 !important;
      border-radius: 2px 0 0 2px; }
    .header-button-wrap button:last-child {
      margin-left: -3px;
      border-left: 0 !important;
      border-radius: 0 2px 2px 0; }
    .header-button-wrap button.selected {
      background-color: #50e3c2;
      border: solid 1px #50e3c2 !important; }

.reschedule-popup .mt-modal {
  padding: 2rem; }
  .reschedule-popup .mt-modal .header .title-text {
    font-size: 2rem;
    font-weight: 500;
    color: #2f3a4f; }
  .reschedule-popup .mt-modal .body .desc-text {
    font-size: 1.8rem;
    color: #525f78; }
    .reschedule-popup .mt-modal .body .desc-text span {
      font-weight: 500; }
  .reschedule-popup .mt-modal .body .notify-wrap {
    margin-bottom: 1rem; }
    .reschedule-popup .mt-modal .body .notify-wrap .grid {
      display: inline-block;
      width: calc(50% - 1rem); }
    .reschedule-popup .mt-modal .body .notify-wrap .key {
      font-size: 1.6rem;
      font-weight: 500;
      color: #2f3a4f;
      margin-right: 6rem; }
    .reschedule-popup .mt-modal .body .notify-wrap .ui.checkbox {
      margin-right: 5rem; }
  .reschedule-popup .mt-modal .body .mb-60 {
    margin-bottom: 6rem; }
  .reschedule-popup .mt-modal .body .mt-60 {
    margin-top: 6rem; }

.printscreen-popup .mt-modal .body {
  padding: 0 2rem; }
  .printscreen-popup .mt-modal .body .table-wrapper .table-details .table thead th.date {
    width: 12rem; }
  .printscreen-popup .mt-modal .body .table-wrapper .table-details .table tbody tr td.date {
    width: 10rem; }
  .printscreen-popup .mt-modal .body .table-wrapper .table-details .table tbody tr td.timing {
    width: 15rem; }
  .printscreen-popup .mt-modal .body .table-wrapper .table-details .table tbody tr td label {
    display: block;
    margin-bottom: 1rem;
    color: #2e394e; }
  .printscreen-popup .mt-modal .body .print-table ul li {
    display: inline-block;
    padding: 2rem 1rem;
    font-size: 1.4rem;
    border-right: solid 1px rgba(235, 238, 244, 0.55);
    border-bottom: solid 1px rgba(235, 238, 244, 0.55);
    text-transform: capitalize;
    color: #2e394e;
    vertical-align: middle; }
    .printscreen-popup .mt-modal .body .print-table ul li.title {
      font-weight: 500;
      color: #2f3a4f;
      text-align: left; }
    .printscreen-popup .mt-modal .body .print-table ul li.date {
      width: 12rem; }
    .printscreen-popup .mt-modal .body .print-table ul li label {
      display: block;
      margin-bottom: 1rem;
      color: #2e394e; }

.printscreen-popup .mt-modal .header {
  padding: 2rem; }

.datepicker-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2; }

.uib-datepicker-popup.dropdown-menu {
  min-width: 30rem;
  outline: none; }
  .uib-datepicker-popup.dropdown-menu table {
    outline: none; }
    .uib-datepicker-popup.dropdown-menu table:focus {
      outline: none; }

.calendar-loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  margin: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.3);
  z-index: 999; }
  .calendar-loader img {
    max-width: 100px; }

.student-list .student {
  border: solid 0.5px #c8ceda;
  background-color: #ffffff;
  padding: 4px;
  margin: 0 4px 4px 0;
  border-radius: 2px;
  line-height: 1;
  display: inline-block;
  vertical-align: middle; }
  .student-list .student p {
    font-size: 1.2rem;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2f3a4f;
    margin: 0; }
    .student-list .student p span {
      display: inline-block;
      margin-left: 4px;
      cursor: pointer; }

.days-time-wrapper {
  padding-top: 40px; }
  .days-time-wrapper > div > div {
    background: #f8f9fa;
    border-radius: 2px;
    margin-bottom: 12px;
    padding: 12px; }
  .days-time-wrapper .time-input-wrapper {
    display: flex; }
    .days-time-wrapper .time-input-wrapper div {
      flex-grow: 1;
      flex-basis: 100%; }
  .days-time-wrapper .days-wrapper {
    display: flex;
    align-items: center; }
    .days-time-wrapper .days-wrapper .day {
      flex-grow: 1;
      flex-basis: 100%; }
      .days-time-wrapper .days-wrapper .day p {
        border-radius: 2px;
        background-color: white;
        border: solid 1px rgba(190, 190, 190, 0.52) !important;
        padding: 1rem .5rem;
        font-size: 1rem;
        line-height: 1.29;
        text-align: center;
        color: #2f3a4f;
        display: inline-block;
        width: calc(100% - .5rem);
        text-align: center;
        text-transform: capitalize !important;
        cursor: pointer; }
        .days-time-wrapper .days-wrapper .day p.ui-error.input {
          border: 1px solid rgba(235, 62, 85, 0.35) !important; }
        .days-time-wrapper .days-wrapper .day p.selected {
          border: 1px solid #50e3c2 !important;
          background: #50e3c2; }
      .days-time-wrapper .days-wrapper .day:last-child button {
        width: 100%; }

.add-more {
  border-radius: 2px;
  border: solid 0.5px #bebebe;
  background-color: #ffffff;
  max-width: 80%;
  margin: 24px auto;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #eb3e55;
  padding: 8px 12px;
  cursor: pointer; }

.event-student {
  margin-bottom: 2px; }
  .event-student span {
    display: block; }

@media (max-width: 768px) {
  header {
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.5); }
  .mobile-events .event {
    flex-direction: column;
    align-items: flex-start; }
    .mobile-events .event .link-wrap {
      margin-top: 12px; }
      .mobile-events .event .link-wrap .link {
        font-size: 14px; }
    .mobile-events .event .student {
      text-align: left; }
      .mobile-events .event .student .text-two {
        display: block; }
    .mobile-events .event > div {
      margin-right: 0;
      text-align: left;
      width: 100%;
      min-width: 100%;
      max-width: 100%; }
      .mobile-events .event > div:not(:last-child) {
        margin-right: 0; }
      .mobile-events .event > div .text-one {
        font-size: 14px; }
      .mobile-events .event > div .text-three {
        font-size: 12px; }
      .mobile-events .event > div .text-two {
        display: none; }
  .calendar-wrapper {
    padding-top: 24px; }
    .calendar-wrapper .calendar-container {
      width: 100%;
      margin: 0; }
    .calendar-wrapper .fc .fc-toolbar.fc-header-toolbar {
      display: none; }
    .calendar-wrapper .details-section {
      border-left: 4px solid #f71976;
      padding: 8px 12px; }
      .calendar-wrapper .details-section .text {
        font-size: 14px;
        font-weight: normal; }
        .calendar-wrapper .details-section .text span {
          font-weight: 500; }
  .filter-content {
    display: none; }
  .calendar-right-panel {
    top: 10.2rem; }
  .calendar-right-panel.normal-wrapper {
    top: 10.5rem;
    height: calc(100% - 10.5rem); } }

@media screen and (max-width: 768px) {
  .ant-calendar-picker-container {
    width: calc(100% - 30px); }
    .ant-calendar-picker-container .ant-calendar {
      width: 100%;
      box-shadow: none;
      border-radius: 0 0 20px 20px; }
      .ant-calendar-picker-container .ant-calendar .ant-calendar-body {
        border-radius: 0 0 20px 20px;
        box-shadow: 0 6px 10px 0 rgba(131, 134, 163, 0.12);
        border: solid 1px #efefef;
        background-color: #ffffff; }
      .ant-calendar-picker-container .ant-calendar .ant-calendar-footer {
        display: none; }
  .calendar-wrapper .calendar-view {
    padding-top: 20px; }
    .calendar-wrapper .calendar-view .action-wrap {
      top: -16px;
      right: 0; }
    .calendar-wrapper .calendar-view .right-action {
      top: -12px; }
    .calendar-wrapper .calendar-view .fc-unthemed .fc-view-container {
      margin-top: 24px; }
      .calendar-wrapper .calendar-view .fc-unthemed .fc-view-container .fc-list-view {
        border: 0; }
        .calendar-wrapper .calendar-view .fc-unthemed .fc-view-container .fc-list-view .fc-list-table {
          border-top: 2px solid #6741d0;
          background: #f8f9fa; }
          .calendar-wrapper .calendar-view .fc-unthemed .fc-view-container .fc-list-view .fc-list-table .fc-list-item td {
            border: 0;
            padding: 12px 8px;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #525f78; }
            .calendar-wrapper .calendar-view .fc-unthemed .fc-view-container .fc-list-view .fc-list-table .fc-list-item td:not(:first-child) {
              display: none; }
        .calendar-wrapper .calendar-view .fc-unthemed .fc-view-container .fc-list-view .fc-list-heading {
          border: 0;
          display: none; }
        .calendar-wrapper .calendar-view .fc-unthemed .fc-view-container .fc-list-view .fc-widget-header {
          background: transparent;
          border: 0; }
          .calendar-wrapper .calendar-view .fc-unthemed .fc-view-container .fc-list-view .fc-widget-header .fc-widget-content {
            border: 0; }
    .calendar-wrapper .calendar-view .ant-calendar-picker {
      border: 0;
      width: 100%;
      background: #fff;
      box-shadow: none; }
      .calendar-wrapper .calendar-view .ant-calendar-picker .ant-input {
        border: 0;
        width: 100%;
        background: #fff; }
        .calendar-wrapper .calendar-view .ant-calendar-picker .ant-input:focus, .calendar-wrapper .calendar-view .ant-calendar-picker .ant-input:hover {
          border: 0; }
  .event-student {
    margin-bottom: 1px;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #525f78; }
    .event-student.title {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2f3a4f; }
    .event-student span {
      display: block; } }

.link-wrap {
  display: flex;
  position: absolute;
  right: 8px; }
  .link-wrap .link {
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #363ed6;
    margin-left: 12px;
    cursor: pointer; }

.notification-wrap {
  position: fixed;
  width: 100%;
  min-height: 60px;
  z-index: 9999;
  right: 0;
  top: 0;
  left: 0;
  color: #fff;
  animation-duration: .5s;
  animation-fill-mode: both;
  background: #fff;
  display: table;
  border: solid 1px #e8e8e8;
  text-align: center; }
  .notification-wrap .close {
    position: absolute;
    right: 15px;
    top: calc(50% - 5px);
    cursor: pointer;
    color: #333; }
  .notification-wrap .icon-wrap {
    height: 100%;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    padding: 10px;
    text-align: right;
    padding-right: 20px; }
  .notification-wrap .message-wrap {
    height: 100%;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    padding: 10px; }
    .notification-wrap .message-wrap img {
      display: none;
      vertical-align: middle; }
    .notification-wrap .message-wrap .title-text {
      font-size: 16px;
      text-transform: capitalize;
      margin-bottom: 0; }
    .notification-wrap .message-wrap img {
      margin-right: 20px; }
    .notification-wrap .message-wrap .text {
      font-size: 14px;
      color: #4a4a4a;
      line-height: 1;
      display: inline-block;
      vertical-align: middle;
      text-transform: capitalize; }
  .notification-wrap.info {
    background-color: #dae8f8;
    box-shadow: 0 2px 12px 5px rgba(0, 0, 0, 0.04); }
    .notification-wrap.info .message-wrap .info {
      display: inline-block; }
    .notification-wrap.info .message-wrap .title-text {
      color: #23b9b0; }
  .notification-wrap.warning {
    background-color: #fcefd3;
    box-shadow: 0 2px 12px 5px rgba(0, 0, 0, 0.04); }
    .notification-wrap.warning .message-wrap .title-text {
      color: #f5822a; }
    .notification-wrap.warning .message-wrap .warning {
      display: inline-block; }
  .notification-wrap.error {
    background-color: #edd1d1;
    box-shadow: 0 2px 12px 5px rgba(0, 0, 0, 0.04); }
    .notification-wrap.error .message-wrap .error {
      display: inline-block; }
    .notification-wrap.error .message-wrap .title-text {
      color: #d92c2c; }
  .notification-wrap.success {
    background-color: #e0edcf;
    box-shadow: 0 2px 12px 5px rgba(0, 0, 0, 0.04); }
    .notification-wrap.success .message-wrap .success {
      display: inline-block; }
    .notification-wrap.success .message-wrap .title-text {
      color: #4a4a4a;
      font-size: 14px; }

@media screen and (max-width: 1024px) {
  .notification-wrap .close {
    display: none; } }

.group-classes .container {
  max-width: 1200px;
  margin: 0 auto; }

.group-classes .white-button {
  border-radius: 4px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  cursor: pointer;
  padding: 13px 40px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #2a00e8;
  border: 0; }

.group-classes .ant-select-selection {
  border-radius: 4px;
  border: solid 1px #ced0da;
  background-image: linear-gradient(to top, #f2f4f7, #ffffff);
  min-width: 180px; }
  .group-classes .ant-select-selection .anticon svg {
    color: #3031ea; }

.group-classes .banner-wrapper {
  background: url(images/group-classes/banner.png) no-repeat;
  background-size: cover;
  padding-top: 160px; }
  .group-classes .banner-wrapper .title-text {
    text-shadow: 0 0 23px rgba(0, 0, 0, 0.85);
    font-size: 40px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin-bottom: 5px; }
  .group-classes .banner-wrapper .sub-text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #ffffff;
    max-width: 56rem; }
  .group-classes .banner-wrapper .list-wrap {
    border-radius: 6px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 48px 74px;
    position: relative;
    display: flex;
    align-items: flex-end;
    top: 42px; }
    .group-classes .banner-wrapper .list-wrap .list {
      flex-grow: 1;
      flex-basis: 100%;
      text-align: center; }
      .group-classes .banner-wrapper .list-wrap .list:not(:last-child) {
        margin-right: 32px; }
      .group-classes .banner-wrapper .list-wrap .list img {
        margin-bottom: 24px; }
      .group-classes .banner-wrapper .list-wrap .list .text-one {
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.32;
        letter-spacing: normal;
        color: #262626;
        margin-bottom: 7px; }
      .group-classes .banner-wrapper .list-wrap .list .text-two {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: #2f3a4f; }

.group-classes .section-one {
  padding-top: 120px; }
  .group-classes .section-one .text-one {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #262626; }
    .group-classes .section-one .text-one span {
      font-weight: bold; }
  .group-classes .section-one .header-wrap {
    display: flex;
    align-items: flex-end; }
  .group-classes .section-one .filter-wrap {
    flex-grow: 1;
    text-align: right; }
    .group-classes .section-one .filter-wrap .filter {
      display: inline-flex;
      align-items: center; }
      .group-classes .section-one .filter-wrap .filter:not(:last-child) {
        margin-right: 32px; }
      .group-classes .section-one .filter-wrap .filter > p {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #7f8fa4;
        margin-right: 12px; }
  .group-classes .section-one .cards-wrapper {
    margin: 55px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px; }
  .group-classes .section-one .divider-banner {
    background-image: linear-gradient(275deg, #700aad 124%, #8a3ceb 22%);
    padding: 46px 0 52px  0; }
    .group-classes .section-one .divider-banner .container {
      display: flex;
      align-items: center; }
      .group-classes .section-one .divider-banner .container .col {
        flex-grow: 1;
        display: flex;
        align-items: center; }
        .group-classes .section-one .divider-banner .container .col img {
          margin-right: 25px; }
        .group-classes .section-one .divider-banner .container .col .text-wrap .text-one {
          font-size: 40px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 0.66;
          letter-spacing: normal;
          color: #ffffff;
          margin-bottom: 12px; }
        .group-classes .section-one .divider-banner .container .col .text-wrap .text-two {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;
          letter-spacing: normal;
          color: #ffffff; }
        .group-classes .section-one .divider-banner .container .col:not(:last-child) {
          margin-right: 60px; }
  .group-classes .section-one .button-wrap {
    margin-bottom: 100px;
    text-align: center; }

.group-classes .footer {
  padding-top: 200px; }

.course-card {
  border-radius: 4px;
  box-shadow: 0 2px 37px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 26px 20px 86px 20px;
  position: relative; }
  .course-card .title-text {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262626; }
  .course-card .sub-text {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #464646;
    margin-bottom: 6px; }
  .course-card .text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #464646; }
    .course-card .text span {
      color: #262626; }
  .course-card .line {
    opacity: 0.4;
    border-top: solid 1px #525f78;
    margin: 25px 0 18px 0; }
  .course-card .img-wrap {
    height: 160px;
    margin: 20px 0 18px 0;
    position: relative; }
    .course-card .img-wrap img {
      object-fit: cover;
      border-radius: 4px;
      width: 100%;
      height: 100%; }
    .course-card .img-wrap .desc-wrap {
      opacity: 0.7;
      border-radius: 4px;
      background-color: #000000;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      padding: 6px 10px;
      display: flex;
      align-items: center; }
      .course-card .img-wrap .desc-wrap p {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.3px;
        color: #ffffff; }
        .course-card .img-wrap .desc-wrap p:last-child {
          text-align: right; }
  .course-card .bottom-section {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 20px 20px; }
  .course-card .flex-wrap {
    display: flex;
    vertical-align: middle; }
    .course-card .flex-wrap .text-one {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #262626;
      margin-right: 12px; }
    .course-card .flex-wrap .text-two {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      text-align: right;
      color: #2a00e8;
      text-transform: uppercase;
      cursor: pointer;
      flex-grow: 1;
      text-align: right; }

@media screen and (max-width: 768px) {
  .group-classes .ant-select-selection {
    min-width: 260px;
    height: 24px; }
    .group-classes .ant-select-selection .anticon svg {
      font-size: 12px; }
    .group-classes .ant-select-selection .ant-select-selection__rendered {
      line-height: 20px; }
    .group-classes .ant-select-selection .ant-select-selection-selected-value {
      font-size: 8.4px;
      font-weight: 500; }
  .group-classes .cards-wrapper .class-card {
    margin-bottom: 20px; }
  .group-classes .banner-wrapper {
    padding: 120px 28px 28px;
    position: relative;
    height: 450px; }
    .group-classes .banner-wrapper .title-text {
      font-size: 24px;
      font-weight: 300; }
    .group-classes .banner-wrapper .sub-text {
      font-size: 9.6px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56; }
    .group-classes .banner-wrapper .list-wrap {
      flex-direction: column;
      padding: 26px 30px;
      position: absolute;
      top: 65%;
      margin: auto;
      left: 6px;
      right: 6px; }
      .group-classes .banner-wrapper .list-wrap .list {
        display: flex;
        justify-content: center;
        text-align: left;
        width: 100%; }
        .group-classes .banner-wrapper .list-wrap .list img {
          margin-right: 15px;
          width: 26px;
          height: 24px;
          object-fit: contain; }
        .group-classes .banner-wrapper .list-wrap .list > div {
          flex-grow: 1; }
          .group-classes .banner-wrapper .list-wrap .list > div .text-one {
            font-size: 14px;
            font-weight: 500;
            text-align: left;
            margin-bottom: 5px; }
          .group-classes .banner-wrapper .list-wrap .list > div .text-two {
            font-size: 11.2px;
            font-weight: normal;
            text-align: left; }
        .group-classes .banner-wrapper .list-wrap .list:not(:last-child) {
          margin: 0 0 16px 0; }
  .group-classes .section-one {
    padding-top: 220px; }
    .group-classes .section-one .container {
      padding: 0 10px; }
    .group-classes .section-one .text-one {
      text-align: left;
      margin-bottom: 12px;
      font-size: 12px;
      font-weight: 500; }
    .group-classes .section-one .header-wrap {
      flex-direction: column;
      width: 100%;
      align-items: flex-start; }
      .group-classes .section-one .header-wrap .filter-wrap {
        display: flex;
        align-items: center; }
        .group-classes .section-one .header-wrap .filter-wrap .filter p {
          font-size: 8.4px; }
        .group-classes .section-one .header-wrap .filter-wrap .filter:not(::last-child) {
          margin-right: 20px; }
    .group-classes .section-one .cards-wrapper {
      display: block; }
      .group-classes .section-one .cards-wrapper .course-card {
        margin-bottom: 18px; }
    .group-classes .section-one .divider-banner {
      padding: 46px 13px; }
      .group-classes .section-one .divider-banner .container {
        padding: 0;
        display: block; }
        .group-classes .section-one .divider-banner .container .col {
          display: inline-flex;
          min-width: 50%;
          margin: 0;
          max-width: 50%; }
          .group-classes .section-one .divider-banner .container .col:first-child, .group-classes .section-one .divider-banner .container .col:nth-child(2) {
            margin-bottom: 48px; }
          .group-classes .section-one .divider-banner .container .col img {
            width: 28.7px;
            height: 29px;
            margin-right: 15px; }
          .group-classes .section-one .divider-banner .container .col .text-wrap .text-one {
            font-size: 25.2px;
            font-weight: 300;
            line-height: 0.66; }
          .group-classes .section-one .divider-banner .container .col .text-wrap .text-two {
            font-size: 10.1px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: normal;
            color: #ffffff; }
          .group-classes .section-one .divider-banner .container .col:not(:last-child) {
            margin-right: 0; }
  .course-card {
    padding: 22px 16px 88px 16px; }
    .course-card .title-text {
      font-size: 13.6px;
      margin-bottom: 4px; }
    .course-card .sub-text {
      font-size: 11.9px;
      font-weight: 500; }
    .course-card .text {
      font-size: 11.9px; }
    .course-card .line {
      margin: 21px 0 14px 0; }
    .course-card .bottom-section {
      padding: 0 16px 16px; }
    .course-card .flex-wrap .text-one {
      font-size: 13.6px;
      font-weight: bold; }
    .course-card .flex-wrap .text-two {
      font-size: 11.9px;
      font-weight: bold;
      letter-spacing: 0.42px; } }

.master-classes.adults .banner-wrapper {
  background: url(images/master-classes/adult-banner.png) no-repeat;
  background-size: cover;
  padding-bottom: 160px; }
  .master-classes.adults .banner-wrapper .title-text {
    font-size: 40px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: #1e174a;
    margin-bottom: 8px;
    text-shadow: none; }
  .master-classes.adults .banner-wrapper .sub-text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #1e174a;
    margin-bottom: 4px; }
  .master-classes.adults .banner-wrapper .text {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1e174a; }
    .master-classes.adults .banner-wrapper .text span {
      color: #4922f3; }
  .master-classes.adults .banner-wrapper button {
    border-radius: 2px;
    padding: 15px 52px;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.16px;
    text-align: center;
    color: #ffffff;
    outline: none;
    cursor: pointer;
    border: 0;
    margin-top: 30px; }

.master-classes.adults .container.helper {
  max-width: 1140px; }

.master-classes.adults .helper-wrapper {
  background: url(images/master-classes/helper-background.png) no-repeat;
  background-size: 100% 100%;
  padding: 44px 58px;
  display: flex;
  margin: 124px 0 130px 0;
  align-items: center; }
  .master-classes.adults .helper-wrapper button {
    border-radius: 2px;
    padding: 15px 52px;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.16px;
    text-align: center;
    color: #ffffff;
    outline: none;
    cursor: pointer;
    border: 0; }
  .master-classes.adults .helper-wrapper > div:last-child {
    flex-grow: 1;
    text-align: right; }
  .master-classes.adults .helper-wrapper .content {
    max-width: 460px; }
    .master-classes.adults .helper-wrapper .content > img {
      max-width: 116px; }
    .master-classes.adults .helper-wrapper .content .text {
      font-size: 36px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.9px;
      text-align: left;
      color: #2f3a4f;
      margin-bottom: 4px; }
    .master-classes.adults .helper-wrapper .content .sub-text {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #464646; }
      .master-classes.adults .helper-wrapper .content .sub-text span {
        font-weight: bold;
        color: #2a00e8; }

.master-classes.adults .section-one {
  padding-top: 60px; }

.master-classes .container {
  max-width: 1080px;
  margin: 0 auto; }

.master-classes .white-button {
  border-radius: 4px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  cursor: pointer;
  padding: 13px 40px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #2a00e8;
  border: 0; }

.master-classes .ant-select-selection {
  border-radius: 4px;
  border: solid 1px #ced0da;
  background-image: linear-gradient(to top, #f2f4f7, #ffffff);
  min-width: 180px; }
  .master-classes .ant-select-selection .anticon svg {
    color: #3031ea; }

.master-classes .banner-wrapper {
  background: url(images/banners/aus.webp) no-repeat;
  background-size: cover;
  padding-top: 160px; }
  .master-classes .banner-wrapper .title-text {
    text-shadow: 0 0 23px rgba(0, 0, 0, 0.85);
    font-size: 40px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin-bottom: 5px;
    max-width: 460px; }
  .master-classes .banner-wrapper .sub-text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #ffffff;
    max-width: 56rem; }
  .master-classes .banner-wrapper .list-wrap {
    border-radius: 6px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 48px 74px;
    position: relative;
    display: flex;
    align-items: flex-start;
    top: 42px; }
    .master-classes .banner-wrapper .list-wrap .list {
      flex-grow: 1;
      flex-basis: 100%;
      text-align: center; }
      .master-classes .banner-wrapper .list-wrap .list:not(:last-child) {
        margin-right: 60px; }
      .master-classes .banner-wrapper .list-wrap .list img {
        margin-bottom: 24px; }
      .master-classes .banner-wrapper .list-wrap .list .text-one {
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.32;
        letter-spacing: normal;
        color: #262626;
        margin-bottom: 7px; }
      .master-classes .banner-wrapper .list-wrap .list .text-two {
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: #2f3a4f; }

.master-classes .section-one {
  padding-top: 120px; }
  .master-classes .section-one .text-one {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #262626; }
    .master-classes .section-one .text-one span {
      font-weight: bold; }
  .master-classes .section-one .header-wrap {
    display: flex;
    align-items: flex-end; }
  .master-classes .section-one .filter-wrap {
    flex-grow: 1;
    text-align: right; }
    .master-classes .section-one .filter-wrap .filter {
      display: inline-flex;
      align-items: center; }
      .master-classes .section-one .filter-wrap .filter:not(:last-child) {
        margin-right: 32px; }
      .master-classes .section-one .filter-wrap .filter > p {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #7f8fa4;
        margin-right: 12px; }
  .master-classes .section-one .cards-wrapper {
    margin: 40px 0 55px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px; }
  .master-classes .section-one .button-wrap {
    margin-bottom: 100px;
    text-align: center; }

.master-classes .section-two {
  background-image: linear-gradient(248deg, #f8f8f8, #efefef -25%);
  padding: 50px 0 100px 0; }
  .master-classes .section-two h2 {
    font-size: 36px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.9px;
    text-align: center;
    color: #2f3a4f;
    max-width: 540px;
    margin: 0 auto 34px auto; }
  .master-classes .section-two .carousel-wrapper {
    margin: 20px 0 0 0; }
    .master-classes .section-two .carousel-wrapper .testimonial-card {
      margin-right: 20px; }
    .master-classes .section-two .carousel-wrapper .project-card {
      margin-right: 20px; }
    .master-classes .section-two .carousel-wrapper .tutorial-card {
      margin-right: 33px; }
    .master-classes .section-two .carousel-wrapper .slick-list {
      padding: 24px 0;
      position: relative; }
    .master-classes .section-two .carousel-wrapper .slick-arrow {
      position: absolute;
      top: calc(50% - 38px);
      outline: none;
      cursor: pointer; }
      .master-classes .section-two .carousel-wrapper .slick-arrow.slick-disabled {
        pointer-events: none;
        opacity: .8; }
      .master-classes .section-two .carousel-wrapper .slick-arrow.slick-prev {
        background: url(images/static/arrow-left.svg) no-repeat;
        left: -80px;
        width: 90px;
        height: 90px;
        background-size: contain;
        border: 0;
        color: transparent; }
      .master-classes .section-two .carousel-wrapper .slick-arrow.slick-next {
        right: -80px;
        background: url(images/static/arrow-right.svg) no-repeat;
        width: 90px;
        height: 90px;
        background-size: contain;
        border: 0;
        color: transparent; }

.master-classes .divider-banner {
  background-image: linear-gradient(275deg, #700aad 124%, #8a3ceb 22%);
  padding: 46px 0 52px  0; }
  .master-classes .divider-banner .container {
    display: flex;
    align-items: center; }
    .master-classes .divider-banner .container .col {
      flex-grow: 1;
      display: flex;
      align-items: center; }
      .master-classes .divider-banner .container .col img {
        margin-right: 25px; }
      .master-classes .divider-banner .container .col .text-wrap .text-one {
        font-size: 40px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.66;
        letter-spacing: normal;
        color: #ffffff;
        margin-bottom: 12px; }
      .master-classes .divider-banner .container .col .text-wrap .text-two {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        color: #ffffff; }
      .master-classes .divider-banner .container .col:not(:last-child) {
        margin-right: 60px; }

.master-classes .footer {
  padding-top: 200px; }

.info-wrapper {
  border-radius: 8px;
  box-shadow: 0 2px 44px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(106deg, #fba649, #f2534c);
  padding: 30px;
  max-width: 1080px;
  margin: 0 auto;
  position: relative;
  margin-bottom: -80px; }
  .info-wrapper .row {
    display: flex;
    align-items: flex-start; }
    .info-wrapper .row .col {
      text-align: center; }
      .info-wrapper .row .col:not(:last-child) {
        margin-right: 32px; }
      .info-wrapper .row .col:first-child {
        border-right: solid 2px #bb4039;
        padding: 12px 20px 12px 0;
        min-width: 322px; }
      .info-wrapper .row .col > div {
        height: 40px;
        margin-bottom: 22px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .info-wrapper .row .col > div img {
          max-height: 100%; }
      .info-wrapper .row .col .text-one {
        font-size: 30px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.75px;
        color: #ffffff;
        text-align: left; }
      .info-wrapper .row .col .text-two {
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.69;
        letter-spacing: normal;
        color: #ffffff; }
        .info-wrapper .row .col .text-two span {
          display: block; }
      .info-wrapper .row .col a {
        color: inherit; }

.course-card-with-trainer,
.course-card-light {
  border-radius: 4px;
  box-shadow: 0 2px 37px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px transparent;
  background-color: #ffffff;
  padding: 26px 20px 22px 20px;
  position: relative;
  padding-bottom: 100px; }
  .course-card-with-trainer .title-text,
  .course-card-light .title-text {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262626;
    margin-bottom: 6px;
    height: 44px;
    overflow: hidden; }
  .course-card-with-trainer .sub-text,
  .course-card-light .sub-text {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #464646; }
  .course-card-with-trainer .label,
  .course-card-light .label {
    background-color: rgba(184, 233, 134, 0.7);
    padding: 6px 11px 6px 16px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    color: #2f3a4f;
    display: inline-block;
    margin: 20px 0 16px 0; }
    .course-card-with-trainer .label span,
    .course-card-light .label span {
      font-weight: bold; }
  .course-card-with-trainer .age,
  .course-card-light .age {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #464646;
    margin-bottom: 6px; }
  .course-card-with-trainer .time,
  .course-card-light .time {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #464646;
    margin-bottom: 12px; }
  .course-card-with-trainer .trainer-wrap,
  .course-card-light .trainer-wrap {
    display: flex;
    align-items: center;
    margin-top: 24px; }
    .course-card-with-trainer .trainer-wrap .img-wrap,
    .course-card-light .trainer-wrap .img-wrap {
      width: 75px;
      height: 75px;
      border-radius: 100%;
      margin-right: 12px;
      background: #ccc; }
      .course-card-with-trainer .trainer-wrap .img-wrap img,
      .course-card-light .trainer-wrap .img-wrap img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100%; }
    .course-card-with-trainer .trainer-wrap .content-wrap,
    .course-card-light .trainer-wrap .content-wrap {
      flex-grow: 1; }
      .course-card-with-trainer .trainer-wrap .content-wrap .text,
      .course-card-light .trainer-wrap .content-wrap .text {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.3px;
        color: #2f3a4f; }
      .course-card-with-trainer .trainer-wrap .content-wrap .name,
      .course-card-light .trainer-wrap .content-wrap .name {
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.45px;
        color: #262626;
        margin-bottom: 3px; }
      .course-card-with-trainer .trainer-wrap .content-wrap .expert,
      .course-card-light .trainer-wrap .content-wrap .expert {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.3px;
        color: #ea147b; }
      .course-card-with-trainer .trainer-wrap .content-wrap .qualification,
      .course-card-light .trainer-wrap .content-wrap .qualification {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.35px;
        color: #2f3a4f; }
  .course-card-with-trainer .bottom-section,
  .course-card-light .bottom-section {
    border-top: solid 1px rgba(82, 95, 120, 0.4);
    padding-top: 20px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    position: absolute;
    left: 20px;
    bottom: 0;
    right: 20px;
    margin: 0;
    width: calc(100% - 40px);
    padding: 32px 20px 30px 20px;
    display: flex;
    justify-content: center; }
    .course-card-with-trainer .bottom-section .text-one,
    .course-card-light .bottom-section .text-one {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      text-align: center;
      color: #2a00e8;
      text-transform: uppercase;
      margin: 0;
      display: inline-block;
      position: relative;
      cursor: pointer;
      padding: 8px 16px;
      position: absolute;
      bottom: 16px; }
      .course-card-with-trainer .bottom-section .text-one:hover,
      .course-card-light .bottom-section .text-one:hover {
        border-radius: 2px;
        padding: 8px 16px;
        background-color: #edeafd;
        position: absolute;
        margin-top: -8px; }

.course-card-light {
  border-radius: 4px;
  box-shadow: 0 2px 37px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff; }
  .course-card-light:hover {
    border: solid 1px #2a00e8; }
  .course-card-light .title-text {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262626;
    margin-bottom: 30px; }
  .course-card-light .sub-text {
    margin-bottom: 4px; }
  .course-card-light .flex-wrap {
    justify-content: center; }

.testimonial-card {
  border-radius: 4px;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 20px 15px; }
  .testimonial-card img {
    margin-bottom: 12px; }
  .testimonial-card .text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #464646;
    margin-bottom: 80px; }
  .testimonial-card .user-wrap {
    display: flex;
    align-items: center; }
    .testimonial-card .user-wrap .img-wrap {
      width: 50px;
      height: 50px;
      margin-right: 12px;
      background: #ddd;
      border-radius: 100%; }
    .testimonial-card .user-wrap .content-wrap .text-one {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.45px;
      color: #262626;
      margin-bottom: 2px; }
    .testimonial-card .user-wrap .content-wrap .text-two {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.3px;
      color: #2f3a4f; }

@media screen and (max-width: 768px) {
  .master-classes .ant-select-selection {
    min-width: 110px;
    height: 24px; }
    .master-classes .ant-select-selection .anticon svg {
      font-size: 12px; }
    .master-classes .ant-select-selection .ant-select-selection__rendered {
      line-height: 20px; }
    .master-classes .ant-select-selection .ant-select-selection-selected-value {
      font-size: 8.4px;
      font-weight: 500; }
  .master-classes.hindi .banner-wrapper .title-text {
    max-width: 100%; }
  .master-classes.adults .banner-wrapper {
    background: url(images/master-classes/adult-banner-mobile.png) no-repeat;
    background-size: cover; }
    .master-classes.adults .banner-wrapper .title-text {
      max-width: 100%;
      font-size: 25.6px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.23;
      letter-spacing: normal;
      color: #ffffff; }
    .master-classes.adults .banner-wrapper .sub-text {
      font-size: 10.2px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      color: #ffffff; }
    .master-classes.adults .banner-wrapper .text {
      font-size: 11.5px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff; }
      .master-classes.adults .banner-wrapper .text span {
        color: #ffffff; }
    .master-classes.adults .banner-wrapper button {
      margin-top: 14px;
      font-size: 10.2px;
      padding: 9px 46px; }
  .master-classes.adults .section-one {
    padding-top: 24px; }
  .master-classes.adults .helper-wrapper {
    background: url(images/master-classes/helper-background-mobile.png) no-repeat;
    background-size: 100% 100%;
    margin: 0;
    flex-direction: column;
    width: 100%;
    padding: 52px 24px; }
    .master-classes.adults .helper-wrapper .content .text {
      font-size: 26px;
      font-weight: 300; }
    .master-classes.adults .helper-wrapper .content .sub-text {
      font-size: 11px;
      font-weight: 500;
      line-height: 1.82;
      margin-bottom: 20px; }
    .master-classes.adults .helper-wrapper > div:last-child {
      width: 100%;
      text-align: left; }
    .master-classes.adults .helper-wrapper button {
      font-size: 10.5px;
      padding: 10px 60px; }
  .master-classes .banner-wrapper {
    padding: 120px 28px 28px;
    position: relative;
    height: 450px;
    background: url(images/master-classes/banner-mobile.png) no-repeat;
    background-size: cover; }
    .master-classes .banner-wrapper .title-text {
      font-size: 24px;
      font-weight: 300;
      max-width: 160px; }
    .master-classes .banner-wrapper .sub-text {
      font-size: 9.6px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56; }
    .master-classes .banner-wrapper .list-wrap {
      flex-direction: column;
      padding: 26px 30px;
      position: absolute;
      top: 65%;
      margin: auto;
      left: 6px;
      right: 6px; }
      .master-classes .banner-wrapper .list-wrap .list {
        display: flex;
        justify-content: center;
        text-align: left;
        width: 100%; }
        .master-classes .banner-wrapper .list-wrap .list img {
          margin-right: 15px;
          width: 26px;
          height: 24px;
          object-fit: contain; }
        .master-classes .banner-wrapper .list-wrap .list > div {
          flex-grow: 1; }
          .master-classes .banner-wrapper .list-wrap .list > div .text-one {
            font-size: 14px;
            font-weight: 500;
            text-align: left;
            margin-bottom: 5px; }
          .master-classes .banner-wrapper .list-wrap .list > div .text-two {
            font-size: 11.2px;
            font-weight: normal;
            text-align: left; }
        .master-classes .banner-wrapper .list-wrap .list:not(:last-child) {
          margin: 0 0 16px 0; }
  .master-classes .section-one {
    padding-top: 100px; }
    .master-classes .section-one .container {
      padding: 0; }
    .master-classes .section-one .header-wrap {
      padding: 0 12px; }
    .master-classes .section-one .carousel-wrapper {
      margin: 20px 0 0 0; }
      .master-classes .section-one .carousel-wrapper .testimonial-card {
        margin-right: 20px; }
      .master-classes .section-one .carousel-wrapper .project-card {
        margin-right: 20px; }
      .master-classes .section-one .carousel-wrapper .tutorial-card {
        margin-right: 33px; }
      .master-classes .section-one .carousel-wrapper .slick-track {
        display: flex !important; }
      .master-classes .section-one .carousel-wrapper .slick-slide {
        height: inherit !important; }
      .master-classes .section-one .carousel-wrapper .slick-list {
        padding: 24px 0;
        position: relative; }
      .master-classes .section-one .carousel-wrapper .slick-slide {
        padding: 0 16px; }
      .master-classes .section-one .carousel-wrapper .slick-arrow {
        position: absolute;
        top: calc(50% - 38px);
        outline: none;
        cursor: pointer; }
        .master-classes .section-one .carousel-wrapper .slick-arrow.slick-disabled {
          pointer-events: none;
          opacity: .8; }
        .master-classes .section-one .carousel-wrapper .slick-arrow.slick-prev {
          background: url(images/master-classes/arrow-left-white.svg) no-repeat;
          left: 0px;
          width: 37px;
          height: 37px;
          background-size: contain;
          border: 0;
          color: transparent;
          z-index: 6; }
        .master-classes .section-one .carousel-wrapper .slick-arrow.slick-next {
          right: 0px;
          background: url(images/master-classes/arrow-right-white.svg) no-repeat;
          width: 37px;
          height: 37px;
          background-size: contain;
          border: 0;
          color: transparent;
          z-index: 6; }
    .master-classes .section-one .text-one {
      text-align: left;
      margin-bottom: 12px;
      font-size: 12px;
      font-weight: 500; }
    .master-classes .section-one .header-wrap {
      flex-direction: column;
      width: 100%;
      align-items: flex-start; }
      .master-classes .section-one .header-wrap .filter-wrap {
        display: flex;
        align-items: center; }
        .master-classes .section-one .header-wrap .filter-wrap .filter p {
          font-size: 8.4px; }
        .master-classes .section-one .header-wrap .filter-wrap .filter:not(::last-child) {
          margin-right: 20px; }
    .master-classes .section-one .cards-wrapper {
      display: block;
      margin: 24px 0;
      padding: 0 12px; }
      .master-classes .section-one .cards-wrapper .course-card-with-trainer {
        margin-bottom: 12px; }
      .master-classes .section-one .cards-wrapper .course-card {
        margin-bottom: 18px; }
  .master-classes .section-two {
    padding: 38px 0 70px 0; }
    .master-classes .section-two h2 {
      font-size: 22px;
      font-weight: 300;
      padding: 0 20px; }
    .master-classes .section-two .carousel-wrapper {
      padding: 0 0 0 20px;
      margin: 20px 0 0 0; }
      .master-classes .section-two .carousel-wrapper .testimonial-card {
        margin-right: 0; }
      .master-classes .section-two .carousel-wrapper .slick-slide {
        padding: 0 16px 0 0; }
  .master-classes .divider-banner {
    padding: 46px 13px; }
    .master-classes .divider-banner .container {
      padding: 0;
      display: block; }
      .master-classes .divider-banner .container .col {
        display: inline-flex;
        min-width: 50%;
        margin: 0;
        max-width: 50%; }
        .master-classes .divider-banner .container .col:first-child, .master-classes .divider-banner .container .col:nth-child(2) {
          margin-bottom: 48px; }
        .master-classes .divider-banner .container .col img {
          width: 28.7px;
          height: 29px;
          margin-right: 15px; }
        .master-classes .divider-banner .container .col .text-wrap .text-one {
          font-size: 25.2px;
          font-weight: 300;
          line-height: 0.66; }
        .master-classes .divider-banner .container .col .text-wrap .text-two {
          font-size: 10.1px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;
          letter-spacing: normal;
          color: #ffffff; }
        .master-classes .divider-banner .container .col:not(:last-child) {
          margin-right: 0; }
  .testimonial-card .text {
    font-size: 11.9px;
    margin-bottom: 36px; }
  .testimonial-card > img {
    width: 18.7px;
    height: 39.1px;
    object-fit: contain; }
  .testimonial-card .user-wrap .img-wrap {
    width: 42.5px;
    height: 42.5px; }
  .testimonial-card .user-wrap .content-wrap .text-one {
    font-size: 15.3px; }
  .testimonial-card .user-wrap .content-wrap .text-two {
    font-size: 10.2px; }
  .course-card-with-trainer,
  .course-card-light {
    border-radius: 3.4px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.19);
    background-color: #ffffff;
    padding: 22px 16px 16px 16px; }
    .course-card-with-trainer .title-text,
    .course-card-light .title-text {
      font-size: 13.6px;
      margin-bottom: 4.8px; }
    .course-card-with-trainer .sub-text,
    .course-card-light .sub-text {
      font-size: 11.9px;
      font-weight: 500; }
    .course-card-with-trainer .label,
    .course-card-light .label {
      font-size: 10.2px;
      margin: 23px 0 19px 0; }
      .course-card-with-trainer .label span,
      .course-card-light .label span {
        font-weight: bold; }
    .course-card-with-trainer .age,
    .course-card-light .age {
      font-size: 11.9px;
      margin-bottom: 4.8px; }
    .course-card-with-trainer .time,
    .course-card-light .time {
      font-size: 11.9px;
      margin-bottom: 9.9px; }
    .course-card-with-trainer .trainer-wrap,
    .course-card-light .trainer-wrap {
      display: flex;
      align-items: center; }
      .course-card-with-trainer .trainer-wrap .img-wrap,
      .course-card-light .trainer-wrap .img-wrap {
        width: 63.8px;
        height: 63.8px;
        margin-right: 10px; }
      .course-card-with-trainer .trainer-wrap .content-wrap,
      .course-card-light .trainer-wrap .content-wrap {
        flex-grow: 1; }
        .course-card-with-trainer .trainer-wrap .content-wrap .text,
        .course-card-light .trainer-wrap .content-wrap .text {
          font-size: 10.2px; }
        .course-card-with-trainer .trainer-wrap .content-wrap .name,
        .course-card-light .trainer-wrap .content-wrap .name {
          font-size: 15.3px; }
        .course-card-with-trainer .trainer-wrap .content-wrap .expert,
        .course-card-light .trainer-wrap .content-wrap .expert {
          font-size: 10.2px;
          margin-bottom: 3px; }
        .course-card-with-trainer .trainer-wrap .content-wrap .qualification,
        .course-card-light .trainer-wrap .content-wrap .qualification {
          font-size: 11.9px; }
    .course-card-with-trainer .bottom-section,
    .course-card-light .bottom-section {
      padding-top: 16px;
      margin-top: 25px;
      position: relative;
      left: 0;
      right: 0;
      width: 100%;
      padding: 12px 0 0; }
      .course-card-with-trainer .bottom-section .text-one,
      .course-card-light .bottom-section .text-one {
        font-size: 11.9px;
        letter-spacing: 0.43px;
        text-align: center;
        margin: 0;
        position: relative;
        bottom: 0; }
        .course-card-with-trainer .bottom-section .text-one:hover,
        .course-card-light .bottom-section .text-one:hover {
          border-radius: 0;
          background-color: transparent;
          position: relative;
          margin-top: 0; }
  .course-card-light:hover {
    border: 0; }
  .info-wrapper {
    border-radius: 0;
    box-shadow: none;
    max-width: 100%;
    margin-bottom: 0; }
    .info-wrapper .row {
      flex-direction: column; }
      .info-wrapper .row .col {
        margin-bottom: 30px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%; }
        .info-wrapper .row .col > div {
          margin-bottom: 0;
          min-width: 50px;
          justify-content: flex-start; }
        .info-wrapper .row .col img {
          max-width: 22px;
          margin: 0 14px 0 0; }
        .info-wrapper .row .col:not(:last-child) {
          margin-right: 0; }
        .info-wrapper .row .col:first-child {
          border-right: 0;
          padding: 0;
          justify-content: flex-start; }
        .info-wrapper .row .col .text-one {
          font-size: 18px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.64px;
          text-align: left;
          color: #ffffff; }
        .info-wrapper .row .col .text-two {
          font-size: 12px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 2.41;
          letter-spacing: normal;
          text-align: left;
          color: #ffffff; } }

.study_in_uk.adults .banner-wrapper {
  background: url(images/master-classes/adult-banner.png) no-repeat;
  background-size: cover;
  padding-bottom: 160px; }
  .study_in_uk.adults .banner-wrapper .title-text {
    font-size: 40px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: #1e174a;
    margin-bottom: 8px;
    text-shadow: none; }
  .study_in_uk.adults .banner-wrapper .sub-text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #1e174a;
    margin-bottom: 4px; }
  .study_in_uk.adults .banner-wrapper .text {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1e174a; }
    .study_in_uk.adults .banner-wrapper .text span {
      color: #4922f3; }
  .study_in_uk.adults .banner-wrapper button {
    border-radius: 2px;
    padding: 15px 52px;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.16px;
    text-align: center;
    color: #ffffff;
    outline: none;
    cursor: pointer;
    border: 0;
    margin-top: 30px; }

.study_in_uk.adults .container.helper {
  max-width: 1140px; }

.study_in_uk.adults .helper-wrapper {
  background: url(images/master-classes/helper-background.png) no-repeat;
  background-size: 100% 100%;
  padding: 44px 58px;
  display: flex;
  margin: 124px 0 130px 0;
  align-items: center; }
  .study_in_uk.adults .helper-wrapper button {
    border-radius: 2px;
    padding: 15px 52px;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.16px;
    text-align: center;
    color: #ffffff;
    outline: none;
    cursor: pointer;
    border: 0; }
  .study_in_uk.adults .helper-wrapper > div:last-child {
    flex-grow: 1;
    text-align: right; }
  .study_in_uk.adults .helper-wrapper .content {
    max-width: 460px; }
    .study_in_uk.adults .helper-wrapper .content > img {
      max-width: 116px; }
    .study_in_uk.adults .helper-wrapper .content .text {
      font-size: 36px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.9px;
      text-align: left;
      color: #2f3a4f;
      margin-bottom: 4px; }
    .study_in_uk.adults .helper-wrapper .content .sub-text {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #464646; }
      .study_in_uk.adults .helper-wrapper .content .sub-text span {
        font-weight: bold;
        color: #2a00e8; }

.study_in_uk.adults .section-one {
  padding-top: 60px; }

.study_in_uk .container {
  max-width: 1080px;
  margin: 0 auto; }

.study_in_uk .white-button {
  border-radius: 4px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  cursor: pointer;
  padding: 13px 40px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #2a00e8;
  border: 0; }

.study_in_uk .ant-select-selection {
  border-radius: 4px;
  border: solid 1px #ced0da;
  background-image: linear-gradient(to top, #f2f4f7, #ffffff);
  min-width: 180px; }
  .study_in_uk .ant-select-selection .anticon svg {
    color: #3031ea; }

.study_in_uk .banner-wrapper {
  background: url(images/banners/unitedkindom.webp) no-repeat;
  background-size: cover;
  padding-top: 160px; }
  .study_in_uk .banner-wrapper .title-text {
    font-size: 40px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
    margin-bottom: 5px;
    max-width: 480px;
    background: white; }
  .study_in_uk .banner-wrapper .sub-text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #000;
    max-width: 62rem;
    background: white; }
  .study_in_uk .banner-wrapper .list-wrap {
    border-radius: 6px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 48px 74px;
    position: relative;
    display: flex;
    align-items: flex-start;
    top: 42px; }
    .study_in_uk .banner-wrapper .list-wrap .list {
      flex-grow: 1;
      flex-basis: 100%;
      text-align: center; }
      .study_in_uk .banner-wrapper .list-wrap .list:not(:last-child) {
        margin-right: 60px; }
      .study_in_uk .banner-wrapper .list-wrap .list img {
        margin-bottom: 24px; }
      .study_in_uk .banner-wrapper .list-wrap .list .text-one {
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.32;
        letter-spacing: normal;
        color: #262626;
        margin-bottom: 7px; }
      .study_in_uk .banner-wrapper .list-wrap .list .text-two {
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: #2f3a4f; }

.study_in_uk .section-one {
  padding-top: 120px; }
  .study_in_uk .section-one .text-one {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #262626; }
    .study_in_uk .section-one .text-one span {
      font-weight: bold; }
  .study_in_uk .section-one .header-wrap {
    display: flex;
    align-items: flex-end; }
  .study_in_uk .section-one .filter-wrap {
    flex-grow: 1;
    text-align: right; }
    .study_in_uk .section-one .filter-wrap .filter {
      display: inline-flex;
      align-items: center; }
      .study_in_uk .section-one .filter-wrap .filter:not(:last-child) {
        margin-right: 32px; }
      .study_in_uk .section-one .filter-wrap .filter > p {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #7f8fa4;
        margin-right: 12px; }
  .study_in_uk .section-one .cards-wrapper {
    margin: 40px 0 55px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px; }
  .study_in_uk .section-one .button-wrap {
    margin-bottom: 100px;
    text-align: center; }

.study_in_uk .section-two {
  background-image: linear-gradient(248deg, #f8f8f8, #efefef -25%);
  padding: 50px 0 100px 0; }
  .study_in_uk .section-two h2 {
    font-size: 36px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.9px;
    text-align: center;
    color: #2f3a4f;
    max-width: 540px;
    margin: 0 auto 34px auto; }
  .study_in_uk .section-two .carousel-wrapper {
    margin: 20px 0 0 0; }
    .study_in_uk .section-two .carousel-wrapper .testimonial-card {
      margin-right: 20px; }
    .study_in_uk .section-two .carousel-wrapper .project-card {
      margin-right: 20px; }
    .study_in_uk .section-two .carousel-wrapper .tutorial-card {
      margin-right: 33px; }
    .study_in_uk .section-two .carousel-wrapper .slick-list {
      padding: 24px 0;
      position: relative; }
    .study_in_uk .section-two .carousel-wrapper .slick-arrow {
      position: absolute;
      top: calc(50% - 38px);
      outline: none;
      cursor: pointer; }
      .study_in_uk .section-two .carousel-wrapper .slick-arrow.slick-disabled {
        pointer-events: none;
        opacity: .8; }
      .study_in_uk .section-two .carousel-wrapper .slick-arrow.slick-prev {
        background: url(images/static/arrow-left.svg) no-repeat;
        left: -80px;
        width: 90px;
        height: 90px;
        background-size: contain;
        border: 0;
        color: transparent; }
      .study_in_uk .section-two .carousel-wrapper .slick-arrow.slick-next {
        right: -80px;
        background: url(images/static/arrow-right.svg) no-repeat;
        width: 90px;
        height: 90px;
        background-size: contain;
        border: 0;
        color: transparent; }

.study_in_uk .divider-banner {
  background-image: linear-gradient(275deg, #700aad 124%, #8a3ceb 22%);
  padding: 46px 0 52px  0; }
  .study_in_uk .divider-banner .container {
    display: flex;
    align-items: center; }
    .study_in_uk .divider-banner .container .col {
      flex-grow: 1;
      display: flex;
      align-items: center; }
      .study_in_uk .divider-banner .container .col img {
        margin-right: 25px; }
      .study_in_uk .divider-banner .container .col .text-wrap .text-one {
        font-size: 40px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.66;
        letter-spacing: normal;
        color: #ffffff;
        margin-bottom: 12px; }
      .study_in_uk .divider-banner .container .col .text-wrap .text-two {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        color: #ffffff; }
      .study_in_uk .divider-banner .container .col:not(:last-child) {
        margin-right: 60px; }

.study_in_uk .footer {
  padding-top: 200px; }

.info-wrapper {
  border-radius: 8px;
  box-shadow: 0 2px 44px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(106deg, #fba649, #f2534c);
  padding: 30px;
  max-width: 1080px;
  margin: 0 auto;
  position: relative;
  margin-bottom: -80px; }
  .info-wrapper .row {
    display: flex;
    align-items: flex-start; }
    .info-wrapper .row .col {
      text-align: center; }
      .info-wrapper .row .col:not(:last-child) {
        margin-right: 32px; }
      .info-wrapper .row .col:first-child {
        border-right: solid 2px #bb4039;
        padding: 12px 20px 12px 0;
        min-width: 322px; }
      .info-wrapper .row .col > div {
        height: 40px;
        margin-bottom: 22px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .info-wrapper .row .col > div img {
          max-height: 100%; }
      .info-wrapper .row .col .text-one {
        font-size: 30px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.75px;
        color: #ffffff;
        text-align: left; }
      .info-wrapper .row .col .text-two {
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.69;
        letter-spacing: normal;
        color: #ffffff; }
        .info-wrapper .row .col .text-two span {
          display: block; }
      .info-wrapper .row .col a {
        color: inherit; }

.course-card-with-trainer,
.course-card-light {
  border-radius: 4px;
  box-shadow: 0 2px 37px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px transparent;
  background-color: #ffffff;
  padding: 26px 20px 22px 20px;
  position: relative;
  padding-bottom: 100px; }
  .course-card-with-trainer .title-text,
  .course-card-light .title-text {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262626;
    margin-bottom: 6px;
    height: 44px;
    overflow: hidden; }
  .course-card-with-trainer .sub-text,
  .course-card-light .sub-text {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #464646; }
  .course-card-with-trainer .label,
  .course-card-light .label {
    background-color: rgba(184, 233, 134, 0.7);
    padding: 6px 11px 6px 16px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    color: #2f3a4f;
    display: inline-block;
    margin: 20px 0 16px 0; }
    .course-card-with-trainer .label span,
    .course-card-light .label span {
      font-weight: bold; }
  .course-card-with-trainer .age,
  .course-card-light .age {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #464646;
    margin-bottom: 6px; }
  .course-card-with-trainer .time,
  .course-card-light .time {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #464646;
    margin-bottom: 12px; }
  .course-card-with-trainer .trainer-wrap,
  .course-card-light .trainer-wrap {
    display: flex;
    align-items: center;
    margin-top: 24px; }
    .course-card-with-trainer .trainer-wrap .img-wrap,
    .course-card-light .trainer-wrap .img-wrap {
      width: 75px;
      height: 75px;
      border-radius: 100%;
      margin-right: 12px;
      background: #ccc; }
      .course-card-with-trainer .trainer-wrap .img-wrap img,
      .course-card-light .trainer-wrap .img-wrap img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100%; }
    .course-card-with-trainer .trainer-wrap .content-wrap,
    .course-card-light .trainer-wrap .content-wrap {
      flex-grow: 1; }
      .course-card-with-trainer .trainer-wrap .content-wrap .text,
      .course-card-light .trainer-wrap .content-wrap .text {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.3px;
        color: #2f3a4f; }
      .course-card-with-trainer .trainer-wrap .content-wrap .name,
      .course-card-light .trainer-wrap .content-wrap .name {
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.45px;
        color: #262626;
        margin-bottom: 3px; }
      .course-card-with-trainer .trainer-wrap .content-wrap .expert,
      .course-card-light .trainer-wrap .content-wrap .expert {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.3px;
        color: #ea147b; }
      .course-card-with-trainer .trainer-wrap .content-wrap .qualification,
      .course-card-light .trainer-wrap .content-wrap .qualification {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.35px;
        color: #2f3a4f; }
  .course-card-with-trainer .bottom-section,
  .course-card-light .bottom-section {
    border-top: solid 1px rgba(82, 95, 120, 0.4);
    padding-top: 20px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    position: absolute;
    left: 20px;
    bottom: 0;
    right: 20px;
    margin: 0;
    width: calc(100% - 40px);
    padding: 32px 20px 30px 20px;
    display: flex;
    justify-content: center; }
    .course-card-with-trainer .bottom-section .text-one,
    .course-card-light .bottom-section .text-one {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      text-align: center;
      color: #2a00e8;
      text-transform: uppercase;
      margin: 0;
      display: inline-block;
      position: relative;
      cursor: pointer;
      padding: 8px 16px;
      position: absolute;
      bottom: 16px; }
      .course-card-with-trainer .bottom-section .text-one:hover,
      .course-card-light .bottom-section .text-one:hover {
        border-radius: 2px;
        padding: 8px 16px;
        background-color: #edeafd;
        position: absolute;
        margin-top: -8px; }

.course-card-light {
  border-radius: 4px;
  box-shadow: 0 2px 37px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff; }
  .course-card-light:hover {
    border: solid 1px #2a00e8; }
  .course-card-light .title-text {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262626;
    margin-bottom: 30px; }
  .course-card-light .sub-text {
    margin-bottom: 4px; }
  .course-card-light .flex-wrap {
    justify-content: center; }

.testimonial-card {
  border-radius: 4px;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 20px 15px; }
  .testimonial-card img {
    margin-bottom: 12px; }
  .testimonial-card .text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #464646;
    margin-bottom: 80px; }
  .testimonial-card .user-wrap {
    display: flex;
    align-items: center; }
    .testimonial-card .user-wrap .img-wrap {
      width: 50px;
      height: 50px;
      margin-right: 12px;
      background: #ddd;
      border-radius: 100%; }
    .testimonial-card .user-wrap .content-wrap .text-one {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.45px;
      color: #262626;
      margin-bottom: 2px; }
    .testimonial-card .user-wrap .content-wrap .text-two {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.3px;
      color: #2f3a4f; }

@media screen and (max-width: 768px) {
  .study_in_uk .ant-select-selection {
    min-width: 110px;
    height: 24px; }
    .study_in_uk .ant-select-selection .anticon svg {
      font-size: 12px; }
    .study_in_uk .ant-select-selection .ant-select-selection__rendered {
      line-height: 20px; }
    .study_in_uk .ant-select-selection .ant-select-selection-selected-value {
      font-size: 8.4px;
      font-weight: 500; }
  .study_in_uk.hindi .banner-wrapper .title-text {
    max-width: 100%; }
  .study_in_uk.adults .banner-wrapper {
    background: url(images/master-classes/adult-banner-mobile.png) no-repeat;
    background-size: cover; }
    .study_in_uk.adults .banner-wrapper .title-text {
      max-width: 100%;
      font-size: 25.6px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.23;
      letter-spacing: normal;
      color: #ffffff; }
    .study_in_uk.adults .banner-wrapper .sub-text {
      font-size: 10.2px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      color: #ffffff; }
    .study_in_uk.adults .banner-wrapper .text {
      font-size: 11.5px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff; }
      .study_in_uk.adults .banner-wrapper .text span {
        color: #ffffff; }
    .study_in_uk.adults .banner-wrapper button {
      margin-top: 14px;
      font-size: 10.2px;
      padding: 9px 46px; }
  .study_in_uk.adults .section-one {
    padding-top: 24px; }
  .study_in_uk.adults .helper-wrapper {
    background: url(images/master-classes/helper-background-mobile.png) no-repeat;
    background-size: 100% 100%;
    margin: 0;
    flex-direction: column;
    width: 100%;
    padding: 52px 24px; }
    .study_in_uk.adults .helper-wrapper .content .text {
      font-size: 26px;
      font-weight: 300; }
    .study_in_uk.adults .helper-wrapper .content .sub-text {
      font-size: 11px;
      font-weight: 500;
      line-height: 1.82;
      margin-bottom: 20px; }
    .study_in_uk.adults .helper-wrapper > div:last-child {
      width: 100%;
      text-align: left; }
    .study_in_uk.adults .helper-wrapper button {
      font-size: 10.5px;
      padding: 10px 60px; }
  .study_in_uk .banner-wrapper {
    padding: 120px 28px 28px;
    position: relative;
    height: 450px;
    background: url(images/master-classes/banner-mobile.png) no-repeat;
    background-size: cover; }
    .study_in_uk .banner-wrapper .title-text {
      font-size: 24px;
      font-weight: 300;
      max-width: 160px; }
    .study_in_uk .banner-wrapper .sub-text {
      font-size: 9.6px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56; }
    .study_in_uk .banner-wrapper .list-wrap {
      flex-direction: column;
      padding: 26px 30px;
      position: absolute;
      top: 65%;
      margin: auto;
      left: 6px;
      right: 6px; }
      .study_in_uk .banner-wrapper .list-wrap .list {
        display: flex;
        justify-content: center;
        text-align: left;
        width: 100%; }
        .study_in_uk .banner-wrapper .list-wrap .list img {
          margin-right: 15px;
          width: 26px;
          height: 24px;
          object-fit: contain; }
        .study_in_uk .banner-wrapper .list-wrap .list > div {
          flex-grow: 1; }
          .study_in_uk .banner-wrapper .list-wrap .list > div .text-one {
            font-size: 14px;
            font-weight: 500;
            text-align: left;
            margin-bottom: 5px; }
          .study_in_uk .banner-wrapper .list-wrap .list > div .text-two {
            font-size: 11.2px;
            font-weight: normal;
            text-align: left; }
        .study_in_uk .banner-wrapper .list-wrap .list:not(:last-child) {
          margin: 0 0 16px 0; }
  .study_in_uk .section-one {
    padding-top: 100px; }
    .study_in_uk .section-one .container {
      padding: 0; }
    .study_in_uk .section-one .header-wrap {
      padding: 0 12px; }
    .study_in_uk .section-one .carousel-wrapper {
      margin: 20px 0 0 0; }
      .study_in_uk .section-one .carousel-wrapper .testimonial-card {
        margin-right: 20px; }
      .study_in_uk .section-one .carousel-wrapper .project-card {
        margin-right: 20px; }
      .study_in_uk .section-one .carousel-wrapper .tutorial-card {
        margin-right: 33px; }
      .study_in_uk .section-one .carousel-wrapper .slick-track {
        display: flex !important; }
      .study_in_uk .section-one .carousel-wrapper .slick-slide {
        height: inherit !important; }
      .study_in_uk .section-one .carousel-wrapper .slick-list {
        padding: 24px 0;
        position: relative; }
      .study_in_uk .section-one .carousel-wrapper .slick-slide {
        padding: 0 16px; }
      .study_in_uk .section-one .carousel-wrapper .slick-arrow {
        position: absolute;
        top: calc(50% - 38px);
        outline: none;
        cursor: pointer; }
        .study_in_uk .section-one .carousel-wrapper .slick-arrow.slick-disabled {
          pointer-events: none;
          opacity: .8; }
        .study_in_uk .section-one .carousel-wrapper .slick-arrow.slick-prev {
          background: url(images/master-classes/arrow-left-white.svg) no-repeat;
          left: 0px;
          width: 37px;
          height: 37px;
          background-size: contain;
          border: 0;
          color: transparent;
          z-index: 6; }
        .study_in_uk .section-one .carousel-wrapper .slick-arrow.slick-next {
          right: 0px;
          background: url(images/master-classes/arrow-right-white.svg) no-repeat;
          width: 37px;
          height: 37px;
          background-size: contain;
          border: 0;
          color: transparent;
          z-index: 6; }
    .study_in_uk .section-one .text-one {
      text-align: left;
      margin-bottom: 12px;
      font-size: 12px;
      font-weight: 500; }
    .study_in_uk .section-one .header-wrap {
      flex-direction: column;
      width: 100%;
      align-items: flex-start; }
      .study_in_uk .section-one .header-wrap .filter-wrap {
        display: flex;
        align-items: center; }
        .study_in_uk .section-one .header-wrap .filter-wrap .filter p {
          font-size: 8.4px; }
        .study_in_uk .section-one .header-wrap .filter-wrap .filter:not(::last-child) {
          margin-right: 20px; }
    .study_in_uk .section-one .cards-wrapper {
      display: inline;
      margin: 24px 0;
      padding: 0 12px; }
      .study_in_uk .section-one .cards-wrapper .course-card-with-trainer {
        margin-bottom: 12px; }
      .study_in_uk .section-one .cards-wrapper .course-card {
        margin-bottom: 18px; }
  .study_in_uk .section-two {
    padding: 38px 0 70px 0; }
    .study_in_uk .section-two h2 {
      font-size: 22px;
      font-weight: 300;
      padding: 0 20px; }
    .study_in_uk .section-two .carousel-wrapper {
      padding: 0 0 0 20px;
      margin: 20px 0 0 0; }
      .study_in_uk .section-two .carousel-wrapper .testimonial-card {
        margin-right: 0; }
      .study_in_uk .section-two .carousel-wrapper .slick-slide {
        padding: 0 16px 0 0; }
  .study_in_uk .divider-banner {
    padding: 46px 13px; }
    .study_in_uk .divider-banner .container {
      padding: 0;
      display: block; }
      .study_in_uk .divider-banner .container .col {
        display: inline-flex;
        min-width: 50%;
        margin: 0;
        max-width: 50%; }
        .study_in_uk .divider-banner .container .col:first-child, .study_in_uk .divider-banner .container .col:nth-child(2) {
          margin-bottom: 48px; }
        .study_in_uk .divider-banner .container .col img {
          width: 28.7px;
          height: 29px;
          margin-right: 15px; }
        .study_in_uk .divider-banner .container .col .text-wrap .text-one {
          font-size: 25.2px;
          font-weight: 300;
          line-height: 0.66; }
        .study_in_uk .divider-banner .container .col .text-wrap .text-two {
          font-size: 10.1px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;
          letter-spacing: normal;
          color: #ffffff; }
        .study_in_uk .divider-banner .container .col:not(:last-child) {
          margin-right: 0; }
  .testimonial-card .text {
    font-size: 11.9px;
    margin-bottom: 36px; }
  .testimonial-card > img {
    width: 18.7px;
    height: 39.1px;
    object-fit: contain; }
  .testimonial-card .user-wrap .img-wrap {
    width: 42.5px;
    height: 42.5px; }
  .testimonial-card .user-wrap .content-wrap .text-one {
    font-size: 15.3px; }
  .testimonial-card .user-wrap .content-wrap .text-two {
    font-size: 10.2px; }
  .course-card-with-trainer,
  .course-card-light {
    border-radius: 3.4px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.19);
    background-color: #ffffff;
    padding: 22px 16px 16px 16px; }
    .course-card-with-trainer .title-text,
    .course-card-light .title-text {
      font-size: 13.6px;
      margin-bottom: 4.8px; }
    .course-card-with-trainer .sub-text,
    .course-card-light .sub-text {
      font-size: 11.9px;
      font-weight: 500; }
    .course-card-with-trainer .label,
    .course-card-light .label {
      font-size: 10.2px;
      margin: 23px 0 19px 0; }
      .course-card-with-trainer .label span,
      .course-card-light .label span {
        font-weight: bold; }
    .course-card-with-trainer .age,
    .course-card-light .age {
      font-size: 11.9px;
      margin-bottom: 4.8px; }
    .course-card-with-trainer .time,
    .course-card-light .time {
      font-size: 11.9px;
      margin-bottom: 9.9px; }
    .course-card-with-trainer .trainer-wrap,
    .course-card-light .trainer-wrap {
      display: flex;
      align-items: center; }
      .course-card-with-trainer .trainer-wrap .img-wrap,
      .course-card-light .trainer-wrap .img-wrap {
        width: 63.8px;
        height: 63.8px;
        margin-right: 10px; }
      .course-card-with-trainer .trainer-wrap .content-wrap,
      .course-card-light .trainer-wrap .content-wrap {
        flex-grow: 1; }
        .course-card-with-trainer .trainer-wrap .content-wrap .text,
        .course-card-light .trainer-wrap .content-wrap .text {
          font-size: 10.2px; }
        .course-card-with-trainer .trainer-wrap .content-wrap .name,
        .course-card-light .trainer-wrap .content-wrap .name {
          font-size: 15.3px; }
        .course-card-with-trainer .trainer-wrap .content-wrap .expert,
        .course-card-light .trainer-wrap .content-wrap .expert {
          font-size: 10.2px;
          margin-bottom: 3px; }
        .course-card-with-trainer .trainer-wrap .content-wrap .qualification,
        .course-card-light .trainer-wrap .content-wrap .qualification {
          font-size: 11.9px; }
    .course-card-with-trainer .bottom-section,
    .course-card-light .bottom-section {
      padding-top: 16px;
      margin-top: 25px;
      position: relative;
      left: 0;
      right: 0;
      width: 100%;
      padding: 12px 0 0; }
      .course-card-with-trainer .bottom-section .text-one,
      .course-card-light .bottom-section .text-one {
        font-size: 11.9px;
        letter-spacing: 0.43px;
        text-align: center;
        margin: 0;
        position: relative;
        bottom: 0; }
        .course-card-with-trainer .bottom-section .text-one:hover,
        .course-card-light .bottom-section .text-one:hover {
          border-radius: 0;
          background-color: transparent;
          position: relative;
          margin-top: 0; }
  .course-card-light:hover {
    border: 0; }
  .info-wrapper {
    border-radius: 0;
    box-shadow: none;
    max-width: 100%;
    margin-bottom: 0; }
    .info-wrapper .row {
      flex-direction: column; }
      .info-wrapper .row .col {
        margin-bottom: 30px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%; }
        .info-wrapper .row .col > div {
          margin-bottom: 0;
          min-width: 50px;
          justify-content: flex-start; }
        .info-wrapper .row .col img {
          max-width: 22px;
          margin: 0 14px 0 0; }
        .info-wrapper .row .col:not(:last-child) {
          margin-right: 0; }
        .info-wrapper .row .col:first-child {
          border-right: 0;
          padding: 0;
          justify-content: flex-start; }
        .info-wrapper .row .col .text-one {
          font-size: 18px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.64px;
          text-align: left;
          color: #ffffff; }
        .info-wrapper .row .col .text-two {
          font-size: 12px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 2.41;
          letter-spacing: normal;
          text-align: left;
          color: #ffffff; } }

.study_in_ireland.adults .banner-wrapper {
  background: url(images/master-classes/adult-banner.png) no-repeat;
  background-size: cover;
  padding-bottom: 160px; }
  .study_in_ireland.adults .banner-wrapper .title-text {
    font-size: 40px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: #1e174a;
    margin-bottom: 8px;
    text-shadow: none; }
  .study_in_ireland.adults .banner-wrapper .sub-text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #1e174a;
    margin-bottom: 4px; }
  .study_in_ireland.adults .banner-wrapper .text {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1e174a; }
    .study_in_ireland.adults .banner-wrapper .text span {
      color: #4922f3; }
  .study_in_ireland.adults .banner-wrapper button {
    border-radius: 2px;
    padding: 15px 52px;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.16px;
    text-align: center;
    color: #ffffff;
    outline: none;
    cursor: pointer;
    border: 0;
    margin-top: 30px; }

.study_in_ireland.adults .container.helper {
  max-width: 1140px; }

.study_in_ireland.adults .helper-wrapper {
  background: url(images/master-classes/helper-background.png) no-repeat;
  background-size: 100% 100%;
  padding: 44px 58px;
  display: flex;
  margin: 124px 0 130px 0;
  align-items: center; }
  .study_in_ireland.adults .helper-wrapper button {
    border-radius: 2px;
    padding: 15px 52px;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.16px;
    text-align: center;
    color: #ffffff;
    outline: none;
    cursor: pointer;
    border: 0; }
  .study_in_ireland.adults .helper-wrapper > div:last-child {
    flex-grow: 1;
    text-align: right; }
  .study_in_ireland.adults .helper-wrapper .content {
    max-width: 460px; }
    .study_in_ireland.adults .helper-wrapper .content > img {
      max-width: 116px; }
    .study_in_ireland.adults .helper-wrapper .content .text {
      font-size: 36px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.9px;
      text-align: left;
      color: #2f3a4f;
      margin-bottom: 4px; }
    .study_in_ireland.adults .helper-wrapper .content .sub-text {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #464646; }
      .study_in_ireland.adults .helper-wrapper .content .sub-text span {
        font-weight: bold;
        color: #2a00e8; }

.study_in_ireland.adults .section-one {
  padding-top: 60px; }

.study_in_ireland .container {
  max-width: 1080px;
  margin: 0 auto; }

.study_in_ireland .white-button {
  border-radius: 4px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  cursor: pointer;
  padding: 13px 40px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #2a00e8;
  border: 0; }

.study_in_ireland .ant-select-selection {
  border-radius: 4px;
  border: solid 1px #ced0da;
  background-image: linear-gradient(to top, #f2f4f7, #ffffff);
  min-width: 180px; }
  .study_in_ireland .ant-select-selection .anticon svg {
    color: #3031ea; }

.study_in_ireland .banner-wrapper {
  background: url(images/banners/banners.webp) no-repeat;
  background-size: cover;
  padding-top: 160px; }
  .study_in_ireland .banner-wrapper .title-text {
    font-size: 40px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
    margin-bottom: 5px;
    max-width: 320px;
    background: white; }
  .study_in_ireland .banner-wrapper .sub-text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #000;
    max-width: 54rem;
    background: white; }
  .study_in_ireland .banner-wrapper .list-wrap {
    border-radius: 6px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 48px 74px;
    position: relative;
    display: flex;
    align-items: flex-start;
    top: 42px; }
    .study_in_ireland .banner-wrapper .list-wrap .list {
      flex-grow: 1;
      flex-basis: 100%;
      text-align: center; }
      .study_in_ireland .banner-wrapper .list-wrap .list:not(:last-child) {
        margin-right: 60px; }
      .study_in_ireland .banner-wrapper .list-wrap .list img {
        margin-bottom: 24px; }
      .study_in_ireland .banner-wrapper .list-wrap .list .text-one {
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.32;
        letter-spacing: normal;
        color: #262626;
        margin-bottom: 7px; }
      .study_in_ireland .banner-wrapper .list-wrap .list .text-two {
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: #2f3a4f; }

.study_in_ireland .section-one {
  padding-top: 120px; }
  .study_in_ireland .section-one .text-one {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #262626; }
    .study_in_ireland .section-one .text-one span {
      font-weight: bold; }
  .study_in_ireland .section-one .header-wrap {
    display: flex;
    align-items: flex-end; }
  .study_in_ireland .section-one .filter-wrap {
    flex-grow: 1;
    text-align: right; }
    .study_in_ireland .section-one .filter-wrap .filter {
      display: inline-flex;
      align-items: center; }
      .study_in_ireland .section-one .filter-wrap .filter:not(:last-child) {
        margin-right: 32px; }
      .study_in_ireland .section-one .filter-wrap .filter > p {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #7f8fa4;
        margin-right: 12px; }
  .study_in_ireland .section-one .cards-wrapper {
    margin: 40px 0 55px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px; }
  .study_in_ireland .section-one .button-wrap {
    margin-bottom: 100px;
    text-align: center; }

.study_in_ireland .section-two {
  background-image: linear-gradient(248deg, #f8f8f8, #efefef -25%);
  padding: 50px 0 100px 0; }
  .study_in_ireland .section-two h2 {
    font-size: 36px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.9px;
    text-align: center;
    color: #2f3a4f;
    max-width: 540px;
    margin: 0 auto 34px auto; }
  .study_in_ireland .section-two .carousel-wrapper {
    margin: 20px 0 0 0; }
    .study_in_ireland .section-two .carousel-wrapper .testimonial-card {
      margin-right: 20px; }
    .study_in_ireland .section-two .carousel-wrapper .project-card {
      margin-right: 20px; }
    .study_in_ireland .section-two .carousel-wrapper .tutorial-card {
      margin-right: 33px; }
    .study_in_ireland .section-two .carousel-wrapper .slick-list {
      padding: 24px 0;
      position: relative; }
    .study_in_ireland .section-two .carousel-wrapper .slick-arrow {
      position: absolute;
      top: calc(50% - 38px);
      outline: none;
      cursor: pointer; }
      .study_in_ireland .section-two .carousel-wrapper .slick-arrow.slick-disabled {
        pointer-events: none;
        opacity: .8; }
      .study_in_ireland .section-two .carousel-wrapper .slick-arrow.slick-prev {
        background: url(images/static/arrow-left.svg) no-repeat;
        left: -80px;
        width: 90px;
        height: 90px;
        background-size: contain;
        border: 0;
        color: transparent; }
      .study_in_ireland .section-two .carousel-wrapper .slick-arrow.slick-next {
        right: -80px;
        background: url(images/static/arrow-right.svg) no-repeat;
        width: 90px;
        height: 90px;
        background-size: contain;
        border: 0;
        color: transparent; }

.study_in_ireland .divider-banner {
  background-image: linear-gradient(275deg, #700aad 124%, #8a3ceb 22%);
  padding: 46px 0 52px  0; }
  .study_in_ireland .divider-banner .container {
    display: flex;
    align-items: center; }
    .study_in_ireland .divider-banner .container .col {
      flex-grow: 1;
      display: flex;
      align-items: center; }
      .study_in_ireland .divider-banner .container .col img {
        margin-right: 25px; }
      .study_in_ireland .divider-banner .container .col .text-wrap .text-one {
        font-size: 40px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.66;
        letter-spacing: normal;
        color: #ffffff;
        margin-bottom: 12px; }
      .study_in_ireland .divider-banner .container .col .text-wrap .text-two {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        color: #ffffff; }
      .study_in_ireland .divider-banner .container .col:not(:last-child) {
        margin-right: 60px; }

.study_in_ireland .footer {
  padding-top: 200px; }

.info-wrapper {
  border-radius: 8px;
  box-shadow: 0 2px 44px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(106deg, #fba649, #f2534c);
  padding: 30px;
  max-width: 1080px;
  margin: 0 auto;
  position: relative;
  margin-bottom: -80px; }
  .info-wrapper .row {
    display: flex;
    align-items: flex-start; }
    .info-wrapper .row .col {
      text-align: center; }
      .info-wrapper .row .col:not(:last-child) {
        margin-right: 32px; }
      .info-wrapper .row .col:first-child {
        border-right: solid 2px #bb4039;
        padding: 12px 20px 12px 0;
        min-width: 322px; }
      .info-wrapper .row .col > div {
        height: 40px;
        margin-bottom: 22px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .info-wrapper .row .col > div img {
          max-height: 100%; }
      .info-wrapper .row .col .text-one {
        font-size: 30px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.75px;
        color: #ffffff;
        text-align: left; }
      .info-wrapper .row .col .text-two {
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.69;
        letter-spacing: normal;
        color: #ffffff; }
        .info-wrapper .row .col .text-two span {
          display: block; }
      .info-wrapper .row .col a {
        color: inherit; }

.course-card-with-trainer,
.course-card-light {
  border-radius: 4px;
  box-shadow: 0 2px 37px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px transparent;
  background-color: #ffffff;
  padding: 26px 20px 22px 20px;
  position: relative;
  padding-bottom: 100px; }
  .course-card-with-trainer .title-text,
  .course-card-light .title-text {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262626;
    margin-bottom: 6px;
    height: 44px;
    overflow: hidden; }
  .course-card-with-trainer .sub-text,
  .course-card-light .sub-text {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #464646; }
  .course-card-with-trainer .label,
  .course-card-light .label {
    background-color: rgba(184, 233, 134, 0.7);
    padding: 6px 11px 6px 16px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    color: #2f3a4f;
    display: inline-block;
    margin: 20px 0 16px 0; }
    .course-card-with-trainer .label span,
    .course-card-light .label span {
      font-weight: bold; }
  .course-card-with-trainer .age,
  .course-card-light .age {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #464646;
    margin-bottom: 6px; }
  .course-card-with-trainer .time,
  .course-card-light .time {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #464646;
    margin-bottom: 12px; }
  .course-card-with-trainer .trainer-wrap,
  .course-card-light .trainer-wrap {
    display: flex;
    align-items: center;
    margin-top: 24px; }
    .course-card-with-trainer .trainer-wrap .img-wrap,
    .course-card-light .trainer-wrap .img-wrap {
      width: 75px;
      height: 75px;
      border-radius: 100%;
      margin-right: 12px;
      background: #ccc; }
      .course-card-with-trainer .trainer-wrap .img-wrap img,
      .course-card-light .trainer-wrap .img-wrap img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100%; }
    .course-card-with-trainer .trainer-wrap .content-wrap,
    .course-card-light .trainer-wrap .content-wrap {
      flex-grow: 1; }
      .course-card-with-trainer .trainer-wrap .content-wrap .text,
      .course-card-light .trainer-wrap .content-wrap .text {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.3px;
        color: #2f3a4f; }
      .course-card-with-trainer .trainer-wrap .content-wrap .name,
      .course-card-light .trainer-wrap .content-wrap .name {
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.45px;
        color: #262626;
        margin-bottom: 3px; }
      .course-card-with-trainer .trainer-wrap .content-wrap .expert,
      .course-card-light .trainer-wrap .content-wrap .expert {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.3px;
        color: #ea147b; }
      .course-card-with-trainer .trainer-wrap .content-wrap .qualification,
      .course-card-light .trainer-wrap .content-wrap .qualification {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.35px;
        color: #2f3a4f; }
  .course-card-with-trainer .bottom-section,
  .course-card-light .bottom-section {
    border-top: solid 1px rgba(82, 95, 120, 0.4);
    padding-top: 20px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    position: absolute;
    left: 20px;
    bottom: 0;
    right: 20px;
    margin: 0;
    width: calc(100% - 40px);
    padding: 32px 20px 30px 20px;
    display: flex;
    justify-content: center; }
    .course-card-with-trainer .bottom-section .text-one,
    .course-card-light .bottom-section .text-one {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      text-align: center;
      color: #2a00e8;
      text-transform: uppercase;
      margin: 0;
      display: inline-block;
      position: relative;
      cursor: pointer;
      padding: 8px 16px;
      position: absolute;
      bottom: 16px; }
      .course-card-with-trainer .bottom-section .text-one:hover,
      .course-card-light .bottom-section .text-one:hover {
        border-radius: 2px;
        padding: 8px 16px;
        background-color: #edeafd;
        position: absolute;
        margin-top: -8px; }

.course-card-light {
  border-radius: 4px;
  box-shadow: 0 2px 37px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff; }
  .course-card-light:hover {
    border: solid 1px #2a00e8; }
  .course-card-light .title-text {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262626;
    margin-bottom: 30px; }
  .course-card-light .sub-text {
    margin-bottom: 4px; }
  .course-card-light .flex-wrap {
    justify-content: center; }

.testimonial-card {
  border-radius: 4px;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 20px 15px; }
  .testimonial-card img {
    margin-bottom: 12px; }
  .testimonial-card .text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #464646;
    margin-bottom: 80px; }
  .testimonial-card .user-wrap {
    display: flex;
    align-items: center; }
    .testimonial-card .user-wrap .img-wrap {
      width: 50px;
      height: 50px;
      margin-right: 12px;
      background: #ddd;
      border-radius: 100%; }
    .testimonial-card .user-wrap .content-wrap .text-one {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.45px;
      color: #262626;
      margin-bottom: 2px; }
    .testimonial-card .user-wrap .content-wrap .text-two {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.3px;
      color: #2f3a4f; }

@media screen and (max-width: 768px) {
  .study_in_ireland .ant-select-selection {
    min-width: 110px;
    height: 24px; }
    .study_in_ireland .ant-select-selection .anticon svg {
      font-size: 12px; }
    .study_in_ireland .ant-select-selection .ant-select-selection__rendered {
      line-height: 20px; }
    .study_in_ireland .ant-select-selection .ant-select-selection-selected-value {
      font-size: 8.4px;
      font-weight: 500; }
  .study_in_ireland.hindi .banner-wrapper .title-text {
    max-width: 100%; }
  .study_in_ireland.adults .banner-wrapper {
    background: url(images/master-classes/adult-banner-mobile.png) no-repeat;
    background-size: cover; }
    .study_in_ireland.adults .banner-wrapper .title-text {
      max-width: 100%;
      font-size: 25.6px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.23;
      letter-spacing: normal;
      color: #ffffff; }
    .study_in_ireland.adults .banner-wrapper .sub-text {
      font-size: 10.2px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      color: #ffffff; }
    .study_in_ireland.adults .banner-wrapper .text {
      font-size: 11.5px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff; }
      .study_in_ireland.adults .banner-wrapper .text span {
        color: #ffffff; }
    .study_in_ireland.adults .banner-wrapper button {
      margin-top: 14px;
      font-size: 10.2px;
      padding: 9px 46px; }
  .study_in_ireland.adults .section-one {
    padding-top: 24px; }
  .study_in_ireland.adults .helper-wrapper {
    background: url(images/master-classes/helper-background-mobile.png) no-repeat;
    background-size: 100% 100%;
    margin: 0;
    flex-direction: column;
    width: 100%;
    padding: 52px 24px; }
    .study_in_ireland.adults .helper-wrapper .content .text {
      font-size: 26px;
      font-weight: 300; }
    .study_in_ireland.adults .helper-wrapper .content .sub-text {
      font-size: 11px;
      font-weight: 500;
      line-height: 1.82;
      margin-bottom: 20px; }
    .study_in_ireland.adults .helper-wrapper > div:last-child {
      width: 100%;
      text-align: left; }
    .study_in_ireland.adults .helper-wrapper button {
      font-size: 10.5px;
      padding: 10px 60px; }
  .study_in_ireland .banner-wrapper {
    padding: 120px 28px 28px;
    position: relative;
    height: 450px;
    background: url(images/master-classes/banner-mobile.png) no-repeat;
    background-size: cover; }
    .study_in_ireland .banner-wrapper .title-text {
      font-size: 24px;
      font-weight: 300;
      max-width: 160px; }
    .study_in_ireland .banner-wrapper .sub-text {
      font-size: 9.6px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56; }
    .study_in_ireland .banner-wrapper .list-wrap {
      flex-direction: column;
      padding: 26px 30px;
      position: absolute;
      top: 65%;
      margin: auto;
      left: 6px;
      right: 6px; }
      .study_in_ireland .banner-wrapper .list-wrap .list {
        display: flex;
        justify-content: center;
        text-align: left;
        width: 100%; }
        .study_in_ireland .banner-wrapper .list-wrap .list img {
          margin-right: 15px;
          width: 26px;
          height: 24px;
          object-fit: contain; }
        .study_in_ireland .banner-wrapper .list-wrap .list > div {
          flex-grow: 1; }
          .study_in_ireland .banner-wrapper .list-wrap .list > div .text-one {
            font-size: 14px;
            font-weight: 500;
            text-align: left;
            margin-bottom: 5px; }
          .study_in_ireland .banner-wrapper .list-wrap .list > div .text-two {
            font-size: 11.2px;
            font-weight: normal;
            text-align: left; }
        .study_in_ireland .banner-wrapper .list-wrap .list:not(:last-child) {
          margin: 0 0 16px 0; }
  .study_in_ireland .section-one {
    padding-top: 100px; }
    .study_in_ireland .section-one .container {
      padding: 0; }
    .study_in_ireland .section-one .header-wrap {
      padding: 0 12px; }
    .study_in_ireland .section-one .carousel-wrapper {
      margin: 20px 0 0 0; }
      .study_in_ireland .section-one .carousel-wrapper .testimonial-card {
        margin-right: 20px; }
      .study_in_ireland .section-one .carousel-wrapper .project-card {
        margin-right: 20px; }
      .study_in_ireland .section-one .carousel-wrapper .tutorial-card {
        margin-right: 33px; }
      .study_in_ireland .section-one .carousel-wrapper .slick-track {
        display: flex !important; }
      .study_in_ireland .section-one .carousel-wrapper .slick-slide {
        height: inherit !important; }
      .study_in_ireland .section-one .carousel-wrapper .slick-list {
        padding: 24px 0;
        position: relative; }
      .study_in_ireland .section-one .carousel-wrapper .slick-slide {
        padding: 0 16px; }
      .study_in_ireland .section-one .carousel-wrapper .slick-arrow {
        position: absolute;
        top: calc(50% - 38px);
        outline: none;
        cursor: pointer; }
        .study_in_ireland .section-one .carousel-wrapper .slick-arrow.slick-disabled {
          pointer-events: none;
          opacity: .8; }
        .study_in_ireland .section-one .carousel-wrapper .slick-arrow.slick-prev {
          background: url(images/master-classes/arrow-left-white.svg) no-repeat;
          left: 0px;
          width: 37px;
          height: 37px;
          background-size: contain;
          border: 0;
          color: transparent;
          z-index: 6; }
        .study_in_ireland .section-one .carousel-wrapper .slick-arrow.slick-next {
          right: 0px;
          background: url(images/master-classes/arrow-right-white.svg) no-repeat;
          width: 37px;
          height: 37px;
          background-size: contain;
          border: 0;
          color: transparent;
          z-index: 6; }
    .study_in_ireland .section-one .text-one {
      text-align: left;
      margin-bottom: 12px;
      font-size: 12px;
      font-weight: 500; }
    .study_in_ireland .section-one .header-wrap {
      flex-direction: column;
      width: 100%;
      align-items: flex-start; }
      .study_in_ireland .section-one .header-wrap .filter-wrap {
        display: flex;
        align-items: center; }
        .study_in_ireland .section-one .header-wrap .filter-wrap .filter p {
          font-size: 8.4px; }
        .study_in_ireland .section-one .header-wrap .filter-wrap .filter:not(::last-child) {
          margin-right: 20px; }
    .study_in_ireland .section-one .cards-wrapper {
      display: block;
      margin: 24px 0;
      padding: 0 12px; }
      .study_in_ireland .section-one .cards-wrapper .course-card-with-trainer {
        margin-bottom: 12px; }
      .study_in_ireland .section-one .cards-wrapper .course-card {
        margin-bottom: 18px; }
  .study_in_ireland .section-two {
    padding: 38px 0 70px 0; }
    .study_in_ireland .section-two h2 {
      font-size: 22px;
      font-weight: 300;
      padding: 0 20px; }
    .study_in_ireland .section-two .carousel-wrapper {
      padding: 0 0 0 20px;
      margin: 20px 0 0 0; }
      .study_in_ireland .section-two .carousel-wrapper .testimonial-card {
        margin-right: 0; }
      .study_in_ireland .section-two .carousel-wrapper .slick-slide {
        padding: 0 16px 0 0; }
  .study_in_ireland .divider-banner {
    padding: 46px 13px; }
    .study_in_ireland .divider-banner .container {
      padding: 0;
      display: block; }
      .study_in_ireland .divider-banner .container .col {
        display: inline-flex;
        min-width: 50%;
        margin: 0;
        max-width: 50%; }
        .study_in_ireland .divider-banner .container .col:first-child, .study_in_ireland .divider-banner .container .col:nth-child(2) {
          margin-bottom: 48px; }
        .study_in_ireland .divider-banner .container .col img {
          width: 28.7px;
          height: 29px;
          margin-right: 15px; }
        .study_in_ireland .divider-banner .container .col .text-wrap .text-one {
          font-size: 25.2px;
          font-weight: 300;
          line-height: 0.66; }
        .study_in_ireland .divider-banner .container .col .text-wrap .text-two {
          font-size: 10.1px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;
          letter-spacing: normal;
          color: #ffffff; }
        .study_in_ireland .divider-banner .container .col:not(:last-child) {
          margin-right: 0; }
  .testimonial-card .text {
    font-size: 11.9px;
    margin-bottom: 36px; }
  .testimonial-card > img {
    width: 18.7px;
    height: 39.1px;
    object-fit: contain; }
  .testimonial-card .user-wrap .img-wrap {
    width: 42.5px;
    height: 42.5px; }
  .testimonial-card .user-wrap .content-wrap .text-one {
    font-size: 15.3px; }
  .testimonial-card .user-wrap .content-wrap .text-two {
    font-size: 10.2px; }
  .course-card-with-trainer,
  .course-card-light {
    border-radius: 3.4px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.19);
    background-color: #ffffff;
    padding: 22px 16px 16px 16px; }
    .course-card-with-trainer .title-text,
    .course-card-light .title-text {
      font-size: 13.6px;
      margin-bottom: 4.8px; }
    .course-card-with-trainer .sub-text,
    .course-card-light .sub-text {
      font-size: 11.9px;
      font-weight: 500; }
    .course-card-with-trainer .label,
    .course-card-light .label {
      font-size: 10.2px;
      margin: 23px 0 19px 0; }
      .course-card-with-trainer .label span,
      .course-card-light .label span {
        font-weight: bold; }
    .course-card-with-trainer .age,
    .course-card-light .age {
      font-size: 11.9px;
      margin-bottom: 4.8px; }
    .course-card-with-trainer .time,
    .course-card-light .time {
      font-size: 11.9px;
      margin-bottom: 9.9px; }
    .course-card-with-trainer .trainer-wrap,
    .course-card-light .trainer-wrap {
      display: flex;
      align-items: center; }
      .course-card-with-trainer .trainer-wrap .img-wrap,
      .course-card-light .trainer-wrap .img-wrap {
        width: 63.8px;
        height: 63.8px;
        margin-right: 10px; }
      .course-card-with-trainer .trainer-wrap .content-wrap,
      .course-card-light .trainer-wrap .content-wrap {
        flex-grow: 1; }
        .course-card-with-trainer .trainer-wrap .content-wrap .text,
        .course-card-light .trainer-wrap .content-wrap .text {
          font-size: 10.2px; }
        .course-card-with-trainer .trainer-wrap .content-wrap .name,
        .course-card-light .trainer-wrap .content-wrap .name {
          font-size: 15.3px; }
        .course-card-with-trainer .trainer-wrap .content-wrap .expert,
        .course-card-light .trainer-wrap .content-wrap .expert {
          font-size: 10.2px;
          margin-bottom: 3px; }
        .course-card-with-trainer .trainer-wrap .content-wrap .qualification,
        .course-card-light .trainer-wrap .content-wrap .qualification {
          font-size: 11.9px; }
    .course-card-with-trainer .bottom-section,
    .course-card-light .bottom-section {
      padding-top: 16px;
      margin-top: 25px;
      position: relative;
      left: 0;
      right: 0;
      width: 100%;
      padding: 12px 0 0; }
      .course-card-with-trainer .bottom-section .text-one,
      .course-card-light .bottom-section .text-one {
        font-size: 11.9px;
        letter-spacing: 0.43px;
        text-align: center;
        margin: 0;
        position: relative;
        bottom: 0; }
        .course-card-with-trainer .bottom-section .text-one:hover,
        .course-card-light .bottom-section .text-one:hover {
          border-radius: 0;
          background-color: transparent;
          position: relative;
          margin-top: 0; }
  .course-card-light:hover {
    border: 0; }
  .info-wrapper {
    border-radius: 0;
    box-shadow: none;
    max-width: 100%;
    margin-bottom: 0; }
    .info-wrapper .row {
      flex-direction: column; }
      .info-wrapper .row .col {
        margin-bottom: 30px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%; }
        .info-wrapper .row .col > div {
          margin-bottom: 0;
          min-width: 50px;
          justify-content: flex-start; }
        .info-wrapper .row .col img {
          max-width: 22px;
          margin: 0 14px 0 0; }
        .info-wrapper .row .col:not(:last-child) {
          margin-right: 0; }
        .info-wrapper .row .col:first-child {
          border-right: 0;
          padding: 0;
          justify-content: flex-start; }
        .info-wrapper .row .col .text-one {
          font-size: 18px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.64px;
          text-align: left;
          color: #ffffff; }
        .info-wrapper .row .col .text-two {
          font-size: 12px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 2.41;
          letter-spacing: normal;
          text-align: left;
          color: #ffffff; } }

.study_in_usa.adults .banner-wrapper {
  background: url(images/master-classes/adult-banner.png) no-repeat;
  background-size: cover;
  padding-bottom: 160px; }
  .study_in_usa.adults .banner-wrapper .title-text {
    font-size: 40px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: #1e174a;
    margin-bottom: 8px;
    text-shadow: none; }
  .study_in_usa.adults .banner-wrapper .sub-text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #1e174a;
    margin-bottom: 4px; }
  .study_in_usa.adults .banner-wrapper .text {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1e174a; }
    .study_in_usa.adults .banner-wrapper .text span {
      color: #4922f3; }
  .study_in_usa.adults .banner-wrapper button {
    border-radius: 2px;
    padding: 15px 52px;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.16px;
    text-align: center;
    color: #ffffff;
    outline: none;
    cursor: pointer;
    border: 0;
    margin-top: 30px; }

.study_in_usa.adults .container.helper {
  max-width: 1140px; }

.study_in_usa.adults .helper-wrapper {
  background: url(images/master-classes/helper-background.png) no-repeat;
  background-size: 100% 100%;
  padding: 44px 58px;
  display: flex;
  margin: 124px 0 130px 0;
  align-items: center; }
  .study_in_usa.adults .helper-wrapper button {
    border-radius: 2px;
    padding: 15px 52px;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.16px;
    text-align: center;
    color: #ffffff;
    outline: none;
    cursor: pointer;
    border: 0; }
  .study_in_usa.adults .helper-wrapper > div:last-child {
    flex-grow: 1;
    text-align: right; }
  .study_in_usa.adults .helper-wrapper .content {
    max-width: 460px; }
    .study_in_usa.adults .helper-wrapper .content > img {
      max-width: 116px; }
    .study_in_usa.adults .helper-wrapper .content .text {
      font-size: 36px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.9px;
      text-align: left;
      color: #2f3a4f;
      margin-bottom: 4px; }
    .study_in_usa.adults .helper-wrapper .content .sub-text {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #464646; }
      .study_in_usa.adults .helper-wrapper .content .sub-text span {
        font-weight: bold;
        color: #2a00e8; }

.study_in_usa.adults .section-one {
  padding-top: 60px; }

.study_in_usa .container {
  max-width: 1080px;
  margin: 0 auto; }

.study_in_usa .white-button {
  border-radius: 4px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  cursor: pointer;
  padding: 13px 40px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #2a00e8;
  border: 0; }

.study_in_usa .ant-select-selection {
  border-radius: 4px;
  border: solid 1px #ced0da;
  background-image: linear-gradient(to top, #f2f4f7, #ffffff);
  min-width: 180px; }
  .study_in_usa .ant-select-selection .anticon svg {
    color: #3031ea; }

.study_in_usa .banner-wrapper {
  background: url(images/banners/usa.webp) no-repeat;
  background-size: cover;
  padding-top: 160px; }
  .study_in_usa .banner-wrapper .title-text {
    font-size: 40px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
    margin-bottom: 5px;
    max-width: 260px;
    background: white; }
  .study_in_usa .banner-wrapper .sub-text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #000;
    max-width: 52rem;
    background: white; }
  .study_in_usa .banner-wrapper .list-wrap {
    border-radius: 6px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 48px 74px;
    position: relative;
    display: flex;
    align-items: flex-start;
    top: 42px; }
    .study_in_usa .banner-wrapper .list-wrap .list {
      flex-grow: 1;
      flex-basis: 100%;
      text-align: center; }
      .study_in_usa .banner-wrapper .list-wrap .list:not(:last-child) {
        margin-right: 60px; }
      .study_in_usa .banner-wrapper .list-wrap .list img {
        margin-bottom: 24px; }
      .study_in_usa .banner-wrapper .list-wrap .list .text-one {
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.32;
        letter-spacing: normal;
        color: #262626;
        margin-bottom: 7px; }
      .study_in_usa .banner-wrapper .list-wrap .list .text-two {
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: #2f3a4f; }

.study_in_usa .section-one {
  padding-top: 120px; }
  .study_in_usa .section-one .text-one {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #262626; }
    .study_in_usa .section-one .text-one span {
      font-weight: bold; }
  .study_in_usa .section-one .header-wrap {
    display: flex;
    align-items: flex-end; }
  .study_in_usa .section-one .filter-wrap {
    flex-grow: 1;
    text-align: right; }
    .study_in_usa .section-one .filter-wrap .filter {
      display: inline-flex;
      align-items: center; }
      .study_in_usa .section-one .filter-wrap .filter:not(:last-child) {
        margin-right: 32px; }
      .study_in_usa .section-one .filter-wrap .filter > p {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #7f8fa4;
        margin-right: 12px; }
  .study_in_usa .section-one .cards-wrapper {
    margin: 40px 0 55px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px; }
  .study_in_usa .section-one .button-wrap {
    margin-bottom: 100px;
    text-align: center; }

.study_in_usa .section-two {
  background-image: linear-gradient(248deg, #f8f8f8, #efefef -25%);
  padding: 50px 0 100px 0; }
  .study_in_usa .section-two h2 {
    font-size: 36px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.9px;
    text-align: center;
    color: #2f3a4f;
    max-width: 540px;
    margin: 0 auto 34px auto; }
  .study_in_usa .section-two .carousel-wrapper {
    margin: 20px 0 0 0; }
    .study_in_usa .section-two .carousel-wrapper .testimonial-card {
      margin-right: 20px; }
    .study_in_usa .section-two .carousel-wrapper .project-card {
      margin-right: 20px; }
    .study_in_usa .section-two .carousel-wrapper .tutorial-card {
      margin-right: 33px; }
    .study_in_usa .section-two .carousel-wrapper .slick-list {
      padding: 24px 0;
      position: relative; }
    .study_in_usa .section-two .carousel-wrapper .slick-arrow {
      position: absolute;
      top: calc(50% - 38px);
      outline: none;
      cursor: pointer; }
      .study_in_usa .section-two .carousel-wrapper .slick-arrow.slick-disabled {
        pointer-events: none;
        opacity: .8; }
      .study_in_usa .section-two .carousel-wrapper .slick-arrow.slick-prev {
        background: url(images/static/arrow-left.svg) no-repeat;
        left: -80px;
        width: 90px;
        height: 90px;
        background-size: contain;
        border: 0;
        color: transparent; }
      .study_in_usa .section-two .carousel-wrapper .slick-arrow.slick-next {
        right: -80px;
        background: url(images/static/arrow-right.svg) no-repeat;
        width: 90px;
        height: 90px;
        background-size: contain;
        border: 0;
        color: transparent; }

.study_in_usa .divider-banner {
  background-image: linear-gradient(275deg, #700aad 124%, #8a3ceb 22%);
  padding: 46px 0 52px  0; }
  .study_in_usa .divider-banner .container {
    display: flex;
    align-items: center; }
    .study_in_usa .divider-banner .container .col {
      flex-grow: 1;
      display: flex;
      align-items: center; }
      .study_in_usa .divider-banner .container .col img {
        margin-right: 25px; }
      .study_in_usa .divider-banner .container .col .text-wrap .text-one {
        font-size: 40px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.66;
        letter-spacing: normal;
        color: #ffffff;
        margin-bottom: 12px; }
      .study_in_usa .divider-banner .container .col .text-wrap .text-two {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        color: #ffffff; }
      .study_in_usa .divider-banner .container .col:not(:last-child) {
        margin-right: 60px; }

.study_in_usa .footer {
  padding-top: 200px; }

.info-wrapper {
  border-radius: 8px;
  box-shadow: 0 2px 44px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(106deg, #fba649, #f2534c);
  padding: 30px;
  max-width: 1080px;
  margin: 0 auto;
  position: relative;
  margin-bottom: -80px; }
  .info-wrapper .row {
    display: flex;
    align-items: flex-start; }
    .info-wrapper .row .col {
      text-align: center; }
      .info-wrapper .row .col:not(:last-child) {
        margin-right: 32px; }
      .info-wrapper .row .col:first-child {
        border-right: solid 2px #bb4039;
        padding: 12px 20px 12px 0;
        min-width: 322px; }
      .info-wrapper .row .col > div {
        height: 40px;
        margin-bottom: 22px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .info-wrapper .row .col > div img {
          max-height: 100%; }
      .info-wrapper .row .col .text-one {
        font-size: 30px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.75px;
        color: #ffffff;
        text-align: left; }
      .info-wrapper .row .col .text-two {
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.69;
        letter-spacing: normal;
        color: #ffffff; }
        .info-wrapper .row .col .text-two span {
          display: block; }
      .info-wrapper .row .col a {
        color: inherit; }

.course-card-with-trainer,
.course-card-light {
  border-radius: 4px;
  box-shadow: 0 2px 37px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px transparent;
  background-color: #ffffff;
  padding: 26px 20px 22px 20px;
  position: relative;
  padding-bottom: 100px; }
  .course-card-with-trainer .title-text,
  .course-card-light .title-text {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262626;
    margin-bottom: 6px;
    height: 44px;
    overflow: hidden; }
  .course-card-with-trainer .sub-text,
  .course-card-light .sub-text {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #464646; }
  .course-card-with-trainer .label,
  .course-card-light .label {
    background-color: rgba(184, 233, 134, 0.7);
    padding: 6px 11px 6px 16px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    color: #2f3a4f;
    display: inline-block;
    margin: 20px 0 16px 0; }
    .course-card-with-trainer .label span,
    .course-card-light .label span {
      font-weight: bold; }
  .course-card-with-trainer .age,
  .course-card-light .age {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #464646;
    margin-bottom: 6px; }
  .course-card-with-trainer .time,
  .course-card-light .time {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #464646;
    margin-bottom: 12px; }
  .course-card-with-trainer .trainer-wrap,
  .course-card-light .trainer-wrap {
    display: flex;
    align-items: center;
    margin-top: 24px; }
    .course-card-with-trainer .trainer-wrap .img-wrap,
    .course-card-light .trainer-wrap .img-wrap {
      width: 75px;
      height: 75px;
      border-radius: 100%;
      margin-right: 12px;
      background: #ccc; }
      .course-card-with-trainer .trainer-wrap .img-wrap img,
      .course-card-light .trainer-wrap .img-wrap img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100%; }
    .course-card-with-trainer .trainer-wrap .content-wrap,
    .course-card-light .trainer-wrap .content-wrap {
      flex-grow: 1; }
      .course-card-with-trainer .trainer-wrap .content-wrap .text,
      .course-card-light .trainer-wrap .content-wrap .text {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.3px;
        color: #2f3a4f; }
      .course-card-with-trainer .trainer-wrap .content-wrap .name,
      .course-card-light .trainer-wrap .content-wrap .name {
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.45px;
        color: #262626;
        margin-bottom: 3px; }
      .course-card-with-trainer .trainer-wrap .content-wrap .expert,
      .course-card-light .trainer-wrap .content-wrap .expert {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.3px;
        color: #ea147b; }
      .course-card-with-trainer .trainer-wrap .content-wrap .qualification,
      .course-card-light .trainer-wrap .content-wrap .qualification {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.35px;
        color: #2f3a4f; }
  .course-card-with-trainer .bottom-section,
  .course-card-light .bottom-section {
    border-top: solid 1px rgba(82, 95, 120, 0.4);
    padding-top: 20px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    position: absolute;
    left: 20px;
    bottom: 0;
    right: 20px;
    margin: 0;
    width: calc(100% - 40px);
    padding: 32px 20px 30px 20px;
    display: flex;
    justify-content: center; }
    .course-card-with-trainer .bottom-section .text-one,
    .course-card-light .bottom-section .text-one {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      text-align: center;
      color: #2a00e8;
      text-transform: uppercase;
      margin: 0;
      display: inline-block;
      position: relative;
      cursor: pointer;
      padding: 8px 16px;
      position: absolute;
      bottom: 16px; }
      .course-card-with-trainer .bottom-section .text-one:hover,
      .course-card-light .bottom-section .text-one:hover {
        border-radius: 2px;
        padding: 8px 16px;
        background-color: #edeafd;
        position: absolute;
        margin-top: -8px; }

.course-card-light {
  border-radius: 4px;
  box-shadow: 0 2px 37px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff; }
  .course-card-light:hover {
    border: solid 1px #2a00e8; }
  .course-card-light .title-text {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262626;
    margin-bottom: 30px; }
  .course-card-light .sub-text {
    margin-bottom: 4px; }
  .course-card-light .flex-wrap {
    justify-content: center; }

.testimonial-card {
  border-radius: 4px;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 20px 15px; }
  .testimonial-card img {
    margin-bottom: 12px; }
  .testimonial-card .text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #464646;
    margin-bottom: 80px; }
  .testimonial-card .user-wrap {
    display: flex;
    align-items: center; }
    .testimonial-card .user-wrap .img-wrap {
      width: 50px;
      height: 50px;
      margin-right: 12px;
      background: #ddd;
      border-radius: 100%; }
    .testimonial-card .user-wrap .content-wrap .text-one {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.45px;
      color: #262626;
      margin-bottom: 2px; }
    .testimonial-card .user-wrap .content-wrap .text-two {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.3px;
      color: #2f3a4f; }

@media screen and (max-width: 768px) {
  .study_in_usa .ant-select-selection {
    min-width: 110px;
    height: 24px; }
    .study_in_usa .ant-select-selection .anticon svg {
      font-size: 12px; }
    .study_in_usa .ant-select-selection .ant-select-selection__rendered {
      line-height: 20px; }
    .study_in_usa .ant-select-selection .ant-select-selection-selected-value {
      font-size: 8.4px;
      font-weight: 500; }
  .study_in_usa.hindi .banner-wrapper .title-text {
    max-width: 100%; }
  .study_in_usa.adults .banner-wrapper {
    background: url(images/master-classes/adult-banner-mobile.png) no-repeat;
    background-size: cover; }
    .study_in_usa.adults .banner-wrapper .title-text {
      max-width: 100%;
      font-size: 25.6px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.23;
      letter-spacing: normal;
      color: #ffffff; }
    .study_in_usa.adults .banner-wrapper .sub-text {
      font-size: 10.2px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      color: #ffffff; }
    .study_in_usa.adults .banner-wrapper .text {
      font-size: 11.5px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff; }
      .study_in_usa.adults .banner-wrapper .text span {
        color: #ffffff; }
    .study_in_usa.adults .banner-wrapper button {
      margin-top: 14px;
      font-size: 10.2px;
      padding: 9px 46px; }
  .study_in_usa.adults .section-one {
    padding-top: 24px; }
  .study_in_usa.adults .helper-wrapper {
    background: url(images/master-classes/helper-background-mobile.png) no-repeat;
    background-size: 100% 100%;
    margin: 0;
    flex-direction: column;
    width: 100%;
    padding: 52px 24px; }
    .study_in_usa.adults .helper-wrapper .content .text {
      font-size: 26px;
      font-weight: 300; }
    .study_in_usa.adults .helper-wrapper .content .sub-text {
      font-size: 11px;
      font-weight: 500;
      line-height: 1.82;
      margin-bottom: 20px; }
    .study_in_usa.adults .helper-wrapper > div:last-child {
      width: 100%;
      text-align: left; }
    .study_in_usa.adults .helper-wrapper button {
      font-size: 10.5px;
      padding: 10px 60px; }
  .study_in_usa .banner-wrapper {
    padding: 120px 28px 28px;
    position: relative;
    height: 450px;
    background: url(images/master-classes/banner-mobile.png) no-repeat;
    background-size: cover; }
    .study_in_usa .banner-wrapper .title-text {
      font-size: 24px;
      font-weight: 300;
      max-width: 160px; }
    .study_in_usa .banner-wrapper .sub-text {
      font-size: 9.6px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56; }
    .study_in_usa .banner-wrapper .list-wrap {
      flex-direction: column;
      padding: 26px 30px;
      position: absolute;
      top: 65%;
      margin: auto;
      left: 6px;
      right: 6px; }
      .study_in_usa .banner-wrapper .list-wrap .list {
        display: flex;
        justify-content: center;
        text-align: left;
        width: 100%; }
        .study_in_usa .banner-wrapper .list-wrap .list img {
          margin-right: 15px;
          width: 26px;
          height: 24px;
          object-fit: contain; }
        .study_in_usa .banner-wrapper .list-wrap .list > div {
          flex-grow: 1; }
          .study_in_usa .banner-wrapper .list-wrap .list > div .text-one {
            font-size: 14px;
            font-weight: 500;
            text-align: left;
            margin-bottom: 5px; }
          .study_in_usa .banner-wrapper .list-wrap .list > div .text-two {
            font-size: 11.2px;
            font-weight: normal;
            text-align: left; }
        .study_in_usa .banner-wrapper .list-wrap .list:not(:last-child) {
          margin: 0 0 16px 0; }
  .study_in_usa .section-one {
    padding-top: 100px; }
    .study_in_usa .section-one .container {
      padding: 0; }
    .study_in_usa .section-one .header-wrap {
      padding: 0 12px; }
    .study_in_usa .section-one .carousel-wrapper {
      margin: 20px 0 0 0; }
      .study_in_usa .section-one .carousel-wrapper .testimonial-card {
        margin-right: 20px; }
      .study_in_usa .section-one .carousel-wrapper .project-card {
        margin-right: 20px; }
      .study_in_usa .section-one .carousel-wrapper .tutorial-card {
        margin-right: 33px; }
      .study_in_usa .section-one .carousel-wrapper .slick-track {
        display: flex !important; }
      .study_in_usa .section-one .carousel-wrapper .slick-slide {
        height: inherit !important; }
      .study_in_usa .section-one .carousel-wrapper .slick-list {
        padding: 24px 0;
        position: relative; }
      .study_in_usa .section-one .carousel-wrapper .slick-slide {
        padding: 0 16px; }
      .study_in_usa .section-one .carousel-wrapper .slick-arrow {
        position: absolute;
        top: calc(50% - 38px);
        outline: none;
        cursor: pointer; }
        .study_in_usa .section-one .carousel-wrapper .slick-arrow.slick-disabled {
          pointer-events: none;
          opacity: .8; }
        .study_in_usa .section-one .carousel-wrapper .slick-arrow.slick-prev {
          background: url(images/master-classes/arrow-left-white.svg) no-repeat;
          left: 0px;
          width: 37px;
          height: 37px;
          background-size: contain;
          border: 0;
          color: transparent;
          z-index: 6; }
        .study_in_usa .section-one .carousel-wrapper .slick-arrow.slick-next {
          right: 0px;
          background: url(images/master-classes/arrow-right-white.svg) no-repeat;
          width: 37px;
          height: 37px;
          background-size: contain;
          border: 0;
          color: transparent;
          z-index: 6; }
    .study_in_usa .section-one .text-one {
      text-align: left;
      margin-bottom: 12px;
      font-size: 12px;
      font-weight: 500; }
    .study_in_usa .section-one .header-wrap {
      flex-direction: column;
      width: 100%;
      align-items: flex-start; }
      .study_in_usa .section-one .header-wrap .filter-wrap {
        display: flex;
        align-items: center; }
        .study_in_usa .section-one .header-wrap .filter-wrap .filter p {
          font-size: 8.4px; }
        .study_in_usa .section-one .header-wrap .filter-wrap .filter:not(::last-child) {
          margin-right: 20px; }
    .study_in_usa .section-one .cards-wrapper {
      display: block;
      margin: 24px 0;
      padding: 0 12px; }
      .study_in_usa .section-one .cards-wrapper .course-card-with-trainer {
        margin-bottom: 12px; }
      .study_in_usa .section-one .cards-wrapper .course-card {
        margin-bottom: 18px; }
  .study_in_usa .section-two {
    padding: 38px 0 70px 0; }
    .study_in_usa .section-two h2 {
      font-size: 22px;
      font-weight: 300;
      padding: 0 20px; }
    .study_in_usa .section-two .carousel-wrapper {
      padding: 0 0 0 20px;
      margin: 20px 0 0 0; }
      .study_in_usa .section-two .carousel-wrapper .testimonial-card {
        margin-right: 0; }
      .study_in_usa .section-two .carousel-wrapper .slick-slide {
        padding: 0 16px 0 0; }
  .study_in_usa .divider-banner {
    padding: 46px 13px; }
    .study_in_usa .divider-banner .container {
      padding: 0;
      display: block; }
      .study_in_usa .divider-banner .container .col {
        display: inline-flex;
        min-width: 50%;
        margin: 0;
        max-width: 50%; }
        .study_in_usa .divider-banner .container .col:first-child, .study_in_usa .divider-banner .container .col:nth-child(2) {
          margin-bottom: 48px; }
        .study_in_usa .divider-banner .container .col img {
          width: 28.7px;
          height: 29px;
          margin-right: 15px; }
        .study_in_usa .divider-banner .container .col .text-wrap .text-one {
          font-size: 25.2px;
          font-weight: 300;
          line-height: 0.66; }
        .study_in_usa .divider-banner .container .col .text-wrap .text-two {
          font-size: 10.1px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;
          letter-spacing: normal;
          color: #ffffff; }
        .study_in_usa .divider-banner .container .col:not(:last-child) {
          margin-right: 0; }
  .testimonial-card .text {
    font-size: 11.9px;
    margin-bottom: 36px; }
  .testimonial-card > img {
    width: 18.7px;
    height: 39.1px;
    object-fit: contain; }
  .testimonial-card .user-wrap .img-wrap {
    width: 42.5px;
    height: 42.5px; }
  .testimonial-card .user-wrap .content-wrap .text-one {
    font-size: 15.3px; }
  .testimonial-card .user-wrap .content-wrap .text-two {
    font-size: 10.2px; }
  .course-card-with-trainer,
  .course-card-light {
    border-radius: 3.4px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.19);
    background-color: #ffffff;
    padding: 22px 16px 16px 16px; }
    .course-card-with-trainer .title-text,
    .course-card-light .title-text {
      font-size: 13.6px;
      margin-bottom: 4.8px; }
    .course-card-with-trainer .sub-text,
    .course-card-light .sub-text {
      font-size: 11.9px;
      font-weight: 500; }
    .course-card-with-trainer .label,
    .course-card-light .label {
      font-size: 10.2px;
      margin: 23px 0 19px 0; }
      .course-card-with-trainer .label span,
      .course-card-light .label span {
        font-weight: bold; }
    .course-card-with-trainer .age,
    .course-card-light .age {
      font-size: 11.9px;
      margin-bottom: 4.8px; }
    .course-card-with-trainer .time,
    .course-card-light .time {
      font-size: 11.9px;
      margin-bottom: 9.9px; }
    .course-card-with-trainer .trainer-wrap,
    .course-card-light .trainer-wrap {
      display: flex;
      align-items: center; }
      .course-card-with-trainer .trainer-wrap .img-wrap,
      .course-card-light .trainer-wrap .img-wrap {
        width: 63.8px;
        height: 63.8px;
        margin-right: 10px; }
      .course-card-with-trainer .trainer-wrap .content-wrap,
      .course-card-light .trainer-wrap .content-wrap {
        flex-grow: 1; }
        .course-card-with-trainer .trainer-wrap .content-wrap .text,
        .course-card-light .trainer-wrap .content-wrap .text {
          font-size: 10.2px; }
        .course-card-with-trainer .trainer-wrap .content-wrap .name,
        .course-card-light .trainer-wrap .content-wrap .name {
          font-size: 15.3px; }
        .course-card-with-trainer .trainer-wrap .content-wrap .expert,
        .course-card-light .trainer-wrap .content-wrap .expert {
          font-size: 10.2px;
          margin-bottom: 3px; }
        .course-card-with-trainer .trainer-wrap .content-wrap .qualification,
        .course-card-light .trainer-wrap .content-wrap .qualification {
          font-size: 11.9px; }
    .course-card-with-trainer .bottom-section,
    .course-card-light .bottom-section {
      padding-top: 16px;
      margin-top: 25px;
      position: relative;
      left: 0;
      right: 0;
      width: 100%;
      padding: 12px 0 0; }
      .course-card-with-trainer .bottom-section .text-one,
      .course-card-light .bottom-section .text-one {
        font-size: 11.9px;
        letter-spacing: 0.43px;
        text-align: center;
        margin: 0;
        position: relative;
        bottom: 0; }
        .course-card-with-trainer .bottom-section .text-one:hover,
        .course-card-light .bottom-section .text-one:hover {
          border-radius: 0;
          background-color: transparent;
          position: relative;
          margin-top: 0; }
  .course-card-light:hover {
    border: 0; }
  .info-wrapper {
    border-radius: 0;
    box-shadow: none;
    max-width: 100%;
    margin-bottom: 0; }
    .info-wrapper .row {
      flex-direction: column; }
      .info-wrapper .row .col {
        margin-bottom: 30px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%; }
        .info-wrapper .row .col > div {
          margin-bottom: 0;
          min-width: 50px;
          justify-content: flex-start; }
        .info-wrapper .row .col img {
          max-width: 22px;
          margin: 0 14px 0 0; }
        .info-wrapper .row .col:not(:last-child) {
          margin-right: 0; }
        .info-wrapper .row .col:first-child {
          border-right: 0;
          padding: 0;
          justify-content: flex-start; }
        .info-wrapper .row .col .text-one {
          font-size: 18px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.64px;
          text-align: left;
          color: #ffffff; }
        .info-wrapper .row .col .text-two {
          font-size: 12px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 2.41;
          letter-spacing: normal;
          text-align: left;
          color: #ffffff; } }

.study_in_canada.adults .banner-wrapper {
  background: url(images/master-classes/adult-banner.png) no-repeat;
  background-size: cover;
  padding-bottom: 160px; }
  .study_in_canada.adults .banner-wrapper .title-text {
    font-size: 40px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: #1e174a;
    margin-bottom: 8px;
    text-shadow: none;
    background-color: #700aad; }
  .study_in_canada.adults .banner-wrapper .sub-text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #1e174a;
    margin-bottom: 4px; }
  .study_in_canada.adults .banner-wrapper .text {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1e174a; }
    .study_in_canada.adults .banner-wrapper .text span {
      color: #4922f3; }
  .study_in_canada.adults .banner-wrapper button {
    border-radius: 2px;
    padding: 15px 52px;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.16px;
    text-align: center;
    color: #ffffff;
    outline: none;
    cursor: pointer;
    border: 0;
    margin-top: 30px; }

.study_in_canada.adults .container.helper {
  max-width: 1140px; }

.study_in_canada.adults .helper-wrapper {
  background: url(images/master-classes/helper-background.png) no-repeat;
  background-size: 100% 100%;
  padding: 44px 58px;
  display: flex;
  margin: 124px 0 130px 0;
  align-items: center; }
  .study_in_canada.adults .helper-wrapper button {
    border-radius: 2px;
    padding: 15px 52px;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.16px;
    text-align: center;
    color: #ffffff;
    outline: none;
    cursor: pointer;
    border: 0; }
  .study_in_canada.adults .helper-wrapper > div:last-child {
    flex-grow: 1;
    text-align: right; }
  .study_in_canada.adults .helper-wrapper .content {
    max-width: 460px; }
    .study_in_canada.adults .helper-wrapper .content > img {
      max-width: 116px; }
    .study_in_canada.adults .helper-wrapper .content .text {
      font-size: 36px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.9px;
      text-align: left;
      color: #2f3a4f;
      margin-bottom: 4px; }
    .study_in_canada.adults .helper-wrapper .content .sub-text {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #464646; }
      .study_in_canada.adults .helper-wrapper .content .sub-text span {
        font-weight: bold;
        color: #2a00e8; }

.study_in_canada.adults .section-one {
  padding-top: 60px; }

.study_in_canada .container {
  max-width: 1080px;
  margin: 0 auto; }

.study_in_canada .white-button {
  border-radius: 4px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  cursor: pointer;
  padding: 13px 40px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #2a00e8;
  border: 0; }

.study_in_canada .ant-select-selection {
  border-radius: 4px;
  border: solid 1px #ced0da;
  background-image: linear-gradient(to top, #f2f4f7, #ffffff);
  min-width: 180px; }
  .study_in_canada .ant-select-selection .anticon svg {
    color: #3031ea; }

.study_in_canada .banner-wrapper {
  background: url(images/banners/canada.webp) no-repeat;
  background-size: cover;
  padding-top: 160px; }
  .study_in_canada .banner-wrapper .title-text {
    font-size: 40px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
    margin-bottom: 5px;
    max-width: 320px;
    background: white; }
  .study_in_canada .banner-wrapper .sub-text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #000;
    max-width: 52rem;
    background: white; }
  .study_in_canada .banner-wrapper .list-wrap {
    border-radius: 6px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 48px 74px;
    position: relative;
    display: flex;
    align-items: flex-start;
    top: 42px; }
    .study_in_canada .banner-wrapper .list-wrap .list {
      flex-grow: 1;
      flex-basis: 100%;
      text-align: center; }
      .study_in_canada .banner-wrapper .list-wrap .list:not(:last-child) {
        margin-right: 60px; }
      .study_in_canada .banner-wrapper .list-wrap .list img {
        margin-bottom: 24px; }
      .study_in_canada .banner-wrapper .list-wrap .list .text-one {
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.32;
        letter-spacing: normal;
        color: #262626;
        margin-bottom: 7px; }
      .study_in_canada .banner-wrapper .list-wrap .list .text-two {
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: #2f3a4f; }

.study_in_canada .section-one {
  padding-top: 120px; }
  .study_in_canada .section-one .text-one {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #262626; }
    .study_in_canada .section-one .text-one span {
      font-weight: bold; }
  .study_in_canada .section-one .header-wrap {
    display: flex;
    align-items: flex-end; }
  .study_in_canada .section-one .filter-wrap {
    flex-grow: 1;
    text-align: right; }
    .study_in_canada .section-one .filter-wrap .filter {
      display: inline-flex;
      align-items: center; }
      .study_in_canada .section-one .filter-wrap .filter:not(:last-child) {
        margin-right: 32px; }
      .study_in_canada .section-one .filter-wrap .filter > p {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #7f8fa4;
        margin-right: 12px; }
  .study_in_canada .section-one .cards-wrapper {
    margin: 40px 0 55px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px; }
  .study_in_canada .section-one .button-wrap {
    margin-bottom: 100px;
    text-align: center; }

.study_in_canada .section-two {
  background-image: linear-gradient(248deg, #f8f8f8, #efefef -25%);
  padding: 50px 0 100px 0; }
  .study_in_canada .section-two h2 {
    font-size: 36px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.9px;
    text-align: center;
    color: #2f3a4f;
    max-width: 540px;
    margin: 0 auto 34px auto; }
  .study_in_canada .section-two .carousel-wrapper {
    margin: 20px 0 0 0; }
    .study_in_canada .section-two .carousel-wrapper .testimonial-card {
      margin-right: 20px; }
    .study_in_canada .section-two .carousel-wrapper .project-card {
      margin-right: 20px; }
    .study_in_canada .section-two .carousel-wrapper .tutorial-card {
      margin-right: 33px; }
    .study_in_canada .section-two .carousel-wrapper .slick-list {
      padding: 24px 0;
      position: relative; }
    .study_in_canada .section-two .carousel-wrapper .slick-arrow {
      position: absolute;
      top: calc(50% - 38px);
      outline: none;
      cursor: pointer; }
      .study_in_canada .section-two .carousel-wrapper .slick-arrow.slick-disabled {
        pointer-events: none;
        opacity: .8; }
      .study_in_canada .section-two .carousel-wrapper .slick-arrow.slick-prev {
        background: url(images/static/arrow-left.svg) no-repeat;
        left: -80px;
        width: 90px;
        height: 90px;
        background-size: contain;
        border: 0;
        color: transparent; }
      .study_in_canada .section-two .carousel-wrapper .slick-arrow.slick-next {
        right: -80px;
        background: url(images/static/arrow-right.svg) no-repeat;
        width: 90px;
        height: 90px;
        background-size: contain;
        border: 0;
        color: transparent; }

.study_in_canada .divider-banner {
  background-image: linear-gradient(275deg, #700aad 124%, #8a3ceb 22%);
  padding: 46px 0 52px  0; }
  .study_in_canada .divider-banner .container {
    display: flex;
    align-items: center; }
    .study_in_canada .divider-banner .container .col {
      flex-grow: 1;
      display: flex;
      align-items: center; }
      .study_in_canada .divider-banner .container .col img {
        margin-right: 25px; }
      .study_in_canada .divider-banner .container .col .text-wrap .text-one {
        font-size: 40px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.66;
        letter-spacing: normal;
        color: #ffffff;
        margin-bottom: 12px; }
      .study_in_canada .divider-banner .container .col .text-wrap .text-two {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        color: #ffffff; }
      .study_in_canada .divider-banner .container .col:not(:last-child) {
        margin-right: 60px; }

.study_in_canada .footer {
  padding-top: 200px; }

.info-wrapper {
  border-radius: 8px;
  box-shadow: 0 2px 44px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(106deg, #fba649, #f2534c);
  padding: 30px;
  max-width: 1080px;
  margin: 0 auto;
  position: relative;
  margin-bottom: -80px; }
  .info-wrapper .row {
    display: flex;
    align-items: flex-start; }
    .info-wrapper .row .col {
      text-align: center; }
      .info-wrapper .row .col:not(:last-child) {
        margin-right: 32px; }
      .info-wrapper .row .col:first-child {
        border-right: solid 2px #bb4039;
        padding: 12px 20px 12px 0;
        min-width: 322px; }
      .info-wrapper .row .col > div {
        height: 40px;
        margin-bottom: 22px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .info-wrapper .row .col > div img {
          max-height: 100%; }
      .info-wrapper .row .col .text-one {
        font-size: 30px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.75px;
        color: #ffffff;
        text-align: left; }
      .info-wrapper .row .col .text-two {
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.69;
        letter-spacing: normal;
        color: #ffffff; }
        .info-wrapper .row .col .text-two span {
          display: block; }
      .info-wrapper .row .col a {
        color: inherit; }

.course-card-with-trainer,
.course-card-light {
  border-radius: 4px;
  box-shadow: 0 2px 37px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px transparent;
  background-color: #ffffff;
  padding: 26px 20px 22px 20px;
  position: relative;
  padding-bottom: 100px; }
  .course-card-with-trainer .title-text,
  .course-card-light .title-text {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262626;
    margin-bottom: 6px;
    height: 44px;
    overflow: hidden; }
  .course-card-with-trainer .sub-text,
  .course-card-light .sub-text {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #464646; }
  .course-card-with-trainer .label,
  .course-card-light .label {
    background-color: rgba(184, 233, 134, 0.7);
    padding: 6px 11px 6px 16px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    color: #2f3a4f;
    display: inline-block;
    margin: 20px 0 16px 0; }
    .course-card-with-trainer .label span,
    .course-card-light .label span {
      font-weight: bold; }
  .course-card-with-trainer .age,
  .course-card-light .age {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #464646;
    margin-bottom: 6px; }
  .course-card-with-trainer .time,
  .course-card-light .time {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #464646;
    margin-bottom: 12px; }
  .course-card-with-trainer .trainer-wrap,
  .course-card-light .trainer-wrap {
    display: flex;
    align-items: center;
    margin-top: 24px; }
    .course-card-with-trainer .trainer-wrap .img-wrap,
    .course-card-light .trainer-wrap .img-wrap {
      width: 75px;
      height: 75px;
      border-radius: 100%;
      margin-right: 12px;
      background: #ccc; }
      .course-card-with-trainer .trainer-wrap .img-wrap img,
      .course-card-light .trainer-wrap .img-wrap img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100%; }
    .course-card-with-trainer .trainer-wrap .content-wrap,
    .course-card-light .trainer-wrap .content-wrap {
      flex-grow: 1; }
      .course-card-with-trainer .trainer-wrap .content-wrap .text,
      .course-card-light .trainer-wrap .content-wrap .text {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.3px;
        color: #2f3a4f; }
      .course-card-with-trainer .trainer-wrap .content-wrap .name,
      .course-card-light .trainer-wrap .content-wrap .name {
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.45px;
        color: #262626;
        margin-bottom: 3px; }
      .course-card-with-trainer .trainer-wrap .content-wrap .expert,
      .course-card-light .trainer-wrap .content-wrap .expert {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.3px;
        color: #ea147b; }
      .course-card-with-trainer .trainer-wrap .content-wrap .qualification,
      .course-card-light .trainer-wrap .content-wrap .qualification {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.35px;
        color: #2f3a4f; }
  .course-card-with-trainer .bottom-section,
  .course-card-light .bottom-section {
    border-top: solid 1px rgba(82, 95, 120, 0.4);
    padding-top: 20px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    position: absolute;
    left: 20px;
    bottom: 0;
    right: 20px;
    margin: 0;
    width: calc(100% - 40px);
    padding: 32px 20px 30px 20px;
    display: flex;
    justify-content: center; }
    .course-card-with-trainer .bottom-section .text-one,
    .course-card-light .bottom-section .text-one {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      text-align: center;
      color: #2a00e8;
      text-transform: uppercase;
      margin: 0;
      display: inline-block;
      position: relative;
      cursor: pointer;
      padding: 8px 16px;
      position: absolute;
      bottom: 16px; }
      .course-card-with-trainer .bottom-section .text-one:hover,
      .course-card-light .bottom-section .text-one:hover {
        border-radius: 2px;
        padding: 8px 16px;
        background-color: #edeafd;
        position: absolute;
        margin-top: -8px; }

.course-card-light {
  border-radius: 4px;
  box-shadow: 0 2px 37px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff; }
  .course-card-light:hover {
    border: solid 1px #2a00e8; }
  .course-card-light .title-text {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262626;
    margin-bottom: 30px; }
  .course-card-light .sub-text {
    margin-bottom: 4px; }
  .course-card-light .flex-wrap {
    justify-content: center; }

.testimonial-card {
  border-radius: 4px;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 20px 15px; }
  .testimonial-card img {
    margin-bottom: 12px; }
  .testimonial-card .text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #464646;
    margin-bottom: 80px; }
  .testimonial-card .user-wrap {
    display: flex;
    align-items: center; }
    .testimonial-card .user-wrap .img-wrap {
      width: 50px;
      height: 50px;
      margin-right: 12px;
      background: #ddd;
      border-radius: 100%; }
    .testimonial-card .user-wrap .content-wrap .text-one {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.45px;
      color: #262626;
      margin-bottom: 2px; }
    .testimonial-card .user-wrap .content-wrap .text-two {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.3px;
      color: #2f3a4f; }

@media screen and (max-width: 768px) {
  .study_in_canada .ant-select-selection {
    min-width: 110px;
    height: 24px; }
    .study_in_canada .ant-select-selection .anticon svg {
      font-size: 12px; }
    .study_in_canada .ant-select-selection .ant-select-selection__rendered {
      line-height: 20px; }
    .study_in_canada .ant-select-selection .ant-select-selection-selected-value {
      font-size: 8.4px;
      font-weight: 500; }
  .study_in_canada.hindi .banner-wrapper .title-text {
    max-width: 100%; }
  .study_in_canada.adults .banner-wrapper {
    background: url(images/master-classes/adult-banner-mobile.png) no-repeat;
    background-size: cover; }
    .study_in_canada.adults .banner-wrapper .title-text {
      max-width: 100%;
      font-size: 25.6px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.23;
      letter-spacing: normal;
      color: #ffffff; }
    .study_in_canada.adults .banner-wrapper .sub-text {
      font-size: 10.2px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      color: #ffffff; }
    .study_in_canada.adults .banner-wrapper .text {
      font-size: 11.5px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff; }
      .study_in_canada.adults .banner-wrapper .text span {
        color: #ffffff; }
    .study_in_canada.adults .banner-wrapper button {
      margin-top: 14px;
      font-size: 10.2px;
      padding: 9px 46px; }
  .study_in_canada.adults .section-one {
    padding-top: 24px; }
  .study_in_canada.adults .helper-wrapper {
    background: url(images/master-classes/helper-background-mobile.png) no-repeat;
    background-size: 100% 100%;
    margin: 0;
    flex-direction: column;
    width: 100%;
    padding: 52px 24px; }
    .study_in_canada.adults .helper-wrapper .content .text {
      font-size: 26px;
      font-weight: 300; }
    .study_in_canada.adults .helper-wrapper .content .sub-text {
      font-size: 11px;
      font-weight: 500;
      line-height: 1.82;
      margin-bottom: 20px; }
    .study_in_canada.adults .helper-wrapper > div:last-child {
      width: 100%;
      text-align: left; }
    .study_in_canada.adults .helper-wrapper button {
      font-size: 10.5px;
      padding: 10px 60px; }
  .study_in_canada .banner-wrapper {
    padding: 120px 28px 28px;
    position: relative;
    height: 450px;
    background: url(images/master-classes/banner-mobile.png) no-repeat;
    background-size: cover; }
    .study_in_canada .banner-wrapper .title-text {
      font-size: 24px;
      font-weight: 300;
      max-width: 160px; }
    .study_in_canada .banner-wrapper .sub-text {
      font-size: 9.6px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56; }
    .study_in_canada .banner-wrapper .list-wrap {
      flex-direction: column;
      padding: 26px 30px;
      position: absolute;
      top: 65%;
      margin: auto;
      left: 6px;
      right: 6px; }
      .study_in_canada .banner-wrapper .list-wrap .list {
        display: flex;
        justify-content: center;
        text-align: left;
        width: 100%; }
        .study_in_canada .banner-wrapper .list-wrap .list img {
          margin-right: 15px;
          width: 26px;
          height: 24px;
          object-fit: contain; }
        .study_in_canada .banner-wrapper .list-wrap .list > div {
          flex-grow: 1; }
          .study_in_canada .banner-wrapper .list-wrap .list > div .text-one {
            font-size: 14px;
            font-weight: 500;
            text-align: left;
            margin-bottom: 5px; }
          .study_in_canada .banner-wrapper .list-wrap .list > div .text-two {
            font-size: 11.2px;
            font-weight: normal;
            text-align: left; }
        .study_in_canada .banner-wrapper .list-wrap .list:not(:last-child) {
          margin: 0 0 16px 0; }
  .study_in_canada .section-one {
    padding-top: 100px; }
    .study_in_canada .section-one .container {
      padding: 0; }
    .study_in_canada .section-one .header-wrap {
      padding: 0 12px; }
    .study_in_canada .section-one .carousel-wrapper {
      margin: 20px 0 0 0; }
      .study_in_canada .section-one .carousel-wrapper .testimonial-card {
        margin-right: 20px; }
      .study_in_canada .section-one .carousel-wrapper .project-card {
        margin-right: 20px; }
      .study_in_canada .section-one .carousel-wrapper .tutorial-card {
        margin-right: 33px; }
      .study_in_canada .section-one .carousel-wrapper .slick-track {
        display: flex !important; }
      .study_in_canada .section-one .carousel-wrapper .slick-slide {
        height: inherit !important; }
      .study_in_canada .section-one .carousel-wrapper .slick-list {
        padding: 24px 0;
        position: relative; }
      .study_in_canada .section-one .carousel-wrapper .slick-slide {
        padding: 0 16px; }
      .study_in_canada .section-one .carousel-wrapper .slick-arrow {
        position: absolute;
        top: calc(50% - 38px);
        outline: none;
        cursor: pointer; }
        .study_in_canada .section-one .carousel-wrapper .slick-arrow.slick-disabled {
          pointer-events: none;
          opacity: .8; }
        .study_in_canada .section-one .carousel-wrapper .slick-arrow.slick-prev {
          background: url(images/master-classes/arrow-left-white.svg) no-repeat;
          left: 0px;
          width: 37px;
          height: 37px;
          background-size: contain;
          border: 0;
          color: transparent;
          z-index: 6; }
        .study_in_canada .section-one .carousel-wrapper .slick-arrow.slick-next {
          right: 0px;
          background: url(images/master-classes/arrow-right-white.svg) no-repeat;
          width: 37px;
          height: 37px;
          background-size: contain;
          border: 0;
          color: transparent;
          z-index: 6; }
    .study_in_canada .section-one .text-one {
      text-align: left;
      margin-bottom: 12px;
      font-size: 12px;
      font-weight: 500; }
    .study_in_canada .section-one .header-wrap {
      flex-direction: column;
      width: 100%;
      align-items: flex-start; }
      .study_in_canada .section-one .header-wrap .filter-wrap {
        display: flex;
        align-items: center; }
        .study_in_canada .section-one .header-wrap .filter-wrap .filter p {
          font-size: 8.4px; }
        .study_in_canada .section-one .header-wrap .filter-wrap .filter:not(::last-child) {
          margin-right: 20px; }
    .study_in_canada .section-one .cards-wrapper {
      display: block;
      margin: 24px 0;
      padding: 0 12px; }
      .study_in_canada .section-one .cards-wrapper .course-card-with-trainer {
        margin-bottom: 12px; }
      .study_in_canada .section-one .cards-wrapper .course-card {
        margin-bottom: 18px; }
  .study_in_canada .section-two {
    padding: 38px 0 70px 0; }
    .study_in_canada .section-two h2 {
      font-size: 22px;
      font-weight: 300;
      padding: 0 20px; }
    .study_in_canada .section-two .carousel-wrapper {
      padding: 0 0 0 20px;
      margin: 20px 0 0 0; }
      .study_in_canada .section-two .carousel-wrapper .testimonial-card {
        margin-right: 0; }
      .study_in_canada .section-two .carousel-wrapper .slick-slide {
        padding: 0 16px 0 0; }
  .study_in_canada .divider-banner {
    padding: 46px 13px; }
    .study_in_canada .divider-banner .container {
      padding: 0;
      display: block; }
      .study_in_canada .divider-banner .container .col {
        display: inline-flex;
        min-width: 50%;
        margin: 0;
        max-width: 50%; }
        .study_in_canada .divider-banner .container .col:first-child, .study_in_canada .divider-banner .container .col:nth-child(2) {
          margin-bottom: 48px; }
        .study_in_canada .divider-banner .container .col img {
          width: 28.7px;
          height: 29px;
          margin-right: 15px; }
        .study_in_canada .divider-banner .container .col .text-wrap .text-one {
          font-size: 25.2px;
          font-weight: 300;
          line-height: 0.66; }
        .study_in_canada .divider-banner .container .col .text-wrap .text-two {
          font-size: 10.1px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;
          letter-spacing: normal;
          color: #ffffff; }
        .study_in_canada .divider-banner .container .col:not(:last-child) {
          margin-right: 0; }
  .testimonial-card .text {
    font-size: 11.9px;
    margin-bottom: 36px; }
  .testimonial-card > img {
    width: 18.7px;
    height: 39.1px;
    object-fit: contain; }
  .testimonial-card .user-wrap .img-wrap {
    width: 42.5px;
    height: 42.5px; }
  .testimonial-card .user-wrap .content-wrap .text-one {
    font-size: 15.3px; }
  .testimonial-card .user-wrap .content-wrap .text-two {
    font-size: 10.2px; }
  .course-card-with-trainer,
  .course-card-light {
    border-radius: 3.4px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.19);
    background-color: #ffffff;
    padding: 22px 16px 16px 16px; }
    .course-card-with-trainer .title-text,
    .course-card-light .title-text {
      font-size: 13.6px;
      margin-bottom: 4.8px; }
    .course-card-with-trainer .sub-text,
    .course-card-light .sub-text {
      font-size: 11.9px;
      font-weight: 500; }
    .course-card-with-trainer .label,
    .course-card-light .label {
      font-size: 10.2px;
      margin: 23px 0 19px 0; }
      .course-card-with-trainer .label span,
      .course-card-light .label span {
        font-weight: bold; }
    .course-card-with-trainer .age,
    .course-card-light .age {
      font-size: 11.9px;
      margin-bottom: 4.8px; }
    .course-card-with-trainer .time,
    .course-card-light .time {
      font-size: 11.9px;
      margin-bottom: 9.9px; }
    .course-card-with-trainer .trainer-wrap,
    .course-card-light .trainer-wrap {
      display: flex;
      align-items: center; }
      .course-card-with-trainer .trainer-wrap .img-wrap,
      .course-card-light .trainer-wrap .img-wrap {
        width: 63.8px;
        height: 63.8px;
        margin-right: 10px; }
      .course-card-with-trainer .trainer-wrap .content-wrap,
      .course-card-light .trainer-wrap .content-wrap {
        flex-grow: 1; }
        .course-card-with-trainer .trainer-wrap .content-wrap .text,
        .course-card-light .trainer-wrap .content-wrap .text {
          font-size: 10.2px; }
        .course-card-with-trainer .trainer-wrap .content-wrap .name,
        .course-card-light .trainer-wrap .content-wrap .name {
          font-size: 15.3px; }
        .course-card-with-trainer .trainer-wrap .content-wrap .expert,
        .course-card-light .trainer-wrap .content-wrap .expert {
          font-size: 10.2px;
          margin-bottom: 3px; }
        .course-card-with-trainer .trainer-wrap .content-wrap .qualification,
        .course-card-light .trainer-wrap .content-wrap .qualification {
          font-size: 11.9px; }
    .course-card-with-trainer .bottom-section,
    .course-card-light .bottom-section {
      padding-top: 16px;
      margin-top: 25px;
      position: relative;
      left: 0;
      right: 0;
      width: 100%;
      padding: 12px 0 0; }
      .course-card-with-trainer .bottom-section .text-one,
      .course-card-light .bottom-section .text-one {
        font-size: 11.9px;
        letter-spacing: 0.43px;
        text-align: center;
        margin: 0;
        position: relative;
        bottom: 0; }
        .course-card-with-trainer .bottom-section .text-one:hover,
        .course-card-light .bottom-section .text-one:hover {
          border-radius: 0;
          background-color: transparent;
          position: relative;
          margin-top: 0; }
  .course-card-light:hover {
    border: 0; }
  .info-wrapper {
    border-radius: 0;
    box-shadow: none;
    max-width: 100%;
    margin-bottom: 0; }
    .info-wrapper .row {
      flex-direction: column; }
      .info-wrapper .row .col {
        margin-bottom: 30px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%; }
        .info-wrapper .row .col > div {
          margin-bottom: 0;
          min-width: 50px;
          justify-content: flex-start; }
        .info-wrapper .row .col img {
          max-width: 22px;
          margin: 0 14px 0 0; }
        .info-wrapper .row .col:not(:last-child) {
          margin-right: 0; }
        .info-wrapper .row .col:first-child {
          border-right: 0;
          padding: 0;
          justify-content: flex-start; }
        .info-wrapper .row .col .text-one {
          font-size: 18px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.64px;
          text-align: left;
          color: #ffffff; }
        .info-wrapper .row .col .text-two {
          font-size: 12px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 2.41;
          letter-spacing: normal;
          text-align: left;
          color: #ffffff; } }

.study_in_europe.adults .banner-wrapper {
  background: url(images/master-classes/adult-banner.png) no-repeat;
  background-size: cover;
  padding-bottom: 160px; }
  .study_in_europe.adults .banner-wrapper .title-text {
    font-size: 40px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: #1e174a;
    margin-bottom: 8px;
    text-shadow: none; }
  .study_in_europe.adults .banner-wrapper .sub-text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #1e174a;
    margin-bottom: 4px; }
  .study_in_europe.adults .banner-wrapper .text {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1e174a; }
    .study_in_europe.adults .banner-wrapper .text span {
      color: #4922f3; }
  .study_in_europe.adults .banner-wrapper button {
    border-radius: 2px;
    padding: 15px 52px;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.16px;
    text-align: center;
    color: #ffffff;
    outline: none;
    cursor: pointer;
    border: 0;
    margin-top: 30px; }

.study_in_europe.adults .container.helper {
  max-width: 1140px; }

.study_in_europe.adults .helper-wrapper {
  background: url(images/master-classes/helper-background.png) no-repeat;
  background-size: 100% 100%;
  padding: 44px 58px;
  display: flex;
  margin: 124px 0 130px 0;
  align-items: center; }
  .study_in_europe.adults .helper-wrapper button {
    border-radius: 2px;
    padding: 15px 52px;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.16px;
    text-align: center;
    color: #ffffff;
    outline: none;
    cursor: pointer;
    border: 0; }
  .study_in_europe.adults .helper-wrapper > div:last-child {
    flex-grow: 1;
    text-align: right; }
  .study_in_europe.adults .helper-wrapper .content {
    max-width: 460px; }
    .study_in_europe.adults .helper-wrapper .content > img {
      max-width: 116px; }
    .study_in_europe.adults .helper-wrapper .content .text {
      font-size: 36px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.9px;
      text-align: left;
      color: #2f3a4f;
      margin-bottom: 4px; }
    .study_in_europe.adults .helper-wrapper .content .sub-text {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #464646; }
      .study_in_europe.adults .helper-wrapper .content .sub-text span {
        font-weight: bold;
        color: #2a00e8; }

.study_in_europe.adults .section-one {
  padding-top: 60px; }

.study_in_europe .container {
  max-width: 1080px;
  margin: 0 auto; }

.study_in_europe .white-button {
  border-radius: 4px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  cursor: pointer;
  padding: 13px 40px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #2a00e8;
  border: 0; }

.study_in_europe .ant-select-selection {
  border-radius: 4px;
  border: solid 1px #ced0da;
  background-image: linear-gradient(to top, #f2f4f7, #ffffff);
  min-width: 180px; }
  .study_in_europe .ant-select-selection .anticon svg {
    color: #3031ea; }

.study_in_europe .banner-wrapper {
  background: url(images/banners/france.webp) no-repeat;
  background-size: cover;
  padding-top: 160px; }
  .study_in_europe .banner-wrapper .title-text {
    font-size: 40px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
    margin-bottom: 5px;
    max-width: 330px;
    background: white; }
  .study_in_europe .banner-wrapper .sub-text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #000;
    max-width: 55rem;
    background: white; }
  .study_in_europe .banner-wrapper .list-wrap {
    border-radius: 6px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 48px 74px;
    position: relative;
    display: flex;
    align-items: flex-start;
    top: 42px; }
    .study_in_europe .banner-wrapper .list-wrap .list {
      flex-grow: 1;
      flex-basis: 100%;
      text-align: center; }
      .study_in_europe .banner-wrapper .list-wrap .list:not(:last-child) {
        margin-right: 60px; }
      .study_in_europe .banner-wrapper .list-wrap .list img {
        margin-bottom: 24px; }
      .study_in_europe .banner-wrapper .list-wrap .list .text-one {
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.32;
        letter-spacing: normal;
        color: #262626;
        margin-bottom: 7px; }
      .study_in_europe .banner-wrapper .list-wrap .list .text-two {
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: #2f3a4f; }

.study_in_europe .section-one {
  padding-top: 120px; }
  .study_in_europe .section-one .text-one {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #262626; }
    .study_in_europe .section-one .text-one span {
      font-weight: bold; }
  .study_in_europe .section-one .header-wrap {
    display: flex;
    align-items: flex-end; }
  .study_in_europe .section-one .filter-wrap {
    flex-grow: 1;
    text-align: right; }
    .study_in_europe .section-one .filter-wrap .filter {
      display: inline-flex;
      align-items: center; }
      .study_in_europe .section-one .filter-wrap .filter:not(:last-child) {
        margin-right: 32px; }
      .study_in_europe .section-one .filter-wrap .filter > p {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #7f8fa4;
        margin-right: 12px; }
  .study_in_europe .section-one .cards-wrapper {
    margin: 40px 0 55px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px; }
  .study_in_europe .section-one .button-wrap {
    margin-bottom: 100px;
    text-align: center; }

.study_in_europe .section-two {
  background-image: linear-gradient(248deg, #f8f8f8, #efefef -25%);
  padding: 50px 0 100px 0; }
  .study_in_europe .section-two h2 {
    font-size: 36px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.9px;
    text-align: center;
    color: #2f3a4f;
    max-width: 540px;
    margin: 0 auto 34px auto; }
  .study_in_europe .section-two .carousel-wrapper {
    margin: 20px 0 0 0; }
    .study_in_europe .section-two .carousel-wrapper .testimonial-card {
      margin-right: 20px; }
    .study_in_europe .section-two .carousel-wrapper .project-card {
      margin-right: 20px; }
    .study_in_europe .section-two .carousel-wrapper .tutorial-card {
      margin-right: 33px; }
    .study_in_europe .section-two .carousel-wrapper .slick-list {
      padding: 24px 0;
      position: relative; }
    .study_in_europe .section-two .carousel-wrapper .slick-arrow {
      position: absolute;
      top: calc(50% - 38px);
      outline: none;
      cursor: pointer; }
      .study_in_europe .section-two .carousel-wrapper .slick-arrow.slick-disabled {
        pointer-events: none;
        opacity: .8; }
      .study_in_europe .section-two .carousel-wrapper .slick-arrow.slick-prev {
        background: url(images/static/arrow-left.svg) no-repeat;
        left: -80px;
        width: 90px;
        height: 90px;
        background-size: contain;
        border: 0;
        color: transparent; }
      .study_in_europe .section-two .carousel-wrapper .slick-arrow.slick-next {
        right: -80px;
        background: url(images/static/arrow-right.svg) no-repeat;
        width: 90px;
        height: 90px;
        background-size: contain;
        border: 0;
        color: transparent; }

.study_in_europe .divider-banner {
  background-image: linear-gradient(275deg, #700aad 124%, #8a3ceb 22%);
  padding: 46px 0 52px  0; }
  .study_in_europe .divider-banner .container {
    display: flex;
    align-items: center; }
    .study_in_europe .divider-banner .container .col {
      flex-grow: 1;
      display: flex;
      align-items: center; }
      .study_in_europe .divider-banner .container .col img {
        margin-right: 25px; }
      .study_in_europe .divider-banner .container .col .text-wrap .text-one {
        font-size: 40px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.66;
        letter-spacing: normal;
        color: #ffffff;
        margin-bottom: 12px; }
      .study_in_europe .divider-banner .container .col .text-wrap .text-two {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        color: #ffffff; }
      .study_in_europe .divider-banner .container .col:not(:last-child) {
        margin-right: 60px; }

.study_in_europe .footer {
  padding-top: 200px; }

.info-wrapper {
  border-radius: 8px;
  box-shadow: 0 2px 44px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(106deg, #fba649, #f2534c);
  padding: 30px;
  max-width: 1080px;
  margin: 0 auto;
  position: relative;
  margin-bottom: -80px; }
  .info-wrapper .row {
    display: flex;
    align-items: flex-start; }
    .info-wrapper .row .col {
      text-align: center; }
      .info-wrapper .row .col:not(:last-child) {
        margin-right: 32px; }
      .info-wrapper .row .col:first-child {
        border-right: solid 2px #bb4039;
        padding: 12px 20px 12px 0;
        min-width: 322px; }
      .info-wrapper .row .col > div {
        height: 40px;
        margin-bottom: 22px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .info-wrapper .row .col > div img {
          max-height: 100%; }
      .info-wrapper .row .col .text-one {
        font-size: 30px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.75px;
        color: #ffffff;
        text-align: left; }
      .info-wrapper .row .col .text-two {
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.69;
        letter-spacing: normal;
        color: #ffffff; }
        .info-wrapper .row .col .text-two span {
          display: block; }
      .info-wrapper .row .col a {
        color: inherit; }

.course-card-with-trainer,
.course-card-light {
  border-radius: 4px;
  box-shadow: 0 2px 37px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px transparent;
  background-color: #ffffff;
  padding: 26px 20px 22px 20px;
  position: relative;
  padding-bottom: 100px; }
  .course-card-with-trainer .title-text,
  .course-card-light .title-text {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262626;
    margin-bottom: 6px;
    height: 44px;
    overflow: hidden; }
  .course-card-with-trainer .sub-text,
  .course-card-light .sub-text {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #464646; }
  .course-card-with-trainer .label,
  .course-card-light .label {
    background-color: rgba(184, 233, 134, 0.7);
    padding: 6px 11px 6px 16px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    color: #2f3a4f;
    display: inline-block;
    margin: 20px 0 16px 0; }
    .course-card-with-trainer .label span,
    .course-card-light .label span {
      font-weight: bold; }
  .course-card-with-trainer .age,
  .course-card-light .age {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #464646;
    margin-bottom: 6px; }
  .course-card-with-trainer .time,
  .course-card-light .time {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #464646;
    margin-bottom: 12px; }
  .course-card-with-trainer .trainer-wrap,
  .course-card-light .trainer-wrap {
    display: flex;
    align-items: center;
    margin-top: 24px; }
    .course-card-with-trainer .trainer-wrap .img-wrap,
    .course-card-light .trainer-wrap .img-wrap {
      width: 75px;
      height: 75px;
      border-radius: 100%;
      margin-right: 12px;
      background: #ccc; }
      .course-card-with-trainer .trainer-wrap .img-wrap img,
      .course-card-light .trainer-wrap .img-wrap img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100%; }
    .course-card-with-trainer .trainer-wrap .content-wrap,
    .course-card-light .trainer-wrap .content-wrap {
      flex-grow: 1; }
      .course-card-with-trainer .trainer-wrap .content-wrap .text,
      .course-card-light .trainer-wrap .content-wrap .text {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.3px;
        color: #2f3a4f; }
      .course-card-with-trainer .trainer-wrap .content-wrap .name,
      .course-card-light .trainer-wrap .content-wrap .name {
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.45px;
        color: #262626;
        margin-bottom: 3px; }
      .course-card-with-trainer .trainer-wrap .content-wrap .expert,
      .course-card-light .trainer-wrap .content-wrap .expert {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.3px;
        color: #ea147b; }
      .course-card-with-trainer .trainer-wrap .content-wrap .qualification,
      .course-card-light .trainer-wrap .content-wrap .qualification {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.35px;
        color: #2f3a4f; }
  .course-card-with-trainer .bottom-section,
  .course-card-light .bottom-section {
    border-top: solid 1px rgba(82, 95, 120, 0.4);
    padding-top: 20px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    position: absolute;
    left: 20px;
    bottom: 0;
    right: 20px;
    margin: 0;
    width: calc(100% - 40px);
    padding: 32px 20px 30px 20px;
    display: flex;
    justify-content: center; }
    .course-card-with-trainer .bottom-section .text-one,
    .course-card-light .bottom-section .text-one {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      text-align: center;
      color: #2a00e8;
      text-transform: uppercase;
      margin: 0;
      display: inline-block;
      position: relative;
      cursor: pointer;
      padding: 8px 16px;
      position: absolute;
      bottom: 16px; }
      .course-card-with-trainer .bottom-section .text-one:hover,
      .course-card-light .bottom-section .text-one:hover {
        border-radius: 2px;
        padding: 8px 16px;
        background-color: #edeafd;
        position: absolute;
        margin-top: -8px; }

.course-card-light {
  border-radius: 4px;
  box-shadow: 0 2px 37px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff; }
  .course-card-light:hover {
    border: solid 1px #2a00e8; }
  .course-card-light .title-text {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262626;
    margin-bottom: 30px; }
  .course-card-light .sub-text {
    margin-bottom: 4px; }
  .course-card-light .flex-wrap {
    justify-content: center; }

.testimonial-card {
  border-radius: 4px;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 20px 15px; }
  .testimonial-card img {
    margin-bottom: 12px; }
  .testimonial-card .text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #464646;
    margin-bottom: 80px; }
  .testimonial-card .user-wrap {
    display: flex;
    align-items: center; }
    .testimonial-card .user-wrap .img-wrap {
      width: 50px;
      height: 50px;
      margin-right: 12px;
      background: #ddd;
      border-radius: 100%; }
    .testimonial-card .user-wrap .content-wrap .text-one {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.45px;
      color: #262626;
      margin-bottom: 2px; }
    .testimonial-card .user-wrap .content-wrap .text-two {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.3px;
      color: #2f3a4f; }

@media screen and (max-width: 768px) {
  .study_in_europe .ant-select-selection {
    min-width: 110px;
    height: 24px; }
    .study_in_europe .ant-select-selection .anticon svg {
      font-size: 12px; }
    .study_in_europe .ant-select-selection .ant-select-selection__rendered {
      line-height: 20px; }
    .study_in_europe .ant-select-selection .ant-select-selection-selected-value {
      font-size: 8.4px;
      font-weight: 500; }
  .study_in_europe.hindi .banner-wrapper .title-text {
    max-width: 100%; }
  .study_in_europe.adults .banner-wrapper {
    background: url(images/master-classes/adult-banner-mobile.png) no-repeat;
    background-size: cover; }
    .study_in_europe.adults .banner-wrapper .title-text {
      max-width: 100%;
      font-size: 25.6px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.23;
      letter-spacing: normal;
      color: #ffffff; }
    .study_in_europe.adults .banner-wrapper .sub-text {
      font-size: 10.2px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      color: #ffffff; }
    .study_in_europe.adults .banner-wrapper .text {
      font-size: 11.5px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff; }
      .study_in_europe.adults .banner-wrapper .text span {
        color: #ffffff; }
    .study_in_europe.adults .banner-wrapper button {
      margin-top: 14px;
      font-size: 10.2px;
      padding: 9px 46px; }
  .study_in_europe.adults .section-one {
    padding-top: 24px; }
  .study_in_europe.adults .helper-wrapper {
    background: url(images/master-classes/helper-background-mobile.png) no-repeat;
    background-size: 100% 100%;
    margin: 0;
    flex-direction: column;
    width: 100%;
    padding: 52px 24px; }
    .study_in_europe.adults .helper-wrapper .content .text {
      font-size: 26px;
      font-weight: 300; }
    .study_in_europe.adults .helper-wrapper .content .sub-text {
      font-size: 11px;
      font-weight: 500;
      line-height: 1.82;
      margin-bottom: 20px; }
    .study_in_europe.adults .helper-wrapper > div:last-child {
      width: 100%;
      text-align: left; }
    .study_in_europe.adults .helper-wrapper button {
      font-size: 10.5px;
      padding: 10px 60px; }
  .study_in_europe .banner-wrapper {
    padding: 120px 28px 28px;
    position: relative;
    height: 450px;
    background: url(images/master-classes/banner-mobile.png) no-repeat;
    background-size: cover; }
    .study_in_europe .banner-wrapper .title-text {
      font-size: 24px;
      font-weight: 300;
      max-width: 160px; }
    .study_in_europe .banner-wrapper .sub-text {
      font-size: 9.6px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56; }
    .study_in_europe .banner-wrapper .list-wrap {
      flex-direction: column;
      padding: 26px 30px;
      position: absolute;
      top: 65%;
      margin: auto;
      left: 6px;
      right: 6px; }
      .study_in_europe .banner-wrapper .list-wrap .list {
        display: flex;
        justify-content: center;
        text-align: left;
        width: 100%; }
        .study_in_europe .banner-wrapper .list-wrap .list img {
          margin-right: 15px;
          width: 26px;
          height: 24px;
          object-fit: contain; }
        .study_in_europe .banner-wrapper .list-wrap .list > div {
          flex-grow: 1; }
          .study_in_europe .banner-wrapper .list-wrap .list > div .text-one {
            font-size: 14px;
            font-weight: 500;
            text-align: left;
            margin-bottom: 5px; }
          .study_in_europe .banner-wrapper .list-wrap .list > div .text-two {
            font-size: 11.2px;
            font-weight: normal;
            text-align: left; }
        .study_in_europe .banner-wrapper .list-wrap .list:not(:last-child) {
          margin: 0 0 16px 0; }
  .study_in_europe .section-one {
    padding-top: 100px; }
    .study_in_europe .section-one .container {
      padding: 0; }
    .study_in_europe .section-one .header-wrap {
      padding: 0 12px; }
    .study_in_europe .section-one .carousel-wrapper {
      margin: 20px 0 0 0; }
      .study_in_europe .section-one .carousel-wrapper .testimonial-card {
        margin-right: 20px; }
      .study_in_europe .section-one .carousel-wrapper .project-card {
        margin-right: 20px; }
      .study_in_europe .section-one .carousel-wrapper .tutorial-card {
        margin-right: 33px; }
      .study_in_europe .section-one .carousel-wrapper .slick-track {
        display: flex !important; }
      .study_in_europe .section-one .carousel-wrapper .slick-slide {
        height: inherit !important; }
      .study_in_europe .section-one .carousel-wrapper .slick-list {
        padding: 24px 0;
        position: relative; }
      .study_in_europe .section-one .carousel-wrapper .slick-slide {
        padding: 0 16px; }
      .study_in_europe .section-one .carousel-wrapper .slick-arrow {
        position: absolute;
        top: calc(50% - 38px);
        outline: none;
        cursor: pointer; }
        .study_in_europe .section-one .carousel-wrapper .slick-arrow.slick-disabled {
          pointer-events: none;
          opacity: .8; }
        .study_in_europe .section-one .carousel-wrapper .slick-arrow.slick-prev {
          background: url(images/master-classes/arrow-left-white.svg) no-repeat;
          left: 0px;
          width: 37px;
          height: 37px;
          background-size: contain;
          border: 0;
          color: transparent;
          z-index: 6; }
        .study_in_europe .section-one .carousel-wrapper .slick-arrow.slick-next {
          right: 0px;
          background: url(images/master-classes/arrow-right-white.svg) no-repeat;
          width: 37px;
          height: 37px;
          background-size: contain;
          border: 0;
          color: transparent;
          z-index: 6; }
    .study_in_europe .section-one .text-one {
      text-align: left;
      margin-bottom: 12px;
      font-size: 12px;
      font-weight: 500; }
    .study_in_europe .section-one .header-wrap {
      flex-direction: column;
      width: 100%;
      align-items: flex-start; }
      .study_in_europe .section-one .header-wrap .filter-wrap {
        display: flex;
        align-items: center; }
        .study_in_europe .section-one .header-wrap .filter-wrap .filter p {
          font-size: 8.4px; }
        .study_in_europe .section-one .header-wrap .filter-wrap .filter:not(::last-child) {
          margin-right: 20px; }
    .study_in_europe .section-one .cards-wrapper {
      display: block;
      margin: 24px 0;
      padding: 0 12px; }
      .study_in_europe .section-one .cards-wrapper .course-card-with-trainer {
        margin-bottom: 12px; }
      .study_in_europe .section-one .cards-wrapper .course-card {
        margin-bottom: 18px; }
  .study_in_europe .section-two {
    padding: 38px 0 70px 0; }
    .study_in_europe .section-two h2 {
      font-size: 22px;
      font-weight: 300;
      padding: 0 20px; }
    .study_in_europe .section-two .carousel-wrapper {
      padding: 0 0 0 20px;
      margin: 20px 0 0 0; }
      .study_in_europe .section-two .carousel-wrapper .testimonial-card {
        margin-right: 0; }
      .study_in_europe .section-two .carousel-wrapper .slick-slide {
        padding: 0 16px 0 0; }
  .study_in_europe .divider-banner {
    padding: 46px 13px; }
    .study_in_europe .divider-banner .container {
      padding: 0;
      display: block; }
      .study_in_europe .divider-banner .container .col {
        display: inline-flex;
        min-width: 50%;
        margin: 0;
        max-width: 50%; }
        .study_in_europe .divider-banner .container .col:first-child, .study_in_europe .divider-banner .container .col:nth-child(2) {
          margin-bottom: 48px; }
        .study_in_europe .divider-banner .container .col img {
          width: 28.7px;
          height: 29px;
          margin-right: 15px; }
        .study_in_europe .divider-banner .container .col .text-wrap .text-one {
          font-size: 25.2px;
          font-weight: 300;
          line-height: 0.66; }
        .study_in_europe .divider-banner .container .col .text-wrap .text-two {
          font-size: 10.1px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;
          letter-spacing: normal;
          color: #ffffff; }
        .study_in_europe .divider-banner .container .col:not(:last-child) {
          margin-right: 0; }
  .testimonial-card .text {
    font-size: 11.9px;
    margin-bottom: 36px; }
  .testimonial-card > img {
    width: 18.7px;
    height: 39.1px;
    object-fit: contain; }
  .testimonial-card .user-wrap .img-wrap {
    width: 42.5px;
    height: 42.5px; }
  .testimonial-card .user-wrap .content-wrap .text-one {
    font-size: 15.3px; }
  .testimonial-card .user-wrap .content-wrap .text-two {
    font-size: 10.2px; }
  .course-card-with-trainer,
  .course-card-light {
    border-radius: 3.4px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.19);
    background-color: #ffffff;
    padding: 22px 16px 16px 16px; }
    .course-card-with-trainer .title-text,
    .course-card-light .title-text {
      font-size: 13.6px;
      margin-bottom: 4.8px; }
    .course-card-with-trainer .sub-text,
    .course-card-light .sub-text {
      font-size: 11.9px;
      font-weight: 500; }
    .course-card-with-trainer .label,
    .course-card-light .label {
      font-size: 10.2px;
      margin: 23px 0 19px 0; }
      .course-card-with-trainer .label span,
      .course-card-light .label span {
        font-weight: bold; }
    .course-card-with-trainer .age,
    .course-card-light .age {
      font-size: 11.9px;
      margin-bottom: 4.8px; }
    .course-card-with-trainer .time,
    .course-card-light .time {
      font-size: 11.9px;
      margin-bottom: 9.9px; }
    .course-card-with-trainer .trainer-wrap,
    .course-card-light .trainer-wrap {
      display: flex;
      align-items: center; }
      .course-card-with-trainer .trainer-wrap .img-wrap,
      .course-card-light .trainer-wrap .img-wrap {
        width: 63.8px;
        height: 63.8px;
        margin-right: 10px; }
      .course-card-with-trainer .trainer-wrap .content-wrap,
      .course-card-light .trainer-wrap .content-wrap {
        flex-grow: 1; }
        .course-card-with-trainer .trainer-wrap .content-wrap .text,
        .course-card-light .trainer-wrap .content-wrap .text {
          font-size: 10.2px; }
        .course-card-with-trainer .trainer-wrap .content-wrap .name,
        .course-card-light .trainer-wrap .content-wrap .name {
          font-size: 15.3px; }
        .course-card-with-trainer .trainer-wrap .content-wrap .expert,
        .course-card-light .trainer-wrap .content-wrap .expert {
          font-size: 10.2px;
          margin-bottom: 3px; }
        .course-card-with-trainer .trainer-wrap .content-wrap .qualification,
        .course-card-light .trainer-wrap .content-wrap .qualification {
          font-size: 11.9px; }
    .course-card-with-trainer .bottom-section,
    .course-card-light .bottom-section {
      padding-top: 16px;
      margin-top: 25px;
      position: relative;
      left: 0;
      right: 0;
      width: 100%;
      padding: 12px 0 0; }
      .course-card-with-trainer .bottom-section .text-one,
      .course-card-light .bottom-section .text-one {
        font-size: 11.9px;
        letter-spacing: 0.43px;
        text-align: center;
        margin: 0;
        position: relative;
        bottom: 0; }
        .course-card-with-trainer .bottom-section .text-one:hover,
        .course-card-light .bottom-section .text-one:hover {
          border-radius: 0;
          background-color: transparent;
          position: relative;
          margin-top: 0; }
  .course-card-light:hover {
    border: 0; }
  .info-wrapper {
    border-radius: 0;
    box-shadow: none;
    max-width: 100%;
    margin-bottom: 0; }
    .info-wrapper .row {
      flex-direction: column; }
      .info-wrapper .row .col {
        margin-bottom: 30px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%; }
        .info-wrapper .row .col > div {
          margin-bottom: 0;
          min-width: 50px;
          justify-content: flex-start; }
        .info-wrapper .row .col img {
          max-width: 22px;
          margin: 0 14px 0 0; }
        .info-wrapper .row .col:not(:last-child) {
          margin-right: 0; }
        .info-wrapper .row .col:first-child {
          border-right: 0;
          padding: 0;
          justify-content: flex-start; }
        .info-wrapper .row .col .text-one {
          font-size: 18px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.64px;
          text-align: left;
          color: #ffffff; }
        .info-wrapper .row .col .text-two {
          font-size: 12px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 2.41;
          letter-spacing: normal;
          text-align: left;
          color: #ffffff; } }

.study_in_aus.adults .banner-wrapper {
  background: url(images/master-classes/adult-banner.png) no-repeat;
  background-size: cover;
  padding-bottom: 160px; }
  .study_in_aus.adults .banner-wrapper .title-text {
    font-size: 40px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: #1e174a;
    margin-bottom: 8px;
    text-shadow: none; }
  .study_in_aus.adults .banner-wrapper .sub-text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #1e174a;
    margin-bottom: 4px; }
  .study_in_aus.adults .banner-wrapper .text {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1e174a; }
    .study_in_aus.adults .banner-wrapper .text span {
      color: #4922f3; }
  .study_in_aus.adults .banner-wrapper button {
    border-radius: 2px;
    padding: 15px 52px;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.16px;
    text-align: center;
    color: #ffffff;
    outline: none;
    cursor: pointer;
    border: 0;
    margin-top: 30px; }

.study_in_aus.adults .container.helper {
  max-width: 1140px; }

.study_in_aus.adults .helper-wrapper {
  background: url(images/master-classes/helper-background.png) no-repeat;
  background-size: 100% 100%;
  padding: 44px 58px;
  display: flex;
  margin: 124px 0 130px 0;
  align-items: center; }
  .study_in_aus.adults .helper-wrapper button {
    border-radius: 2px;
    padding: 15px 52px;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.16px;
    text-align: center;
    color: #ffffff;
    outline: none;
    cursor: pointer;
    border: 0; }
  .study_in_aus.adults .helper-wrapper > div:last-child {
    flex-grow: 1;
    text-align: right; }
  .study_in_aus.adults .helper-wrapper .content {
    max-width: 460px; }
    .study_in_aus.adults .helper-wrapper .content > img {
      max-width: 116px; }
    .study_in_aus.adults .helper-wrapper .content .text {
      font-size: 36px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.9px;
      text-align: left;
      color: #2f3a4f;
      margin-bottom: 4px; }
    .study_in_aus.adults .helper-wrapper .content .sub-text {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #464646; }
      .study_in_aus.adults .helper-wrapper .content .sub-text span {
        font-weight: bold;
        color: #2a00e8; }

.study_in_aus.adults .section-one {
  padding-top: 60px; }

.study_in_aus .container {
  max-width: 1080px;
  margin: 0 auto; }

.study_in_aus .white-button {
  border-radius: 4px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  cursor: pointer;
  padding: 13px 40px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #2a00e8;
  border: 0; }

.study_in_aus .ant-select-selection {
  border-radius: 4px;
  border: solid 1px #ced0da;
  background-image: linear-gradient(to top, #f2f4f7, #ffffff);
  min-width: 180px; }
  .study_in_aus .ant-select-selection .anticon svg {
    color: #3031ea; }

.study_in_aus .banner-wrapper {
  background: url(images/banners/aus.webp) no-repeat;
  background-size: cover;
  padding-top: 160px; }
  .study_in_aus .banner-wrapper .title-text {
    font-size: 40px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
    margin-bottom: 5px;
    max-width: 350px;
    background: white; }
  .study_in_aus .banner-wrapper .sub-text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #000;
    max-width: 52rem;
    background: white; }
  .study_in_aus .banner-wrapper .list-wrap {
    border-radius: 6px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 48px 74px;
    position: relative;
    display: flex;
    align-items: flex-start;
    top: 42px; }
    .study_in_aus .banner-wrapper .list-wrap .list {
      flex-grow: 1;
      flex-basis: 100%;
      text-align: center; }
      .study_in_aus .banner-wrapper .list-wrap .list:not(:last-child) {
        margin-right: 60px; }
      .study_in_aus .banner-wrapper .list-wrap .list img {
        margin-bottom: 24px; }
      .study_in_aus .banner-wrapper .list-wrap .list .text-one {
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.32;
        letter-spacing: normal;
        color: #262626;
        margin-bottom: 7px; }
      .study_in_aus .banner-wrapper .list-wrap .list .text-two {
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: #2f3a4f; }

.study_in_aus .section-one {
  padding-top: 120px; }
  .study_in_aus .section-one .text-one {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #262626; }
    .study_in_aus .section-one .text-one span {
      font-weight: bold; }
  .study_in_aus .section-one .header-wrap {
    display: flex;
    align-items: flex-end; }
  .study_in_aus .section-one .filter-wrap {
    flex-grow: 1;
    text-align: right; }
    .study_in_aus .section-one .filter-wrap .filter {
      display: inline-flex;
      align-items: center; }
      .study_in_aus .section-one .filter-wrap .filter:not(:last-child) {
        margin-right: 32px; }
      .study_in_aus .section-one .filter-wrap .filter > p {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #7f8fa4;
        margin-right: 12px; }
  .study_in_aus .section-one .cards-wrapper {
    margin: 40px 0 55px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px; }
  .study_in_aus .section-one .button-wrap {
    margin-bottom: 100px;
    text-align: center; }

.study_in_aus .section-two {
  background-image: linear-gradient(248deg, #f8f8f8, #efefef -25%);
  padding: 50px 0 100px 0; }
  .study_in_aus .section-two h2 {
    font-size: 36px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.9px;
    text-align: center;
    color: #2f3a4f;
    max-width: 540px;
    margin: 0 auto 34px auto; }
  .study_in_aus .section-two .carousel-wrapper {
    margin: 20px 0 0 0; }
    .study_in_aus .section-two .carousel-wrapper .testimonial-card {
      margin-right: 20px; }
    .study_in_aus .section-two .carousel-wrapper .project-card {
      margin-right: 20px; }
    .study_in_aus .section-two .carousel-wrapper .tutorial-card {
      margin-right: 33px; }
    .study_in_aus .section-two .carousel-wrapper .slick-list {
      padding: 24px 0;
      position: relative; }
    .study_in_aus .section-two .carousel-wrapper .slick-arrow {
      position: absolute;
      top: calc(50% - 38px);
      outline: none;
      cursor: pointer; }
      .study_in_aus .section-two .carousel-wrapper .slick-arrow.slick-disabled {
        pointer-events: none;
        opacity: .8; }
      .study_in_aus .section-two .carousel-wrapper .slick-arrow.slick-prev {
        background: url(images/static/arrow-left.svg) no-repeat;
        left: -80px;
        width: 90px;
        height: 90px;
        background-size: contain;
        border: 0;
        color: transparent; }
      .study_in_aus .section-two .carousel-wrapper .slick-arrow.slick-next {
        right: -80px;
        background: url(images/static/arrow-right.svg) no-repeat;
        width: 90px;
        height: 90px;
        background-size: contain;
        border: 0;
        color: transparent; }

.study_in_aus .divider-banner {
  background-image: linear-gradient(275deg, #700aad 124%, #8a3ceb 22%);
  padding: 46px 0 52px  0; }
  .study_in_aus .divider-banner .container {
    display: flex;
    align-items: center; }
    .study_in_aus .divider-banner .container .col {
      flex-grow: 1;
      display: flex;
      align-items: center; }
      .study_in_aus .divider-banner .container .col img {
        margin-right: 25px; }
      .study_in_aus .divider-banner .container .col .text-wrap .text-one {
        font-size: 40px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.66;
        letter-spacing: normal;
        color: #ffffff;
        margin-bottom: 12px; }
      .study_in_aus .divider-banner .container .col .text-wrap .text-two {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        color: #ffffff; }
      .study_in_aus .divider-banner .container .col:not(:last-child) {
        margin-right: 60px; }

.study_in_aus .footer {
  padding-top: 200px; }

.info-wrapper {
  border-radius: 8px;
  box-shadow: 0 2px 44px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(106deg, #fba649, #f2534c);
  padding: 30px;
  max-width: 1080px;
  margin: 0 auto;
  position: relative;
  margin-bottom: -80px; }
  .info-wrapper .row {
    display: flex;
    align-items: flex-start; }
    .info-wrapper .row .col {
      text-align: center; }
      .info-wrapper .row .col:not(:last-child) {
        margin-right: 32px; }
      .info-wrapper .row .col:first-child {
        border-right: solid 2px #bb4039;
        padding: 12px 20px 12px 0;
        min-width: 322px; }
      .info-wrapper .row .col > div {
        height: 40px;
        margin-bottom: 22px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .info-wrapper .row .col > div img {
          max-height: 100%; }
      .info-wrapper .row .col .text-one {
        font-size: 30px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.75px;
        color: #ffffff;
        text-align: left; }
      .info-wrapper .row .col .text-two {
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.69;
        letter-spacing: normal;
        color: #ffffff; }
        .info-wrapper .row .col .text-two span {
          display: block; }
      .info-wrapper .row .col a {
        color: inherit; }

.course-card-with-trainer,
.course-card-light {
  border-radius: 4px;
  box-shadow: 0 2px 37px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px transparent;
  background-color: #ffffff;
  padding: 26px 20px 22px 20px;
  position: relative;
  padding-bottom: 100px; }
  .course-card-with-trainer .title-text,
  .course-card-light .title-text {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262626;
    margin-bottom: 6px;
    height: 44px;
    overflow: hidden; }
  .course-card-with-trainer .sub-text,
  .course-card-light .sub-text {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #464646; }
  .course-card-with-trainer .label,
  .course-card-light .label {
    background-color: rgba(184, 233, 134, 0.7);
    padding: 6px 11px 6px 16px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    color: #2f3a4f;
    display: inline-block;
    margin: 20px 0 16px 0; }
    .course-card-with-trainer .label span,
    .course-card-light .label span {
      font-weight: bold; }
  .course-card-with-trainer .age,
  .course-card-light .age {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #464646;
    margin-bottom: 6px; }
  .course-card-with-trainer .time,
  .course-card-light .time {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #464646;
    margin-bottom: 12px; }
  .course-card-with-trainer .trainer-wrap,
  .course-card-light .trainer-wrap {
    display: flex;
    align-items: center;
    margin-top: 24px; }
    .course-card-with-trainer .trainer-wrap .img-wrap,
    .course-card-light .trainer-wrap .img-wrap {
      width: 75px;
      height: 75px;
      border-radius: 100%;
      margin-right: 12px;
      background: #ccc; }
      .course-card-with-trainer .trainer-wrap .img-wrap img,
      .course-card-light .trainer-wrap .img-wrap img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100%; }
    .course-card-with-trainer .trainer-wrap .content-wrap,
    .course-card-light .trainer-wrap .content-wrap {
      flex-grow: 1; }
      .course-card-with-trainer .trainer-wrap .content-wrap .text,
      .course-card-light .trainer-wrap .content-wrap .text {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.3px;
        color: #2f3a4f; }
      .course-card-with-trainer .trainer-wrap .content-wrap .name,
      .course-card-light .trainer-wrap .content-wrap .name {
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.45px;
        color: #262626;
        margin-bottom: 3px; }
      .course-card-with-trainer .trainer-wrap .content-wrap .expert,
      .course-card-light .trainer-wrap .content-wrap .expert {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.3px;
        color: #ea147b; }
      .course-card-with-trainer .trainer-wrap .content-wrap .qualification,
      .course-card-light .trainer-wrap .content-wrap .qualification {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.35px;
        color: #2f3a4f; }
  .course-card-with-trainer .bottom-section,
  .course-card-light .bottom-section {
    border-top: solid 1px rgba(82, 95, 120, 0.4);
    padding-top: 20px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    position: absolute;
    left: 20px;
    bottom: 0;
    right: 20px;
    margin: 0;
    width: calc(100% - 40px);
    padding: 32px 20px 30px 20px;
    display: flex;
    justify-content: center; }
    .course-card-with-trainer .bottom-section .text-one,
    .course-card-light .bottom-section .text-one {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      text-align: center;
      color: #2a00e8;
      text-transform: uppercase;
      margin: 0;
      display: inline-block;
      position: relative;
      cursor: pointer;
      padding: 8px 16px;
      position: absolute;
      bottom: 16px; }
      .course-card-with-trainer .bottom-section .text-one:hover,
      .course-card-light .bottom-section .text-one:hover {
        border-radius: 2px;
        padding: 8px 16px;
        background-color: #edeafd;
        position: absolute;
        margin-top: -8px; }

.course-card-light {
  border-radius: 4px;
  box-shadow: 0 2px 37px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff; }
  .course-card-light:hover {
    border: solid 1px #2a00e8; }
  .course-card-light .title-text {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262626;
    margin-bottom: 30px; }
  .course-card-light .sub-text {
    margin-bottom: 4px; }
  .course-card-light .flex-wrap {
    justify-content: center; }

.testimonial-card {
  border-radius: 4px;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 20px 15px; }
  .testimonial-card img {
    margin-bottom: 12px; }
  .testimonial-card .text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #464646;
    margin-bottom: 80px; }
  .testimonial-card .user-wrap {
    display: flex;
    align-items: center; }
    .testimonial-card .user-wrap .img-wrap {
      width: 50px;
      height: 50px;
      margin-right: 12px;
      background: #ddd;
      border-radius: 100%; }
    .testimonial-card .user-wrap .content-wrap .text-one {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.45px;
      color: #262626;
      margin-bottom: 2px; }
    .testimonial-card .user-wrap .content-wrap .text-two {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.3px;
      color: #2f3a4f; }

@media screen and (max-width: 768px) {
  .study_in_aus .ant-select-selection {
    min-width: 110px;
    height: 24px; }
    .study_in_aus .ant-select-selection .anticon svg {
      font-size: 12px; }
    .study_in_aus .ant-select-selection .ant-select-selection__rendered {
      line-height: 20px; }
    .study_in_aus .ant-select-selection .ant-select-selection-selected-value {
      font-size: 8.4px;
      font-weight: 500; }
  .study_in_aus.hindi .banner-wrapper .title-text {
    max-width: 100%; }
  .study_in_aus.adults .banner-wrapper {
    background: url(images/master-classes/adult-banner-mobile.png) no-repeat;
    background-size: cover; }
    .study_in_aus.adults .banner-wrapper .title-text {
      max-width: 100%;
      font-size: 25.6px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.23;
      letter-spacing: normal;
      color: #ffffff; }
    .study_in_aus.adults .banner-wrapper .sub-text {
      font-size: 10.2px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      color: #ffffff; }
    .study_in_aus.adults .banner-wrapper .text {
      font-size: 11.5px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff; }
      .study_in_aus.adults .banner-wrapper .text span {
        color: #ffffff; }
    .study_in_aus.adults .banner-wrapper button {
      margin-top: 14px;
      font-size: 10.2px;
      padding: 9px 46px; }
  .study_in_aus.adults .section-one {
    padding-top: 24px; }
  .study_in_aus.adults .helper-wrapper {
    background: url(images/master-classes/helper-background-mobile.png) no-repeat;
    background-size: 100% 100%;
    margin: 0;
    flex-direction: column;
    width: 100%;
    padding: 52px 24px; }
    .study_in_aus.adults .helper-wrapper .content .text {
      font-size: 26px;
      font-weight: 300; }
    .study_in_aus.adults .helper-wrapper .content .sub-text {
      font-size: 11px;
      font-weight: 500;
      line-height: 1.82;
      margin-bottom: 20px; }
    .study_in_aus.adults .helper-wrapper > div:last-child {
      width: 100%;
      text-align: left; }
    .study_in_aus.adults .helper-wrapper button {
      font-size: 10.5px;
      padding: 10px 60px; }
  .study_in_aus .banner-wrapper {
    padding: 120px 28px 28px;
    position: relative;
    height: 450px;
    background: url(images/master-classes/banner-mobile.png) no-repeat;
    background-size: cover; }
    .study_in_aus .banner-wrapper .title-text {
      font-size: 24px;
      font-weight: 300;
      max-width: 160px; }
    .study_in_aus .banner-wrapper .sub-text {
      font-size: 9.6px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56; }
    .study_in_aus .banner-wrapper .list-wrap {
      flex-direction: column;
      padding: 26px 30px;
      position: absolute;
      top: 65%;
      margin: auto;
      left: 6px;
      right: 6px; }
      .study_in_aus .banner-wrapper .list-wrap .list {
        display: flex;
        justify-content: center;
        text-align: left;
        width: 100%; }
        .study_in_aus .banner-wrapper .list-wrap .list img {
          margin-right: 15px;
          width: 26px;
          height: 24px;
          object-fit: contain; }
        .study_in_aus .banner-wrapper .list-wrap .list > div {
          flex-grow: 1; }
          .study_in_aus .banner-wrapper .list-wrap .list > div .text-one {
            font-size: 14px;
            font-weight: 500;
            text-align: left;
            margin-bottom: 5px; }
          .study_in_aus .banner-wrapper .list-wrap .list > div .text-two {
            font-size: 11.2px;
            font-weight: normal;
            text-align: left; }
        .study_in_aus .banner-wrapper .list-wrap .list:not(:last-child) {
          margin: 0 0 16px 0; }
  .study_in_aus .section-one {
    padding-top: 100px; }
    .study_in_aus .section-one .container {
      padding: 0; }
    .study_in_aus .section-one .header-wrap {
      padding: 0 12px; }
    .study_in_aus .section-one .carousel-wrapper {
      margin: 20px 0 0 0; }
      .study_in_aus .section-one .carousel-wrapper .testimonial-card {
        margin-right: 20px; }
      .study_in_aus .section-one .carousel-wrapper .project-card {
        margin-right: 20px; }
      .study_in_aus .section-one .carousel-wrapper .tutorial-card {
        margin-right: 33px; }
      .study_in_aus .section-one .carousel-wrapper .slick-track {
        display: flex !important; }
      .study_in_aus .section-one .carousel-wrapper .slick-slide {
        height: inherit !important; }
      .study_in_aus .section-one .carousel-wrapper .slick-list {
        padding: 24px 0;
        position: relative; }
      .study_in_aus .section-one .carousel-wrapper .slick-slide {
        padding: 0 16px; }
      .study_in_aus .section-one .carousel-wrapper .slick-arrow {
        position: absolute;
        top: calc(50% - 38px);
        outline: none;
        cursor: pointer; }
        .study_in_aus .section-one .carousel-wrapper .slick-arrow.slick-disabled {
          pointer-events: none;
          opacity: .8; }
        .study_in_aus .section-one .carousel-wrapper .slick-arrow.slick-prev {
          background: url(images/master-classes/arrow-left-white.svg) no-repeat;
          left: 0px;
          width: 37px;
          height: 37px;
          background-size: contain;
          border: 0;
          color: transparent;
          z-index: 6; }
        .study_in_aus .section-one .carousel-wrapper .slick-arrow.slick-next {
          right: 0px;
          background: url(images/master-classes/arrow-right-white.svg) no-repeat;
          width: 37px;
          height: 37px;
          background-size: contain;
          border: 0;
          color: transparent;
          z-index: 6; }
    .study_in_aus .section-one .text-one {
      text-align: left;
      margin-bottom: 12px;
      font-size: 12px;
      font-weight: 500; }
    .study_in_aus .section-one .header-wrap {
      flex-direction: column;
      width: 100%;
      align-items: flex-start; }
      .study_in_aus .section-one .header-wrap .filter-wrap {
        display: flex;
        align-items: center; }
        .study_in_aus .section-one .header-wrap .filter-wrap .filter p {
          font-size: 8.4px; }
        .study_in_aus .section-one .header-wrap .filter-wrap .filter:not(::last-child) {
          margin-right: 20px; }
    .study_in_aus .section-one .cards-wrapper {
      display: block;
      margin: 24px 0;
      padding: 0 12px; }
      .study_in_aus .section-one .cards-wrapper .course-card-with-trainer {
        margin-bottom: 12px; }
      .study_in_aus .section-one .cards-wrapper .course-card {
        margin-bottom: 18px; }
  .study_in_aus .section-two {
    padding: 38px 0 70px 0; }
    .study_in_aus .section-two h2 {
      font-size: 22px;
      font-weight: 300;
      padding: 0 20px; }
    .study_in_aus .section-two .carousel-wrapper {
      padding: 0 0 0 20px;
      margin: 20px 0 0 0; }
      .study_in_aus .section-two .carousel-wrapper .testimonial-card {
        margin-right: 0; }
      .study_in_aus .section-two .carousel-wrapper .slick-slide {
        padding: 0 16px 0 0; }
  .study_in_aus .divider-banner {
    padding: 46px 13px; }
    .study_in_aus .divider-banner .container {
      padding: 0;
      display: block; }
      .study_in_aus .divider-banner .container .col {
        display: inline-flex;
        min-width: 50%;
        margin: 0;
        max-width: 50%; }
        .study_in_aus .divider-banner .container .col:first-child, .study_in_aus .divider-banner .container .col:nth-child(2) {
          margin-bottom: 48px; }
        .study_in_aus .divider-banner .container .col img {
          width: 28.7px;
          height: 29px;
          margin-right: 15px; }
        .study_in_aus .divider-banner .container .col .text-wrap .text-one {
          font-size: 25.2px;
          font-weight: 300;
          line-height: 0.66; }
        .study_in_aus .divider-banner .container .col .text-wrap .text-two {
          font-size: 10.1px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;
          letter-spacing: normal;
          color: #ffffff; }
        .study_in_aus .divider-banner .container .col:not(:last-child) {
          margin-right: 0; }
  .testimonial-card .text {
    font-size: 11.9px;
    margin-bottom: 36px; }
  .testimonial-card > img {
    width: 18.7px;
    height: 39.1px;
    object-fit: contain; }
  .testimonial-card .user-wrap .img-wrap {
    width: 42.5px;
    height: 42.5px; }
  .testimonial-card .user-wrap .content-wrap .text-one {
    font-size: 15.3px; }
  .testimonial-card .user-wrap .content-wrap .text-two {
    font-size: 10.2px; }
  .course-card-with-trainer,
  .course-card-light {
    border-radius: 3.4px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.19);
    background-color: #ffffff;
    padding: 22px 16px 16px 16px; }
    .course-card-with-trainer .title-text,
    .course-card-light .title-text {
      font-size: 13.6px;
      margin-bottom: 4.8px; }
    .course-card-with-trainer .sub-text,
    .course-card-light .sub-text {
      font-size: 11.9px;
      font-weight: 500; }
    .course-card-with-trainer .label,
    .course-card-light .label {
      font-size: 10.2px;
      margin: 23px 0 19px 0; }
      .course-card-with-trainer .label span,
      .course-card-light .label span {
        font-weight: bold; }
    .course-card-with-trainer .age,
    .course-card-light .age {
      font-size: 11.9px;
      margin-bottom: 4.8px; }
    .course-card-with-trainer .time,
    .course-card-light .time {
      font-size: 11.9px;
      margin-bottom: 9.9px; }
    .course-card-with-trainer .trainer-wrap,
    .course-card-light .trainer-wrap {
      display: flex;
      align-items: center; }
      .course-card-with-trainer .trainer-wrap .img-wrap,
      .course-card-light .trainer-wrap .img-wrap {
        width: 63.8px;
        height: 63.8px;
        margin-right: 10px; }
      .course-card-with-trainer .trainer-wrap .content-wrap,
      .course-card-light .trainer-wrap .content-wrap {
        flex-grow: 1; }
        .course-card-with-trainer .trainer-wrap .content-wrap .text,
        .course-card-light .trainer-wrap .content-wrap .text {
          font-size: 10.2px; }
        .course-card-with-trainer .trainer-wrap .content-wrap .name,
        .course-card-light .trainer-wrap .content-wrap .name {
          font-size: 15.3px; }
        .course-card-with-trainer .trainer-wrap .content-wrap .expert,
        .course-card-light .trainer-wrap .content-wrap .expert {
          font-size: 10.2px;
          margin-bottom: 3px; }
        .course-card-with-trainer .trainer-wrap .content-wrap .qualification,
        .course-card-light .trainer-wrap .content-wrap .qualification {
          font-size: 11.9px; }
    .course-card-with-trainer .bottom-section,
    .course-card-light .bottom-section {
      padding-top: 16px;
      margin-top: 25px;
      position: relative;
      left: 0;
      right: 0;
      width: 100%;
      padding: 12px 0 0; }
      .course-card-with-trainer .bottom-section .text-one,
      .course-card-light .bottom-section .text-one {
        font-size: 11.9px;
        letter-spacing: 0.43px;
        text-align: center;
        margin: 0;
        position: relative;
        bottom: 0; }
        .course-card-with-trainer .bottom-section .text-one:hover,
        .course-card-light .bottom-section .text-one:hover {
          border-radius: 0;
          background-color: transparent;
          position: relative;
          margin-top: 0; }
  .course-card-light:hover {
    border: 0; }
  .info-wrapper {
    border-radius: 0;
    box-shadow: none;
    max-width: 100%;
    margin-bottom: 0; }
    .info-wrapper .row {
      flex-direction: column; }
      .info-wrapper .row .col {
        margin-bottom: 30px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%; }
        .info-wrapper .row .col > div {
          margin-bottom: 0;
          min-width: 50px;
          justify-content: flex-start; }
        .info-wrapper .row .col img {
          max-width: 22px;
          margin: 0 14px 0 0; }
        .info-wrapper .row .col:not(:last-child) {
          margin-right: 0; }
        .info-wrapper .row .col:first-child {
          border-right: 0;
          padding: 0;
          justify-content: flex-start; }
        .info-wrapper .row .col .text-one {
          font-size: 18px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.64px;
          text-align: left;
          color: #ffffff; }
        .info-wrapper .row .col .text-two {
          font-size: 12px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 2.41;
          letter-spacing: normal;
          text-align: left;
          color: #ffffff; } }

.master-classes.adults .banner-wrapper {
  background: url(images/master-classes/adult-banner.png) no-repeat;
  background-size: cover;
  padding-bottom: 160px; }
  .master-classes.adults .banner-wrapper .title-text {
    font-size: 40px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: #1e174a;
    margin-bottom: 8px;
    text-shadow: none; }
  .master-classes.adults .banner-wrapper .sub-text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #1e174a;
    margin-bottom: 4px; }
  .master-classes.adults .banner-wrapper .text {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1e174a; }
    .master-classes.adults .banner-wrapper .text span {
      color: #4922f3; }
  .master-classes.adults .banner-wrapper button {
    border-radius: 2px;
    padding: 15px 52px;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.16px;
    text-align: center;
    color: #ffffff;
    outline: none;
    cursor: pointer;
    border: 0;
    margin-top: 30px; }

.master-classes.adults .container.helper {
  max-width: 1140px; }

.master-classes.adults .helper-wrapper {
  background: url(images/master-classes/helper-background.png) no-repeat;
  background-size: 100% 100%;
  padding: 44px 58px;
  display: flex;
  margin: 124px 0 130px 0;
  align-items: center; }
  .master-classes.adults .helper-wrapper button {
    border-radius: 2px;
    padding: 15px 52px;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.16px;
    text-align: center;
    color: #ffffff;
    outline: none;
    cursor: pointer;
    border: 0; }
  .master-classes.adults .helper-wrapper > div:last-child {
    flex-grow: 1;
    text-align: right; }
  .master-classes.adults .helper-wrapper .content {
    max-width: 460px; }
    .master-classes.adults .helper-wrapper .content > img {
      max-width: 116px; }
    .master-classes.adults .helper-wrapper .content .text {
      font-size: 36px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.9px;
      text-align: left;
      color: #2f3a4f;
      margin-bottom: 4px; }
    .master-classes.adults .helper-wrapper .content .sub-text {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #464646; }
      .master-classes.adults .helper-wrapper .content .sub-text span {
        font-weight: bold;
        color: #2a00e8; }

.master-classes.adults .section-one {
  padding-top: 60px; }

.master-classes .container {
  max-width: 1080px;
  margin: 0 auto; }

.master-classes .white-button {
  border-radius: 4px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  cursor: pointer;
  padding: 13px 40px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #2a00e8;
  border: 0; }

.master-classes .ant-select-selection {
  border-radius: 4px;
  border: solid 1px #ced0da;
  background-image: linear-gradient(to top, #f2f4f7, #ffffff);
  min-width: 180px; }
  .master-classes .ant-select-selection .anticon svg {
    color: #3031ea; }

.master-classes .banner-wrapper {
  background: url(images/banners/newzealand.webp) no-repeat;
  background-size: cover;
  padding-top: 160px; }
  .master-classes .banner-wrapper .title-text {
    text-shadow: 0 0 23px rgba(0, 0, 0, 0.85);
    font-size: 40px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin-bottom: 5px;
    max-width: 460px; }
  .master-classes .banner-wrapper .sub-text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #ffffff;
    max-width: 56rem; }
  .master-classes .banner-wrapper .list-wrap {
    border-radius: 6px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 48px 74px;
    position: relative;
    display: flex;
    align-items: flex-start;
    top: 42px; }
    .master-classes .banner-wrapper .list-wrap .list {
      flex-grow: 1;
      flex-basis: 100%;
      text-align: center; }
      .master-classes .banner-wrapper .list-wrap .list:not(:last-child) {
        margin-right: 60px; }
      .master-classes .banner-wrapper .list-wrap .list img {
        margin-bottom: 24px; }
      .master-classes .banner-wrapper .list-wrap .list .text-one {
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.32;
        letter-spacing: normal;
        color: #262626;
        margin-bottom: 7px; }
      .master-classes .banner-wrapper .list-wrap .list .text-two {
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: #2f3a4f; }

.master-classes .section-one {
  padding-top: 120px; }
  .master-classes .section-one .text-one {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #262626; }
    .master-classes .section-one .text-one span {
      font-weight: bold; }
  .master-classes .section-one .header-wrap {
    display: flex;
    align-items: flex-end; }
  .master-classes .section-one .filter-wrap {
    flex-grow: 1;
    text-align: right; }
    .master-classes .section-one .filter-wrap .filter {
      display: inline-flex;
      align-items: center; }
      .master-classes .section-one .filter-wrap .filter:not(:last-child) {
        margin-right: 32px; }
      .master-classes .section-one .filter-wrap .filter > p {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #7f8fa4;
        margin-right: 12px; }
  .master-classes .section-one .cards-wrapper {
    margin: 40px 0 55px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px; }
  .master-classes .section-one .button-wrap {
    margin-bottom: 100px;
    text-align: center; }

.master-classes .section-two {
  background-image: linear-gradient(248deg, #f8f8f8, #efefef -25%);
  padding: 50px 0 100px 0; }
  .master-classes .section-two h2 {
    font-size: 36px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.9px;
    text-align: center;
    color: #2f3a4f;
    max-width: 540px;
    margin: 0 auto 34px auto; }
  .master-classes .section-two .carousel-wrapper {
    margin: 20px 0 0 0; }
    .master-classes .section-two .carousel-wrapper .testimonial-card {
      margin-right: 20px; }
    .master-classes .section-two .carousel-wrapper .project-card {
      margin-right: 20px; }
    .master-classes .section-two .carousel-wrapper .tutorial-card {
      margin-right: 33px; }
    .master-classes .section-two .carousel-wrapper .slick-list {
      padding: 24px 0;
      position: relative; }
    .master-classes .section-two .carousel-wrapper .slick-arrow {
      position: absolute;
      top: calc(50% - 38px);
      outline: none;
      cursor: pointer; }
      .master-classes .section-two .carousel-wrapper .slick-arrow.slick-disabled {
        pointer-events: none;
        opacity: .8; }
      .master-classes .section-two .carousel-wrapper .slick-arrow.slick-prev {
        background: url(images/static/arrow-left.svg) no-repeat;
        left: -80px;
        width: 90px;
        height: 90px;
        background-size: contain;
        border: 0;
        color: transparent; }
      .master-classes .section-two .carousel-wrapper .slick-arrow.slick-next {
        right: -80px;
        background: url(images/static/arrow-right.svg) no-repeat;
        width: 90px;
        height: 90px;
        background-size: contain;
        border: 0;
        color: transparent; }

.master-classes .divider-banner {
  background-image: linear-gradient(275deg, #700aad 124%, #8a3ceb 22%);
  padding: 46px 0 52px  0; }
  .master-classes .divider-banner .container {
    display: flex;
    align-items: center; }
    .master-classes .divider-banner .container .col {
      flex-grow: 1;
      display: flex;
      align-items: center; }
      .master-classes .divider-banner .container .col img {
        margin-right: 25px; }
      .master-classes .divider-banner .container .col .text-wrap .text-one {
        font-size: 40px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.66;
        letter-spacing: normal;
        color: #ffffff;
        margin-bottom: 12px; }
      .master-classes .divider-banner .container .col .text-wrap .text-two {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        color: #ffffff; }
      .master-classes .divider-banner .container .col:not(:last-child) {
        margin-right: 60px; }

.master-classes .footer {
  padding-top: 200px; }

.info-wrapper {
  border-radius: 8px;
  box-shadow: 0 2px 44px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(106deg, #fba649, #f2534c);
  padding: 30px;
  max-width: 1080px;
  margin: 0 auto;
  position: relative;
  margin-bottom: -80px; }
  .info-wrapper .row {
    display: flex;
    align-items: flex-start; }
    .info-wrapper .row .col {
      text-align: center; }
      .info-wrapper .row .col:not(:last-child) {
        margin-right: 32px; }
      .info-wrapper .row .col:first-child {
        border-right: solid 2px #bb4039;
        padding: 12px 20px 12px 0;
        min-width: 322px; }
      .info-wrapper .row .col > div {
        height: 40px;
        margin-bottom: 22px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .info-wrapper .row .col > div img {
          max-height: 100%; }
      .info-wrapper .row .col .text-one {
        font-size: 30px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.75px;
        color: #ffffff;
        text-align: left; }
      .info-wrapper .row .col .text-two {
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.69;
        letter-spacing: normal;
        color: #ffffff; }
        .info-wrapper .row .col .text-two span {
          display: block; }
      .info-wrapper .row .col a {
        color: inherit; }

.course-card-with-trainer,
.course-card-light {
  border-radius: 4px;
  box-shadow: 0 2px 37px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px transparent;
  background-color: #ffffff;
  padding: 26px 20px 22px 20px;
  position: relative;
  padding-bottom: 100px; }
  .course-card-with-trainer .title-text,
  .course-card-light .title-text {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262626;
    margin-bottom: 6px;
    height: 44px;
    overflow: hidden; }
  .course-card-with-trainer .sub-text,
  .course-card-light .sub-text {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #464646; }
  .course-card-with-trainer .label,
  .course-card-light .label {
    background-color: rgba(184, 233, 134, 0.7);
    padding: 6px 11px 6px 16px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    color: #2f3a4f;
    display: inline-block;
    margin: 20px 0 16px 0; }
    .course-card-with-trainer .label span,
    .course-card-light .label span {
      font-weight: bold; }
  .course-card-with-trainer .age,
  .course-card-light .age {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #464646;
    margin-bottom: 6px; }
  .course-card-with-trainer .time,
  .course-card-light .time {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #464646;
    margin-bottom: 12px; }
  .course-card-with-trainer .trainer-wrap,
  .course-card-light .trainer-wrap {
    display: flex;
    align-items: center;
    margin-top: 24px; }
    .course-card-with-trainer .trainer-wrap .img-wrap,
    .course-card-light .trainer-wrap .img-wrap {
      width: 75px;
      height: 75px;
      border-radius: 100%;
      margin-right: 12px;
      background: #ccc; }
      .course-card-with-trainer .trainer-wrap .img-wrap img,
      .course-card-light .trainer-wrap .img-wrap img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100%; }
    .course-card-with-trainer .trainer-wrap .content-wrap,
    .course-card-light .trainer-wrap .content-wrap {
      flex-grow: 1; }
      .course-card-with-trainer .trainer-wrap .content-wrap .text,
      .course-card-light .trainer-wrap .content-wrap .text {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.3px;
        color: #2f3a4f; }
      .course-card-with-trainer .trainer-wrap .content-wrap .name,
      .course-card-light .trainer-wrap .content-wrap .name {
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.45px;
        color: #262626;
        margin-bottom: 3px; }
      .course-card-with-trainer .trainer-wrap .content-wrap .expert,
      .course-card-light .trainer-wrap .content-wrap .expert {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.3px;
        color: #ea147b; }
      .course-card-with-trainer .trainer-wrap .content-wrap .qualification,
      .course-card-light .trainer-wrap .content-wrap .qualification {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.35px;
        color: #2f3a4f; }
  .course-card-with-trainer .bottom-section,
  .course-card-light .bottom-section {
    border-top: solid 1px rgba(82, 95, 120, 0.4);
    padding-top: 20px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    position: absolute;
    left: 20px;
    bottom: 0;
    right: 20px;
    margin: 0;
    width: calc(100% - 40px);
    padding: 32px 20px 30px 20px;
    display: flex;
    justify-content: center; }
    .course-card-with-trainer .bottom-section .text-one,
    .course-card-light .bottom-section .text-one {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      text-align: center;
      color: #2a00e8;
      text-transform: uppercase;
      margin: 0;
      display: inline-block;
      position: relative;
      cursor: pointer;
      padding: 8px 16px;
      position: absolute;
      bottom: 16px; }
      .course-card-with-trainer .bottom-section .text-one:hover,
      .course-card-light .bottom-section .text-one:hover {
        border-radius: 2px;
        padding: 8px 16px;
        background-color: #edeafd;
        position: absolute;
        margin-top: -8px; }

.course-card-light {
  border-radius: 4px;
  box-shadow: 0 2px 37px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff; }
  .course-card-light:hover {
    border: solid 1px #2a00e8; }
  .course-card-light .title-text {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262626;
    margin-bottom: 30px; }
  .course-card-light .sub-text {
    margin-bottom: 4px; }
  .course-card-light .flex-wrap {
    justify-content: center; }

.testimonial-card {
  border-radius: 4px;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 20px 15px; }
  .testimonial-card img {
    margin-bottom: 12px; }
  .testimonial-card .text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #464646;
    margin-bottom: 80px; }
  .testimonial-card .user-wrap {
    display: flex;
    align-items: center; }
    .testimonial-card .user-wrap .img-wrap {
      width: 50px;
      height: 50px;
      margin-right: 12px;
      background: #ddd;
      border-radius: 100%; }
    .testimonial-card .user-wrap .content-wrap .text-one {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.45px;
      color: #262626;
      margin-bottom: 2px; }
    .testimonial-card .user-wrap .content-wrap .text-two {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.3px;
      color: #2f3a4f; }

@media screen and (max-width: 768px) {
  .master-classes .ant-select-selection {
    min-width: 110px;
    height: 24px; }
    .master-classes .ant-select-selection .anticon svg {
      font-size: 12px; }
    .master-classes .ant-select-selection .ant-select-selection__rendered {
      line-height: 20px; }
    .master-classes .ant-select-selection .ant-select-selection-selected-value {
      font-size: 8.4px;
      font-weight: 500; }
  .master-classes.hindi .banner-wrapper .title-text {
    max-width: 100%; }
  .master-classes.adults .banner-wrapper {
    background: url(images/master-classes/adult-banner-mobile.png) no-repeat;
    background-size: cover; }
    .master-classes.adults .banner-wrapper .title-text {
      max-width: 100%;
      font-size: 25.6px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.23;
      letter-spacing: normal;
      color: #ffffff; }
    .master-classes.adults .banner-wrapper .sub-text {
      font-size: 10.2px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      color: #ffffff; }
    .master-classes.adults .banner-wrapper .text {
      font-size: 11.5px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff; }
      .master-classes.adults .banner-wrapper .text span {
        color: #ffffff; }
    .master-classes.adults .banner-wrapper button {
      margin-top: 14px;
      font-size: 10.2px;
      padding: 9px 46px; }
  .master-classes.adults .section-one {
    padding-top: 24px; }
  .master-classes.adults .helper-wrapper {
    background: url(images/master-classes/helper-background-mobile.png) no-repeat;
    background-size: 100% 100%;
    margin: 0;
    flex-direction: column;
    width: 100%;
    padding: 52px 24px; }
    .master-classes.adults .helper-wrapper .content .text {
      font-size: 26px;
      font-weight: 300; }
    .master-classes.adults .helper-wrapper .content .sub-text {
      font-size: 11px;
      font-weight: 500;
      line-height: 1.82;
      margin-bottom: 20px; }
    .master-classes.adults .helper-wrapper > div:last-child {
      width: 100%;
      text-align: left; }
    .master-classes.adults .helper-wrapper button {
      font-size: 10.5px;
      padding: 10px 60px; }
  .master-classes .banner-wrapper {
    padding: 120px 28px 28px;
    position: relative;
    height: 450px;
    background: url(images/master-classes/banner-mobile.png) no-repeat;
    background-size: cover; }
    .master-classes .banner-wrapper .title-text {
      font-size: 24px;
      font-weight: 300;
      max-width: 160px; }
    .master-classes .banner-wrapper .sub-text {
      font-size: 9.6px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56; }
    .master-classes .banner-wrapper .list-wrap {
      flex-direction: column;
      padding: 26px 30px;
      position: absolute;
      top: 65%;
      margin: auto;
      left: 6px;
      right: 6px; }
      .master-classes .banner-wrapper .list-wrap .list {
        display: flex;
        justify-content: center;
        text-align: left;
        width: 100%; }
        .master-classes .banner-wrapper .list-wrap .list img {
          margin-right: 15px;
          width: 26px;
          height: 24px;
          object-fit: contain; }
        .master-classes .banner-wrapper .list-wrap .list > div {
          flex-grow: 1; }
          .master-classes .banner-wrapper .list-wrap .list > div .text-one {
            font-size: 14px;
            font-weight: 500;
            text-align: left;
            margin-bottom: 5px; }
          .master-classes .banner-wrapper .list-wrap .list > div .text-two {
            font-size: 11.2px;
            font-weight: normal;
            text-align: left; }
        .master-classes .banner-wrapper .list-wrap .list:not(:last-child) {
          margin: 0 0 16px 0; }
  .master-classes .section-one {
    padding-top: 100px; }
    .master-classes .section-one .container {
      padding: 0; }
    .master-classes .section-one .header-wrap {
      padding: 0 12px; }
    .master-classes .section-one .carousel-wrapper {
      margin: 20px 0 0 0; }
      .master-classes .section-one .carousel-wrapper .testimonial-card {
        margin-right: 20px; }
      .master-classes .section-one .carousel-wrapper .project-card {
        margin-right: 20px; }
      .master-classes .section-one .carousel-wrapper .tutorial-card {
        margin-right: 33px; }
      .master-classes .section-one .carousel-wrapper .slick-track {
        display: flex !important; }
      .master-classes .section-one .carousel-wrapper .slick-slide {
        height: inherit !important; }
      .master-classes .section-one .carousel-wrapper .slick-list {
        padding: 24px 0;
        position: relative; }
      .master-classes .section-one .carousel-wrapper .slick-slide {
        padding: 0 16px; }
      .master-classes .section-one .carousel-wrapper .slick-arrow {
        position: absolute;
        top: calc(50% - 38px);
        outline: none;
        cursor: pointer; }
        .master-classes .section-one .carousel-wrapper .slick-arrow.slick-disabled {
          pointer-events: none;
          opacity: .8; }
        .master-classes .section-one .carousel-wrapper .slick-arrow.slick-prev {
          background: url(images/master-classes/arrow-left-white.svg) no-repeat;
          left: 0px;
          width: 37px;
          height: 37px;
          background-size: contain;
          border: 0;
          color: transparent;
          z-index: 6; }
        .master-classes .section-one .carousel-wrapper .slick-arrow.slick-next {
          right: 0px;
          background: url(images/master-classes/arrow-right-white.svg) no-repeat;
          width: 37px;
          height: 37px;
          background-size: contain;
          border: 0;
          color: transparent;
          z-index: 6; }
    .master-classes .section-one .text-one {
      text-align: left;
      margin-bottom: 12px;
      font-size: 12px;
      font-weight: 500; }
    .master-classes .section-one .header-wrap {
      flex-direction: column;
      width: 100%;
      align-items: flex-start; }
      .master-classes .section-one .header-wrap .filter-wrap {
        display: flex;
        align-items: center; }
        .master-classes .section-one .header-wrap .filter-wrap .filter p {
          font-size: 8.4px; }
        .master-classes .section-one .header-wrap .filter-wrap .filter:not(::last-child) {
          margin-right: 20px; }
    .master-classes .section-one .cards-wrapper {
      display: block;
      margin: 24px 0;
      padding: 0 12px; }
      .master-classes .section-one .cards-wrapper .course-card-with-trainer {
        margin-bottom: 12px; }
      .master-classes .section-one .cards-wrapper .course-card {
        margin-bottom: 18px; }
  .master-classes .section-two {
    padding: 38px 0 70px 0; }
    .master-classes .section-two h2 {
      font-size: 22px;
      font-weight: 300;
      padding: 0 20px; }
    .master-classes .section-two .carousel-wrapper {
      padding: 0 0 0 20px;
      margin: 20px 0 0 0; }
      .master-classes .section-two .carousel-wrapper .testimonial-card {
        margin-right: 0; }
      .master-classes .section-two .carousel-wrapper .slick-slide {
        padding: 0 16px 0 0; }
  .master-classes .divider-banner {
    padding: 46px 13px; }
    .master-classes .divider-banner .container {
      padding: 0;
      display: block; }
      .master-classes .divider-banner .container .col {
        display: inline-flex;
        min-width: 50%;
        margin: 0;
        max-width: 50%; }
        .master-classes .divider-banner .container .col:first-child, .master-classes .divider-banner .container .col:nth-child(2) {
          margin-bottom: 48px; }
        .master-classes .divider-banner .container .col img {
          width: 28.7px;
          height: 29px;
          margin-right: 15px; }
        .master-classes .divider-banner .container .col .text-wrap .text-one {
          font-size: 25.2px;
          font-weight: 300;
          line-height: 0.66; }
        .master-classes .divider-banner .container .col .text-wrap .text-two {
          font-size: 10.1px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;
          letter-spacing: normal;
          color: #ffffff; }
        .master-classes .divider-banner .container .col:not(:last-child) {
          margin-right: 0; }
  .testimonial-card .text {
    font-size: 11.9px;
    margin-bottom: 36px; }
  .testimonial-card > img {
    width: 18.7px;
    height: 39.1px;
    object-fit: contain; }
  .testimonial-card .user-wrap .img-wrap {
    width: 42.5px;
    height: 42.5px; }
  .testimonial-card .user-wrap .content-wrap .text-one {
    font-size: 15.3px; }
  .testimonial-card .user-wrap .content-wrap .text-two {
    font-size: 10.2px; }
  .course-card-with-trainer,
  .course-card-light {
    border-radius: 3.4px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.19);
    background-color: #ffffff;
    padding: 22px 16px 16px 16px; }
    .course-card-with-trainer .title-text,
    .course-card-light .title-text {
      font-size: 13.6px;
      margin-bottom: 4.8px; }
    .course-card-with-trainer .sub-text,
    .course-card-light .sub-text {
      font-size: 11.9px;
      font-weight: 500; }
    .course-card-with-trainer .label,
    .course-card-light .label {
      font-size: 10.2px;
      margin: 23px 0 19px 0; }
      .course-card-with-trainer .label span,
      .course-card-light .label span {
        font-weight: bold; }
    .course-card-with-trainer .age,
    .course-card-light .age {
      font-size: 11.9px;
      margin-bottom: 4.8px; }
    .course-card-with-trainer .time,
    .course-card-light .time {
      font-size: 11.9px;
      margin-bottom: 9.9px; }
    .course-card-with-trainer .trainer-wrap,
    .course-card-light .trainer-wrap {
      display: flex;
      align-items: center; }
      .course-card-with-trainer .trainer-wrap .img-wrap,
      .course-card-light .trainer-wrap .img-wrap {
        width: 63.8px;
        height: 63.8px;
        margin-right: 10px; }
      .course-card-with-trainer .trainer-wrap .content-wrap,
      .course-card-light .trainer-wrap .content-wrap {
        flex-grow: 1; }
        .course-card-with-trainer .trainer-wrap .content-wrap .text,
        .course-card-light .trainer-wrap .content-wrap .text {
          font-size: 10.2px; }
        .course-card-with-trainer .trainer-wrap .content-wrap .name,
        .course-card-light .trainer-wrap .content-wrap .name {
          font-size: 15.3px; }
        .course-card-with-trainer .trainer-wrap .content-wrap .expert,
        .course-card-light .trainer-wrap .content-wrap .expert {
          font-size: 10.2px;
          margin-bottom: 3px; }
        .course-card-with-trainer .trainer-wrap .content-wrap .qualification,
        .course-card-light .trainer-wrap .content-wrap .qualification {
          font-size: 11.9px; }
    .course-card-with-trainer .bottom-section,
    .course-card-light .bottom-section {
      padding-top: 16px;
      margin-top: 25px;
      position: relative;
      left: 0;
      right: 0;
      width: 100%;
      padding: 12px 0 0; }
      .course-card-with-trainer .bottom-section .text-one,
      .course-card-light .bottom-section .text-one {
        font-size: 11.9px;
        letter-spacing: 0.43px;
        text-align: center;
        margin: 0;
        position: relative;
        bottom: 0; }
        .course-card-with-trainer .bottom-section .text-one:hover,
        .course-card-light .bottom-section .text-one:hover {
          border-radius: 0;
          background-color: transparent;
          position: relative;
          margin-top: 0; }
  .course-card-light:hover {
    border: 0; }
  .info-wrapper {
    border-radius: 0;
    box-shadow: none;
    max-width: 100%;
    margin-bottom: 0; }
    .info-wrapper .row {
      flex-direction: column; }
      .info-wrapper .row .col {
        margin-bottom: 30px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%; }
        .info-wrapper .row .col > div {
          margin-bottom: 0;
          min-width: 50px;
          justify-content: flex-start; }
        .info-wrapper .row .col img {
          max-width: 22px;
          margin: 0 14px 0 0; }
        .info-wrapper .row .col:not(:last-child) {
          margin-right: 0; }
        .info-wrapper .row .col:first-child {
          border-right: 0;
          padding: 0;
          justify-content: flex-start; }
        .info-wrapper .row .col .text-one {
          font-size: 18px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.64px;
          text-align: left;
          color: #ffffff; }
        .info-wrapper .row .col .text-two {
          font-size: 12px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 2.41;
          letter-spacing: normal;
          text-align: left;
          color: #ffffff; } }

.gallery-layout .container {
  max-width: 1080px;
  margin: 0 auto; }

.gallery-layout .white-button {
  border-radius: 4px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  cursor: pointer;
  padding: 13px 40px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #2a00e8;
  border: 0; }

.gallery-layout .ant-select-selection {
  border-radius: 4px;
  border: solid 1px #ced0da;
  background-image: linear-gradient(to top, #f2f4f7, #ffffff);
  min-width: 180px; }
  .gallery-layout .ant-select-selection .anticon svg {
    color: #3031ea; }

.gallery-layout .banner-wrapper {
  background: url(images/gallery/banner.webp) no-repeat;
  background-size: cover;
  height: 380px;
  padding: 94px 0; }
  .gallery-layout .banner-wrapper .title-text {
    font-size: 42px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    color: #ffffff;
    margin-bottom: 5px;
    max-width: 400px; }
  .gallery-layout .banner-wrapper .sub-text {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    color: #ffffff;
    max-width: 460px; }

.gallery-layout .carousel-wrapper .project-card {
  margin-right: 20px; }

.gallery-layout .carousel-wrapper .tutorial-card {
  margin-right: 33px; }

.gallery-layout .carousel-wrapper .quiz-card {
  margin-right: 20px; }

.gallery-layout .carousel-wrapper .testimonial-card {
  margin-right: 20px;
  width: calc(100% - 20px) !important; }

.gallery-layout .carousel-wrapper .slick-list {
  position: relative; }

.gallery-layout .carousel-wrapper .slick-arrow {
  position: absolute;
  outline: none;
  cursor: pointer; }
  .gallery-layout .carousel-wrapper .slick-arrow.slick-disabled {
    pointer-events: none;
    opacity: .8; }
  .gallery-layout .carousel-wrapper .slick-arrow.slick-prev {
    background: #fff url(images/static/gallery/left-arrow.svg) no-repeat;
    left: -80px;
    width: 73px;
    height: 73px;
    background-size: contain;
    border: 0;
    color: transparent; }
    .gallery-layout .carousel-wrapper .slick-arrow.slick-prev:before {
      display: none; }
  .gallery-layout .carousel-wrapper .slick-arrow.slick-next {
    right: -80px;
    background: #fff url(images/static/gallery/right-arrow.svg) no-repeat;
    width: 73px;
    height: 73px;
    background-size: contain;
    border: 0;
    color: transparent; }
    .gallery-layout .carousel-wrapper .slick-arrow.slick-next:before {
      display: none; }

.gallery-layout .section-one {
  padding: 40px 0 52px 0; }
  .gallery-layout .section-one h2 {
    font-size: 34px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.85px;
    text-align: center;
    color: #2f3a4f;
    margin-bottom: 60px; }
    .gallery-layout .section-one h2 span {
      font-weight: bold; }

.gallery-layout .section-two {
  background: #594ec4;
  background: linear-gradient(90deg, #594ec4 0%, #3a4999 100%);
  padding: 50px 0 90px 0; }
  .gallery-layout .section-two h2 {
    font-size: 36px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.9px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 50px; }
  .gallery-layout .section-two .carousel-wrapper .slick-arrow.slick-prev {
    background: url(images/static/gallery/trans-left-arrow.svg) no-repeat;
    background-size: contain; }
  .gallery-layout .section-two .carousel-wrapper .slick-arrow.slick-next {
    background: url(images/static/gallery/trans-right-arrow.svg) no-repeat;
    background-size: contain; }

.gallery-layout .section-three {
  padding: 75px 0 55px 0; }
  .gallery-layout .section-three h2 {
    font-size: 36px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.9px;
    text-align: center;
    color: #262626;
    max-width: 545px;
    margin: 0 auto 48px auto; }
  .gallery-layout .section-three .cards-wrapper {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px; }

.gallery-layout .section-four {
  padding: 55px 0 55px 0; }
  .gallery-layout .section-four h2 {
    font-size: 36px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.9px;
    text-align: center;
    color: #262626;
    max-width: 545px;
    margin: 0 auto 66px auto; }
  .gallery-layout .section-four .carousel-wrapper .slick-arrow.slick-prev {
    background: url(images/static/gallery/trans-left-arrow.svg) no-repeat;
    background-size: contain; }
  .gallery-layout .section-four .carousel-wrapper .slick-arrow.slick-next {
    background: url(images/static/gallery/trans-right-arrow.svg) no-repeat;
    background-size: contain; }

.project-card {
  border-radius: 4px;
  border: solid 0.8px #dee2e6;
  background-color: #ffffff;
  padding: 26px 20px 20px; }
  .project-card .text-one {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262626;
    margin-bottom: 6px; }
  .project-card .text-two {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #464646;
    margin-bottom: 20px;
    position: relative; }
    .project-card .text-two span {
      position: absolute;
      right: 0; }
  .project-card .media-wrap {
    height: 160px;
    position: relative; }
    .project-card .media-wrap .play {
      width: 42px;
      height: 42px;
      object-fit: contain;
      box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.24);
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      cursor: pointer; }
    .project-card .media-wrap .media-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px; }

.tutorial-card {
  border-radius: 4px;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff; }
  .tutorial-card .media-wrap {
    height: 186px;
    position: relative; }
    .tutorial-card .media-wrap .play {
      width: 42px;
      height: 42px;
      object-fit: contain;
      box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.24);
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      cursor: pointer; }
    .tutorial-card .media-wrap .media-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px; }
    .tutorial-card .media-wrap .info-wrap {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.8);
      padding: 9px 16px;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center; }
      .tutorial-card .media-wrap .info-wrap p {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.3px;
        color: #ffffff;
        flex-grow: 1;
        flex-basis: 100%; }
        .tutorial-card .media-wrap .info-wrap p:last-child {
          text-align: right; }
  .tutorial-card .content-wrap {
    padding: 14px 20px 25px 15px; }
    .tutorial-card .content-wrap > .text-one {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #262626;
      margin-bottom: 12px;
      height: 48px; }
    .tutorial-card .content-wrap .details-wrap {
      display: flex;
      align-items: center; }
      .tutorial-card .content-wrap .details-wrap .img-wrap {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        margin-right: 12px;
        background: #ccc; }
      .tutorial-card .content-wrap .details-wrap .text-wrap .text-one {
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.25px;
        color: #2f3a4f; }
      .tutorial-card .content-wrap .details-wrap .text-wrap .text-two {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.35px;
        color: #262626;
        margin-bottom: 2px; }
      .tutorial-card .content-wrap .details-wrap .text-wrap .text-three {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.3px;
        color: #2f3a4f; }
    .tutorial-card .content-wrap .info-wrap p {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      color: #525f78;
      color: #262626; }

.quiz-card {
  height: 204px;
  object-fit: contain;
  border-radius: 4px;
  padding: 26px 20px 22px 20px; }
  .quiz-card.one {
    background: #9b7ad5; }
  .quiz-card.two {
    background: #64b5bf; }
  .quiz-card.three {
    background: #9787ea; }
  .quiz-card.four {
    background: #f09f64; }
  .quiz-card.five {
    background: #f4696b; }
  .quiz-card .text-one {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin-bottom: 6px; }
  .quiz-card .text-two {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #ffffff; }
  .quiz-card .action-wrap {
    margin-top: 70px;
    display: flex;
    align-items: center; }
    .quiz-card .action-wrap div {
      flex-grow: 1;
      flex-basis: 100%; }
      .quiz-card .action-wrap div span {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.35px;
        color: #ffffff; }
      .quiz-card .action-wrap div img {
        cursor: pointer;
        display: inline-block; }
      .quiz-card .action-wrap div:last-child {
        text-align: right; }

@media screen and (max-width: 768px) {
  .gallery-layout .banner-wrapper {
    background: url(images/gallery/banner.webp) no-repeat;
    background-size: cover;
    padding: 80px 28px;
    height: auto;
    background-position: 75% 0; }
    .gallery-layout .banner-wrapper .title-text {
      text-shadow: 0 0 14px rgba(0, 0, 0, 0.85);
      font-size: 24px;
      font-weight: 300;
      max-width: 100%;
      text-align: center; }
    .gallery-layout .banner-wrapper .sub-text {
      font-size: 11px;
      line-height: 1.56;
      max-width: 100%;
      text-align: center; }
  .gallery-layout .carousel-wrapper .project-card {
    margin-right: 0; }
  .gallery-layout .carousel-wrapper .tutorial-card {
    margin-right: 12px; }
  .gallery-layout .carousel-wrapper .testimonial-card {
    margin-right: 12px;
    width: calc(100% - 10px) !important; }
  .gallery-layout .carousel-wrapper .slick-arrow {
    z-index: 9; }
    .gallery-layout .carousel-wrapper .slick-arrow.slick-prev {
      left: 0;
      width: 37px;
      height: 37px; }
    .gallery-layout .carousel-wrapper .slick-arrow.slick-next {
      right: 0;
      width: 37px;
      height: 37px; }
  .gallery-layout .section-one h2,
  .gallery-layout .section-two h2,
  .gallery-layout .section-three h2,
  .gallery-layout .section-four h2,
  .gallery-layout .section-five h2,
  .gallery-layout .section-six h2 {
    font-size: 1.6rem; }
  .gallery-layout .section-one {
    padding: 4rem 1.2rem; }
    .gallery-layout .section-one h2 {
      margin-bottom: 24px; }
  .gallery-layout .section-four {
    padding: 1.2rem; }
  .project-card .text-one {
    font-size: 13.6px; }
  .project-card .text-two {
    font-size: 11.9px; }
  .tutorial-card .content-wrap .text-one {
    font-size: 13.6px; }
  .tutorial-card .content-wrap .info-wrap p {
    font-size: 11.9px; }
  .tutorial-card .content-wrap .text-two {
    font-size: 11.9px; } }

.waiting-room .loading-wrapper > div {
  text-align: center; }
  .waiting-room .loading-wrapper > div p {
    margin: 24px; }

.waiting-room .link {
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid #341eea;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  margin: 12px 12px 12px 0;
  color: #341eea; }

.waiting-room .flex-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 0; }
  .waiting-room .flex-wrap .info-bar {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.71;
    letter-spacing: normal;
    color: #22272e;
    position: relative;
    margin-right: 12px; }
  .waiting-room .flex-wrap .link {
    margin: 0 12px 0 0; }

.waiting-room .wc-header {
  position: relative;
  border-radius: 4px;
  box-shadow: 0 1px 0 0 #f2f4f6;
  background-color: #ffffff;
  padding: 24px 20px; }
  .waiting-room .wc-header p {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.71;
    letter-spacing: normal;
    color: #22272e;
    position: relative;
    cursor: pointer; }
    .waiting-room .wc-header p img {
      position: absolute;
      right: 0; }

.waiting-room.modal {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: #fff;
  position: fixed; }
  .waiting-room.modal .container {
    max-width: 100%;
    padding: 24px; }

.waiting-room.component .timer-wrapper {
  margin-left: 12px; }

.waiting-room.component .timer {
  margin: 0 auto; }

.waiting-room.component .ant-dropdown-button {
  margin-bottom: 12px; }

.waiting-room.component .bottom-text {
  position: relative;
  margin-top: 32px;
  bottom: 0; }

.waiting-room.component .container {
  display: flex;
  justify-content: center;
  margin: 24px 0 0; }
  .waiting-room.component .container .time-wrapper {
    margin-left: 24px; }

.waiting-room .btn-wrapper {
  text-align: right;
  margin: 12px auto 16px auto; }
  .waiting-room .btn-wrapper .button {
    padding: 10px 20px; }

.waiting-room .container {
  text-align: center;
  position: relative; }

.waiting-room .title-text {
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.9px;
  color: #2f3a4f;
  margin-bottom: 8px; }

.waiting-room .sub-text {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #262626;
  margin-bottom: 42px; }
  .waiting-room .sub-text.with-margin {
    margin-top: 44px; }

.waiting-room .text {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #262626;
  margin-bottom: 10px; }
  .waiting-room .text span {
    color: #341eea !important; }

.waiting-room .card {
  border-radius: 2px;
  box-shadow: 0 0 22px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  padding: 18px 32px 22px 32px;
  margin-bottom: 30px;
  max-width: 385px;
  margin: 0 auto 30px auto;
  border-top: 2px solid #f71976; }
  .waiting-room .card p {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #262626; }
    .waiting-room .card p span {
      font-weight: normal; }

.waiting-room .timer {
  border-radius: 4.5px;
  background-color: rgba(224, 229, 237, 0.3);
  margin: 0 auto 32px auto;
  padding: 4px 16px 12px 16px;
  display: inline-flex;
  align-items: center; }
  .waiting-room .timer .timer-wrap {
    position: relative; }
    .waiting-room .timer .timer-wrap span {
      position: absolute;
      bottom: -8px;
      left: 28px;
      font-size: 10.1px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #2f3a4f; }
  .waiting-room .timer .time-hidden {
    display: none; }
  .waiting-room .timer .time {
    padding: 0 6px 4px 6px;
    background: url(images/timer.svg) no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.81);
    font-size: 44.6px;
    font-weight: bold;
    font-stretch: condensed;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.36px;
    color: rgba(0, 0, 0, 0.81); }

.waiting-room .bottom-text {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #262626;
  bottom: 24px;
  text-align: center;
  width: 100%; }

.loading-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  font-size: 1.6rem; }

@media screen and (max-width: 768px) {
  .waiting-room .btn-wrapper button {
    font-size: 10px;
    padding: 8px 14px; }
  .waiting-room.component .timer-wrapper {
    margin-left: 0; }
  .waiting-room.component .container {
    flex-direction: column;
    margin: 0;
    padding: 12px; }
    .waiting-room.component .container > div:first-child {
      margin: 0; }
  .waiting-room .title-text {
    font-size: 22px;
    margin-bottom: 4px; }
  .waiting-room .sub-text {
    font-size: 10px;
    margin-bottom: 30px; }
  .waiting-room .text {
    font-size: 14px;
    margin-bottom: 8px; }
  .waiting-room .card p {
    font-size: 12.8px; }
    .waiting-room .card p span {
      font-weight: normal; }
  .waiting-room .timer .timer-wrap span {
    position: absolute;
    bottom: -8px;
    left: 28px;
    font-size: 10.1px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #2f3a4f; }
  .waiting-room .timer .time-hidden {
    display: none; }
  .waiting-room .timer .time {
    padding: 0 6px 4px 6px;
    font-size: 36px; }
  .waiting-room .bottom-text {
    font-size: 12px;
    bottom: 0; } }

.signup {
  position: relative;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999; }
  .signup .message-wrap {
    border-radius: 2px;
    background-color: #ffe2c0;
    padding: 12px 20px 16px 20px; }
    .signup .message-wrap .info-type {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: #262626;
      margin-bottom: 4px; }
      .signup .message-wrap .info-type span {
        font-weight: 500; }
    .signup .message-wrap .info-text {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2f3a4f; }
  .signup .info-wrap {
    height: 10rem; }
  .signup .datepicker-wrap .time-slot {
    margin-bottom: 1rem; }
    .signup .datepicker-wrap .time-slot.disabled {
      display: none; }
    .signup .datepicker-wrap .time-slot:last-child {
      margin-bottom: 0; }
  .signup.fixed {
    position: fixed; }
  .signup .inline-error {
    display: inline-block;
    margin-top: 4px;
    color: #ed1c24; }
  .signup .bg-login {
    width: 100%;
    height: 100%;
    min-width: 800px;
    min-height: 500px; }
  .signup .header-wrap {
    display: flex;
    padding: 30px 0;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    align-items: center; }
    .signup .header-wrap > div:last-child {
      flex-grow: 1;
      text-align: right;
      padding-right: 45px; }
    .signup .header-wrap > div:first-child {
      padding-left: 80px; }
    .signup .header-wrap > div .cross {
      cursor: pointer; }
    .signup .header-wrap > div .logo {
      cursor: pointer;
      max-width: 250px; }
  .signup .content-wrapper {
    border-radius: 4px;
    box-shadow: 0 2px 37px 0 rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    width: 412px;
    padding: 45px 18px 35px 18px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    transform: translateY(-50%); }
    .signup .content-wrapper.otp-screen .title-text {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #262626;
      display: flex;
      align-items: center;
      margin-bottom: 12px; }
      .signup .content-wrapper.otp-screen .title-text span {
        padding-right: 14px;
        border-right: 1px solid #adb5c6;
        margin-right: 14px;
        cursor: pointer; }
      .signup .content-wrapper.otp-screen .title-text img {
        width: 7px;
        object-fit: contain; }
    .signup .content-wrapper.otp-screen .sub-text {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #262626;
      margin-bottom: 64px; }
      .signup .content-wrapper.otp-screen .sub-text span {
        color: #2e36ea;
        cursor: pointer; }
    .signup .content-wrapper.otp-screen input {
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      padding: 12px 15px; }
    .signup .content-wrapper.otp-screen .helper-text {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #262626;
      margin-bottom: 22px;
      margin-top: 73px; }
      .signup .content-wrapper.otp-screen .helper-text span {
        color: #2e36ea;
        cursor: pointer; }
        .signup .content-wrapper.otp-screen .helper-text span:focus, .signup .content-wrapper.otp-screen .helper-text span:hover {
          text-decoration: underline; }
    .signup .content-wrapper.otp-screen button {
      margin-top: 0; }
    .signup .content-wrapper .title-text {
      font-size: 36px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.9px;
      color: #2f3a4f;
      margin-bottom: 8px; }
    .signup .content-wrapper .sub-text {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #262626;
      margin-bottom: 36px; }
    .signup .content-wrapper .label {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #363e4c;
      display: block;
      margin-bottom: 6px; }
    .signup .content-wrapper input {
      border-radius: 2px;
      border: solid 1px #7c8390;
      background-color: #ffffff;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
      color: #363e4c;
      padding: 15px;
      width: 100%;
      outline: none; }
      .signup .content-wrapper input.error {
        border: 1px solid #ed1c24;
        color: #ed1c24; }
      .signup .content-wrapper input:focus {
        border: solid 1px #7c8390;
        color: #363e4c; }
    .signup .content-wrapper .input-with-flag {
      position: relative; }
      .signup .content-wrapper .input-with-flag input {
        padding-left: 76px; }
      .signup .content-wrapper .input-with-flag span {
        position: absolute;
        left: 12px;
        top: 14px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: normal;
        color: #363e4c;
        padding-right: 4px;
        border-right: 1px solid #adb5c6; }
        .signup .content-wrapper .input-with-flag span img {
          width: 22px;
          margin-right: 6px; }
    .signup .content-wrapper .helper-text {
      font-size: 1.2rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #525f78;
      margin-top: .8rem;
      margin-bottom: 6.4rem; }
      .signup .content-wrapper .helper-text.with-action {
        margin-bottom: 12px; }
        .signup .content-wrapper .helper-text.with-action span {
          color: #341eea;
          cursor: pointer; }
    .signup .content-wrapper button {
      padding: 16px;
      width: 100%; }
      .signup .content-wrapper button.disabled {
        opacity: 0.3;
        background-image: linear-gradient(266deg, #341eea 100%, #1e7eea -8%);
        pointer-events: none; }

.authentication {
  overflow-y: auto; }
  .authentication .action-wrapper {
    box-shadow: 0 -2px 20px 0 rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
    padding: 10px 0;
    width: 100%; }
    .authentication .action-wrapper .content {
      max-width: 960px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center; }
      .authentication .action-wrapper .content p {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #2f3a4f; }
      .authentication .action-wrapper .content div {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end; }
        .authentication .action-wrapper .content div .link {
          margin-right: 20px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #4139eb;
          cursor: pointer; }
        .authentication .action-wrapper .content div button {
          padding: 10px 20px;
          font-size: 14px;
          font-weight: 500;
          text-transform: none; }

.course-selection-wrapper {
  max-width: 960px;
  width: 100%;
  margin: 60px auto;
  border-radius: 4px;
  z-index: 2;
  padding: 0 0 32px 0;
  min-height: calc(100vh - 100px); }
  .course-selection-wrapper .message-wrapper {
    background-color: rgba(2, 184, 117, 0.2);
    padding: 18px;
    text-align: center;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #02b875;
    display: flex;
    align-items: center;
    justify-content: center; }
    .course-selection-wrapper .message-wrapper img {
      width: 18px;
      margin-right: 5px; }
  .course-selection-wrapper .text {
    font-size: 18px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.45px;
    color: #2f3a4f;
    margin: 0 0 45px 0;
    padding: 44px 0 0; }
  .course-selection-wrapper .title-text {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.83;
    letter-spacing: normal;
    color: #22272e;
    margin: 16px 22px; }
  .course-selection-wrapper .cards-wrapper .platform-program-card {
    max-width: 300px;
    display: inline-block;
    margin: 10px; }

.platform-program-card {
  border-radius: 8px;
  border: solid 1px #f2f4fa;
  background-color: #ffffff;
  padding: 14px 14px 22px 14px;
  position: relative;
  cursor: pointer; }
  .platform-program-card .flex-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 24px; }
  .platform-program-card.with-action {
    padding: 10px 10px 18px 10px; }
  .platform-program-card .tick-wrap {
    width: 100%;
    display: flex;
    justify-content: flex-end; }
  .platform-program-card.selected {
    border: solid 0.8px #4139eb; }
    .platform-program-card.selected .tick {
      background-color: #4139eb;
      border: 1px solid #4139eb;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center; }
      .platform-program-card.selected .tick .anticon-check {
        font-size: 12px;
        color: #fff;
        display: flex; }
  .platform-program-card .tick {
    width: 22px;
    height: 22px;
    border: solid 1px #a8afbc;
    border-radius: 100%;
    display: inline-block; }
    .platform-program-card .tick .anticon-check {
      display: none; }
  .platform-program-card .logo-wrap {
    margin-bottom: 8px;
    position: relative;
    text-align: left; }
    .platform-program-card .logo-wrap img {
      height: 32px;
      display: inline-block;
      position: relative; }
  .platform-program-card .text {
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262626;
    margin: 12px 0 12px 0;
    padding: 0;
    text-align: left; }
    .platform-program-card .text.bold {
      font-weight: 500; }
  .platform-program-card .btn {
    margin-top: 20px;
    border-radius: 2px;
    background-color: #4139eb;
    border: 0;
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.92;
    letter-spacing: normal;
    color: #ffffff;
    padding: 8px 12px;
    outline: none;
    display: inline-block; }
  .platform-program-card .desc-wrap {
    padding: 14px 10px 0 10px;
    display: flex;
    align-items: center;
    border-top: solid 1px rgba(82, 95, 120, 0.1);
    margin-top: 32px; }
    .platform-program-card .desc-wrap span {
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.3px;
      text-align: center;
      color: #2f3a4f; }
      .platform-program-card .desc-wrap span:last-child {
        flex-grow: 1;
        text-align: right;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #2a00e8;
        cursor: pointer; }
        .platform-program-card .desc-wrap span:last-child.disabled {
          pointer-events: none !important; }

.platform-course-card {
  border-radius: 4.5px;
  box-shadow: 0 2px 33px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 10px 10px 18px 10px;
  position: relative;
  text-align: center; }
  .platform-course-card .flex-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 24px; }
  .platform-course-card.with-action {
    padding: 10px 10px 18px 10px; }
  .platform-course-card .tick-wrap {
    width: 100%;
    display: flex;
    justify-content: flex-end; }
  .platform-course-card.selected {
    border: solid 0.8px #4139eb; }
    .platform-course-card.selected .tick {
      background-color: #4139eb;
      border: 1px solid #4139eb;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center; }
      .platform-course-card.selected .tick .anticon-check {
        font-size: 12px;
        color: #fff;
        display: flex; }
  .platform-course-card .tick {
    width: 22px;
    height: 22px;
    border: solid 1px #a8afbc;
    border-radius: 100%;
    display: inline-block; }
    .platform-course-card .tick .anticon-check {
      display: none; }
  .platform-course-card .logo-wrap {
    text-align: center;
    margin-bottom: 8px;
    position: relative;
    display: inline-flex;
    justify-content: center;
    margin: 0 auto; }
    .platform-course-card .logo-wrap img {
      height: 44px;
      display: inline-block;
      position: relative; }
    .platform-course-card .logo-wrap .abs {
      position: absolute;
      right: -9px;
      top: 9px;
      display: inline-block;
      padding: 4.5px 10px;
      font-size: 11px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2f3a4f;
      background: #f0f5f7; }
  .platform-course-card .text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #2f3a4f;
    padding: 0;
    margin: 0 0 6px 0; }
    .platform-course-card .text.bold {
      font-weight: 500; }
  .platform-course-card .desc-wrap {
    padding: 14px 10px 0 10px;
    display: flex;
    align-items: center;
    border-top: solid 1px rgba(82, 95, 120, 0.1);
    margin-top: 32px; }
    .platform-course-card .desc-wrap span {
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.3px;
      text-align: center;
      color: #2f3a4f; }
      .platform-course-card .desc-wrap span:last-child {
        flex-grow: 1;
        text-align: right;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #2a00e8;
        cursor: pointer; }
        .platform-course-card .desc-wrap span:last-child.disabled {
          pointer-events: none !important; }

.add-details {
  max-width: 960px;
  width: 100%;
  margin: 60px auto;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 3rem 10rem 3rem; }
  .add-details .title-text-large {
    font-size: 36px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.9px;
    color: #2f3a4f;
    margin-bottom: 8px; }
  .add-details .left-content {
    max-width: 270px;
    min-width: 270px;
    margin-right: 50px; }
  .add-details .form-wrapper {
    flex-grow: 1;
    max-width: 540px; }
    .add-details .form-wrapper .dial-code {
      height: 41px; }
    .add-details .form-wrapper .book-lead .react-tel-input .form-control {
      font-family: "Heebo";
      width: 100%;
      height: 41px;
      border-radius: 2px;
      border: 1px solid #cacaca;
      font-size: 14px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #222;
      width: 100%;
      outline: none;
      padding: 6px 12px 6px 48px; }
    .add-details .form-wrapper .custom-tab {
      margin-top: 1rem; }
      .add-details .form-wrapper .custom-tab .tab-content {
        margin-top: 70px; }
      .add-details .form-wrapper .custom-tab .tabs {
        border-radius: 2px;
        /* background-color: #eff1f3; */
        padding: 0px;
        display: flex;
        position: relative;
        background-color: #ffffff;
        border-radius: 3.6px;
        flex-wrap: wrap;
        margin-bottom: 10px; }
        .add-details .form-wrapper .custom-tab .tabs .tab {
          font-size: 12px;
          padding: 1rem 1rem;
          background-color: #ffffff;
          color: #2f3a4f;
          border: solid 0.9px #d1d8e1;
          border-radius: 3.6px;
          border-radius: 3.6px;
          text-align: center;
          margin-right: 10px;
          margin-bottom: 10px; }
          .add-details .form-wrapper .custom-tab .tabs .tab div {
            text-align: center; }
            .add-details .form-wrapper .custom-tab .tabs .tab div p {
              margin: 0; }
        .add-details .form-wrapper .custom-tab .tabs .tab.active {
          border: solid 0.9px #063bff;
          background-color: #eef1ff;
          color: #063bff; }
        .add-details .form-wrapper .custom-tab .tabs .tab-background {
          transition: all 0.5s ease;
          box-shadow: none; }
          .add-details .form-wrapper .custom-tab .tabs .tab-background.Toddler {
            left: 0; }
          .add-details .form-wrapper .custom-tab .tabs .tab-background.Junior {
            left: 180; }
          .add-details .form-wrapper .custom-tab .tabs .tab-background.Junior + {
            left: 360; }
          .add-details .form-wrapper .custom-tab .tabs .tab-background.Explorer {
            left: 480px; }
    .add-details .form-wrapper .info-wrap {
      height: 44px; }
    .add-details .form-wrapper .inline-wrapper {
      margin-bottom: 0; }
    .add-details .form-wrapper .text {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #262626;
      margin-bottom: 15px; }
      .add-details .form-wrapper .text.red {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ee363d; }
        .add-details .form-wrapper .text.red span {
          font-weight: bold; }
    .add-details .form-wrapper .inline-wrapper {
      display: block; }
      .add-details .form-wrapper .inline-wrapper .form-field {
        width: calc(50% - 20px);
        display: inline-block; }
        .add-details .form-wrapper .inline-wrapper .form-field:nth-child(odd) {
          margin-right: 20px; }
    .add-details .form-wrapper .form-field {
      margin-bottom: 20px; }
      .add-details .form-wrapper .form-field .label {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #7f8fa4;
        margin-bottom: 6px;
        display: block; }
      .add-details .form-wrapper .form-field.inline {
        display: flex;
        align-items: flex-start; }
        .add-details .form-wrapper .form-field.inline > div {
          flex-basis: 100%;
          flex-grow: 1;
          min-width: 50%; }
          .add-details .form-wrapper .form-field.inline > div:first-child {
            margin-right: 12px; }
      .add-details .form-wrapper .form-field .ant-calendar-input-wrap {
        pointer-events: none;
        display: none; }
      .add-details .form-wrapper .form-field .ant-calendar-picker {
        display: block; }
        .add-details .form-wrapper .form-field .ant-calendar-picker input {
          border-radius: 2px;
          border: solid 1px #adb5c6;
          background-color: #ffffff;
          opacity: 0.9;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.36;
          letter-spacing: normal;
          color: #525f78;
          padding: 10px 12px;
          width: 100%;
          outline: none;
          height: auto; }
      .add-details .form-wrapper .form-field input[type="text"],
      .add-details .form-wrapper .form-field input[type="number"],
      .add-details .form-wrapper .form-field input[type="email"],
      .add-details .form-wrapper .form-field textarea,
      .add-details .form-wrapper .form-field .ant-select-selection {
        border-radius: 2px;
        border: solid 1px #adb5c6;
        background-color: #ffffff;
        opacity: 0.9;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: normal;
        color: #525f78;
        padding: 10px 12px;
        width: 100%;
        outline: none;
        height: auto; }
      .add-details .form-wrapper .form-field .ant-select-dropdown-menu-item {
        text-transform: capitalize;
        white-space: unset;
        text-overflow: unset; }
      .add-details .form-wrapper .form-field .ant-select-selection {
        padding: 10px 0; }
        .add-details .form-wrapper .form-field .ant-select-selection .ant-select-selection__rendered {
          line-height: 1.4; }
        .add-details .form-wrapper .form-field .ant-select-selection .ant-select-selection__placeholder {
          color: #757575; }
        .add-details .form-wrapper .form-field .ant-select-selection .ant-select-selection-selected-value {
          text-transform: capitalize; }
    .add-details .form-wrapper .available-slots-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 14px; }
    .add-details .form-wrapper .time-slot {
      border-radius: 2px;
      border: solid 1px #adb5c6;
      background-color: #ffffff;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #262626;
      padding: 10px 8px;
      cursor: pointer; }
      .add-details .form-wrapper .time-slot.selected {
        border: solid 1px #363ed6;
        background-color: #edeef7;
        color: #363ed6;
        pointer-events: none; }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

/* Firefox */
input[type=number] {
  -moz-appearance: textfield; }

@media screen and (max-width: 768px) {
  #iframe_fuguWidget {
    display: none; }
  .authentication .action-wrapper {
    padding: 0; }
    .authentication .action-wrapper .content div button {
      border-radius: 0; }
  .course-selection-wrapper {
    max-width: 100%;
    margin: 0 auto;
    padding: 70px 12px 80px 12px;
    background: #f4f7fc;
    min-height: 100vh; }
    .course-selection-wrapper .cards-wrapper {
      display: block;
      padding: 0; }
      .course-selection-wrapper .cards-wrapper .platform-program-card {
        margin: 0 0 16px 0;
        max-width: 100%;
        width: 100%; }
    .course-selection-wrapper .platform-course-card {
      margin-bottom: 16px; }
    .course-selection-wrapper .title-text {
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.83;
      letter-spacing: normal;
      color: #22272e;
      margin: 16px 0 22px 0; }
  .platform-course-card .text {
    font-size: 16px; }
  .platform-course-card .desc-wrap span:last-child {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #2a00e8; }
  .add-details {
    padding: 20px 12px 80px 12px;
    border-radius: 4px;
    box-shadow: 0 2px 37px 0 rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    min-height: 100vh;
    flex-direction: column; }
    .add-details .left-content {
      margin: 0 auto 12px auto;
      width: 100%;
      max-width: 100%; }
      .add-details .left-content .platform-course-card {
        padding: 6px 6px 8px 6px;
        border: 0;
        background: transparent;
        box-shadow: none; }
        .add-details .left-content .platform-course-card .text,
        .add-details .left-content .platform-course-card .tick-wrap,
        .add-details .left-content .platform-course-card .desc-wrap {
          display: none; }
        .add-details .left-content .platform-course-card .desc-wrap {
          margin-top: 4px;
          border: 0; }
    .add-details .form-wrapper {
      max-width: 100%; }
      .add-details .form-wrapper .time-slot {
        font-size: 12px;
        font-weight: 500; }
      .add-details .form-wrapper .inline-wrapper {
        display: block; }
      .add-details .form-wrapper .text {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #262626; }
      .add-details .form-wrapper .form-field .label {
        font-size: 12px;
        margin-bottom: 5px; }
      .add-details .form-wrapper .inline-wrapper .form-field {
        width: 100%;
        margin: 0 0 20px 0; }
  .signup {
    position: fixed;
    background: #fff; }
    .signup .bg {
      display: none; }
    .signup .datepicker-wrap {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem; }
      .signup .datepicker-wrap .time-slot {
        margin: 0; }
    .signup .header-wrap {
      background-color: #fff;
      padding: 12px 18px;
      position: fixed;
      z-index: 999; }
      .signup .header-wrap div:first-child {
        padding-left: 0; }
        .signup .header-wrap div:first-child .logo {
          max-height: 40px; }
      .signup .header-wrap div:last-child {
        padding-right: 0; }
        .signup .header-wrap div:last-child .cross {
          max-width: 12px; }
    .signup .content-wrapper {
      max-width: calc(100% - 12px);
      padding: 32px 14px;
      width: 100%; }
      .signup .content-wrapper .input-with-flag span {
        top: 9px; }
      .signup .content-wrapper input {
        padding: 11px; }
      .signup .content-wrapper.otp-screen .title-text {
        font-size: 12px; }
      .signup .content-wrapper.otp-screen .sub-text {
        font-size: 12px;
        margin-bottom: 46px; }
      .signup .content-wrapper.otp-screen .helper-text {
        font-size: 12px;
        margin: 62px 0 16px 0; }
      .signup .content-wrapper .title-text {
        font-size: 28px;
        font-weight: 300;
        margin-bottom: 6px; }
      .signup .content-wrapper .sub-text {
        font-size: 12px;
        margin-bottom: 24px; }
      .signup .content-wrapper .label {
        font-size: 12px; }
      .signup .content-wrapper .helper-text {
        font-size: 10px;
        margin-bottom: 32px; }
      .signup .content-wrapper button {
        font-size: 12px;
        padding: 11px; } }

#newage {
  width: 100%;
  margin-bottom: 0px; }

.profile {
  background: #f4f7fc;
  padding: 90px 10px 10px;
  min-height: 100vh; }
  .profile .button-wrap {
    margin-top: 10px;
    margin-left: 14px; }
  .profile .wrapper {
    max-width: 56rem;
    margin: 0 auto; }
  .profile .kid-title-text {
    margin-left: 21px;
    margin-top: 43px;
    font-family: Heebo;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262626; }
  .profile .kid-container {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    margin-left: 21px;
    margin-right: 28px; }
    .profile .kid-container .kid-number-text {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #262626; }
    .profile .kid-container .kid-name-text {
      font-family: Heebo;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #262626; }
    .profile .kid-container .kid-phone-text {
      font-family: Heebo;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #262626; }
    .profile .kid-container .kid-info {
      margin-top: 6px; }
    .profile .kid-container .action {
      min-width: 3rem;
      max-width: 3rem;
      height: 3rem;
      border-radius: 2px;
      background-color: rgba(224, 228, 235, 0.4);
      padding: 7px;
      cursor: pointer; }
  .profile .info-container {
    display: flex;
    flex-direction: column; }
    .profile .info-container .action {
      min-width: 3rem;
      max-width: 3rem;
      height: 3rem;
      border-radius: 2px;
      background-color: rgba(224, 228, 235, 0.4);
      padding: 7px;
      cursor: pointer; }
    .profile .info-container .email-container {
      display: flex;
      justify-content: space-between;
      margin-top: 43px;
      margin-left: 21px;
      margin-right: 28px;
      align-items: center; }
      .profile .info-container .email-container .email-text {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #262626; }
      .profile .info-container .email-container .email-text-three {
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #262626; }
    .profile .info-container .phone-container {
      display: flex;
      justify-content: space-between;
      margin-top: 34px;
      margin-left: 21px;
      margin-right: 28px;
      align-items: center; }
      .profile .info-container .phone-container .phone-text {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #262626; }
      .profile .info-container .phone-container .phone-text-three {
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #262626; }
  .profile .title-text {
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262626;
    margin-bottom: 12px; }
  .profile .container {
    max-width: 708px;
    margin: 0 auto;
    padding: 32px 0; }
    .profile .container > .text {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: #262626;
      margin-bottom: 12px; }
    .profile .container .with-background {
      border-radius: 4px;
      background-color: #f4f5f6;
      padding: 20px;
      position: relative;
      margin-bottom: 20px; }
      .profile .container .with-background .anticon {
        position: absolute;
        font-size: 16px;
        color: #525f78;
        right: 18px;
        top: 18px;
        cursor: pointer; }
    .profile .container .row {
      display: flex;
      margin-bottom: 20px;
      align-items: flex-end; }
      .profile .container .row > div {
        flex-grow: 1;
        flex-basis: 100%; }
        .profile .container .row > div:not(:last-child) {
          margin-right: 20px; }
    .profile .container .line {
      border-bottom: 1px solid #dfe2e5;
      margin: 30px 0 14px 0; }
    .profile .container .form-field {
      position: relative; }
      .profile .container .form-field .label {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #7f8fa4;
        display: block;
        margin-bottom: 6px; }
      .profile .container .form-field .inline-error {
        display: block;
        margin-top: 4px;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ee363d;
        position: absolute; }
      .profile .container .form-field .ant-calendar-input-wrap {
        display: none; }
      .profile .container .form-field .ant-calendar-picker {
        display: block; }
        .profile .container .form-field .ant-calendar-picker input {
          border-radius: 2px;
          border: solid 1px #adb5c6;
          background-color: #ffffff;
          opacity: 0.9;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.36;
          letter-spacing: normal;
          color: #525f78;
          padding: 10px 12px;
          width: 100%;
          outline: none;
          height: auto; }
      .profile .container .form-field input {
        border-radius: 2px;
        border: solid 1px #adb5c6;
        background-color: #ffffff;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #262626;
        padding: 10px 12px;
        width: 100%;
        outline: none; }
        .profile .container .form-field input::-webkit-input-placeholder {
          /* Edge */
          color: #525f78;
          opacity: 0.9;
          font-weight: normal; }
        .profile .container .form-field input:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #525f78;
          opacity: 0.9;
          font-weight: normal; }
        .profile .container .form-field input::placeholder {
          color: #525f78;
          opacity: 0.9;
          font-weight: normal; }
  .profile .profile-card {
    border-radius: 8px;
    border: solid 1px #f2f4fa;
    background-color: #ffffff;
    padding: 20px 16px 24px 16px;
    max-width: 56rem;
    margin: 0 auto 10px auto;
    position: relative; }
    .profile .profile-card .content {
      display: flex;
      align-items: center; }
      .profile .profile-card .content .action-wrap {
        display: flex;
        align-items: center;
        position: absolute;
        right: 1.5rem;
        top: 2rem; }
        .profile .profile-card .content .action-wrap .action {
          min-width: 3rem;
          max-width: 3rem;
          height: 3rem;
          border-radius: 2px;
          background-color: rgba(224, 228, 235, 0.4);
          padding: 7px;
          cursor: pointer; }
          .profile .profile-card .content .action-wrap .action:not(:last-child) {
            margin-right: 1rem; }
      .profile .profile-card .content .dp {
        max-width: 50px;
        min-width: 50px;
        height: 50px;
        margin-right: 10px;
        border-radius: 100%;
        background: #7f8fa4;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #262626; }
      .profile .profile-card .content .desc-wrap {
        flex-grow: 1; }
        .profile .profile-card .content .desc-wrap .text-one {
          font-size: 10px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #627386;
          margin-bottom: 2px; }
        .profile .profile-card .content .desc-wrap .text-two {
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #262626;
          margin-bottom: 8px; }
        .profile .profile-card .content .desc-wrap .text-three {
          font-size: 9px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #262626;
          margin-bottom: 3px; }
  .profile .profile-card-kid {
    border-radius: 8px;
    border: solid 1px #f2f4fa;
    background-color: #ffffff;
    padding: 20px 16px 24px 16px;
    max-width: 56rem;
    margin: 0 auto 10px auto;
    position: relative; }
    .profile .profile-card-kid .content {
      display: flex;
      align-items: center; }
      .profile .profile-card-kid .content .action-wrap {
        display: flex;
        align-items: center;
        position: absolute;
        right: 1.5rem;
        top: 2rem; }
        .profile .profile-card-kid .content .action-wrap .action {
          min-width: 3rem;
          max-width: 3rem;
          height: 3rem;
          border-radius: 2px;
          background-color: rgba(224, 228, 235, 0.4);
          padding: 7px;
          cursor: pointer; }
          .profile .profile-card-kid .content .action-wrap .action:not(:last-child) {
            margin-right: 1rem; }
      .profile .profile-card-kid .content .dp {
        max-width: 50px;
        min-width: 50px;
        height: 50px;
        margin-right: 10px;
        border-radius: 100%;
        background: #7f8fa4;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #262626; }
      .profile .profile-card-kid .content .desc-wrap {
        flex-grow: 1; }
        .profile .profile-card-kid .content .desc-wrap .text-one {
          font-size: 10px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #627386;
          margin-bottom: 2px; }
        .profile .profile-card-kid .content .desc-wrap .text-two {
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #262626;
          margin-bottom: 8px; }
        .profile .profile-card-kid .content .desc-wrap .text-three {
          font-size: 9px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #262626;
          margin-bottom: 3px; }
  .profile .action-wrapper {
    box-shadow: 0 -1px 0 0 #e6eaee;
    background-color: #f0f3f8;
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    padding: 10px 0;
    width: 100%; }
    .profile .action-wrapper .content {
      max-width: 960px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center; }
      .profile .action-wrapper .content p {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #2f3a4f; }
      .profile .action-wrapper .content div {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end; }
        .profile .action-wrapper .content div .link {
          margin-right: 20px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #4139eb; }
        .profile .action-wrapper .content div button {
          padding: 10px 20px;
          font-size: 14px;
          font-weight: 500;
          text-transform: none;
          border-radius: 4px;
          background-color: #fff;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.36;
          letter-spacing: normal;
          text-align: center;
          margin-left: 10px;
          box-shadow: none; }
          .profile .action-wrapper .content div button:first-child {
            color: #2f3a4f; }
          .profile .action-wrapper .content div button:last-child {
            background-color: #4139eb;
            background-image: none; }

.profile-form {
  padding: 2rem; }
  .profile-form button {
    border-radius: 2px;
    background-color: #4139eb;
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.92;
    letter-spacing: normal;
    color: #ffffff;
    border: 0;
    outline: none;
    padding: 5px 36px;
    margin: 0 auto;
    display: inherit; }

@media screen and (max-width: 768px) {
  .profile {
    padding: 0rem;
    background: #ffffff; }
    .profile .profile-card {
      border-radius: 0px;
      border: solid 1px #2a2934;
      background-color: #2a2934;
      padding: 20px 16px 24px 16px;
      max-width: 56rem;
      margin: 0 auto 10px auto;
      position: relative; }
      .profile .profile-card .content .desc-wrap .text-one {
        color: #ffffff; }
      .profile .profile-card .content .desc-wrap .text-two {
        color: #ffffff; }
      .profile .profile-card .content .desc-wrap {
        margin-left: 111px;
        margin-top: 35px; }
      .profile .profile-card .content .dp {
        max-width: 103px;
        min-width: 103px;
        height: 103px;
        margin-right: 10px;
        border-radius: 100%;
        background: #7f8fa4;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #262626;
        border: solid 5px #23222b;
        position: absolute;
        top: 38px; }
    .profile .profile-card-kid {
      /* border-radius: 8px; */
      border: solid 1px #ffffff;
      background-color: #ffffff;
      padding: 20px 16px 24px 16px;
      max-width: 56rem;
      margin: 0 auto 10px auto;
      position: relative; }
    .profile .container {
      padding: 60px 0 60px 0; }
      .profile .container .row {
        margin: 0;
        flex-direction: column; }
        .profile .container .row .form-field {
          width: 100%;
          margin-bottom: 20px; }
          .profile .container .row .form-field:not(:last-child) {
            margin: 0;
            margin-bottom: 20px; }
    .profile .action-wrapper .content div button:first-child {
      border-radius: 2px;
      border: solid 0.9px #adb5c6;
      background-color: #ffffff; }
    .profile .action-wrapper .content div button {
      flex-grow: 1;
      flex-basis: 100%;
      margin: 0 10px; } }

.survey.readonly {
  padding-top: 0; }
  .survey.readonly .wrapper {
    max-width: 100%;
    height: auto;
    padding: 0; }
    .survey.readonly .wrapper .right-section {
      padding: 0; }

.survey .wrapper {
  max-width: 768px;
  margin: 0 auto;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  padding: 0 64px;
  text-align: left;
  height: calc(100% - 60px); }
  .survey .wrapper .inline-error {
    color: #f73737; }
  .survey .wrapper .event-details {
    margin: 12px 0 16px 0; }
    .survey .wrapper .event-details p {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.26;
      color: rgba(0, 0, 0, 0.65); }
  .survey .wrapper .thank-you {
    display: flex;
    align-items: center;
    justify-content: center; }
    .survey .wrapper .thank-you div {
      text-align: center; }
    .survey .wrapper .thank-you img {
      margin-bottom: 24px; }
    .survey .wrapper .thank-you p {
      font-size: 18px;
      margin-top: 12px; }
  .survey .wrapper .inline-button-wrap {
    margin-top: 20px;
    text-align: center;
    width: 100%; }
    .survey .wrapper .inline-button-wrap button {
      margin-right: 12px;
      min-width: 120px; }
  .survey .wrapper .inline-error {
    margin-top: 12px; }
  .survey .wrapper .form-field {
    margin-top: 20px; }
    .survey .wrapper .form-field .input-field {
      min-height: 10rem;
      vertical-align: top; }
    .survey .wrapper .form-field input,
    .survey .wrapper .form-field textarea {
      border-radius: 2px;
      border: solid 1px #7c8390;
      background-color: #ffffff;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
      color: #363e4c;
      padding: 8px 15px;
      width: 100%;
      outline: none; }
      .survey .wrapper .form-field input.error,
      .survey .wrapper .form-field textarea.error {
        border: 1px solid #ed1c24;
        color: #ed1c24; }
      .survey .wrapper .form-field input:focus,
      .survey .wrapper .form-field textarea:focus {
        border: solid 1px #7c8390;
        color: #363e4c; }
    .survey .wrapper .form-field .label {
      font-size: 14px;
      margin-bottom: 12px;
      display: block; }
  .survey .wrapper .text-one {
    font-size: 14px;
    margin-bottom: 12px; }
  .survey .wrapper .text-two {
    font-size: 14px;
    margin-bottom: 12px; }
  .survey .wrapper .inline-error {
    display: block; }
  .survey .wrapper .testimonial-wrap .text {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.26;
    color: #1b1b1b;
    margin-bottom: 12px; }
  .survey .wrapper .left-section {
    max-width: 440px;
    width: 100%;
    padding: 42px 0; }
    .survey .wrapper .left-section .content {
      width: 100%;
      position: relative;
      top: 0; }
      .survey .wrapper .left-section .content .img-wrap {
        margin-bottom: 32px; }
        .survey .wrapper .left-section .content .img-wrap img {
          max-width: 100%; }
      .survey .wrapper .left-section .content .text {
        font-size: 14px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: #9b9b9b;
        font-style: italic;
        font-weight: 300; }
  .survey .wrapper .right-section {
    flex-grow: 1;
    padding: 42px 0 42px 0;
    max-width: 100%;
    position: relative; }
    .survey .wrapper .right-section .stepper-wrapper {
      margin-bottom: 30px; }
      .survey .wrapper .right-section .stepper-wrapper .step-text {
        font-size: 10px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #c7cfe4;
        margin-bottom: 8px; }
      .survey .wrapper .right-section .stepper-wrapper .steps-wrap {
        display: flex; }
        .survey .wrapper .right-section .stepper-wrapper .steps-wrap .step {
          flex-grow: 1;
          flex-basis: 100%;
          border-radius: 2px;
          margin-right: 4px;
          background: #e4e4e4;
          padding: 2px;
          max-width: 45px; }
          .survey .wrapper .right-section .stepper-wrapper .steps-wrap .step.completed {
            background: #003bdf; }
    .survey .wrapper .right-section .select-wrap .select {
      border: 1px solid #e0e0e0;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #5e5e5e;
      padding: 8px 16px;
      cursor: pointer; }
      .survey .wrapper .right-section .select-wrap .select.selected {
        border: 1px solid #003bdf;
        color: #003bdf; }
      .survey .wrapper .right-section .select-wrap .select:not(:last-child) {
        margin-bottom: 16px; }
  .survey .wrapper .helper-text-wrap {
    display: flex; }
    .survey .wrapper .helper-text-wrap span {
      flex-grow: 1;
      flex-basis: 100%;
      font-size: 10px;
      color: #4a4a4a;
      font-style: italic; }
      .survey .wrapper .helper-text-wrap span:last-child {
        text-align: right; }
  .survey .wrapper .rating-wrap {
    display: flex;
    flex-direction: column; }
    .survey .wrapper .rating-wrap .step {
      border: 1px solid #e0e0e0;
      margin-bottom: 12px;
      width: 100%;
      padding: 12px;
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a;
      min-width: 50px;
      cursor: pointer; }
      .survey .wrapper .rating-wrap .step.selected {
        border: 1px solid #003bdf; }
      .survey .wrapper .rating-wrap .step:not(:last-child).selected {
        border-right: 1px solid #003bdf;
        color: #003bdf; }

.page-not-found {
  color: #495057;
  text-align: left;
  background-color: #f5f7fb;
  height: 100vh;
  width: 100%;
  padding: 24px; }
  .page-not-found .page-content {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: center; }
    .page-not-found .page-content .title-text {
      color: #9aa0ac !important;
      font-size: 4.5rem;
      line-height: 1.1;
      margin-bottom: 20px; }
    .page-not-found .page-content .text {
      color: #495057;
      font-size: 16px; }

@media screen and (max-width: 768px) {
  .survey .wrapper {
    max-width: 100%;
    padding: 0 24px;
    display: block; }
    .survey .wrapper .inline-button-wrap {
      position: relative;
      bottom: 0; }
    .survey .wrapper .right-section {
      max-width: 100%;
      padding: 24px 0 24px 0px; } }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.no-pointer {
  cursor: default !important; }

.dial-code {
  position: absolute;
  width: 5rem;
  height: 100%;
  left: 0;
  bottom: 0;
  background-color: #fafafa;
  border: solid 1px #adb5c6;
  border-radius: 2px;
  display: flex;
  align-items: center;
  padding: 1rem;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: rgba(53, 64, 82, 0.9);
  z-index: 1; }

.with-dial-code {
  padding-left: 6rem !important; }

.no-daling-code {
  padding-left: 6rem !important; }

.form-wrapper ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  text-transform: capitalize !important;
  text-transform: capitalize; }

.form-wrapper ::-moz-placeholder {
  /* Firefox 19+ */
  text-transform: capitalize !important;
  text-transform: capitalize; }

.form-wrapper :-ms-input-placeholder {
  /* IE 10+ */
  text-transform: capitalize !important;
  text-transform: capitalize; }

.form-wrapper :-moz-placeholder {
  /* Firefox 18- */
  text-transform: capitalize !important;
  text-transform: capitalize; }

.form-wrapper.inline-view .inline-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0 20px; }

.form-wrapper .ant-time-picker {
  width: 100%;
  margin-bottom: 24px; }

.form-wrapper.with-stepper {
  padding-top: 48px; }

.form-wrapper .mt-40 {
  margin-top: 40px; }

.form-wrapper .profile-pic-wrapper {
  text-align: center;
  margin-bottom: 16px; }
  .form-wrapper .profile-pic-wrapper img {
    width: 120px;
    height: 120px;
    border-radius: 100%;
    display: inline-block; }

.form-wrapper .stepper-wrap {
  display: flex;
  position: relative;
  margin-bottom: 24px;
  border-bottom: 1px solid #f4f4f4;
  padding: 12px 24px;
  background: #f0efef;
  z-index: 9; }
  .form-wrapper .stepper-wrap .step {
    flex-grow: 1;
    flex-basis: 100%;
    font-size: 14px;
    color: #1b1b1b;
    text-align: center;
    cursor: pointer; }
    .form-wrapper .stepper-wrap .step.current {
      color: #f5a100; }

.form-wrapper .inline-wrapper {
  display: flex;
  margin-bottom: 32px; }
  .form-wrapper .inline-wrapper p {
    margin-right: 12px; }

.form-wrapper input[type=number]::-webkit-inner-spin-button,
.form-wrapper input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.form-wrapper input[type=number] {
  -moz-appearance: textfield; }

.form-wrapper input[type="color"]:disabled,
.form-wrapper input[type="date"]:disabled,
.form-wrapper input[type="datetime"]:disabled,
.form-wrapper input[type="datetime-local"]:disabled,
.form-wrapper input[type="email"]:disabled,
.form-wrapper input[type="month"]:disabled,
.form-wrapper input[type="password"]:disabled,
.form-wrapper input[type="number"]:disabled,
.form-wrapper input[type="search"]:disabled,
.form-wrapper input[type="tel"]:disabled,
.form-wrapper input[type="text"]:disabled,
.form-wrapper input[type="time"]:disabled,
.form-wrapper input[type="url"]:disabled,
.form-wrapper input[type="week"]:disabled {
  background-color: transparent; }

.form-wrapper .message-header {
  margin-bottom: 16px;
  color: #bb0017; }

.form-wrapper .link-text {
  color: #f5a100;
  font-size: 14px;
  cursor: pointer; }

.form-wrapper .student-details {
  background: #fbfbfb;
  margin: 0 -24px;
  padding: 24px; }
  .form-wrapper .student-details input[type="color"],
  .form-wrapper .student-details input[type="date"],
  .form-wrapper .student-details input[type="datetime"],
  .form-wrapper .student-details input[type="datetime-local"],
  .form-wrapper .student-details input[type="email"],
  .form-wrapper .student-details input[type="month"],
  .form-wrapper .student-details input[type="password"],
  .form-wrapper .student-details input[type="number"],
  .form-wrapper .student-details input[type="search"],
  .form-wrapper .student-details input[type="tel"],
  .form-wrapper .student-details input[type="text"],
  .form-wrapper .student-details input[type="time"],
  .form-wrapper .student-details input[type="url"],
  .form-wrapper .student-details input[type="week"],
  .form-wrapper .student-details .search-list .search-input input {
    background-color: transparent; }

.form-wrapper .title {
  color: #1976d2; }

.form-wrapper .sub-text {
  color: #313131;
  font-size: 14px;
  margin-bottom: 18px; }
  .form-wrapper .sub-text:last-child {
    margin-bottom: 36px; }
  .form-wrapper .sub-text span {
    color: #5e5e5e;
    font-weight: 500; }

.form-wrapper .week-selector {
  margin-top: 24px; }

.form-wrapper .form-field {
  margin-bottom: 20px;
  position: relative; }
  .form-wrapper .form-field .ant-checkbox-wrapper {
    position: relative;
    display: block;
    top: auto;
    pointer-events: initial; }
  .form-wrapper .form-field > p {
    margin: 0; }
  .form-wrapper .form-field .ant-calendar-picker {
    width: 100%; }
  .form-wrapper .form-field.switch-wrap {
    display: flex;
    align-items: center; }
    .form-wrapper .form-field.switch-wrap label {
      margin: 0 12px 0 0 !important; }
  .form-wrapper .form-field .ant-switch {
    display: inline-block;
    max-width: 60px; }
  .form-wrapper .form-field .no-daling-code {
    padding-left: 53px; }
  .form-wrapper .form-field .radio-group {
    margin-top: 12px;
    display: flex; }
    .form-wrapper .form-field .radio-group > div {
      margin-right: 12px;
      display: flex;
      align-items: center; }
      .form-wrapper .form-field .radio-group > div input {
        margin-right: 12px; }
  .form-wrapper .form-field .inline-error {
    position: absolute;
    left: 0;
    bottom: -16px; }
  .form-wrapper .form-field .inline-error-button {
    position: absolute;
    left: 0;
    bottom: 2px; }
  .form-wrapper .form-field.no-margin {
    margin-bottom: 15px; }
  .form-wrapper .form-field > label {
    width: 100%;
    margin: 0 0 12px 0 !important;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7f8fa4;
    transition: 0.2s ease all;
    font-size: 14px;
    display: inline-block;
    left: 0;
    pointer-events: none;
    line-height: 1; }
    .form-wrapper .form-field > label.form-label {
      position: relative;
      top: auto;
      bottom: auto; }

.form-wrapper .selection-controls svg {
  color: #d9d9d9; }

.form-wrapper .selection-controls label span:last-child {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5e5e5e; }

.form-wrapper .ant-time-picker-input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  text-transform: capitalize !important;
  color: #1b1b1b !important;
  text-transform: capitalize; }

.form-wrapper .ant-time-picker-input::-moz-placeholder {
  /* Firefox 19+ */
  text-transform: capitalize !important;
  color: #1b1b1b !important;
  text-transform: capitalize; }

.form-wrapper .ant-time-picker-input:-ms-input-placeholder {
  /* IE 10+ */
  text-transform: capitalize !important;
  color: #1b1b1b !important;
  text-transform: capitalize; }

.form-wrapper .ant-time-picker-input:-moz-placeholder {
  /* Firefox 18- */
  text-transform: capitalize !important;
  color: #1b1b1b !important;
  text-transform: capitalize; }

.form-wrapper .ant-select-selection {
  padding: 1px; }

.form-wrapper .ant-select-selection--multiple > ul > li,
.form-wrapper .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  height: 24px;
  margin: 3px 3px 3px 0; }

.form-wrapper .ant-select-selection__rendered {
  line-height: 2;
  height: 100%; }

.form-wrapper .ant-select-selection__placeholder,
.form-wrapper .ant-select-search__field__placeholder {
  height: 100%; }

.form-wrapper textarea {
  resize: none; }

.form-wrapper .disabled {
  opacity: .7;
  pointer-events: none; }

.form-wrapper .inline-error {
  display: inline-block;
  text-align: left;
  color: #bb0017;
  font-size: 12px; }

.form-wrapper .radio-wrap {
  display: flex;
  align-items: center; }
  .form-wrapper .radio-wrap > div {
    flex-grow: 1;
    flex-basis: 100%;
    border-radius: 2px;
    border: solid 1px #b6b6b6;
    padding: 10px;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    cursor: pointer;
    text-transform: uppercase; }
    .form-wrapper .radio-wrap > div.selected {
      background: #f5a100;
      color: #e6e6e6;
      border: 0; }
    .form-wrapper .radio-wrap > div:not(:last-child) {
      margin-right: 12px; }

.days-time-wrapper > div > div {
  background: #f8f9fa;
  border-radius: 2px;
  margin-bottom: 12px;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #c4cfe4; }

.days-time-wrapper .time-input-wrapper {
  display: flex; }
  .days-time-wrapper .time-input-wrapper div {
    flex-grow: 1;
    flex-basis: 100%; }
    .days-time-wrapper .time-input-wrapper div label {
      margin-right: 12px; }

.days-time-wrapper .days-wrapper {
  display: flex;
  align-items: center; }
  .days-time-wrapper .days-wrapper .day {
    flex-grow: 1;
    flex-basis: 100%; }
    .days-time-wrapper .days-wrapper .day p {
      border-radius: 2px;
      background-color: white;
      border: solid 1px rgba(190, 190, 190, 0.52) !important;
      padding: 1rem .5rem;
      font-size: 1rem;
      line-height: 1.29;
      text-align: center;
      color: #2f3a4f;
      display: inline-block;
      width: calc(100% - .5rem);
      text-align: center;
      text-transform: capitalize !important;
      cursor: pointer; }
      .days-time-wrapper .days-wrapper .day p.ui-error.input {
        border: 1px solid rgba(235, 62, 85, 0.35) !important; }
      .days-time-wrapper .days-wrapper .day p.selected {
        border: 1px solid #50e3c2 !important;
        background: #50e3c2; }
    .days-time-wrapper .days-wrapper .day:last-child button {
      width: 100%; }

.ant-select-dropdown .ant-select-dropdown-menu-item {
  font-size: 12px !important;
  padding: 6px 10px !important;
  line-height: 2 !important; }

.form .label {
  font-size: 1.4rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: .6rem;
  display: block; }

.form input,
.form textarea {
  border-radius: 2px;
  border: 1px solid #c4cfe4;
  background-color: #fff;
  padding: .8rem 1.2rem;
  font-size: 1.4rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.8);
  width: 100%;
  outline: none; }
  .form input::-webkit-input-placeholder,
  .form textarea::-webkit-input-placeholder {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #bfbfbf;
    font-weight: 500; }

.form .ant-select {
  font-size: 1.4rem;
  color: rgba(0, 0, 0, 0.8);
  width: 100%; }
  .form .ant-select .ant-select-arrow {
    font-size: 1.2rem; }
  .form .ant-select input {
    padding: 0; }
  .form .ant-select.ant-select-open .ant-select-selection {
    border-radius: 2px;
    border: 1px solid #c4cfe4;
    background-color: #fff; }
  .form .ant-select .ant-select-selection {
    border: solid 1px #c4cfe4; }
    .form .ant-select .ant-select-selection:hover {
      border: solid 1px #c4cfe4; }
    .form .ant-select .ant-select-selection:focus {
      box-shadow: none; }
  .form .ant-select input {
    border: 0; }
    .form .ant-select input:focus {
      background: transparent; }
  .form .ant-select .ant-select-selection--single {
    height: auto;
    border-radius: .4rem; }
    .form .ant-select .ant-select-selection--single .ant-select-selection__rendered {
      line-height: 3.6rem; }

.form .ant-radio-group label span:last-child {
  font-size: 1.4rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000; }

.form .ant-radio-group .ant-radio-checked .ant-radio-inner {
  border: 1px solid #033d79;
  background-color: #033d79; }
  .form .ant-radio-group .ant-radio-checked .ant-radio-inner:after {
    background-color: #fff;
    width: 6px;
    height: 6px;
    top: 4px;
    left: 4px; }

.form .ant-radio-group .ant-radio-checked:after {
  border: 1px solid #033d79; }

.form .ant-input {
  height: auto;
  line-height: 1; }
  .form .ant-input:hover {
    border: solid 1px #cccccc;
    box-shadow: none; }
  .form .ant-input::-webkit-input-placeholder {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #bfbfbf;
    font-weight: 500; }

.form .ant-calendar-picker {
  width: 100%; }
  .form .ant-calendar-picker:hover input {
    border: solid 1px #c4cfe4;
    box-shadow: none; }
  .form .ant-calendar-picker .ant-calendar-picker-input:not(.ant-input-disabled) {
    border: solid 1px #c4cfe4;
    box-shadow: none;
    height: 32px; }

.form .form-field {
  margin-bottom: 2rem;
  position: relative; }
  .form .form-field.hidden {
    visibility: hidden; }
  .form .form-field.right {
    text-align: right; }
  .form .form-field .inline-error {
    position: relative;
    top: .8rem;
    left: 0;
    color: #f51e1e;
    display: block; }
  .form .form-field button {
    width: auto;
    margin: 0 auto; }

.form .mt-44 {
  margin-top: 4.4rem; }

.ant-select-dropdown .ant-select-dropdown-menu-item {
  padding: .5rem 1.2rem;
  line-height: 2.2rem;
  font-size: 1.1rem; }

.disabled {
  pointer-events: none;
  opacity: .5; }

@media screen and (max-width: 768px) {
  .ant-select-dropdown {
    background: #fff;
    box-shadow: none; }
  .form {
    padding-bottom: 4rem; }
    .form .ant-radio-group {
      display: flex;
      align-items: center; }
      .form .ant-radio-group .ant-radio-wrapper-checked {
        border: solid 1px #003bdf;
        background-color: #033d79;
        color: #fff; }
        .form .ant-radio-group .ant-radio-wrapper-checked span:last-child {
          color: #fff; }
      .form .ant-radio-group > label {
        flex-grow: 1;
        flex-basis: 100%;
        border-radius: .6rem;
        border: solid 1px #d9d9d9;
        background-color: #ffffff;
        padding: 10px;
        text-align: center;
        position: relative; }
        .form .ant-radio-group > label .ant-radio {
          visibility: hidden;
          position: absolute; }
        .form .ant-radio-group > label:first-child {
          margin-right: .8rem; }
    .form .buttons-wrap {
      position: fixed;
      box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.11);
      background-color: #ffffff;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 !important;
      padding: 1rem 1.2rem; }
      .form .buttons-wrap .form-field {
        margin: 0 !important; }
      .form .buttons-wrap .back {
        display: none !important; } }

@media only screen and (max-width: 992px) {
  .form-wrapper .stepper-wrap {
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    top: 62px;
    border-bottom: 1px solid #f4f4f4;
    padding: 12px 24px;
    background: #f0efef;
    z-index: 9; }
  .form-wrapper .details-text {
    max-width: 100%; } }

.student-classes {
  max-width: 980px;
  width: 980px;
  background: #fff;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  transform: scale(0); }
  .student-classes .header-section {
    background: #f2534c;
    background: linear-gradient(90deg, #f2534c 0%, #fba649 100%);
    padding: 20px; }
    .student-classes .header-section .logo-wrap {
      display: flex;
      align-items: center;
      margin-bottom: 24px; }
      .student-classes .header-section .logo-wrap > div {
        flex-grow: 1;
        flex-basis: 100%; }
        .student-classes .header-section .logo-wrap > div:first-child {
          text-align: left; }
          .student-classes .header-section .logo-wrap > div:first-child img {
            height: 57px; }
        .student-classes .header-section .logo-wrap > div:last-child {
          text-align: right; }
          .student-classes .header-section .logo-wrap > div:last-child img {
            height: 64px; }
    .student-classes .header-section .key-value-wrapper {
      display: flex;
      align-items: flex-start; }
      .student-classes .header-section .key-value-wrapper .key-value:not(:last-child) {
        margin-right: 60px; }
      .student-classes .header-section .key-value-wrapper .key-value .key {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #ffffff;
        margin-bottom: 2px; }
      .student-classes .header-section .key-value-wrapper .key-value .value {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff; }
  .student-classes .table-section table {
    width: 100%;
    table-layout: fixed; }
    .student-classes .table-section table tr td,
    .student-classes .table-section table tr th {
      border-bottom: 1px solid #dadfea;
      border-right: 1px solid #dadfea;
      padding: 18px 18px 6px 18px; }
      .student-classes .table-section table tr td:first-child,
      .student-classes .table-section table tr th:first-child {
        border-left: 1px solid #dadfea; }
    .student-classes .table-section table tr th {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: #2f3a4f; }
    .student-classes .table-section table tr td {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: #2f3a4f; }

.time-picker {
  position: relative; }
  .time-picker .time-select {
    border-radius: .4rem;
    border: solid 1px #ced0da;
    background-image: linear-gradient(to top, #f2f4f7, #ffffff);
    padding: 1rem 3rem;
    background: url(images/clock.svg) no-repeat;
    background-position: .8rem center;
    cursor: pointer;
    position: relative; }
    .time-picker .time-select span {
      font-size: 1.4rem;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #354052;
      display: inline-block; }
    .time-picker .time-select .arrow {
      position: absolute;
      right: 1.2rem;
      top: 50%;
      transform: translateY(-50%); }
  .time-picker .time-dropdown {
    border-radius: 0 0 .4rem .4rem;
    border: 1px solid #ced0da;
    border-top: 0;
    background-color: #ffffff;
    padding: 1.2rem;
    position: absolute;
    width: 100%;
    background: #fff;
    z-index: 1; }
    .time-picker .time-dropdown .time-pick-wrapper:not(:last-child) {
      margin-bottom: 1rem; }
    .time-picker .time-dropdown .time-pick-wrapper label {
      opacity: 0.5;
      font-size: 1rem;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #354052;
      display: inline-block;
      margin-bottom: .6rem; }
    .time-picker .time-dropdown .time-pick-wrapper .flex {
      display: flex;
      align-items: center; }
      .time-picker .time-dropdown .time-pick-wrapper .flex > span {
        margin: .8rem;
        font-size: 1.2rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #262626; }
    .time-picker .time-dropdown .time-pick {
      border-radius: 2px;
      border: solid 1px #adb5c6;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      max-width: 7rem; }
      .time-picker .time-dropdown .time-pick input[type="number"],
      .time-picker .time-dropdown .time-pick input[type="text"] {
        border: 0 !important;
        font-size: 1rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #262626;
        padding: 0;
        padding: .6rem;
        outline: none;
        width: 100%;
        pointer-events: none; }
        .time-picker .time-dropdown .time-pick input[type="number"]:focus,
        .time-picker .time-dropdown .time-pick input[type="text"]:focus {
          border: 0; }
      .time-picker .time-dropdown .time-pick .inc-dec-wrap {
        border-left: solid 1px #adb5c6;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; }
        .time-picker .time-dropdown .time-pick .inc-dec-wrap span {
          padding: .2rem .4rem;
          cursor: pointer; }
          .time-picker .time-dropdown .time-pick .inc-dec-wrap span:first-child {
            border-bottom: solid 1px #adb5c6; }

@media screen and (max-width: 768px) {
  .time-picker .time-dropdown {
    position: relative; } }

.adt-layout {
  max-width: 480px;
  margin: 0 auto;
  padding: 12px; }
  .adt-layout .section-one {
    margin-bottom: 30px;
    min-height: 200px; }
    .adt-layout .section-one img {
      max-width: 100%; }
  .adt-layout .list-section {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px; }
    .adt-layout .list-section .list {
      text-align: center;
      display: inline-block; }
      .adt-layout .list-section .list:last-child {
        grid-column: 1 / 3;
        grid-row: 3; }
      .adt-layout .list-section .list .icon {
        width: 42px;
        height: 36px;
        object-fit: contain;
        margin-bottom: 16px; }
      .adt-layout .list-section .list .text {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;
        color: #2f3a4f; }
  .adt-layout .video-wrapper > div {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    width: 100%;
    margin-top: 30px; }
  .adt-layout .video-wrapper iframe,
  .adt-layout .video-wrapper object,
  .adt-layout .video-wrapper embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }
  .adt-layout .form {
    border-radius: 12px;
    box-shadow: 0 1px 22px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    padding: 44px 30px 40px 30px;
    margin-top: 20px; }
    .adt-layout .form .text {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: center;
      color: #2f3a4f;
      margin-bottom: 4px; }
    .adt-layout .form .bold-text {
      font-size: 20px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #4922f3;
      margin-bottom: 14px; }
    .adt-layout .form button {
      border-radius: 1.3px;
      box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.15);
      background-color: #4922f3;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.93px;
      text-align: center;
      color: #ffffff;
      padding: 15px;
      width: 100%;
      outline: none;
      border: 0; }
    .adt-layout .form .form-field {
      margin-bottom: 14px; }
      .adt-layout .form .form-field input {
        border-radius: 2px;
        border: solid 0.7px #adb5c6;
        background-color: #ffffff;
        padding: 16px 10px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.99;
        letter-spacing: normal;
        color: #354052;
        margin-bottom: 14px;
        width: 100%;
        outline: none; }
        .adt-layout .form .form-field input.error {
          border-color: red; }

.relative {
  position: relative; }

.sticky-button {
  border-radius: 1.3px;
  box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.15);
  background-color: #4922f3;
  cursor: pointer;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding: 12px;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999; }

.meesho-ad-layout {
  max-width: 460px;
  padding: 12px;
  margin: 0 auto; }
  .meesho-ad-layout .header-section {
    padding: 20px 22px;
    margin-bottom: 12px; }
    .meesho-ad-layout .header-section p {
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.91;
      letter-spacing: normal;
      text-align: right;
      color: #ffffff;
      text-align: right; }
    .meesho-ad-layout .header-section.lingoq-adults {
      background: url(images/meesho/lingoq-adults.png) no-repeat;
      background-position: center center;
      background-size: 100%; }
  .meesho-ad-layout .video-section > div {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    width: 100%; }
  .meesho-ad-layout .video-section iframe,
  .meesho-ad-layout .video-section object,
  .meesho-ad-layout .video-section embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 6px;
    background-color: #453b85; }
  .meesho-ad-layout .content-section {
    padding: 32px 20px; }
    .meesho-ad-layout .content-section .row {
      display: flex;
      align-items: center;
      margin-bottom: 20px; }
      .meesho-ad-layout .content-section .row .text-wrap .title,
      .meesho-ad-layout .content-section .row .text-wrap .text {
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.85;
        letter-spacing: normal;
        color: #2f3a4f; }
      .meesho-ad-layout .content-section .row .text-wrap .title {
        font-weight: 500; }
      .meesho-ad-layout .content-section .row.one .img-wrap {
        margin-right: 20px; }
        .meesho-ad-layout .content-section .row.one .img-wrap img {
          width: 83px; }
      .meesho-ad-layout .content-section .row.two .img-wrap {
        margin-left: 20px; }
        .meesho-ad-layout .content-section .row.two .img-wrap img {
          width: 100px; }
      .meesho-ad-layout .content-section .row.two .text-wrap .title,
      .meesho-ad-layout .content-section .row.two .text-wrap .text {
        text-align: right; }
      .meesho-ad-layout .content-section .row.three .img-wrap {
        margin-right: 20px; }
        .meesho-ad-layout .content-section .row.three .img-wrap img {
          width: 70px; }
  .meesho-ad-layout .form-section {
    background-color: #edd6db;
    padding: 24px 42px;
    margin-top: 20px; }
    .meesho-ad-layout .form-section .inline-error {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #eb3e55;
      margin-top: 8px;
      display: block; }
    .meesho-ad-layout .form-section .text {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #2f3a4f;
      max-width: 260px;
      margin: 0 auto 16px auto; }
    .meesho-ad-layout .form-section .bold-text {
      font-size: 20px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #4922f3;
      margin-bottom: 14px; }
    .meesho-ad-layout .form-section button {
      border-radius: 4px;
      box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.15);
      background-color: #4922f3;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.93px;
      text-align: center;
      color: #ffffff;
      padding: 16px;
      width: 100%;
      outline: none;
      border: 0;
      cursor: pointer; }
    .meesho-ad-layout .form-section .form-field {
      margin-bottom: 10px; }
      .meesho-ad-layout .form-section .form-field.coupon-code input {
        border: dashed 1px #d7bdc2; }
      .meesho-ad-layout .form-section .form-field.button-wrapper {
        margin-top: 30px; }
      .meesho-ad-layout .form-section .form-field input {
        border-radius: 2px;
        background-color: #ffffff;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: #2f3a4f;
        padding: 13px 16px;
        border: 0;
        width: 100%;
        outline: none; }
        .meesho-ad-layout .form-section .form-field input.error {
          border: 1px solid  #eb3e55;
          color: #eb3e55; }

.table-wrapper {
  display: block;
  width: 100%;
  position: relative; }
  .table-wrapper.scrollable-table .scrollable-notify {
    position: absolute;
    top: -18px;
    font-size: 14px;
    background-color: #f58b1e;
    color: #192383;
    padding: 2px; }
  .table-wrapper .with-overflow {
    overflow-x: auto; }
  .table-wrapper .text-ellipsis {
    overflow-x: hidden;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 5ch; }
  .table-wrapper .action-wrapper {
    position: absolute;
    right: 0;
    top: 12px;
    padding: 4px 24px 4px 4px; }
  .table-wrapper .menu-wrapper {
    display: inline-block;
    position: absolute;
    right: 12px;
    top: 0; }
    .table-wrapper .menu-wrapper > div svg {
      fill: #c4cfe4;
      font-size: 18px; }
  .table-wrapper .comment-wrap .comment-text {
    display: flex;
    align-items: center;
    justify-content: left; }
  .table-wrapper .comment-wrap .initials {
    font-size: 12px;
    color: #121212;
    margin-right: 4px;
    padding: 4px;
    background: #5cc4ef;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .table-wrapper .comment-wrap .comment {
    font-size: 12px;
    color: #666;
    margin-right: 4px;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .table-wrapper .comment-wrap .more {
    font-size: 12px;
    color: #5cc4ef;
    cursor: pointer;
    margin-right: 4px; }
  .table-wrapper .with-padding {
    padding: 24px; }
  .table-wrapper .blue,
  .table-wrapper .pending {
    color: #1d2785;
    font-weight: 500; }
    .table-wrapper .blue a,
    .table-wrapper .pending a {
      color: #1d2785; }
  .table-wrapper .green,
  .table-wrapper .approved {
    color: #589707;
    font-weight: 500; }
    .table-wrapper .green a,
    .table-wrapper .approved a {
      color: #589707; }
  .table-wrapper .red,
  .table-wrapper .rejected {
    color: #d70202;
    font-weight: 500; }
  .table-wrapper .yellow {
    color: #f5a623;
    font-weight: 500; }
  .table-wrapper .ant-calendar-picker,
  .table-wrapper .ant-select .ant-select-selection {
    border-radius: 2px;
    border: 1px solid #c4cfe4;
    background-color: #fff; }
  .table-wrapper table {
    width: 100%;
    margin-top: 20px;
    border-collapse: separate;
    border-spacing: 0 1em; }
    .table-wrapper table .checkbox {
      padding: 0; }
    .table-wrapper table .anticon-star,
    .table-wrapper table .anticon-more {
      font-size: 1.8rem;
      cursor: pointer; }
      .table-wrapper table .anticon-star.active,
      .table-wrapper table .anticon-more.active {
        color: pink; }
    .table-wrapper table .ant-select {
      max-width: 16rem;
      min-width: 16rem; }
      .table-wrapper table .ant-select .ant-select-arrow {
        font-size: 1.2rem;
        right: 1rem;
        margin-top: -.6rem; }
      .table-wrapper table .ant-select .ant-select-selection--single {
        height: auto; }
      .table-wrapper table .ant-select:not(:last-child) {
        margin-bottom: 4px; }
      .table-wrapper table .ant-select .ant-select-selection__placeholder {
        font-size: 1.2rem; }
      .table-wrapper table .ant-select .ant-select-selection__rendered {
        line-height: 1.6; }
    .table-wrapper table input {
      border: 0;
      outline: 0;
      font-size: 12px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a; }
      .table-wrapper table input:focus {
        border-bottom: 1px solid #efefef;
        padding-bottom: 2px;
        color: #000; }
    .table-wrapper table .crm-label {
      border-radius: 11px;
      font-size: 10px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      line-height: normal;
      padding: 1px 4px;
      display: inline-block;
      margin-right: 4px;
      border: 1px solid #ccc;
      min-width: 40px;
      text-align: center;
      margin-bottom: 4px; }
      .table-wrapper table .crm-label.red {
        color: #b04747;
        border: solid 1px #f8a0a0;
        background-color: #fff3f3; }
      .table-wrapper table .crm-label.green {
        color: #378033;
        border: solid 1px #289a13;
        background-color: #f1fdf5; }
      .table-wrapper table .crm-label.yellow {
        border: solid 1px #fff200;
        background-color: #ffffeb;
        color: #000000; }
    .table-wrapper table thead tr th {
      font-size: 1.2rem;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      padding: 4px 4px 10px 0;
      text-align: left;
      border-bottom: solid 0.5px #dee4ff;
      text-transform: capitalize;
      vertical-align: bottom; }
      .table-wrapper table thead tr th.right {
        text-align: right !important; }
      .table-wrapper table thead tr th label {
        margin-left: 0; }
      .table-wrapper table thead tr th:first-child {
        padding-left: 24px; }
      .table-wrapper table thead tr th:last-child {
        padding-right: 24px; }
    .table-wrapper table tbody tr td {
      font-size: 1rem;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a;
      padding: 1.2rem 3.2rem 1.2rem 0;
      word-break: break-all;
      min-width: 12rem;
      position: relative;
      vertical-align: middle; }
      .table-wrapper table tbody tr td.small {
        min-width: 6rem; }
      .table-wrapper table tbody tr td.medium {
        min-width: 12rem; }
      .table-wrapper table tbody tr td.large {
        min-width: 20rem; }
      .table-wrapper table tbody tr td.right {
        text-align: right !important; }
      .table-wrapper table tbody tr td .button {
        font-size: 10px;
        font-weight: 500;
        padding: 6px 10px;
        border: 1px solid #003bdf;
        background: #fff;
        color: #003bdf;
        text-transform: none;
        display: inline-block;
        width: auto; }
      .table-wrapper table tbody tr td:first-child {
        padding-left: 24px; }
      .table-wrapper table tbody tr td:last-child {
        padding-right: 12px; }
      .table-wrapper table tbody tr td.with-menu {
        position: relative;
        padding-right: 44px; }
      .table-wrapper table tbody tr td > span.select-text {
        cursor: pointer;
        display: block; }
      .table-wrapper table tbody tr td .remark-text {
        font-size: 14px;
        color: #fe972c;
        cursor: pointer;
        min-width: 90px;
        display: inline-block; }
      .table-wrapper table tbody tr td.message-wrapper {
        padding: 32px;
        text-align: center !important; }
        .table-wrapper table tbody tr td.message-wrapper .text {
          font-size: 18px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #4a4a4a;
          margin-bottom: 20px; }
        .table-wrapper table tbody tr td.message-wrapper .refer-earn-flat button {
          min-width: 230px;
          background: #ff901d;
          color: #fff; }
    .table-wrapper table tbody tr {
      border-radius: 2px;
      box-shadow: 0 2px 8px 0 rgba(211, 216, 255, 0.36);
      background-color: #ffffff; }
      .table-wrapper table tbody tr:last-child td {
        padding-top: 24px; }
      .table-wrapper table tbody tr:last-child td,
      .table-wrapper table tbody tr:last-child th {
        border-bottom: 1px solid #d1d1d1; }
  .table-wrapper.vertical-table table tr th {
    font-size: 1.2rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    padding: 4px 4px 10px 0;
    text-align: left;
    border-bottom: solid 0.5px #dee4ff;
    text-transform: capitalize;
    vertical-align: bottom; }
    .table-wrapper.vertical-table table tr th.right {
      text-align: right !important; }
    .table-wrapper.vertical-table table tr th label {
      margin-left: 0; }
    .table-wrapper.vertical-table table tr th:first-child {
      padding-left: 24px; }
    .table-wrapper.vertical-table table tr th:last-child {
      padding-right: 24px; }
  .table-wrapper.vertical-table table tr td {
    font-size: 1rem;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a;
    padding: 1.2rem 3.2rem 1.2rem 0;
    word-break: break-all;
    min-width: 12rem;
    position: relative;
    vertical-align: middle; }
    .table-wrapper.vertical-table table tr td.small {
      min-width: 6rem; }
    .table-wrapper.vertical-table table tr td.medium {
      min-width: 12rem; }
    .table-wrapper.vertical-table table tr td.large {
      min-width: 20rem; }
    .table-wrapper.vertical-table table tr td.right {
      text-align: right !important; }
    .table-wrapper.vertical-table table tr td .button {
      font-size: 10px;
      font-weight: 500;
      padding: 6px 10px;
      border: 1px solid #003bdf;
      background: #fff;
      color: #003bdf;
      text-transform: none;
      display: inline-block;
      width: auto; }
    .table-wrapper.vertical-table table tr td:first-child {
      padding-left: 24px; }
    .table-wrapper.vertical-table table tr td:last-child {
      padding-right: 12px; }
    .table-wrapper.vertical-table table tr td.with-menu {
      position: relative;
      padding-right: 44px; }
    .table-wrapper.vertical-table table tr td > span.select-text {
      cursor: pointer;
      display: block; }
    .table-wrapper.vertical-table table tr td .remark-text {
      font-size: 14px;
      color: #fe972c;
      cursor: pointer;
      min-width: 90px;
      display: inline-block; }
    .table-wrapper.vertical-table table tr td.message-wrapper {
      padding: 32px;
      text-align: center !important; }
      .table-wrapper.vertical-table table tr td.message-wrapper .text {
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #4a4a4a;
        margin-bottom: 20px; }
      .table-wrapper.vertical-table table tr td.message-wrapper .refer-earn-flat button {
        min-width: 230px;
        background: #ff901d;
        color: #fff; }
  .table-wrapper.vertical-table table tr th {
    color: #8a95aa; }
  .table-wrapper.vertical-table table tr td {
    border-bottom: solid 0.5px #e3e3e3; }

.crm-table-info-action-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 16px; }
  .crm-table-info-action-wrapper .text-wrap {
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a;
    font-size: 12px; }
    .crm-table-info-action-wrapper .text-wrap span {
      font-weight: 500;
      color: #000000; }
  .crm-table-info-action-wrapper .right-align {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end; }
    .crm-table-info-action-wrapper .right-align > div {
      display: flex;
      align-items: center; }
      .crm-table-info-action-wrapper .right-align > div .ant-btn:not(:last-child) {
        margin-right: .8rem; }
  .crm-table-info-action-wrapper .line {
    width: 1px;
    height: 17px;
    border: solid 1px #c4cfe4;
    margin: 0 16px; }
  .crm-table-info-action-wrapper .text {
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a; }
  .crm-table-info-action-wrapper .pagination {
    border-radius: 1.4px;
    border: solid 1px #c4cfe4;
    display: flex;
    margin-left: 16px; }
    .crm-table-info-action-wrapper .pagination p {
      padding: 4px 9px;
      cursor: pointer; }
      .crm-table-info-action-wrapper .pagination p:first-child {
        border-right: solid 0.7px #c4cfe4; }
      .crm-table-info-action-wrapper .pagination p img {
        max-width: 10px; }
  .crm-table-info-action-wrapper .refresh {
    width: 26px;
    height: 25px;
    border-radius: 1.4px;
    background-color: #ffffff;
    cursor: pointer; }
  .crm-table-info-action-wrapper .button {
    border-radius: 1px;
    border: solid 1px #f58b1e;
    background-color: #ffffff;
    padding: 4px 12px;
    font-size: 10px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #f58b1e;
    text-transform: none;
    max-width: 10rem;
    margin-left: 2rem; }

.ant-popover .ant-popover-inner-content {
  padding: .6rem; }

.table-menu {
  border: 0; }
  .table-menu .ant-menu-item {
    height: auto;
    line-height: 2;
    font-size: 1.2rem; }

.static {
  padding-top: 78px; }

@media screen and (max-width: 768px) {
  .static {
    padding: 68px 24px 24px; } }

.homepage-layout {
  position: relative;
  font-family: "Heebo"; }
  .homepage-layout .sticky {
    width: 100% !important;
    z-index: 998; }
  .homepage-layout .sticky-element {
    z-index: 998;
    position: fixed;
    width: 100%; }
    .homepage-layout .sticky-element .home {
      background: #fff; }
      .homepage-layout .sticky-element .home .nav-wrap a {
        color: #35353c;
        display: inline-block; }
        .homepage-layout .sticky-element .home .nav-wrap a.with-divider:before {
          border: solid 1px #000; }
      .homepage-layout .sticky-element .home .nav-wrap .ant-dropdown-link .dark {
        display: inline-block; }
      .homepage-layout .sticky-element .home .nav-wrap .ant-dropdown-link .light {
        display: none; }
      .homepage-layout .sticky-element .home .nav-wrap .ant-dropdown-link span {
        display: flex;
        align-items: center; }
        .homepage-layout .sticky-element .home .nav-wrap .ant-dropdown-link span img {
          margin-left: 4px;
          -webkit-transition-duration: 0.5s;
          -moz-transition-duration: 0.5s;
          -o-transition-duration: 0.5s;
          transition-duration: 0.5s;
          -webkit-transition-property: -webkit-transform;
          -moz-transition-property: -moz-transform;
          -o-transition-property: -o-transform;
          transition-property: transform; }
      .homepage-layout .sticky-element .home .nav-wrap .ant-dropdown-link.ant-dropdown-open span img {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg); }
      .homepage-layout .sticky-element .home .white-bordered {
        border: solid 1px #063bff;
        color: #063bff;
        background: transparent; }
    .homepage-layout .sticky-element.sticky .home {
      border-bottom: solid 1px #efefef;
      background-color: #ffffff; }
      .homepage-layout .sticky-element.sticky .home .nav-wrap a {
        color: #35353c; }
        .homepage-layout .sticky-element.sticky .home .nav-wrap a.with-divider:before {
          border: solid 1px #000; }
      .homepage-layout .sticky-element.sticky .home .nav-wrap .ant-dropdown-link .dark {
        display: inline-block; }
      .homepage-layout .sticky-element.sticky .home .nav-wrap .ant-dropdown-link .light {
        display: none; }
      .homepage-layout .sticky-element.sticky .home .white-bordered {
        border: solid 1px #063bff;
        color: #063bff;
        background: transparent; }
  .homepage-layout .header-wrapper .logo-wrap img {
    max-width: 100%;
    max-height: 45px;
    width: auto;
    margin-left: 15px; }
  .homepage-layout .header-wrapper .nav-wrap a.with-divider:before {
    border: solid 1px #fff; }
  .homepage-layout .banner-wrapper {
    background: #003366;
    background-size: cover;
    background-position: top center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 160px 0 140px 0; }
    .homepage-layout .banner-wrapper .scroll-text {
      font-size: 19px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.11;
      letter-spacing: normal;
      color: #ffffff;
      position: absolute;
      left: 0;
      bottom: -100px;
      cursor: pointer; }
    .homepage-layout .banner-wrapper .container {
      max-width: 1180px;
      margin: 0 auto;
      display: flex;
      position: relative; }
      .homepage-layout .banner-wrapper .container .content-wrap {
        flex-grow: 1;
        margin-right: 80px; }
        .homepage-layout .banner-wrapper .container .content-wrap .text-one {
          font-size: 28px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.65;
          letter-spacing: normal;
          color: #ffffff; }
        .homepage-layout .banner-wrapper .container .content-wrap .text-two {
          font-size: 75px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 0.88;
          letter-spacing: normal;
          color: #ffffff;
          margin-bottom: 12px; }
        .homepage-layout .banner-wrapper .container .content-wrap .text-three {
          font-size: 17px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.06;
          letter-spacing: normal;
          color: #ffffff;
          margin-bottom: 32px; }
          .homepage-layout .banner-wrapper .container .content-wrap .text-three span {
            font-weight: 500; }
      .homepage-layout .banner-wrapper .container .video-wrap {
        max-width: 510px;
        min-width: 510px;
        border-radius: 6px;
        background: url(images/homepage/video-thumbnail.webp) no-repeat;
        background-size: cover;
        background-position: center center;
        height: 360px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative; }
        .homepage-layout .banner-wrapper .container .video-wrap .thumbnail {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          object-fit: cover; }
        .homepage-layout .banner-wrapper .container .video-wrap img.play {
          width: 76px;
          height: 76px;
          object-fit: contain;
          cursor: pointer;
          position: absolute;
          z-index: 2;
          margin-top: -22px; }
  .homepage-layout .slogan2 {
    margin-bottom: 150px; }
    .homepage-layout .slogan2 .text-one {
      font-size: 36px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.9px;
      text-align: center;
      color: #2f3a4f;
      margin-bottom: 8px; }
    .homepage-layout .slogan2 .text-two {
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #262626; }
      .homepage-layout .slogan2 .text-two span {
        font-weight: normal;
        display: block; }
    .homepage-layout .slogan2 .row {
      display: flex;
      align-items: center; }
      .homepage-layout .slogan2 .row .text-one {
        text-align: left;
        margin-bottom: 22px; }
      .homepage-layout .slogan2 .row .text-two {
        text-align: left;
        max-width: 480px; }
      .homepage-layout .slogan2 .row .col {
        flex-basis: 100%;
        flex-grow: 1; }
        .homepage-layout .slogan2 .row .col:first-child {
          margin-right: 58px; }
          .homepage-layout .slogan2 .row .col:first-child .img-wrapper {
            display: flex;
            align-items: center; }
            .homepage-layout .slogan2 .row .col:first-child .img-wrapper .img-wrap {
              flex-grow: 1;
              flex-basis: 100%;
              margin: 0 4px 4px 0;
              overflow: hidden; }
              .homepage-layout .slogan2 .row .col:first-child .img-wrapper .img-wrap img {
                max-width: 100%;
                width: 168px;
                height: 160px;
                object-fit: cover; }
  .homepage-layout .section {
    padding: 48px 0;
    background-color: #f8f9fa; }
    .homepage-layout .section .button-wrap {
      text-align: center;
      margin-top: 40px; }
      .homepage-layout .section .button-wrap a {
        display: inline-block;
        margin: 12px 0; }
    .homepage-layout .section .container {
      max-width: 1078px;
      margin: 0 auto; }
    .homepage-layout .section .slider-wrap {
      margin-top: 42px; }
      .homepage-layout .section .slider-wrap .slick-list {
        padding: 12px; }
    .homepage-layout .section .title-text {
      font-size: 30px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.85px;
      text-align: center;
      color: #20211c;
      margin-bottom: 4px; }
      .homepage-layout .section .title-text span {
        font-weight: bold; }
    .homepage-layout .section .sub-text {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      text-align: center;
      color: #20211c; }
    .homepage-layout .section .topics-covered {
      padding: 45px 0;
      max-width: 1040px;
      margin: 0 auto; }
      .homepage-layout .section .topics-covered .title-text {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #35353c;
        margin-bottom: 20px;
        text-align: left; }
      .homepage-layout .section .topics-covered .topics {
        padding: 0;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 20px; }
        .homepage-layout .section .topics-covered .topics .topic {
          border-radius: 4px;
          border: solid 0.8px #dee2e6;
          background-color: #ffffff;
          padding: 18px 14px;
          position: relative; }
          .homepage-layout .section .topics-covered .topics .topic:before {
            content: "";
            width: 2px;
            height: 35px;
            border-radius: 1px;
            background-color: #063bff;
            position: absolute;
            left: -2px;
            top: 20px; }
          .homepage-layout .section .topics-covered .topics .topic .text-one {
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: 0.4px;
            color: #2b2b2b; }
          .homepage-layout .section .topics-covered .topics .topic .text-two {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.35px;
            color: #20211c; }
    .homepage-layout .section.one .container:first-child {
      margin-bottom: 80px; }
    .homepage-layout .section.two .container {
      max-width: 1080px;
      margin: 0 auto; }
    .homepage-layout .section.two .flex-wrap {
      display: flex;
      margin-top: 36px; }
      .homepage-layout .section.two .flex-wrap > div {
        flex-grow: 1;
        flex-basis: 100%; }
        .homepage-layout .section.two .flex-wrap > div:not(:last-child) {
          margin-right: 20px; }
        .homepage-layout .section.two .flex-wrap > div .img-wrap {
          height: 172px;
          opacity: 0.8;
          margin-bottom: 16px;
          position: relative;
          cursor: pointer;
          border-radius: 4px; }
          .homepage-layout .section.two .flex-wrap > div .img-wrap .play {
            position: absolute;
            width: 31px;
            height: 31px;
            right: 10px;
            bottom: 10px;
            cursor: pointer; }
          .homepage-layout .section.two .flex-wrap > div .img-wrap img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 4px; }
        .homepage-layout .section.two .flex-wrap > div .content-wrap p {
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.45px;
          color: #20211c; }
        .homepage-layout .section.two .flex-wrap > div:not(:last-child) {
          margin-right: 20px; }
    .homepage-layout .section.two .experts-wrap {
      margin-top: 64px; }
      .homepage-layout .section.two .experts-wrap .text {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        text-align: center;
        color: #20211c; }
    .homepage-layout .section.two .img-list {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 36px; }
      .homepage-layout .section.two .img-list img {
        max-height: 55px; }
        .homepage-layout .section.two .img-list img:not(:last-child) {
          margin-right: 60px; }
    .homepage-layout .section.three {
      background: #fff; }
      .homepage-layout .section.three .container {
        max-width: 1080px;
        margin: 0 auto; }
      .homepage-layout .section.three .flex-wrap {
        display: flex;
        margin-top: 36px; }
        .homepage-layout .section.three .flex-wrap > div {
          flex-grow: 1;
          flex-basis: 100%; }
          .homepage-layout .section.three .flex-wrap > div:not(:last-child) {
            margin-right: 82px; }
          .homepage-layout .section.three .flex-wrap > div .img-wrap {
            opacity: 0.8;
            margin-bottom: 8px; }
            .homepage-layout .section.three .flex-wrap > div .img-wrap img {
              height: 135px; }
          .homepage-layout .section.three .flex-wrap > div .content-wrap .text-one {
            font-size: 18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #20211c;
            margin-bottom: 10px; }
          .homepage-layout .section.three .flex-wrap > div .content-wrap .text-two {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.45px;
            color: #61625a; }
    .homepage-layout .section.four {
      background: #475a65;
      padding: 48px 0 80px 0; }
      .homepage-layout .section.four .title-text {
        color: #fff;
        margin-bottom: 48px; }
      .homepage-layout .section.four .cards-wrapper .slick-slide {
        padding-right: 20px; }
      .homepage-layout .section.four .proj-card {
        background: #fff;
        border-radius: 4px; }
        .homepage-layout .section.four .proj-card .img-wrap {
          height: 180px;
          margin-bottom: 16px;
          cursor: pointer; }
          .homepage-layout .section.four .proj-card .img-wrap img {
            border-radius: 4px 4px 0 0;
            width: 100%;
            height: 100%;
            object-fit: cover; }
        .homepage-layout .section.four .proj-card .text-content {
          padding: 0 15px 20px 15px; }
        .homepage-layout .section.four .proj-card .text-one {
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.45px;
          color: #20211c;
          margin-bottom: 9px; }
        .homepage-layout .section.four .proj-card .text-two {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: normal;
          color: #999b90; }
    .homepage-layout .section.five {
      background: #fff; }
      .homepage-layout .section.five .container {
        max-width: 1080px;
        margin: 0 auto; }
      .homepage-layout .section.five .title-text {
        margin-bottom: 72px; }
      .homepage-layout .section.five .flex-wrap {
        display: flex;
        align-items: flex-start;
        margin-bottom: 70px; }
        .homepage-layout .section.five .flex-wrap > div {
          flex-grow: 1;
          flex-basis: 100%;
          text-align: center; }
          .homepage-layout .section.five .flex-wrap > div .text-one {
            font-size: 44px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.6;
            letter-spacing: normal;
            text-align: center;
            color: #20211c;
            margin-bottom: 16px; }
          .homepage-layout .section.five .flex-wrap > div .text-two {
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.22;
            letter-spacing: normal;
            text-align: center;
            color: #61625a; }
      .homepage-layout .section.five .testimonial-wrapper .slick-slide {
        margin-right: 20px; }
      .homepage-layout .section.five .video-wrapper {
        display: flex;
        margin-bottom: 40px;
        height: 280px; }
        .homepage-layout .section.five .video-wrapper .video-card {
          flex-grow: 1;
          flex-basis: 100%; }
          .homepage-layout .section.five .video-wrapper .video-card:not(:last-child) {
            margin-right: 20px; }
      .homepage-layout .section.five .video-card {
        border-radius: 2px;
        background-color: rgba(6, 59, 255, 0.34);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 380px;
        position: relative; }
        .homepage-layout .section.five .video-card .play {
          position: absolute;
          width: 100px;
          height: 100px;
          margin: auto;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          cursor: pointer; }
        .homepage-layout .section.five .video-card img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
    .homepage-layout .section.six .title-text {
      margin-bottom: 2px; }
    .homepage-layout .section.six .sub-text {
      margin-bottom: 28px;
      font-size: 16px;
      font-weight: normal; }
    .homepage-layout .section.six .container {
      margin: 0 auto; }
    .homepage-layout .section.seven {
      background-color: #456ee6;
      padding: 30px; }
      .homepage-layout .section.seven .container {
        max-width: 1080px; }
  .homepage-layout .carousel-wrapper .slick-track {
    display: flex !important; }
  .homepage-layout .carousel-wrapper .slick-slide {
    height: inherit !important; }
  .homepage-layout .carousel-wrapper button.slick-arrow {
    border-radius: 0;
    border: 0;
    background: url(images/static/arrow-right.svg) no-repeat;
    color: transparent;
    padding: 0;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.16px;
    text-align: center;
    outline: none;
    cursor: pointer;
    width: 104px;
    height: 104px;
    position: absolute;
    right: -100px;
    background-size: 99% 99%; }
    .homepage-layout .carousel-wrapper button.slick-arrow:before {
      display: none; }
    .homepage-layout .carousel-wrapper button.slick-arrow.slick-disabled {
      opacity: 0.2; }
  .homepage-layout .carousel-wrapper button.slick-prev {
    left: -100px;
    background: url(images/static/arrow-left.svg) no-repeat;
    background-size: 99% 99%; }
  .homepage-layout .ant-tabs {
    overflow: inherit; }
    .homepage-layout .ant-tabs .ant-tabs-bar {
      border: 0;
      margin-bottom: 32px; }
      .homepage-layout .ant-tabs .ant-tabs-bar .ant-tabs-nav-scroll {
        text-align: center; }
        .homepage-layout .ant-tabs .ant-tabs-bar .ant-tabs-nav-scroll .ant-tabs-ink-bar {
          display: none !important; }
        .homepage-layout .ant-tabs .ant-tabs-bar .ant-tabs-nav-scroll .ant-tabs-nav {
          border-radius: 2px;
          background-color: #eff1f3;
          padding: 3px; }
        .homepage-layout .ant-tabs .ant-tabs-bar .ant-tabs-nav-scroll .ant-tabs-tab {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #35353c;
          padding: 14px 32px;
          position: relative;
          margin: 0; }
          .homepage-layout .ant-tabs .ant-tabs-bar .ant-tabs-nav-scroll .ant-tabs-tab:after {
            content: "";
            opacity: 0.3;
            background: #c6cdd8;
            height: calc(100% - 6px);
            width: 1px; }
          .homepage-layout .ant-tabs .ant-tabs-bar .ant-tabs-nav-scroll .ant-tabs-tab.ant-tabs-tab-active {
            border-radius: 2px;
            background-color: #ffffff;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #063bff;
            padding: 12px 32px; }

.details-layout {
  padding-top: 78px; }
  .details-layout .header {
    background-color: #ffffff;
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    margin: 0;
    z-index: 99; }
    .details-layout .header .menu-wrap a {
      font-size: 16px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #35353c;
      text-shadow: none; }
    .details-layout .header .menu-wrap .button {
      background: #063bff;
      text-transform: none;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      min-width: 130px;
      padding: 12px;
      border-radius: 4px; }
    .details-layout .header .img-wrap img {
      max-width: 60px; }
    .details-layout .header .container {
      width: 100%;
      max-width: 100%;
      display: flex;
      align-items: center;
      padding: 15px 20px; }
  .details-layout .section.four {
    background: #475a65; }
    .details-layout .section.four .slick-prev,
    .details-layout .section.four .slick-next {
      display: none !important; }
    .details-layout .section.four .tutorial-card {
      max-width: 340px; }
  .details-layout .details-content {
    border-radius: 4px;
    background-color: #f8f9fa;
    padding: 30px; }
    .details-layout .details-content.teach-with-us {
      background: #fff; }
      .details-layout .details-content.teach-with-us .banner-section {
        background: url(images/homepage/teach-banner.png) no-repeat;
        background-size: 100%;
        padding: 30px 20px 30px 40px;
        display: flex;
        align-items: flex-end;
        position: relative; }
        .details-layout .details-content.teach-with-us .banner-section .card {
          bottom: auto;
          top: 80%;
          position: absolute;
          right: 20px; }
          .details-layout .details-content.teach-with-us .banner-section .card .text-one {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: normal;
            color: #2b2b2b; }
          .details-layout .details-content.teach-with-us .banner-section .card .text-two {
            font-size: 18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.22;
            letter-spacing: normal;
            color: #063bff; }
          .details-layout .details-content.teach-with-us .banner-section .card .mb-24 {
            margin-bottom: 24px; }
        .details-layout .details-content.teach-with-us .banner-section .sub-text {
          font-size: 30px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.27;
          letter-spacing: normal;
          color: #ffffff; }
        .details-layout .details-content.teach-with-us .banner-section .text {
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.11;
          letter-spacing: normal;
          color: #ffffff; }
      .details-layout .details-content.teach-with-us .description-section {
        max-width: 640px; }
        .details-layout .details-content.teach-with-us .description-section .text-one {
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          color: #20211c;
          margin-bottom: 10px;
          text-align: left;
          line-height: 1.4; }
        .details-layout .details-content.teach-with-us .description-section .text-two {
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.45px;
          color: #2b2b2b;
          text-align: left;
          line-height: 1.4; }
    .details-layout .details-content .container {
      max-width: 1080px;
      margin: 0 auto; }
    .details-layout .details-content .banner-section {
      background: url(images/homepage/course-banner.png) no-repeat;
      background-size: 100% 100%;
      padding: 90px 20px 80px 40px;
      display: flex;
      align-items: flex-end;
      position: relative; }
      .details-layout .details-content .banner-section > div:first-child {
        flex-grow: 1;
        padding-right: 40px; }
      .details-layout .details-content .banner-section .title-text {
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
        font-size: 32px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff; }
      .details-layout .details-content .banner-section .text {
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        color: #ffffff;
        max-width: 420px; }
      .details-layout .details-content .banner-section .buttons-wrap {
        display: flex;
        align-items: center;
        margin-top: 16px; }
        .details-layout .details-content .banner-section .buttons-wrap button {
          border: 0;
          border-radius: 4px;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff;
          padding: 10px 20px;
          cursor: pointer;
          outline: none; }
          .details-layout .details-content .banner-section .buttons-wrap button.white {
            background-color: #ffffff;
            color: #063bff;
            margin-right: 12px; }
          .details-layout .details-content .banner-section .buttons-wrap button.orange {
            background-color: #ff8f00;
            color: #ffffff; }
      .details-layout .details-content .banner-section .card {
        min-width: 370px;
        max-width: 370px;
        border-radius: 4px;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.14);
        background-color: #ffffff;
        padding: 30px 20px;
        position: absolute;
        right: 32px;
        top: calc(100% - 180px); }
        .details-layout .details-content .banner-section .card .custom-tab .tabs .tab-background {
          width: calc(100% - 6px); }
          .details-layout .details-content .banner-section .card .custom-tab .tabs .tab-background.multiple {
            width: calc(50% - 3px); }
        .details-layout .details-content .banner-section .card .helper-text {
          margin: 1rem 0;
          text-align: center; }
        .details-layout .details-content .banner-section .card button {
          width: 100%; }
        .details-layout .details-content .banner-section .card .flex-wrap {
          display: flex;
          align-items: center; }
    .details-layout .details-content .description-section {
      margin: 80px 0 0 0;
      padding: 20px;
      max-width: 580px;
      min-height: 280px; }
      .details-layout .details-content .description-section .text-one {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.4px;
        text-align: left;
        color: #2b2b2b;
        white-space: pre-line;
        line-height: 1.4; }
      .details-layout .details-content .description-section .text-two {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.5px;
        color: #2a2a2a;
        text-align: left;
        margin-bottom: 24px;
        line-height: 1.4; }
        .details-layout .details-content .description-section .text-two.mb-12 {
          margin-bottom: 12px; }
      .details-layout .details-content .description-section .left-section {
        margin-right: 72px;
        flex-grow: 1; }
      .details-layout .details-content .description-section .right-section {
        min-width: 350px; }
  .details-layout .section {
    padding: 48px 0;
    background-color: #f8f9fa; }
    .details-layout .section .container {
      max-width: 1078px;
      margin: 0 auto; }
    .details-layout .section .slider-wrap {
      margin-top: 42px; }
      .details-layout .section .slider-wrap .slick-list {
        padding: 12px; }
    .details-layout .section .title-text {
      font-size: 30px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.85px;
      text-align: center;
      color: #20211c;
      margin-bottom: 4px; }
      .details-layout .section .title-text span {
        font-weight: bold; }
    .details-layout .section .sub-text {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      text-align: center;
      color: #20211c; }
    .details-layout .section .topics-covered {
      padding: 45px 0;
      max-width: 1040px;
      margin: 0 auto; }
      .details-layout .section .topics-covered .title-text {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #35353c;
        margin-bottom: 20px;
        text-align: left; }
      .details-layout .section .topics-covered .topics {
        padding: 0;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 20px; }
        .details-layout .section .topics-covered .topics .topic {
          border-radius: 4px;
          border: solid 0.8px #dee2e6;
          background-color: #ffffff;
          padding: 18px 14px;
          position: relative; }
          .details-layout .section .topics-covered .topics .topic:before {
            content: "";
            width: 2px;
            height: 35px;
            border-radius: 1px;
            background-color: #063bff;
            position: absolute;
            left: -2px;
            top: 20px; }
          .details-layout .section .topics-covered .topics .topic .text-one {
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: 0.4px;
            color: #2b2b2b; }
          .details-layout .section .topics-covered .topics .topic .text-two {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.35px;
            color: #20211c; }
    .details-layout .section.one .container:first-child {
      margin-bottom: 80px; }
    .details-layout .section.two .container {
      max-width: 1080px;
      margin: 0 auto; }
    .details-layout .section.two .flex-wrap {
      display: flex;
      margin-top: 36px; }
      .details-layout .section.two .flex-wrap > div {
        flex-grow: 1;
        flex-basis: 100%; }
        .details-layout .section.two .flex-wrap > div:not(:last-child) {
          margin-right: 20px; }
        .details-layout .section.two .flex-wrap > div .img-wrap {
          height: 172px;
          opacity: 0.8;
          margin-bottom: 16px;
          position: relative;
          cursor: pointer;
          border-radius: 4px; }
          .details-layout .section.two .flex-wrap > div .img-wrap .play {
            position: absolute;
            width: 31px;
            height: 31px;
            right: 10px;
            bottom: 10px;
            cursor: pointer; }
          .details-layout .section.two .flex-wrap > div .img-wrap img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 4px; }
        .details-layout .section.two .flex-wrap > div .content-wrap p {
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.45px;
          color: #20211c; }
        .details-layout .section.two .flex-wrap > div:not(:last-child) {
          margin-right: 20px; }
    .details-layout .section.two .experts-wrap {
      margin-top: 64px; }
      .details-layout .section.two .experts-wrap .text {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        text-align: center;
        color: #20211c; }
    .details-layout .section.two .img-list {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 36px; }
      .details-layout .section.two .img-list img {
        max-height: 46px; }
        .details-layout .section.two .img-list img:not(:last-child) {
          margin-right: 60px; }
    .details-layout .section.three {
      background: #fff; }
      .details-layout .section.three .container {
        max-width: 1080px;
        margin: 0 auto; }
      .details-layout .section.three .flex-wrap {
        display: flex;
        margin-top: 36px; }
        .details-layout .section.three .flex-wrap > div {
          flex-grow: 1;
          flex-basis: 100%; }
          .details-layout .section.three .flex-wrap > div:not(:last-child) {
            margin-right: 82px; }
          .details-layout .section.three .flex-wrap > div .img-wrap {
            opacity: 0.8;
            margin-bottom: 8px; }
            .details-layout .section.three .flex-wrap > div .img-wrap img {
              height: 135px; }
          .details-layout .section.three .flex-wrap > div .content-wrap .text-one {
            font-size: 18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #20211c;
            margin-bottom: 10px; }
          .details-layout .section.three .flex-wrap > div .content-wrap .text-two {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.45px;
            color: #61625a; }
    .details-layout .section.four {
      background: #475a65;
      padding: 48px 0 80px 0; }
      .details-layout .section.four .title-text {
        color: #fff;
        margin-bottom: 48px; }
      .details-layout .section.four .cards-wrapper .slick-slide {
        padding-right: 20px; }
      .details-layout .section.four .proj-card {
        background: #fff;
        border-radius: 4px; }
        .details-layout .section.four .proj-card .img-wrap {
          height: 180px;
          margin-bottom: 16px;
          cursor: pointer; }
          .details-layout .section.four .proj-card .img-wrap img {
            border-radius: 4px 4px 0 0;
            width: 100%;
            height: 100%;
            object-fit: cover; }
        .details-layout .section.four .proj-card .text-content {
          padding: 0 15px 20px 15px; }
        .details-layout .section.four .proj-card .text-one {
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.45px;
          color: #20211c;
          margin-bottom: 9px; }
        .details-layout .section.four .proj-card .text-two {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: normal;
          color: #999b90; }
    .details-layout .section.five {
      background: #fff; }
      .details-layout .section.five .container {
        max-width: 1080px;
        margin: 0 auto; }
      .details-layout .section.five .title-text {
        margin-bottom: 72px; }
      .details-layout .section.five .flex-wrap {
        display: flex;
        align-items: flex-start;
        margin-bottom: 70px; }
        .details-layout .section.five .flex-wrap > div {
          flex-grow: 1;
          flex-basis: 100%;
          text-align: center; }
          .details-layout .section.five .flex-wrap > div .text-one {
            font-size: 44px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.6;
            letter-spacing: normal;
            text-align: center;
            color: #20211c;
            margin-bottom: 16px; }
          .details-layout .section.five .flex-wrap > div .text-two {
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.22;
            letter-spacing: normal;
            text-align: center;
            color: #61625a; }
      .details-layout .section.five .testimonial-wrapper .slick-slide {
        margin-right: 20px; }
      .details-layout .section.five .video-wrapper {
        display: flex;
        margin-bottom: 40px;
        height: 280px; }
        .details-layout .section.five .video-wrapper .video-card {
          flex-grow: 1;
          flex-basis: 100%; }
          .details-layout .section.five .video-wrapper .video-card:not(:last-child) {
            margin-right: 20px; }
      .details-layout .section.five .video-card {
        border-radius: 2px;
        background-color: rgba(6, 59, 255, 0.34);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 380px;
        position: relative; }
        .details-layout .section.five .video-card .play {
          position: absolute;
          width: 100px;
          height: 100px;
          margin: auto;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          cursor: pointer; }
        .details-layout .section.five .video-card img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
    .details-layout .section.six .title-text {
      margin-bottom: 2px; }
    .details-layout .section.six .sub-text {
      margin-bottom: 28px;
      font-size: 16px;
      font-weight: normal; }
    .details-layout .section.six .container {
      margin: 0 auto; }
    .details-layout .section.seven {
      background-color: #456ee6;
      padding: 30px; }
      .details-layout .section.seven .container {
        max-width: 1080px; }
  .details-layout .button-wrap {
    text-align: center;
    margin: 70px 0 0; }
    .details-layout .button-wrap button {
      margin: 0 8px; }
    .details-layout .button-wrap a {
      margin: 0 0 12px 0;
      display: inline-block; }

.company-details {
  display: flex;
  margin: 0 auto;
  width: 100%; }
  .company-details .col {
    flex-grow: 1; }
    .company-details .col:last-child {
      max-width: 340px; }
    .company-details .col:nth-child(2) {
      max-width: 280px; }
    .company-details .col .text-one {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.45px;
      color: #fff; }
      .company-details .col .text-one a {
        color: inherit; }
    .company-details .col .text-two {
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #fff; }
      .company-details .col .text-two a {
        color: inherit; }
      .company-details .col .text-two span {
        display: inline-block;
        vertical-align: middle; }
        .company-details .col .text-two span:not(:last-child) {
          margin-right: 6px; }
    .company-details .col:not(:last-child) {
      margin-right: 24px; }

.header-wrapper {
  padding: 14px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  position: fixed;
  background: #fff;
  z-index: 9;
  left: 0;
  top: 0;
  right: 0;
  transition: all 0.2s linear; }
  .header-wrapper .menu {
    display: none; }
  .header-wrapper .logo-wrap img {
    max-height: 50px; }
  .header-wrapper .nav-wrap {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end; }
    .header-wrapper .nav-wrap a {
      font-size: 16px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #35353c;
      margin-right: 35px;
      position: relative;
      display: flex;
      align-items: center; }
      .header-wrapper .nav-wrap a:hover {
        opacity: 0.6; }
      .header-wrapper .nav-wrap a .free {
        background: url(images/free-label.svg) no-repeat;
        width: 33px;
        height: 22px;
        background-size: cover;
        background-position: center center;
        display: inline-block;
        position: absolute;
        left: -8px;
        top: -16px;
        font-size: 11px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        text-align: center; }
      .header-wrapper .nav-wrap a.with-divider {
        margin-left: 35px; }
        .header-wrapper .nav-wrap a.with-divider:before {
          content: "";
          position: absolute;
          left: -35px;
          height: 10px;
          border: solid 1px #000;
          top: 6px; }
      .header-wrapper .nav-wrap a.active:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        background: #063bff; }
    .header-wrapper .nav-wrap button:not(:last-child) {
      margin-right: 15px; }
    .header-wrapper .nav-wrap .ant-dropdown-link .dark {
      display: none; }
    .header-wrapper .nav-wrap .ant-dropdown-link .light {
      display: inline-block; }
    .header-wrapper .nav-wrap .ant-dropdown-link span {
      display: flex;
      align-items: center; }
      .header-wrapper .nav-wrap .ant-dropdown-link span img {
        margin-left: 4px;
        -webkit-transition-duration: 0.5s;
        -moz-transition-duration: 0.5s;
        -o-transition-duration: 0.5s;
        transition-duration: 0.5s;
        -webkit-transition-property: -webkit-transform;
        -moz-transition-property: -moz-transform;
        -o-transition-property: -o-transform;
        transition-property: transform; }
    .header-wrapper .nav-wrap .ant-dropdown-link.ant-dropdown-open span img {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -o-transform: rotate(180deg); }

button,
.button {
  border-radius: 4px;
  outline: none;
  padding: 12px 36px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  background: transparent;
  border: 0;
  cursor: pointer;
  border: solid 1px #063bff;
  background-image: none;
  transition: all 0.5s linear; }
  button.bordered,
  .button.bordered {
    border: solid 1px #063bff;
    color: #063bff;
    background: transparent; }
  button.blue-bordered,
  .button.blue-bordered {
    border: solid 1px #063bff;
    color: #063bff;
    background: transparent; }
  button.grey-bordered,
  .button.grey-bordered {
    color: #063bff;
    border: solid 1px #a9b1c1; }
  button.white-bordered,
  .button.white-bordered {
    border: solid 1px #fff;
    background: transparent;
    color: #fff; }
  button.orange,
  .button.orange {
    background: #ff8f00;
    color: #ffffff;
    border: solid 1px #ff8f00; }
    button.orange:hover,
    .button.orange:hover {
      background: #ffaa3c;
      border: solid 1px #ffaa3c; }
  button.blue,
  .button.blue {
    border: solid 1px #063bff;
    color: #fff;
    background: #063bff; }
    button.blue:hover,
    .button.blue:hover {
      background: #2956fd;
      border: solid 1px #2956fd; }
  button.small,
  .button.small {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    padding: 0.6rem 2rem; }
  button.white,
  .button.white {
    background-color: #ffffff;
    color: #063bff;
    border: 1px solid #fff; }

a.button {
  color: inherit;
  text-decoration: none; }

.nav-dropdown {
  width: 19rem;
  padding: 10px; }
  .nav-dropdown .ant-dropdown-menu-item {
    padding: 0; }
  .nav-dropdown li {
    margin-bottom: 4px;
    border-radius: 4px;
    width: 100%; }
    .nav-dropdown li a {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.4px;
      color: #20211c;
      width: 100%;
      height: 100%;
      display: block;
      padding: 8px 16px;
      margin: 0; }
      .nav-dropdown li a.active {
        background-color: #ececf8;
        border-radius: 4px; }
      .nav-dropdown li a:hover {
        background-color: #ececf8;
        border-radius: 4px; }
    .nav-dropdown li:hover {
      background-color: inherit; }

.form-component .dial-code {
  border: solid 0.8px #dee2e6; }

.form-component .orange {
  background: #ff5b13;
  color: #ffffff;
  border: solid 1px #ff5b13; }

.form-component .component {
  max-width: 540px;
  margin: 0 auto;
  margin-top: 50px; }
  .form-component .component .error-text {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #f70909;
    text-align: center; }

.form-component .title-text {
  font-size: 34px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.85px;
  text-align: center;
  color: #20211c;
  margin-bottom: 4px; }
  .form-component .title-text span {
    font-weight: bold; }

.form-component .sub-text {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: center;
  color: #20211c; }

.form-component .row {
  display: flex;
  margin-bottom: 15px; }
  .form-component .row.center {
    justify-content: center; }
  .form-component .row.tnc {
    display: block; }
  .form-component .row .col {
    flex-grow: 1;
    flex-basis: 100%;
    display: flex;
    position: relative;
    flex-direction: column; }
    .form-component .row .col .ant-tooltip {
      right: 10px;
      top: calc(50% - 5px); }
    .form-component .row .col:not(:last-child) {
      margin-right: 20px; }
    .form-component .row .col .ant-radio-wrapper {
      white-space: pre-wrap; }
    .form-component .row .col .react-tel-input {
      font-family: "Heebo";
      font-size: 14px;
      border-radius: 2px; }
      .form-component .row .col .react-tel-input.error {
        border: solid 1px red; }
      .form-component .row .col .react-tel-input .search {
        padding: 12px; }
      .form-component .row .col .react-tel-input .search-box {
        background-color: #fff;
        border: solid 0.8px #dee2e6;
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #222;
        font-family: "Heebo"; }
        .form-component .row .col .react-tel-input .search-box::-webkit-input-placeholder {
          /* Edge */
          opacity: 0.8;
          font-size: 14px;
          font-weight: 400;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #222; }
        .form-component .row .col .react-tel-input .search-box:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          opacity: 0.8;
          font-size: 14px;
          font-weight: 400;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #222; }
        .form-component .row .col .react-tel-input .search-box::placeholder {
          opacity: 0.8;
          font-size: 14px;
          font-weight: 400;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #222; }
      .form-component .row .col .react-tel-input .flag-dropdown {
        background-color: #fff;
        border: solid 0.8px #dee2e6; }
      .form-component .row .col .react-tel-input .form-control {
        font-family: "Heebo";
        width: 100%;
        height: auto;
        border-radius: 2px;
        border: solid 0.8px #dee2e6;
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #222;
        width: 100%;
        outline: none;
        padding: 6px 12px 6px 48px; }
        .form-component .row .col .react-tel-input .form-control::-webkit-input-placeholder {
          font-family: "Heebo";
          /* Edge */
          opacity: 0.8;
          font-size: 14px;
          font-weight: 400;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #222; }
        .form-component .row .col .react-tel-input .form-control:-ms-input-placeholder {
          font-family: "Heebo";
          /* Internet Explorer 10-11 */
          opacity: 0.8;
          font-size: 14px;
          font-weight: 400;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #222; }
        .form-component .row .col .react-tel-input .form-control::placeholder {
          font-family: "Heebo";
          opacity: 0.8;
          font-size: 14px;
          font-weight: 400;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #222; }
    .form-component .row .col input[type="text"],
    .form-component .row .col input[type="number"],
    .form-component .row .col textarea {
      border-radius: 2px;
      border: solid 0.8px #dee2e6;
      font-size: 14px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #222;
      width: 100%;
      outline: none;
      padding: 8px 15px; }
      .form-component .row .col input[type="text"].error,
      .form-component .row .col input[type="number"].error,
      .form-component .row .col textarea.error {
        border: solid 1px red; }
      .form-component .row .col input[type="text"]::-webkit-input-placeholder,
      .form-component .row .col input[type="number"]::-webkit-input-placeholder,
      .form-component .row .col textarea::-webkit-input-placeholder {
        /* Edge */
        opacity: 0.8;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #222; }
      .form-component .row .col input[type="text"]:-ms-input-placeholder,
      .form-component .row .col input[type="number"]:-ms-input-placeholder,
      .form-component .row .col textarea:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        opacity: 0.8;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #222; }
      .form-component .row .col input[type="text"]::placeholder,
      .form-component .row .col input[type="number"]::placeholder,
      .form-component .row .col textarea::placeholder {
        opacity: 0.8;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #222; }
    .form-component .row .col .label {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #525f78;
      display: block;
      margin-bottom: 6px; }
    .form-component .row .col .ant-checkbox-group > label {
      display: block;
      margin-bottom: 12px; }
    .form-component .row .col .select-dropdown {
      border: solid 0.8px #dee2e6; }
      .form-component .row .col .select-dropdown.error {
        border: solid 0.8px #f53636; }
      .form-component .row .col .select-dropdown select {
        padding: 9px 15px; }
    .form-component .row .col textarea {
      min-height: 115px; }

.class-card {
  border-radius: 4px;
  box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  padding: 0;
  max-width: 340px;
  position: relative;
  font-family: "Heebo";
  cursor: pointer;
  transition: transform 0.2s;
  /* Animation */ }
  .class-card:hover {
    transform: scale(1.025); }
  .class-card .bottom-section {
    margin-top: 20px;
    padding: 20px;
    text-align: center; }
    .class-card .bottom-section button {
      width: 100%;
      margin-bottom: 20px; }
    .class-card .bottom-section p.link {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.57px;
      text-align: center;
      color: #063bff;
      cursor: pointer; }
      .class-card .bottom-section p.link a {
        color: #063bff; }
  .class-card.one .img-wrap {
    background: #f94267 url(images/homepage/class-card-one.png) no-repeat;
    background-size: 100% 100%; }
  .class-card.two .img-wrap {
    background: #5c48fb url(images/homepage/class-card-two.png) no-repeat;
    background-size: 100% 100%; }
  .class-card.three .img-wrap {
    background: #524bde url(images/homepage/class-card-three.png) no-repeat;
    background-size: 100% 100%; }
  .class-card.four .img-wrap {
    background: #1b9dd1 url(images/homepage/class-card-four.png) no-repeat;
    background-size: 100% 100%; }
  .class-card.five .img-wrap {
    background: #e732c4 url(images/homepage/class-card-five.png) no-repeat;
    background-size: 100% 100%; }
  .class-card.six .img-wrap {
    background: rgba(82, 95, 120, 0.22) url(images/homepage/class-card-six.png) no-repeat;
    background-size: 100% 100%; }
  .class-card .img-wrap {
    border-radius: 2px;
    position: relative;
    width: 100%; }
    .class-card .img-wrap > div {
      position: relative;
      z-index: 1;
      padding: 36px 20px 15px 20px; }
    .class-card .img-wrap .text-one {
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
      font-size: 22px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.18;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      margin-bottom: 9px; }
    .class-card .img-wrap .text-two {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      display: block;
      display: -webkit-box;
      max-width: 100%;
      margin: 0 auto;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 64px;
      max-width: 280px;
      margin: 0 auto; }
    .class-card .img-wrap .text-three {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #ffffff;
      display: flex;
      align-items: center; }
      .class-card .img-wrap .text-three img {
        max-width: 1.3rem;
        margin-right: 0.6rem; }
    .class-card .img-wrap .flex-wrap {
      display: flex;
      align-items: center;
      padding: 0; }
      .class-card .img-wrap .flex-wrap > p {
        flex-grow: 1;
        flex-basis: 100%; }
        .class-card .img-wrap .flex-wrap > p:first-child {
          justify-content: flex-end;
          padding-right: 15px; }
        .class-card .img-wrap .flex-wrap > p:last-child {
          justify-content: flex-start;
          padding-left: 15px; }
    .class-card .img-wrap .line {
      height: 1px;
      background-color: rgba(0, 0, 0, 0.12);
      margin: 14px 0; }
    .class-card .img-wrap > img {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 0; }
  .class-card .content {
    padding: 16px 20px 20px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: normal;
    text-align: center;
    color: #2b2b2b;
    height: 88px;
    overflow: hidden;
    /* Track */
    /* Handle */
    /* Handle on hover */ }
    .class-card .content.lingoq {
      height: 88px;
      overflow: hidden;
      display: none; }
    .class-card .content::-webkit-scrollbar {
      width: 1px; }
    .class-card .content::-webkit-scrollbar-track {
      background: #f1f1f1; }
    .class-card .content::-webkit-scrollbar-thumb {
      background: #fafafa; }
    .class-card .content::-webkit-scrollbar-thumb:hover {
      background: #fefefe; }
  .class-card .icons-wrap {
    padding: 0 20px;
    text-align: center;
    height: 90px;
    overflow: hidden; }
    .class-card .icons-wrap .icon {
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      margin: 7.5px 8px; }
      .class-card .icons-wrap .icon img {
        max-height: 26px;
        max-width: 100%;
        display: inline-block; }
  .class-card .tags-wrap {
    text-align: center;
    padding: 0 18px;
    margin-top: 20px; }
    .class-card .tags-wrap .tag {
      display: inline-flex;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #d38e00;
      padding: 8px 12px;
      border-radius: 4px;
      background-color: #fffaec;
      margin: 0 8px 8px 0; }
      .class-card .tags-wrap .tag img {
        margin-right: 4px;
        max-height: 13px;
        display: inline-block;
        vertical-align: middle; }
  .class-card .text-four {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: right;
    color: #2b2b2b;
    margin-top: 28px;
    display: flex; }
    .class-card .text-four span {
      flex-grow: 1;
      flex-basis: 100%; }
      .class-card .text-four span:last-child {
        text-align: right; }
  .class-card .mt-28 {
    margin-top: 28px; }
  .class-card .line {
    height: 1px;
    opacity: 0.2;
    background: #525f78;
    margin: 14px auto 18px auto;
    width: calc(100% - 40px); }
  .class-card .pricing-wrap {
    border-radius: 8px;
    border: solid 1px #d5dce4;
    display: flex;
    padding: 8px 0px;
    max-width: calc(100% - 20px);
    margin: 10px auto auto; }
    .class-card .pricing-wrap .pricing {
      flex-basis: 100%;
      flex-grow: 1;
      padding: 0 8px; }
      .class-card .pricing-wrap .pricing:not(:last-child) {
        border-right: solid 1px #e3e4ec; }
      .class-card .pricing-wrap .pricing .text-one {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        text-align: center;
        color: #2f3a4f; }
        .class-card .pricing-wrap .pricing .text-one span {
          border-left: solid 1px #e3e4ec;
          padding-left: 5px; }
      .class-card .pricing-wrap .pricing .text-two {
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ff6237;
        text-align: center; }
      .class-card .pricing-wrap .pricing .text-session {
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #2f3a4f;
        text-align: center; }
  .class-card .flex-wrap {
    display: flex;
    align-items: center;
    padding: 0 20px 20px; }
    .class-card .flex-wrap button {
      display: block;
      margin-bottom: 20px;
      width: 100%; }

.testimonial-card {
  padding: 30px 20px 40px 20px;
  border-radius: 2px;
  border: solid 1px #c3c9d0;
  background-color: #ffffff;
  background: url(images/gallery/quote-grey.png) no-repeat;
  background-size: 15px 32px;
  background-position: calc(100% - 20px) 30px;
  box-shadow: none; }
  .testimonial-card .user-wrap {
    display: flex;
    margin-bottom: 32px;
    align-items: center; }
    .testimonial-card .user-wrap .dp {
      width: 50px;
      height: 50px;
      margin-right: 20px; }
      .testimonial-card .user-wrap .dp img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100%; }
    .testimonial-card .user-wrap .content-wrap .name {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2b2b2b; }
    .testimonial-card .user-wrap .content-wrap .desc {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #464646; }
  .testimonial-card .text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    color: #464646;
    margin: 0; }
  .testimonial-card .text-wrap {
    height: 220px;
    overflow-y: auto;
    /* Track */
    /* Handle */
    /* Handle on hover */ }
    .testimonial-card .text-wrap::-webkit-scrollbar {
      width: 1px; }
    .testimonial-card .text-wrap::-webkit-scrollbar-track {
      background: #f1f1f1; }
    .testimonial-card .text-wrap::-webkit-scrollbar-thumb {
      background: #fafafa; }
    .testimonial-card .text-wrap::-webkit-scrollbar-thumb:hover {
      background: #fefefe; }

.company-info {
  background-color: #bf2323;
  padding: 30px; }
  .company-info .container {
    max-width: 1080px;
    display: flex;
    margin: 0 auto; }

@media screen and (max-width: 768px) {
  .testimonial-card .text-wrap {
    height: 200px; }
    .testimonial-card .text-wrap .text {
      font-size: 12.6px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: normal;
      color: #464646; }
  .testimonial-card .content-wrap .name {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2b2b2b; }
  .form-component {
    padding: 24px; }
    .form-component .title-text {
      font-size: 20px;
      margin-bottom: 8px; }
    .form-component .sub-text {
      font-size: 16px;
      margin-bottom: 20px; }
    .form-component .row {
      flex-direction: column;
      margin-top: 0; }
      .form-component .row .col {
        max-width: 100%; }
        .form-component .row .col:not(:last-child) {
          margin: 0;
          margin-bottom: 16px; }
        .form-component .row .col .select-dropdown select {
          font-size: 14px;
          font-weight: 500;
          padding: 12px 15px; }
  .company-details {
    flex-direction: column; }
    .company-details .col {
      margin: 0 0 12px 0;
      max-width: 100% !important; }
      .company-details .col:not(:last-child) {
        margin-right: 0; }
      .company-details .col .text-one {
        font-size: 16px; }
      .company-details .col .text-two {
        font-size: 18px; }
        .company-details .col .text-two span {
          display: block;
          margin-bottom: 6px; }
  .header-wrapper {
    background-color: #ffffff;
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    margin: 0;
    z-index: 99;
    padding: 10px 20px;
    align-items: center; }
    .header-wrapper .menu {
      display: inline-block;
      position: absolute;
      left: 0;
      cursor: pointer;
      margin: 0 10px; }
    .header-wrapper .nav-wrap a {
      display: none; }
    .header-wrapper .nav-wrap button {
      padding: 8px 20px;
      font-size: 12px; }
      .header-wrapper .nav-wrap button:not(:last-child) {
        margin-right: 0; }
      .header-wrapper .nav-wrap button:not(:first-child) {
        margin-left: 12px; }
    .header-wrapper .logo-wrap {
      margin-left: 16px;
      padding-top: 10px; }
      .header-wrapper .logo-wrap img {
        max-width: 120px; }
    .header-wrapper .container {
      width: 100%;
      max-width: 100%;
      display: flex;
      align-items: center;
      padding: 15px 20px; }
  .homepage-layout .header-wrapper.home .nav-wrap a {
    display: none; }
  .homepage-layout .header-wrapper.home .nav-wrap .white-bordered {
    display: none; }
  .homepage-layout .class-card {
    margin-right: 8px; }
  .homepage-layout .banner-wrapper {
    padding: 140px 0 80px 0; }
    .homepage-layout .banner-wrapper .container {
      max-width: 100%;
      flex-direction: column;
      width: 100%;
      padding: 0 24px; }
      .homepage-layout .banner-wrapper .container .content-wrap,
      .homepage-layout .banner-wrapper .container .video-wrap {
        min-width: 100%;
        max-width: 100%;
        margin: 0;
        width: 100%; }
      .homepage-layout .banner-wrapper .container .video-wrap {
        margin: 0 -20px;
        width: calc(100% + 40px);
        max-width: calc(100% + 40px);
        height: 250px; }
        .homepage-layout .banner-wrapper .container .video-wrap img.play {
          width: 45px;
          height: 45px; }
      .homepage-layout .banner-wrapper .container .content-wrap {
        margin-bottom: 24px; }
        .homepage-layout .banner-wrapper .container .content-wrap .text-one {
          font-size: 18px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.65; }
        .homepage-layout .banner-wrapper .container .content-wrap .text-two {
          max-width: 350px;
          font-size: 42.2px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.03;
          letter-spacing: normal;
          color: #ffffff; }
        .homepage-layout .banner-wrapper .container .content-wrap .text-three {
          font-size: 12.6px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.06;
          letter-spacing: normal;
          color: #ffffff; }
          .homepage-layout .banner-wrapper .container .content-wrap .text-three span {
            font-weight: 500; }
  .homepage-layout .carousel-wrapper .slick-arrow {
    z-index: 9; }
    .homepage-layout .carousel-wrapper .slick-arrow.slick-prev {
      left: 0;
      width: 37px;
      height: 37px; }
    .homepage-layout .carousel-wrapper .slick-arrow.slick-next {
      right: 0;
      width: 37px;
      height: 37px; }
  .homepage-layout .ant-tabs {
    overflow: inherit; }
    .homepage-layout .ant-tabs .ant-tabs-bar {
      border: 0;
      margin-bottom: 32px; }
      .homepage-layout .ant-tabs .ant-tabs-bar .ant-tabs-nav-scroll {
        text-align: center; }
        .homepage-layout .ant-tabs .ant-tabs-bar .ant-tabs-nav-scroll .ant-tabs-ink-bar {
          display: none !important; }
        .homepage-layout .ant-tabs .ant-tabs-bar .ant-tabs-nav-scroll .ant-tabs-nav {
          border-radius: 2px;
          background-color: #eff1f3;
          padding: 3px; }
        .homepage-layout .ant-tabs .ant-tabs-bar .ant-tabs-nav-scroll .ant-tabs-tab {
          font-size: 12.5px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #35353c;
          padding: 12px 30px;
          position: relative;
          margin: 0; }
          .homepage-layout .ant-tabs .ant-tabs-bar .ant-tabs-nav-scroll .ant-tabs-tab:after {
            content: "";
            opacity: 0.3;
            background: #c6cdd8;
            height: calc(100% - 6px);
            width: 1px; }
          .homepage-layout .ant-tabs .ant-tabs-bar .ant-tabs-nav-scroll .ant-tabs-tab.ant-tabs-tab-active {
            border-radius: 2px;
            background-color: #ffffff;
            color: #063bff;
            padding: 10px 30px;
            font-size: 12.5px; }
  .homepage-layout .section .container {
    padding: 0 24px; }
  .homepage-layout .section .title-text {
    font-size: 22px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: 0.55px;
    text-align: center;
    color: #20211c;
    padding: 0 10px; }
    .homepage-layout .section .title-text br {
      display: none; }
  .homepage-layout .section .sub-text {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: center;
    color: #20211c; }
  .homepage-layout .section .topics-covered {
    padding: 0 24px; }
    .homepage-layout .section .topics-covered .topics {
      display: block; }
      .homepage-layout .section .topics-covered .topics .topic {
        margin-bottom: 12px; }
  .homepage-layout .section.two .flex-wrap {
    flex-direction: column;
    margin-top: 20px; }
    .homepage-layout .section.two .flex-wrap .project-card {
      margin-right: 18px;
      border: 0;
      width: calc(100% - 20px) !important;
      border-radius: 0;
      background: transparent;
      padding: 0; }
    .homepage-layout .section.two .flex-wrap > div {
      margin-bottom: 12px;
      box-shadow: none;
      background-color: transparent;
      padding: 12px; }
      .homepage-layout .section.two .flex-wrap > div .content-wrap p {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.4px;
        color: #20211c; }
      .homepage-layout .section.two .flex-wrap > div .img-wrap img {
        object-fit: cover;
        border-radius: 3.6px; }
      .homepage-layout .section.two .flex-wrap > div:not(:last-child) {
        margin-right: 0; }
  .homepage-layout .section.two .img-list {
    flex-direction: column; }
    .homepage-layout .section.two .img-list img {
      margin-bottom: 24px; }
      .homepage-layout .section.two .img-list img:not(:last-child) {
        margin-right: 0; }
  .homepage-layout .section.three .flex-wrap {
    flex-direction: column; }
    .homepage-layout .section.three .flex-wrap > div {
      margin-bottom: 20px;
      text-align: center; }
      .homepage-layout .section.three .flex-wrap > div:not(:last-child) {
        margin-right: 0; }
  .homepage-layout .section.five .container {
    padding: 0 24px; }
  .homepage-layout .section.five .carousel-wrapper .slick-slide {
    margin-right: 14px; }
  .homepage-layout .section.five .flex-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    align-items: start; }
    .homepage-layout .section.five .flex-wrap > div {
      width: 100%;
      margin-bottom: 12px; }
      .homepage-layout .section.five .flex-wrap > div .text-one {
        font-size: 20px;
        line-height: 1.2; }
      .homepage-layout .section.five .flex-wrap > div .text-two {
        font-size: 14px; }
  .homepage-layout .section.five .testimonial-wrapper {
    display: block; }
  .homepage-layout .section.six .container {
    padding: 0; }
  .homepage-layout .section.seven .container {
    flex-direction: column;
    padding: 0; }
  .details-layout .details-content {
    padding: 0; }
    .details-layout .details-content .banner-section {
      flex-direction: column;
      background: #063bff;
      padding: 30px 20px 30px 20px;
      border-radius: 4px; }
      .details-layout .details-content .banner-section > div:first-child {
        margin-top: 0;
        text-align: center;
        padding-right: 0; }
      .details-layout .details-content .banner-section .buttons-wrap {
        justify-content: center; }
        .details-layout .details-content .banner-section .buttons-wrap .button {
          font-size: 12px; }
      .details-layout .details-content .banner-section .card {
        max-width: 100%;
        min-width: 100%;
        position: relative;
        bottom: -85px;
        margin: auto;
        right: auto; }
    .details-layout .details-content.teach-with-us .description-section {
      padding-top: 180px; }
    .details-layout .details-content.teach-with-us .banner-section {
      flex-direction: column;
      padding: 20px 10px 60px 10px;
      background-size: cover; }
      .details-layout .details-content.teach-with-us .banner-section .card {
        right: 0;
        left: 0;
        width: calc(100% - 40px);
        min-width: auto;
        margin: auto; }
  .details-layout .section .container {
    padding: 0 24px; }
  .details-layout .section .title-text {
    font-size: 22px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: 0.55px;
    text-align: center;
    color: #20211c;
    padding: 0 10px; }
    .details-layout .section .title-text br {
      display: none; }
  .details-layout .section .sub-text {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: center;
    color: #20211c; }
  .details-layout .section .topics-covered {
    padding: 0 24px; }
    .details-layout .section .topics-covered .topics {
      display: block; }
      .details-layout .section .topics-covered .topics .topic {
        margin-bottom: 12px; }
  .details-layout .section.two .flex-wrap {
    flex-direction: column;
    margin-top: 20px; }
    .details-layout .section.two .flex-wrap .project-card {
      margin-right: 18px;
      border: 0;
      width: calc(100% - 20px) !important;
      border-radius: 0;
      background: transparent;
      padding: 0; }
    .details-layout .section.two .flex-wrap > div {
      margin-bottom: 12px;
      box-shadow: none;
      background-color: transparent;
      padding: 12px; }
      .details-layout .section.two .flex-wrap > div .content-wrap p {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.4px;
        color: #20211c; }
      .details-layout .section.two .flex-wrap > div .img-wrap img {
        object-fit: cover;
        border-radius: 3.6px; }
      .details-layout .section.two .flex-wrap > div:not(:last-child) {
        margin-right: 0; }
  .details-layout .section.two .img-list {
    flex-direction: column; }
    .details-layout .section.two .img-list img {
      margin-bottom: 24px; }
      .details-layout .section.two .img-list img:not(:last-child) {
        margin-right: 0; }
  .details-layout .section.three .flex-wrap {
    flex-direction: column; }
    .details-layout .section.three .flex-wrap > div {
      margin-bottom: 20px;
      text-align: center; }
      .details-layout .section.three .flex-wrap > div:not(:last-child) {
        margin-right: 0; }
  .details-layout .section.five .container {
    padding: 0 24px; }
  .details-layout .section.five .carousel-wrapper .slick-slide {
    margin-right: 14px; }
  .details-layout .section.five .flex-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    align-items: start; }
    .details-layout .section.five .flex-wrap > div {
      width: 100%;
      margin-bottom: 12px; }
      .details-layout .section.five .flex-wrap > div .text-one {
        font-size: 20px;
        line-height: 1.2; }
      .details-layout .section.five .flex-wrap > div .text-two {
        font-size: 14px; }
  .details-layout .section.five .testimonial-wrapper {
    display: block; }
  .details-layout .section.six .container {
    padding: 0; }
  .details-layout .section.seven .container {
    flex-direction: column;
    padding: 0; }
  .class-card {
    max-width: 100%; }
    .class-card .icons-wrap {
      height: 74px; }
      .class-card .icons-wrap .icon img {
        max-height: 20px; }
    .class-card .tags-wrap {
      padding: 0 16px; }
      .class-card .tags-wrap .tag {
        font-size: 12px;
        padding: 8px;
        margin: 0 6px 6px 0; }
        .class-card .tags-wrap .tag img {
          max-height: 10px; }
    .class-card .img-wrap .text-two {
      font-size: 13px; } }

.qzard-layout .header-wrap {
  padding: 15px 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  position: absolute;
  z-index: 9;
  left: 0;
  top: 0;
  right: 0;
  background: #063bff; }
  .qzard-layout .header-wrap .menu {
    display: none; }
  .qzard-layout .header-wrap .logo-wrap img {
    max-width: 60px;
    width: 100%; }
  .qzard-layout .header-wrap .nav-wrap {
    flex-grow: 1;
    text-align: right; }
    .qzard-layout .header-wrap .nav-wrap a {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      margin-right: 35px;
      position: relative; }
      .qzard-layout .header-wrap .nav-wrap a.with-divider:before {
        content: '';
        position: absolute;
        left: -16px;
        height: 10px;
        border: solid 1px #ffffff;
        top: 6px; }
    .qzard-layout .header-wrap .nav-wrap button.bordered {
      border-radius: 2px;
      border: solid 1px #ffffff;
      outline: none;
      padding: 10px 32px;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      background: transparent;
      margin-right: 35px;
      cursor: pointer; }
    .qzard-layout .header-wrap .nav-wrap button.orange {
      border-radius: 2px;
      background-color: #ff8f00;
      border: 1px solid #ff8f00;
      outline: none;
      padding: 10px 48px;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      cursor: pointer; }

.qzard-layout .wrapper {
  max-width: 1078px;
  margin: 0 auto; }

.qzard-layout .section.one {
  padding: 100px 0 60px 0; }
  .qzard-layout .section.one .row {
    display: flex;
    align-items: center;
    position: relative; }
    .qzard-layout .section.one .row .col {
      flex-grow: 1; }
      .qzard-layout .section.one .row .col .logo {
        width: 189px;
        height: 98px;
        object-fit: contain;
        margin-bottom: 34px;
        margin-left: -18px; }
      .qzard-layout .section.one .row .col .text-one {
        font-size: 24px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.08;
        letter-spacing: 0.18px;
        color: #536dfe;
        margin-bottom: 22px; }
      .qzard-layout .section.one .row .col .text-two {
        font-size: 22px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.18;
        letter-spacing: normal;
        color: #1e174a;
        margin-bottom: 4px; }
      .qzard-layout .section.one .row .col .text-three {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #1e174a;
        max-width: 340px; }
      .qzard-layout .section.one .row .col .helper-text {
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.51;
        letter-spacing: normal;
        color: #1e174a;
        position: absolute;
        bottom: 0;
        cursor: pointer; }
      .qzard-layout .section.one .row .col:last-child {
        max-width: 655px; }
        .qzard-layout .section.one .row .col:last-child img {
          max-width: 100%; }

.qzard-layout .section.two {
  background: #f8f9fa;
  width: 100%;
  padding: 10px 0; }
  .qzard-layout .section.two .grid-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin-bottom: 40px; }
  .qzard-layout .section.two .cards-wrapper .card {
    margin: 0 auto 20px auto; }
    .qzard-layout .section.two .cards-wrapper .card .text-two {
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #2b2b2b; }
      .qzard-layout .section.two .cards-wrapper .card .text-two span {
        display: block;
        font-weight: 500; }
        .qzard-layout .section.two .cards-wrapper .card .text-two span.inline {
          display: inline-block;
          font-weight: 600; }
  .qzard-layout .section.two .title-text {
    font-size: 25px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.63px;
    text-align: center;
    color: #20211c;
    margin-bottom: 32px; }
    .qzard-layout .section.two .title-text span {
      font-weight: bold; }
    .qzard-layout .section.two .title-text.no-margin {
      margin-bottom: 0; }
    .qzard-layout .section.two .title-text.blue {
      font-size: 16px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.63px;
      text-align: center;
      color: black;
      margin: 8px 0 32px 0; }
  .qzard-layout .section.two .card {
    border-radius: 2px;
    border: solid 0.8px #dee2e6;
    background-color: #ffffff;
    padding: 20px; }
    .qzard-layout .section.two .card .text-one {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.11;
      letter-spacing: normal;
      color: #536dfe;
      margin-bottom: 8px; }
    .qzard-layout .section.two .card .text-two {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2b2b2b; }

.qzard-layout .section.three {
  background-color: #6c6aff;
  padding: 60px 0;
  text-align: center; }
  .qzard-layout .section.three .text-one {
    font-size: 38px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.95px;
    text-align: center;
    color: #ffffff; }
  .qzard-layout .section.three .text-two {
    font-size: 25px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.63px;
    text-align: center;
    color: #ffffff; }
    .qzard-layout .section.three .text-two.with-margin {
      margin-top: 24px; }
  .qzard-layout .section.three .button {
    border: 0;
    outline: none;
    border-radius: 1.4px;
    background-color: #ff8f00;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    padding: 8px 32px;
    margin-top: 32px;
    text-transform: none;
    display: inline-block; }

.qzard-layout .social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0 0; }
  .qzard-layout .social-icons .fb,
  .qzard-layout .social-icons .insta,
  .qzard-layout .social-icons .linkedin {
    width: 56px;
    height: 56px;
    margin-right: 16px; }
  .qzard-layout .social-icons .fb {
    background: url(images/landing/icon-fb-grey.svg) no-repeat;
    background-size: 100% 100%; }
    .qzard-layout .social-icons .fb:hover {
      background: url(images/landing/icon-fb.svg) no-repeat;
      background-size: 100% 100%; }
  .qzard-layout .social-icons .insta {
    background: url(images/landing/icon-instagram-grey.svg) no-repeat;
    background-size: 100% 100%; }
    .qzard-layout .social-icons .insta:hover {
      background: url(images/landing/icon-instagram.png) no-repeat;
      background-size: 100% 100%; }
  .qzard-layout .social-icons .linkedin {
    background: url(images/landing/icon-linkedin-grey.svg) no-repeat;
    background-size: 100% 100%; }
    .qzard-layout .social-icons .linkedin:hover {
      background: url(images/landing/icon-linkedin.svg) no-repeat;
      background-size: 100% 100%; }
  .qzard-layout .social-icons img {
    width: 56px;
    height: 56px;
    object-fit: contain;
    margin-right: 16px; }

@media screen and (max-width: 768px) {
  .qzard-layout .header-wrap .nav-wrap {
    display: none; }
  .qzard-layout .header-wrap .menu {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 20px;
    cursor: pointer; }
  .qzard-layout .header-wrap .logo-wrap {
    margin-left: 12px; }
    .qzard-layout .header-wrap .logo-wrap img {
      max-width: 48px; }
  .qzard-layout .header-wrap .mobile-nav .img-wrap {
    background: #063bff; }
  .qzard-layout .section .wrapper {
    max-width: 100%;
    padding: 0 16px; }
  .qzard-layout .section.one {
    padding: 40px 0; }
    .qzard-layout .section.one .row {
      flex-direction: column; }
      .qzard-layout .section.one .row .col {
        width: 100%; }
        .qzard-layout .section.one .row .col .logo {
          width: auto;
          height: 60px;
          margin-left: auto; }
        .qzard-layout .section.one .row .col .text-one {
          font-size: 18px; }
        .qzard-layout .section.one .row .col .text-two {
          font-size: 16px; }
        .qzard-layout .section.one .row .col .text-three {
          font-size: 14px; }
        .qzard-layout .section.one .row .col .helper-text {
          display: none; }
        .qzard-layout .section.one .row .col:first-child {
          order: 2;
          text-align: center; }
        .qzard-layout .section.one .row .col:last-child {
          max-width: 100%; }
          .qzard-layout .section.one .row .col:last-child img {
            max-width: 70%;
            margin: 0 auto;
            display: inherit; }
  .qzard-layout .section.two {
    padding: 40px 0; }
    .qzard-layout .section.two .title-text {
      font-size: 16px; }
      .qzard-layout .section.two .title-text.blue {
        font-size: 16px; }
    .qzard-layout .section.two .card .text-one {
      font-size: 14px; }
    .qzard-layout .section.two .card .text-two {
      font-size: 16px; }
    .qzard-layout .section.two .grid-wrapper {
      display: block; }
      .qzard-layout .section.two .grid-wrapper .card {
        margin-bottom: 12px; }
    .qzard-layout .section.two .cards-wrapper {
      display: grid; }
      .qzard-layout .section.two .cards-wrapper .card .text-two {
        font-size: 16px; }
  .qzard-layout .section.three .text-one {
    font-size: 22px; }
  .qzard-layout .section.three .text-two {
    font-size: 16px; } }

.course-list {
  padding: 64px 0;
  font-family: 'Heebo'; }
  .course-list .title-text {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #35353c; }
  .course-list .grid-wrapper {
    margin-top: 36px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px; }
    .course-list .grid-wrapper .class-card {
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06); }

.courses-layout {
  padding-top: 80px;
  font-family: 'Heebo'; }
  .courses-layout .header {
    background-color: #ffffff;
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    margin: 0;
    z-index: 99; }
    .courses-layout .header .menu-wrap a {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #35353c;
      text-shadow: none; }
    .courses-layout .header .menu-wrap .button {
      background: #063bff;
      text-transform: none;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      min-width: 130px;
      padding: 12px; }
    .courses-layout .header .img-wrap img {
      height: 50px; }
    .courses-layout .header .container {
      width: 100%;
      max-width: 100%;
      display: flex;
      align-items: center;
      padding: 15px 20px; }
  .courses-layout .wrapper {
    max-width: 1078px;
    margin: 0 auto; }
  .courses-layout .banner-wrap {
    border-radius: 4px;
    background-color: #fbfbfc;
    padding: 15rem 2.4rem;
    background: #fbfbfc url(images/homepage/codeq-banner.png) no-repeat;
    background-position: center center;
    background-size: cover; }
    .courses-layout .banner-wrap.lingoq {
      background: #fbfbfc url(images/homepage/lingoq-banner.png) no-repeat;
      background-position: center center;
      background-size: cover; }
    .courses-layout .banner-wrap .row {
      display: flex;
      align-items: center; }
    .courses-layout .banner-wrap .col {
      flex-grow: 1;
      flex-basis: 100%; }
      .courses-layout .banner-wrap .col:first-child {
        margin-right: 60px; }
    .courses-layout .banner-wrap .title-text {
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.27;
      letter-spacing: normal;
      color: #35353c; }
    .courses-layout .banner-wrap .logo-wrap img {
      height: 105px;
      object-fit: contain; }
    .courses-layout .banner-wrap .sub-text {
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: normal;
      color: #35353c;
      margin-bottom: 18px;
      font-weight: 500; }
      .courses-layout .banner-wrap .sub-text span {
        font-weight: normal; }
    .courses-layout .banner-wrap .img-wrapper img {
      height: 100%;
      max-height: 465px;
      object-fit: contain; }
  .courses-layout .contact {
    background-color: #f8f9fa;
    padding: 66px 0; }
  .courses-layout .company-details-wrapper {
    background-color: #456ee6;
    padding: 32px 0; }

.the-team {
  background-color: #26abf3;
  padding: 50px 40px 65px 40px;
  text-align: center;
  font-family: 'Heebo'; }
  .the-team .title-text {
    font-size: 32px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.8px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 5px; }
  .the-team .sub-text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    color: #ffffff; }
  .the-team .flex-wrap {
    display: flex;
    align-items: flex-start;
    margin-top: 50px; }
    .the-team .flex-wrap > div {
      flex-grow: 1;
      flex-basis: 100%; }
      .the-team .flex-wrap > div .img-wrap {
        width: 110px;
        height: 110px;
        margin: 0 auto 22px auto; }
        .the-team .flex-wrap > div .img-wrap img {
          width: 100%;
          height: 100%;
          border: solid 9.9px #1f99db;
          border-radius: 55px;
          object-fit: cover; }
      .the-team .flex-wrap > div .text-one {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: 0.4px;
        text-align: center;
        color: #ffffff; }
      .the-team .flex-wrap > div .text-two {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.35px;
        color: #ffffff; }
      .the-team .flex-wrap > div:not(:last-child) {
        margin-right: 68px; }

.coding-importance {
  text-align: center;
  padding: 120px 0 90px 0; }
  .coding-importance .title-text {
    font-size: 32px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.8px;
    text-align: center;
    color: #2f3a4f;
    margin-bottom: 20px; }
  .coding-importance .sub-text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    color: #2f3a4f; }
  .coding-importance .text {
    color: #2f3a4f; }
  .coding-importance .row {
    margin: 90px 0 46px 0;
    display: flex;
    align-items: flex-start; }
    .coding-importance .row .col {
      flex-grow: 1;
      flex-basis: 100%; }
      .coding-importance .row .col:not(:last-child) {
        margin-right: 54px; }
      .coding-importance .row .col .text {
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: #2f3a4f; }
      .coding-importance .row .col > div {
        height: 220px;
        margin-bottom: 50px; }
      .coding-importance .row .col img {
        max-height: 100%; }
      .coding-importance .row .col .sub-text {
        margin-bottom: 11px; }

.testimonial-wrapper {
  padding: 0 0 60px 0; }
  .testimonial-wrapper .title-text {
    font-size: 32px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.8px;
    text-align: center;
    color: #2f3a4f;
    margin-bottom: 40px; }
  .testimonial-wrapper .testimonial-card {
    margin-right: 20px;
    max-width: 346px;
    text-align: left; }

@media screen and (max-width: 768px) {
  .courses-layout .header .menu-wrap {
    display: none; }
  .courses-layout .header .menu {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 20px;
    cursor: pointer; }
  .courses-layout .header .logo-wrap {
    margin-left: 12px; }
    .courses-layout .header .logo-wrap img {
      max-width: 48px; }
  .courses-layout .header .mobile-nav .img-wrap {
    background: #063bff; }
  .courses-layout .banner-wrap {
    padding: 0;
    height: 177px;
    position: relative;
    background: #fbfbfc url(images/homepage/codeq-banner-mobile.png) no-repeat;
    background-position: center center;
    background-size: cover; }
    .courses-layout .banner-wrap .row {
      position: absolute;
      max-width: calc(100% - 48px);
      left: 0;
      right: 0;
      bottom: -18rem;
      z-index: 9;
      margin: auto; }
    .courses-layout .banner-wrap.lingoq {
      background: #fbfbfc url(images/homepage/lingoq-banner-mobile.png) no-repeat;
      background-position: center center;
      background-size: cover; }
    .courses-layout .banner-wrap .title-text {
      font-size: 13.2px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.27;
      letter-spacing: normal;
      color: #35353c; }
    .courses-layout .banner-wrap .sub-text {
      font-size: 10px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.68;
      letter-spacing: normal;
      color: #35353c; }
    .courses-layout .banner-wrap .row {
      flex-direction: column; }
      .courses-layout .banner-wrap .row .col:first-child {
        margin: 0;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
        background-color: #ffffff;
        padding: 15px 18px; }
      .courses-layout .banner-wrap .row .col button {
        font-size: 12px; }
    .courses-layout .banner-wrap .logo-wrap img {
      width: auto;
      height: auto;
      object-fit: contain;
      max-width: 200px; }
    .courses-layout .banner-wrap .img-wrapper img {
      width: 100%;
      max-width: 100%;
      height: auto; }
  .courses-layout .company-details-wrapper {
    padding: 4rem 2.4rem; }
  .courses-layout .course-list {
    padding: 20rem 0 4rem 0; }
    .courses-layout .course-list .slick-slider .class-card {
      margin-right: 2rem; }
    .courses-layout .course-list .title-text {
      margin-bottom: 3.4rem;
      padding: 0 1.6rem; }
  .the-team {
    padding: 50px 16px 50px 16px; }
    .the-team .flex-wrap {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
      align-items: start; }
      .the-team .flex-wrap div .img-wrap {
        width: 110px;
        height: 110px;
        margin: 0 auto 22px auto; }
      .the-team .flex-wrap > div:not(:last-child) {
        margin: 0; }
  .testimonial-wrapper {
    padding: 6rem 2.4rem; }
  .coding-importance {
    padding: 40px 20px 40px 20px; }
    .coding-importance .row {
      flex-direction: column; }
      .coding-importance .row .col {
        margin-bottom: 2.4rem; }
        .coding-importance .row .col:not(:last-child) {
          margin-right: 0; }
  .course-list {
    padding: 24px; }
    .course-list .grid-wrapper {
      display: block; }
      .course-list .grid-wrapper .class-card {
        margin-bottom: 20px;
        max-width: 100%; } }

.mobile-design .timer-wrap {
  border-radius: 2px;
  background-color: #171e37;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: normal;
  color: #ffffff;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  min-width: 180px; }
  .mobile-design .timer-wrap .timer {
    display: flex; }
  .mobile-design .timer-wrap .time-hidden {
    margin-right: 4px; }
  .mobile-design .timer-wrap span {
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.4;
    letter-spacing: normal;
    color: #ffffff;
    margin-right: 8px; }

.mobile-design .donwnload-button .ant-btn {
  border-radius: 2px;
  border: solid 1px #f4f7fc;
  background-color: #ffffff;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  height: unset;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: normal;
  color: #4139eb;
  cursor: pointer; }

.mobile-design .info-wrap {
  max-width: 56rem;
  margin: 0 auto;
  padding: 12px;
  border-radius: 2px;
  background-color: #ffe2c0;
  font-size: 12.8px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #262626; }

.mobile-design .empty-wrap {
  min-height: calc(100vh - 240px);
  display: flex;
  align-items: center;
  justify-content: center; }
  .mobile-design .empty-wrap > div {
    text-align: center; }
    .mobile-design .empty-wrap > div .text {
      font-size: 1.2rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #2d3851;
      margin-bottom: 1rem; }
    .mobile-design .empty-wrap > div .btn {
      border-radius: 2px;
      background-color: #4139eb;
      border: 0;
      font-size: 1rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.92;
      letter-spacing: normal;
      color: #ffffff;
      padding: 5px 16px;
      text-align: center;
      cursor: pointer; }
    .mobile-design .empty-wrap > div img {
      margin-bottom: 2rem; }

.mobile-design .loading-component {
  max-width: 56rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh; }

.mobile-design .content-card {
  border-radius: 8px;
  border: solid 1px #d4dbe3;
  background-color: #ffffff;
  padding: 25px 20px 20px;
  position: relative;
  margin: 0 auto 12px auto;
  max-width: 56rem; }
  .mobile-design .content-card.demo {
    border-radius: 0.8rem;
    padding: 2.8rem 2rem 2rem 2rem; }
    .mobile-design .content-card.demo.active {
      background-color: #6356bf; }
      .mobile-design .content-card.demo.active .text-one,
      .mobile-design .content-card.demo.active .text-two,
      .mobile-design .content-card.demo.active .date-time {
        color: #ffffff; }
      .mobile-design .content-card.demo.active .bottom-wrap p {
        color: #ffffff; }
      .mobile-design .content-card.demo.active .bottom-wrap button.blue {
        border-radius: 2px;
        border: solid 1px #413887;
        background-color: #ffffff;
        font-size: 1.2rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: center;
        color: #4139eb; }
    .mobile-design .content-card.demo .flex-wrap {
      margin-bottom: 0.8rem; }
    .mobile-design .content-card.demo .text-one {
      background-color: #f5a623;
      font-size: 1rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #262626; }
    .mobile-design .content-card.demo .text-two {
      font-size: 1.4rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal; }
    .mobile-design .content-card.demo .date-time {
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left; }
      .mobile-design .content-card.demo .date-time span {
        font-weight: normal; }
    .mobile-design .content-card.demo .bottom-wrap {
      margin-top: 1.8rem; }
      .mobile-design .content-card.demo .bottom-wrap .trainer-wrap {
        padding: 0; }
      .mobile-design .content-card.demo .bottom-wrap p {
        font-size: 1.2rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: right; }
        .mobile-design .content-card.demo .bottom-wrap p span {
          display: block;
          font-weight: bold; }
  .mobile-design .content-card.purple {
    border-radius: 8px;
    background-color: #242f57; }
    .mobile-design .content-card.purple .text-one {
      opacity: 0.78;
      border-radius: 2px;
      background-color: #6674a6;
      padding: 5px 10px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      display: inline-block; }
    .mobile-design .content-card.purple .date-time {
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: #ffffff; }
    .mobile-design .content-card.purple .text-two {
      color: #ffffff; }
    .mobile-design .content-card.purple .trainer-wrap p {
      color: #b3bfe8; }
  .mobile-design .content-card .flex-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 16px; }
  .mobile-design .content-card .text-one {
    border-radius: 2px;
    background-color: #fff6dd;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #e69b0a;
    display: inline-block; }
    .mobile-design .content-card .text-one.green {
      background-color: #e1fdea;
      color: #31bb67; }
    .mobile-design .content-card .text-one.grey {
      color: #627386;
      background-color: #e0e4eb; }
    .mobile-design .content-card .text-one.red {
      color: #dc4d57;
      background-color: #ffeaea; }
  .mobile-design .content-card .date-time {
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #262626;
    flex-grow: 1;
    text-align: right; }
  .mobile-design .content-card .text-two {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262626; }
  .mobile-design .content-card .text-three {
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262626;
    margin-top: 4px; }
  .mobile-design .content-card .bottom-wrap {
    display: flex;
    margin-top: 10px;
    align-items: center;
    flex-direction: column; }
    .mobile-design .content-card .bottom-wrap .link-text-dash {
      font-size: 10px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.92;
      letter-spacing: normal;
      color: #4139eb;
      padding: 0 6px;
      cursor: pointer;
      border: 1px solid #f4f7fc !important; }
  .mobile-design .content-card .trainer-wrap {
    padding: 8px 12px;
    flex-grow: 1;
    text-align: right; }
    .mobile-design .content-card .trainer-wrap p {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #262626;
      display: inline-block; }
      .mobile-design .content-card .trainer-wrap p span {
        font-weight: 500;
        display: inline-block;
        margin-left: 4px; }
  .mobile-design .content-card .buttons-wrap.inline {
    border-radius: 2px;
    border: solid 1px #f4f7fc;
    background-color: #ffffff;
    padding: 4px 8px;
    display: flex;
    align-items: center; }
    .mobile-design .content-card .buttons-wrap.inline .link-text {
      font-size: 10px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.92;
      letter-spacing: normal;
      color: #4139eb;
      padding: 0 6px;
      cursor: pointer; }
      .mobile-design .content-card .buttons-wrap.inline .link-text:not(:last-child) {
        border-right: solid 1px #f4f7fc; }
      .mobile-design .content-card .buttons-wrap.inline .link-text.red {
        color: #f44336; }
  .mobile-design .content-card .button {
    border-radius: 2px;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.92;
    letter-spacing: normal;
    color: #ffffff;
    padding: 6px 16px;
    text-transform: none;
    border: 0;
    outline: none;
    box-shadow: none; }
    .mobile-design .content-card .button.blue {
      background-color: #4139eb;
      background-image: none; }
    .mobile-design .content-card .button.inactive {
      background-image: none;
      border: 1px solid #999999;
      background-color: #cccccc;
      color: #666666;
      cursor: not-allowed; }
    .mobile-design .content-card .button.grey-border {
      border: solid 1px #f4f7fc;
      background-color: #ffffff;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.92;
      letter-spacing: normal;
      color: #4139eb; }

.mobile-design .title-text {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #262626;
  margin-bottom: 5px; }

.mobile-design .invoice-table table {
  border-radius: 8px;
  border: solid 1px #f2f4fa;
  background-color: #ffffff;
  width: 100%; }

.mobile-design .invoice-table tr td {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #262626;
  padding: 8px 10px;
  border: 1px solid #f2f4fa; }
  .mobile-design .invoice-table tr td:not(:last-child) {
    border-right: 0; }

.mobile-design .invoice-table tr th {
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #262626;
  padding: 8px 10px;
  border: 1px solid #f2f4fa; }
  .mobile-design .invoice-table tr th:not(:last-child) {
    border-right: 0; }

.mobile-navigation {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 998;
  -webkit-backdrop-filter: blur(17px);
  backdrop-filter: blur(17px);
  box-shadow: 0 -1px 0 0 #f5f6fb;
  background-color: rgba(255, 255, 255, 0.94);
  padding: 10px 12px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .mobile-navigation > div {
    flex-grow: 1;
    flex-basis: 100%;
    text-align: center;
    padding: 0 4px;
    position: relative; }
    .mobile-navigation > div .active span {
      font-size: 10px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.19px;
      text-align: center;
      color: #4139eb;
      position: relative; }
      .mobile-navigation > div .active span:after {
        content: "";
        position: absolute;
        bottom: -10px;
        height: 2px;
        background-color: #4139eb;
        width: 100%;
        left: 0;
        right: 0; }
    .mobile-navigation > div.home div {
      width: 50px;
      height: 50px;
      border: solid 3px #f4f7fc;
      background-color: #7f8fa4;
      border-radius: 100%;
      position: absolute;
      bottom: -14px;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 0;
      right: 0;
      margin: auto; }
    .mobile-navigation > div.home.active div {
      border: solid 3px #f4f7fc;
      background-color: #4139eb; }
    .mobile-navigation > div span {
      font-size: 10px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.19px;
      text-align: center;
      color: #8492a2;
      display: block; }

.dashboard-layout,
.classlogs-layout,
.payments-layout,
.curriculum-layout {
  padding: 2rem 1rem;
  min-height: 100vh; }

.demo-view {
  background: #fff; }

.payments-layout .container {
  max-width: 56rem;
  margin: 0 auto; }

.classlogs-layout .ant-tabs .ant-tabs-content {
  padding-top: 3rem; }

.classlogs-layout .ant-tabs .ant-tabs-bar {
  border: 0; }

.classlogs-layout .ant-tabs .ant-tabs-bar {
  position: fixed;
  left: 0;
  right: 0;
  top: 1rem;
  margin: auto;
  width: 48rem;
  z-index: 99;
  text-align: center;
  background: transparent;
  padding: 1.5rem 0; }

.classlogs-layout .ant-tabs .ant-tabs-nav-wrap .ant-tabs-nav-scroll {
  max-width: 56rem;
  margin: 0 auto; }

.classlogs-layout .ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab {
  box-shadow: inset -1px 0 0 0 #f2f4f6;
  background-color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7f8fa4;
  padding: 10px 40px;
  margin: 0; }
  .classlogs-layout .ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab.ant-tabs-tab-active {
    border-radius: 4px;
    background-color: #525f78;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff; }

.classlogs-layout .ant-tabs .ant-tabs-nav-wrap .ant-tabs-ink-bar {
  display: none !important; }

.classlogs-layout .header-wrap {
  padding: 0;
  width: 100%;
  position: relative;
  margin: 20px 0;
  display: none; }
  .classlogs-layout .header-wrap .month-dropdown {
    top: 18px;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262626;
    display: flex;
    align-items: center; }
    .classlogs-layout .header-wrap .month-dropdown img {
      margin-right: 10px; }
    .classlogs-layout .header-wrap .month-dropdown .arrow {
      margin-left: 10px; }

.curriculum-layout .curr-card .text-one {
  background: #e0e4eb;
  color: #627386; }

.curriculum-layout .curr-card .date-time {
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: normal;
  color: #4139eb;
  cursor: pointer; }
  .curriculum-layout .curr-card .date-time a {
    color: inherit; }

.curriculum-layout .collapse-section {
  margin: 0 auto 12px auto;
  max-width: 56rem; }
  .curriculum-layout .collapse-section .flex p {
    display: inline-block;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #2f3a4f;
    margin-bottom: 12px; }
    .curriculum-layout .collapse-section .flex p span {
      font-weight: 600;
      display: block;
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #242e41; }
  .curriculum-layout .collapse-section .ant-collapse {
    background-color: transparent;
    border: 0;
    border-radius: 0; }
    .curriculum-layout .collapse-section .ant-collapse > .ant-collapse-item {
      margin-bottom: 8px;
      border-radius: 8px;
      border: solid 1px #f2f4fa;
      background-color: #ffffff; }
      .curriculum-layout .collapse-section .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        padding: 12px 14px 20px 14px;
        position: relative; }
        .curriculum-layout .collapse-section .ant-collapse > .ant-collapse-item > .ant-collapse-header .toggle-icon {
          width: 15px;
          height: 15px;
          background-color: #e5e3ff;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center; }
          .curriculum-layout .collapse-section .ant-collapse > .ant-collapse-item > .ant-collapse-header .toggle-icon span.arrow {
            background: url(images/mobile-design/down-arrow.svg) no-repeat;
            width: 100%;
            height: 100%;
            background-position: 3.4px 4px;
            border-radius: 100%;
            background-size: 8px 8px; }
        .curriculum-layout .collapse-section .ant-collapse > .ant-collapse-item > .ant-collapse-header[aria-expanded="true"] span.arrow {
          background: url(images/mobile-design/up-arrow.svg) no-repeat;
          background-position: 3.4px 3px;
          background-size: 8px 8px; }
        .curriculum-layout .collapse-section .ant-collapse > .ant-collapse-item > .ant-collapse-header p .title-text {
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #262626;
          display: block; }
        .curriculum-layout .collapse-section .ant-collapse > .ant-collapse-item > .ant-collapse-header p .text {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #262626; }
    .curriculum-layout .collapse-section .ant-collapse .ant-collapse-content {
      border: 0;
      padding: 0 16px; }
      .curriculum-layout .collapse-section .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
        border-top: solid 1px #f4f7fc;
        padding: 16px 0; }

@media screen and (max-width: 768px) {
  .classlogs-layout .ant-tabs .ant-tabs-content {
    padding-top: 6rem; }
  .classlogs-layout .ant-tabs .ant-tabs-bar {
    width: 100%;
    top: auto;
    background: #fff;
    margin-top: -1.8rem; }
  .classlogs-layout .header-wrap {
    display: block; }
  .mobile-design .invoice-table tr th {
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    color: #262626; }
  .mobile-design .invoice-table tr td {
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262626; }
  .mobile-design .title-text {
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262626; }
  .mobile-design .content-card .text-two {
    font-size: 15px; }
  .mobile-design .content-card .bottom-wrap {
    flex-direction: column;
    align-items: flex-start; }
  .mobile-design .content-card .buttons-wrap.inline {
    padding: 4px;
    order: 2; }
  .mobile-design .content-card .date-time {
    font-size: 10px;
    font-weight: 600; }
  .mobile-design .content-card .trainer-wrap {
    width: 100%; }
    .mobile-design .content-card .trainer-wrap p {
      font-size: 10px; }
      .mobile-design .content-card .trainer-wrap p span {
        display: inline-block !important; }
  .empty-wrap {
    min-height: calc(100vh - 240px);
    display: flex;
    align-items: center;
    justify-content: center; }
    .empty-wrap > div {
      text-align: center; }
      .empty-wrap > div .text {
        font-size: 1.2rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #2d3851;
        margin-bottom: 1rem; }
      .empty-wrap > div .text-mobile {
        font-size: 1.2rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #7f8fa4;
        margin-bottom: 1rem; }
      .empty-wrap > div .text-back {
        font-size: 12px;
        font-weight: 500;
        line-height: 1.6;
        color: #4139eb; }
      .empty-wrap > div .btn {
        border-radius: 2px;
        background-color: #4139eb;
        border: 0;
        font-size: 1rem;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.92;
        letter-spacing: normal;
        color: #ffffff;
        padding: 5px 16px;
        text-align: center;
        cursor: pointer; }
      .empty-wrap > div img {
        margin-bottom: 2rem; }
  .curriculum-layout .curr-card .text-one {
    background: #e0e4eb;
    color: #627386; }
  .curriculum-layout .curr-card .date-time {
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.92;
    letter-spacing: normal;
    color: #4139eb;
    cursor: pointer; }
  .curriculum-layout .collapse-section {
    margin: 0 auto 12px auto;
    max-width: 56rem; }
    .curriculum-layout .collapse-section .flex p {
      font-size: 10px; }
      .curriculum-layout .collapse-section .flex p span {
        font-size: 10px; }
    .curriculum-layout .collapse-section .ant-collapse > .ant-collapse-item > .ant-collapse-header p .title-text {
      font-size: 15px; }
    .curriculum-layout .collapse-section .ant-collapse > .ant-collapse-item > .ant-collapse-header p .text {
      font-size: 10px; }
  .classlogs-layout .ant-tabs .ant-tabs-nav-wrap .ant-tabs-nav-scroll {
    text-align: center; }
  .classlogs-layout .ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab {
    padding: 8px 20px; } }

.nextclass-link-text {
  color: #ffffff;
  cursor: pointer; }

.classlog-link-text {
  cursor: pointer;
  color: #4139eb !important;
  border: 1px solid #f4f7fc !important; }

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  /* STYLES GO HERE */
  .mobile-design .content-card .date-time {
    font-size: 10px; }
  .mobile-design .content-card .text-one {
    font-size: 10px; }
  .mobile-design .content-card .text-two {
    font-size: 14px; }
  .mobile-design .content-card .text-three {
    font-size: 10px; }
  .mobile-design .content-card .trainer-wrap p {
    font-size: 10px; }
    .mobile-design .content-card .trainer-wrap p span {
      display: block;
      font-weight: 600; }
  .mobile-design .content-card .buttons-wrap.inline .link-text {
    font-size: 10px; }
  .mobile-design .content-card .button {
    font-size: 10px; }
  .classlogs-layout .ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab {
    font-size: 12px; }
    .classlogs-layout .ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab.ant-tabs-tab-active {
      font-size: 12px; }
  .mobile-navigation > div .active span {
    font-size: 10px; }
  .mobile-navigation > div span {
    font-size: 10px; }
  .profile .title-text {
    font-size: 14px; }
  .profile .profile-card .content .desc-wrap .text-one {
    font-size: 12px; }
  .profile .profile-card .content .desc-wrap .text-two {
    font-size: 14px; }
  .profile .profile-card .content .desc-wrap .text-three {
    font-size: 12px; } }

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
  /* STYLES GO HERE */
  .mobile-design .content-card .date-time {
    font-size: 12px; }
  .mobile-design .content-card .text-one {
    font-size: 12px; }
  .mobile-design .content-card .text-two {
    font-size: 16px; }
  .mobile-design .content-card .text-three {
    font-size: 12px; }
  .mobile-design .content-card .trainer-wrap p {
    font-size: 12px; }
    .mobile-design .content-card .trainer-wrap p span {
      display: block;
      font-weight: 600; }
  .mobile-design .content-card .buttons-wrap.inline .link-text {
    font-size: 12px; }
  .mobile-design .content-card .button {
    font-size: 12px; }
  .classlogs-layout .ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab {
    font-size: 12px; }
    .classlogs-layout .ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab.ant-tabs-tab-active {
      font-size: 12px; }
  .mobile-navigation > div .active span {
    font-size: 10px; }
  .mobile-navigation > div span {
    font-size: 10px; }
  .profile .title-text {
    font-size: 16px; }
  .profile .profile-card .content .desc-wrap .text-one {
    font-size: 14px; }
  .profile .profile-card .content .desc-wrap .text-two {
    font-size: 16px; }
  .profile .profile-card .content .desc-wrap .text-three {
    font-size: 14px; } }

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) {
  /* STYLES GO HERE */
  .mobile-design .content-card .date-time {
    font-size: 14px; }
  .mobile-design .content-card .text-one {
    font-size: 14px; }
  .mobile-design .content-card .text-two {
    font-size: 20px; }
  .mobile-design .content-card .text-three {
    font-size: 14px; }
  .mobile-design .content-card .trainer-wrap p {
    font-size: 14px; }
    .mobile-design .content-card .trainer-wrap p span {
      display: block;
      font-weight: 600; }
  .mobile-design .content-card .buttons-wrap.inline .link-text {
    font-size: 14px; }
  .mobile-design .content-card .button {
    font-size: 14px; }
  .classlogs-layout .ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab {
    font-size: 14px; }
    .classlogs-layout .ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab.ant-tabs-tab-active {
      font-size: 16px; }
  .mobile-navigation > div .active span {
    font-size: 14px; }
  .mobile-navigation > div span {
    font-size: 14px; }
  .profile .title-text {
    font-size: 20px; }
  .profile .profile-card .content .desc-wrap .text-one {
    font-size: 16px; }
  .profile .profile-card .content .desc-wrap .text-two {
    font-size: 18px; }
  .profile .profile-card .content .desc-wrap .text-three {
    font-size: 16px; } }

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
  /* STYLES GO HERE */
  .mobile-design .content-card .date-time {
    font-size: 14px; }
  .mobile-design .content-card .text-one {
    font-size: 14px; }
  .mobile-design .content-card .text-two {
    font-size: 22px; }
  .mobile-design .content-card .text-three {
    font-size: 14px; }
  .mobile-design .content-card .trainer-wrap p {
    font-size: 14px; }
    .mobile-design .content-card .trainer-wrap p span {
      display: block;
      font-weight: 600; }
  .mobile-design .content-card .buttons-wrap.inline .link-text {
    font-size: 14px; }
  .mobile-design .content-card .button {
    font-size: 14px; }
  .classlogs-layout .ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab {
    font-size: 14px; }
    .classlogs-layout .ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab.ant-tabs-tab-active {
      font-size: 14px; }
  .mobile-navigation > div .active span {
    font-size: 10px; }
  .mobile-navigation > div span {
    font-size: 10px; }
  .profile .title-text {
    font-size: 18px; }
  .profile .profile-card .content .desc-wrap .text-one {
    font-size: 16px; }
  .profile .profile-card .content .desc-wrap .text-two {
    font-size: 18px; }
  .profile .profile-card .content .desc-wrap .text-three {
    font-size: 16px; } }

@media only screen and (min-device-width: 414px) and (max-device-width: 736px) {
  /* STYLES GO HERE */
  .mobile-design .content-card .date-time {
    font-size: 14px; }
  .mobile-design .content-card .text-one {
    font-size: 14px; }
  .mobile-design .content-card .text-two {
    font-size: 20px; }
  .mobile-design .content-card .text-three {
    font-size: 14px; }
  .mobile-design .content-card .trainer-wrap p {
    font-size: 14px; }
    .mobile-design .content-card .trainer-wrap p span {
      display: block;
      font-weight: 600; }
  .mobile-design .content-card .buttons-wrap.inline .link-text {
    font-size: 14px; }
  .mobile-design .content-card .button {
    font-size: 14px; }
  .classlogs-layout .ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab {
    font-size: 16px; }
    .classlogs-layout .ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab.ant-tabs-tab-active {
      font-size: 16px; }
  .mobile-navigation > div .active span {
    font-size: 14px; }
  .mobile-navigation > div span {
    font-size: 14px; }
  .profile .title-text {
    font-size: 18px; }
  .profile .profile-card .content .desc-wrap .text-one {
    font-size: 16px; }
  .profile .profile-card .content .desc-wrap .text-two {
    font-size: 18px; }
  .profile .profile-card .content .desc-wrap .text-three {
    font-size: 16px; } }

.onboarding-layout {
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 99999;
  background: #ffffff; }
  .onboarding-layout .step-one {
    background: #382dc1 url(images/onboarding/one.png) no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%; }
    .onboarding-layout .step-one .content {
      text-align: center; }
      .onboarding-layout .step-one .content img {
        max-width: 110px; }
      .onboarding-layout .step-one .content p {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        margin-top: 20px; }
  .onboarding-layout .content-wrapper {
    position: relative;
    height: 100vh; }
    .onboarding-layout .content-wrapper .logo-wrap {
      padding: 30px 30px 35px 30px; }
      .onboarding-layout .content-wrapper .logo-wrap img {
        max-width: 63px; }
    .onboarding-layout .content-wrapper .button-wrap {
      padding: 0 30px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 30px;
      width: 100%; }
      .onboarding-layout .content-wrapper .button-wrap button {
        width: 100%; }
  .onboarding-layout .carousel-wrapper {
    position: relative; }
    .onboarding-layout .carousel-wrapper .slick-dots {
      bottom: 20px; }
    .onboarding-layout .carousel-wrapper .c-item .text-wrap {
      padding: 0 30px; }
      .onboarding-layout .carousel-wrapper .c-item .text-wrap p {
        font-size: 22px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.32;
        letter-spacing: normal;
        color: #2c286c; }
    .onboarding-layout .carousel-wrapper .c-item .img-wrap {
      margin-top: 15px;
      height: 48vh; }
      .onboarding-layout .carousel-wrapper .c-item .img-wrap img {
        width: 100%;
        height: 100%; }

@media screen and (max-width: 320px) {
  .onboarding-layout .carousel-wrapper .c-item .text-wrap p {
    font-size: 16px; } }

.sixty-minutes-layout .banner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0 120px 0;
  background: #353ED5 url(images/60-min/banner.png) no-repeat;
  position: relative; }
  .sixty-minutes-layout .banner.lingoq {
    background: #FF512F url(images/60-min/lingoq-banner.png) no-repeat;
    background-size: cover; }
  .sixty-minutes-layout .banner > div {
    max-width: 1080px;
    margin: 0 auto;
    text-align: center; }
  .sixty-minutes-layout .banner img {
    width: 80px;
    margin-bottom: 24px; }
  .sixty-minutes-layout .banner .title-text {
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    font-size: 28px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin-bottom: 2px; }
  .sixty-minutes-layout .banner .sub-text {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff; }

.sixty-minutes-layout .content-card {
  max-width: 1080px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  margin: -80px auto 0 auto;
  display: flex;
  align-items: flex-start;
  padding: 20px;
  position: absolute;
  bottom: -120px; }
  .sixty-minutes-layout .content-card > div {
    flex-grow: 1;
    flex-basis: 100%;
    text-align: center; }
    .sixty-minutes-layout .content-card > div:not(:last-child) {
      margin-right: 22px; }
    .sixty-minutes-layout .content-card > div:first-child {
      min-width: 270px; }
    .sixty-minutes-layout .content-card > div .text-one {
      font-size: 14px;
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #20211c;
      margin-bottom: 2px; }
    .sixty-minutes-layout .content-card > div .text-two {
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.4px;
      text-align: center;
      color: #61625a; }
    .sixty-minutes-layout .content-card > div img {
      width: 80px;
      height: 80px;
      object-fit: contain;
      margin-bottom: 12px; }

.sixty-minutes-layout .experts-wrap {
  margin-top: 64px; }
  .sixty-minutes-layout .experts-wrap .text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    color: #20211c; }

.sixty-minutes-layout .img-list {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 36px; }
  .sixty-minutes-layout .img-list img {
    max-height: 46px; }
    .sixty-minutes-layout .img-list img:not(:last-child) {
      margin-right: 60px; }

.sixty-minutes-layout .demo-booking {
  background-color: #f8f9fa;
  padding: 140px 0 40px 0; }
  .sixty-minutes-layout .demo-booking .label {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #2f3a4f; }
  .sixty-minutes-layout .demo-booking .title-text {
    font-size: 34px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.85px;
    text-align: center;
    color: #20211c; }
  .sixty-minutes-layout .demo-booking .sub-text {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.45px;
    text-align: center;
    color: #20211c; }
  .sixty-minutes-layout .demo-booking .col.center {
    text-align: center; }
  .sixty-minutes-layout .demo-booking .text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2f3a4f;
    text-align: center; }

.sixty-minutes-layout .testimonials-wrap {
  padding: 80px 0; }
  .sixty-minutes-layout .testimonials-wrap .title-text {
    font-size: 32px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.8px;
    text-align: center;
    color: #2f3a4f;
    margin-bottom: 60px; }
    .sixty-minutes-layout .testimonials-wrap .title-text span {
      font-weight: bold; }
  .sixty-minutes-layout .testimonials-wrap .carousel-wrapper .testimonial-card {
    max-width: 340px; }

.sixty-minutes-layout .company-wrap {
  background-color: #456ee6;
  padding: 30px; }
  .sixty-minutes-layout .company-wrap > div {
    max-width: 1080px; }

@media screen and (max-width: 768px) {
  .sixty-minutes-layout .banner {
    padding: 35px 16px 132px 16px; }
    .sixty-minutes-layout .banner.lingoq {
      background: #FF512F url(images/60-min/lingoq-banner-mobile.png) no-repeat;
      background-size: cover; }
    .sixty-minutes-layout .banner img {
      width: 71px;
      height: 51px;
      object-fit: contain; }
    .sixty-minutes-layout .banner > div {
      max-width: calc(100% - 32px); }
    .sixty-minutes-layout .banner .title-text {
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
      font-size: 24px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center; }
    .sixty-minutes-layout .banner .sub-text {
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center; }
  .sixty-minutes-layout .testimonials-wrap {
    padding: 20px; }
  .sixty-minutes-layout .content-card {
    flex-direction: column;
    bottom: auto;
    max-width: calc(100% - 40px);
    padding: 30px 20px;
    margin: 20px auto auto;
    border-radius: 3.2px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    position: relative; }
    .sixty-minutes-layout .content-card > div {
      display: flex;
      align-items: center; }
      .sixty-minutes-layout .content-card > div:nth-child(even) > img {
        order: 2; }
      .sixty-minutes-layout .content-card > div:not(:last-child) {
        margin: 0 0 30px 0; }
      .sixty-minutes-layout .content-card > div img {
        width: 86px;
        height: 86px;
        object-fit: contain;
        margin: 0 12px 0 0; }
      .sixty-minutes-layout .content-card > div .text-one {
        font-size: 12px;
        text-align: left; }
      .sixty-minutes-layout .content-card > div .text-two {
        font-size: 12px;
        text-align: left; }
  .sixty-minutes-layout .demo-booking {
    padding-top: 0;
    border-radius: 3.2px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    max-width: calc(100% - 40px);
    margin: -80px auto auto;
    position: relative;
    z-index: 1; }
  .sixty-minutes-layout .experts-wrap {
    padding: 0 16px;
    text-align: center; }
  .sixty-minutes-layout .img-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    text-align: center;
    justify-content: center;
    align-items: center; }
    .sixty-minutes-layout .img-list img {
      max-height: 36px;
      align-self: center;
      justify-self: center; }
      .sixty-minutes-layout .img-list img:not(:last-child) {
        margin: 0; }
  .sixty-minutes-layout .testimonials-wrap .title-text {
    font-size: 24px;
    padding: 0 16px; }
  .sixty-minutes-layout .demo-booking .form-component .title-text {
    font-size: 24px; }
  .sixty-minutes-layout .demo-booking .form-component .row .col {
    max-width: 100%; }
  .sixty-minutes-layout .demo-booking .form-component .sub-text {
    font-size: 14px; } }

.quiz-layout .logo {
  width: 110px;
  height: 80px;
  object-fit: contain;
  margin-bottom: 24px; }

.quiz-layout .padding-five-ten {
  padding: 5px 10px;
  display: block; }

.quiz-layout .t-text {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  font-size: 38px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-bottom: 2px; }

.quiz-layout .title-text {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  font-size: 38px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-bottom: 2px; }

.quiz-layout .sub-text {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  max-width: 736px;
  margin: 0 auto; }

.quiz-layout .landing-layout {
  background: url(images/quiz/background.png) no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
  padding: 24px;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative; }
  .quiz-layout .landing-layout .footer-wrap {
    position: absolute;
    bottom: 44px;
    max-width: 760px; }
    .quiz-layout .landing-layout .footer-wrap p {
      opacity: 0.8;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.83;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff; }
  .quiz-layout .landing-layout > div {
    text-align: center;
    margin-top: -80px; }
  .quiz-layout .landing-layout .score-board {
    width: 560px;
    border-radius: 10px;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
    background-color: #7a1af8;
    padding: 24px 26px;
    display: flex;
    align-items: center;
    margin: 28px auto; }
    .quiz-layout .landing-layout .score-board > div {
      flex-grow: 1;
      flex-basis: 100%; }
    .quiz-layout .landing-layout .score-board .text-one {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: normal;
      color: #ffffff; }
    .quiz-layout .landing-layout .score-board .text-two {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: normal;
      color: #ffffff; }

.quiz-layout .quiz-list-layout {
  background: #f8f9fa;
  min-height: 100vh; }
  .quiz-layout .quiz-list-layout .required-text {
    color: red;
    font-size: 14px;
    display: inline-block; }
  .quiz-layout .quiz-list-layout .banner {
    padding: 62px 24px 120px 24px;
    background: url(images/quiz/background.png) no-repeat;
    background-size: cover;
    width: 100%;
    text-align: center; }
  .quiz-layout .quiz-list-layout .wrapper {
    max-width: 840px;
    margin: -60px auto auto;
    z-index: 1;
    position: relative; }
    .quiz-layout .quiz-list-layout .wrapper .card {
      width: 100%;
      padding: 35px 20px 25px 30px;
      border-radius: 4px;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.06);
      background-color: #ffffff;
      margin-bottom: 20px;
      position: relative; }
      .quiz-layout .quiz-list-layout .wrapper .card.disabled-card {
        pointer-events: none; }
        .quiz-layout .quiz-list-layout .wrapper .card.disabled-card.green {
          border: 1px solid green; }
        .quiz-layout .quiz-list-layout .wrapper .card.disabled-card.red {
          border: 1px solid red; }
      .quiz-layout .quiz-list-layout .wrapper .card.error {
        border: 1px solid #ff3b3b; }
      .quiz-layout .quiz-list-layout .wrapper .card .file-upload {
        margin-top: 12px; }
      .quiz-layout .quiz-list-layout .wrapper .card .question-image {
        max-width: 100%; }
      .quiz-layout .quiz-list-layout .wrapper .card .abs-label {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #00bf8d;
        border-radius: 2.4px;
        background-color: #e1fdea;
        padding: 2px 8px;
        position: absolute;
        right: 20px;
        margin-top: -20px; }
        .quiz-layout .quiz-list-layout .wrapper .card .abs-label.score {
          right: 100px;
          color: #ff3b3b;
          background-color: #ff3b3b33; }
        .quiz-layout .quiz-list-layout .wrapper .card .abs-label.green {
          color: #18ac85;
          background-color: #a0ffbf; }
      .quiz-layout .quiz-list-layout .wrapper .card .question-speech {
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .quiz-layout .quiz-list-layout .wrapper .card .question-speech .speech-button {
          border: none;
          padding: 0; }
        .quiz-layout .quiz-list-layout .wrapper .card .question-speech .text-button {
          height: 32px;
          padding: 0 15px;
          font-size: 12px;
          border-radius: 4px;
          font-weight: bold;
          border: solid 1px #063bff;
          color: #fff;
          background: #063bff; }
      .quiz-layout .quiz-list-layout .wrapper .card .question {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: normal;
        color: #2e394f;
        margin-bottom: 20px;
        max-width: calc(100% - 100px);
        white-space: pre-wrap; }
      .quiz-layout .quiz-list-layout .wrapper .card .text {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: normal;
        color: #2e394f;
        white-space: pre-wrap;
        line-height: 1.6; }
        .quiz-layout .quiz-list-layout .wrapper .card .text.light {
          color: #676767; }
      .quiz-layout .quiz-list-layout .wrapper .card textarea {
        border-radius: 4px;
        border: solid 1px #d2d9e1;
        background-color: #ffffff;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        color: #7e8fa5;
        width: 100%;
        outline: none;
        min-height: 160px; }
      .quiz-layout .quiz-list-layout .wrapper .card .video-holder {
        margin-bottom: 10px; }
        .quiz-layout .quiz-list-layout .wrapper .card .video-holder .video-size {
          width: 100%;
          min-height: 500px;
          height: auto; }
      .quiz-layout .quiz-list-layout .wrapper .card .answer-section .ant-radio-group,
      .quiz-layout .quiz-list-layout .wrapper .card .answer-section .ant-checkbox-group {
        display: flex;
        flex-direction: column; }
        .quiz-layout .quiz-list-layout .wrapper .card .answer-section .ant-radio-group.grid,
        .quiz-layout .quiz-list-layout .wrapper .card .answer-section .ant-checkbox-group.grid {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-gap: 10px; }
          .quiz-layout .quiz-list-layout .wrapper .card .answer-section .ant-radio-group.grid label,
          .quiz-layout .quiz-list-layout .wrapper .card .answer-section .ant-checkbox-group.grid label {
            position: relative;
            z-index: 1;
            display: flex;
            align-items: center; }
            .quiz-layout .quiz-list-layout .wrapper .card .answer-section .ant-radio-group.grid label .ant-radio,
            .quiz-layout .quiz-list-layout .wrapper .card .answer-section .ant-checkbox-group.grid label .ant-radio {
              position: absolute;
              top: 8px;
              right: 8px; }
              .quiz-layout .quiz-list-layout .wrapper .card .answer-section .ant-radio-group.grid label .ant-radio .ant-radio-inner,
              .quiz-layout .quiz-list-layout .wrapper .card .answer-section .ant-checkbox-group.grid label .ant-radio .ant-radio-inner {
                width: 10px;
                height: 10px; }
                .quiz-layout .quiz-list-layout .wrapper .card .answer-section .ant-radio-group.grid label .ant-radio .ant-radio-inner:after,
                .quiz-layout .quiz-list-layout .wrapper .card .answer-section .ant-checkbox-group.grid label .ant-radio .ant-radio-inner:after {
                  top: 1px;
                  left: 1px;
                  display: table;
                  width: 6px;
                  height: 6px; }
            .quiz-layout .quiz-list-layout .wrapper .card .answer-section .ant-radio-group.grid label span,
            .quiz-layout .quiz-list-layout .wrapper .card .answer-section .ant-checkbox-group.grid label span {
              padding: 0; }
              .quiz-layout .quiz-list-layout .wrapper .card .answer-section .ant-radio-group.grid label span:last-child img,
              .quiz-layout .quiz-list-layout .wrapper .card .answer-section .ant-checkbox-group.grid label span:last-child img {
                width: 100%;
                max-width: 100%;
                border-radius: 2px;
                border: solid 1px #dae1ea;
                display: block; }
        .quiz-layout .quiz-list-layout .wrapper .card .answer-section .ant-radio-group label,
        .quiz-layout .quiz-list-layout .wrapper .card .answer-section .ant-checkbox-group label {
          margin: 0 0 12px 0; }
      .quiz-layout .quiz-list-layout .wrapper .card .answer-section.flex-wrap.reorder .ant-radio-group,
      .quiz-layout .quiz-list-layout .wrapper .card .answer-section.flex-wrap.reorder .ant-checkbox-group {
        display: block; }
        .quiz-layout .quiz-list-layout .wrapper .card .answer-section.flex-wrap.reorder .ant-radio-group > label,
        .quiz-layout .quiz-list-layout .wrapper .card .answer-section.flex-wrap.reorder .ant-checkbox-group > label {
          border: 1px solid #ddd;
          margin-bottom: 8px; }
      .quiz-layout .quiz-list-layout .wrapper .card .uploaded-file {
        margin: 12px 0 !important; }
        .quiz-layout .quiz-list-layout .wrapper .card .uploaded-file .download-file {
          height: 32px;
          padding: 0 15px;
          font-size: 11px;
          border-radius: 4px;
          color: rgba(0, 0, 0, 0.65);
          background-color: #fff;
          border-color: #d9d9d9; }
        .quiz-layout .quiz-list-layout .wrapper .card .uploaded-file .anticon {
          font-size: 32px; }
        .quiz-layout .quiz-list-layout .wrapper .card .uploaded-file a {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center; }
        .quiz-layout .quiz-list-layout .wrapper .card .uploaded-file img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .quiz-layout .quiz-list-layout .wrapper .card .flex-wrap {
        display: flex;
        align-items: center; }
        .quiz-layout .quiz-list-layout .wrapper .card .flex-wrap > div {
          flex-grow: 1;
          flex-basis: 100%; }
          .quiz-layout .quiz-list-layout .wrapper .card .flex-wrap > div img {
            max-width: 90%; }
          .quiz-layout .quiz-list-layout .wrapper .card .flex-wrap > div:not(:first-child) {
            text-align: right;
            margin-left: 12px; }
  .quiz-layout .quiz-list-layout .button-wrapper {
    margin: 20px 0 140px 0;
    text-align: center; }
  .quiz-layout .quiz-list-layout .footer-wrapper {
    background-color: #0a1929;
    padding: 28px;
    text-align: center; }
    .quiz-layout .quiz-list-layout .footer-wrapper p {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff; }

.trainer-feedback {
  border: solid 1px #d2d9e1;
  padding: 12px;
  border-radius: 4px;
  margin-top: 12px; }

@media screen and (max-width: 768px) {
  .quiz-layout .logo {
    width: 90px;
    height: 50px;
    object-fit: contain; }
  .quiz-layout .title-text {
    font-size: 24px; }
  .quiz-layout .sub-text {
    font-size: 12px;
    font-weight: 500; }
  .quiz-layout .quiz-list-layout .wrapper {
    margin: -80px auto auto;
    max-width: calc(100% - 20px); }
    .quiz-layout .quiz-list-layout .wrapper .card textarea {
      font-size: 13px; }
    .quiz-layout .quiz-list-layout .wrapper .card .abs-label {
      font-size: 10px; }
    .quiz-layout .quiz-list-layout .wrapper .card .question {
      font-size: 12px;
      font-weight: 500;
      max-width: 100%;
      padding-top: 6px; }
    .quiz-layout .quiz-list-layout .wrapper .card .video-holder .video-size {
      width: 100%;
      min-height: 250px;
      height: auto; }
    .quiz-layout .quiz-list-layout .wrapper .card .answer-section {
      flex-direction: column;
      align-items: flex-start; }
      .quiz-layout .quiz-list-layout .wrapper .card .answer-section > div > img {
        max-width: 100%;
        margin-bottom: 12px; }
      .quiz-layout .quiz-list-layout .wrapper .card .answer-section > div:first-child {
        order: 2; }
      .quiz-layout .quiz-list-layout .wrapper .card .answer-section > div:last-child {
        margin: 0; }
  .quiz-layout .landing-layout > div {
    width: 100%; }
  .quiz-layout .landing-layout .score-board {
    width: 100%;
    padding: 12px; }
    .quiz-layout .landing-layout .score-board .text-one {
      font-size: 12px; }
    .quiz-layout .landing-layout .score-board .text-two {
      font-size: 14px; }
  .quiz-layout .landing-layout .footer-wrap {
    max-width: 100%;
    bottom: 20px;
    padding: 12px; }
    .quiz-layout .landing-layout .footer-wrap p {
      font-size: 10px; } }

.video-container {
  width: 100%;
  margin: auto;
  position: relative;
  padding: 12px 20px;
  background-color: #ffff;
  min-height: 100vh;
  max-width: 100%; }
  .video-container .modal-confirmation {
    z-index: 9999;
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 250px;
    min-height: 100px;
    padding: 15px;
    border-radius: 10px;
    transform: translate(-50%, -50%);
    background: #fff; }
    .video-container .modal-confirmation .bg {
      z-index: 9998;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.3); }

.video-wrap {
  position: relative;
  height: 100%; }
  .video-wrap > div {
    height: 100%; }
  .video-wrap p {
    position: absolute;
    right: 4px;
    bottom: 4px;
    color: #fff;
    font-size: 12px; }
  .video-wrap .local-video-size {
    border-radius: 8px; }

.conference-local-screen-layout {
  position: absolute;
  width: 100%;
  height: 100%;
  min-width: 100% !important;
  max-width: 100% !important;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0; }

.spilt-layout {
  display: flex;
  width: 100%;
  height: 100%; }
  .spilt-layout > div {
    flex-grow: 1;
    flex-basis: 100%;
    min-width: 50%; }

.grid-layout {
  width: 100%;
  height: 100%; }
  .grid-layout.local-screen {
    display: flex; }
    .grid-layout.local-screen > div {
      flex-grow: 1;
      flex-basis: 100%;
      min-width: 50%;
      max-width: 50%; }

.local-video-sharing {
  width: 100%;
  height: 100%; }

.screen-share-layout {
  width: 100%;
  height: 100%; }
  .screen-share-layout .trainer-video {
    width: 100%;
    height: 100%; }

.trainer-video {
  width: 100%;
  height: 100%;
  position: relative; }
  .trainer-video video {
    width: 100%;
    height: 100%;
    border-radius: 8px; }
  .trainer-video p {
    position: absolute;
    left: 4px;
    bottom: 4px;
    color: #fff;
    font-size: 12px; }

.video-flex {
  display: flex;
  align-items: flex-start; }

.pop {
  display: none; }

.video-box {
  margin-top: 20px; }

.video-footer-container {
  background-color: #e4e4ea;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  justify-content: space-between; }
  .video-footer-container .video-footer-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 35rem; }
    .video-footer-container .video-footer-right .ant-btn {
      font-family: 'Heebo', sans-serif;
      font-size: 14px;
      color: #262626;
      border-radius: 2px;
      padding: 12px 15px;
      height: fit-content;
      border-radius: 8px;
      border: solid 1px #bfbfc0;
      background-color: #e4e4ea; }
  .video-footer-container .video-footer-right-mobile {
    visibility: hidden;
    align-items: center;
    margin-right: 20px; }
    .video-footer-container .video-footer-right-mobile .ant-btn {
      margin-left: -550px;
      font-size: 9px;
      color: #262626;
      border-radius: 2px;
      padding: 8px 10px;
      height: fit-content;
      border-radius: 4px;
      border: solid 1px #bfbfc0;
      background-color: #e4e4ea; }

.feedback-container {
  display: flex;
  justify-content: space-between;
  margin-top: 17px; }
  .feedback-container .feedback-right-section {
    display: flex;
    margin-right: 20px; }
    .feedback-container .feedback-right-section .feedback-right-heading {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #262626;
      margin-right: 9px;
      font-family: 'Heebo', sans-serif; }
    .feedback-container .feedback-right-section .feedback-like-button .ant-btn {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      border: solid 1px #d4dbe3;
      background-color: #ffffff;
      padding: 11px 12px;
      height: fit-content; }
    .feedback-container .feedback-right-section .feedback-like-button .like {
      width: 19px;
      height: 18px;
      object-fit: contain; }
    .feedback-container .feedback-right-section .feedback-dislike-button .ant-btn {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border: solid 1px #d4dbe3;
      background-color: #ffffff;
      padding: 11px 12px;
      height: fit-content; }
    .feedback-container .feedback-right-section .feedback-dislike-button .dislike {
      width: 19px;
      height: 18px;
      object-fit: contain; }
  .feedback-container .feedback-button {
    display: flex;
    justify-content: space-between; }
    .feedback-container .feedback-button .ant-btn {
      font-family: 'Heebo', sans-serif;
      color: #262626;
      padding: 15px 17px;
      border-radius: 8px;
      border: solid 1px #d4dbe3;
      background-color: #ffffff;
      font-size: 14px;
      color: #262626;
      height: fit-content;
      margin-right: 10px; }
  .feedback-container .feedback-button-mobile {
    visibility: hidden; }

.video-bottom {
  display: flex;
  align-items: center;
  padding: 16px 8px;
  position: relative;
  width: 100%; }
  .video-bottom > div {
    cursor: pointer; }
    .video-bottom > div.leave {
      position: absolute;
      right: 0; }

.event-img {
  display: flex;
  justify-content: center;
  padding: 2px; }

.heading-event {
  margin-top: 7px;
  font-size: 12px;
  font-family: 'Heebo', sans-serif;
  color: #262626;
  padding: 0 10px; }

.tab-section {
  min-width: 480px;
  width: 480px;
  border-radius: 8px;
  border: solid 1px #d4dbe3;
  background-color: #ffffff;
  margin-left: 20px; }

.tab-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: solid 1px #d4dbe3; }

.tab-container-curriculam {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: solid 1px #d4dbe3; }

.main-video-layout {
  position: relative; }
  .main-video-layout p {
    position: absolute;
    right: 4px;
    bottom: 4px;
    color: #fff;
    font-size: 12px; }

.main-content-curriculam {
  display: flex;
  flex-direction: column;
  padding-left: 21px; }
  .main-content-curriculam .second-heading-curriculam {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262626;
    margin-top: 5px; }
  .main-content-curriculam .third-heading-curriculam {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2f3a4f;
    margin-top: 2px; }

.left-content-heading-curriculam {
  margin-top: 24px; }
  .left-content-heading-curriculam .ant-btn {
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    border-radius: 2px;
    background-color: #7f8fa4;
    padding: 4px 11px;
    border-color: #7f8fa4;
    height: fit-content; }

.right-content-curriculam {
  margin-top: 5px;
  display: flex;
  align-items: center; }
  .right-content-curriculam .ant-btn {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.37;
    letter-spacing: normal;
    text-align: right;
    color: #4139eb;
    border: none;
    height: fit-content; }

.main-content {
  display: flex;
  flex-direction: column;
  padding-left: 21px; }
  .main-content .ant-btn {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #4139eb;
    border: none;
    height: fit-content;
    padding: 0px 0px; }

.right-content {
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #525f78;
  padding-right: 21px;
  margin-top: 8px; }

.second-heading {
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2f3a4f; }

.video-section {
  flex-grow: 1;
  max-width: calc(100% - 480px); }
  .video-section .section-video h2 {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #000000;
    margin-bottom: 10px; }
  .video-section .section-video .carousel-wrapper .slick-arrow.slick-prev {
    background-size: contain; }
  .video-section .section-video .carousel-wrapper .slick-arrow.slick-next {
    background-size: contain; }

.left-content-heading {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #262626;
  margin-top: 8px; }

.tab-section {
  border-radius: 8px;
  border: solid 1px #d4dbe3;
  background-color: #ffffff; }
  .tab-section .ant-tabs-bar {
    margin-bottom: auto; }
  .tab-section .ant-tabs-nav .ant-tabs-tab {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7f8fa4; }
  .tab-section .ant-tabs-nav .ant-tabs-tab-active {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2f3a4f; }

.project-container {
  margin-top: 20px; }
  .project-container h2 {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #000000;
    margin-bottom: 8px; }

.video-tutorial-card {
  border-radius: 4px; }
  .video-tutorial-card .media-wrap {
    position: relative; }
    .video-tutorial-card .media-wrap .play {
      width: 42px;
      height: 42px;
      object-fit: contain;
      box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.24);
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      cursor: pointer; }
    .video-tutorial-card .media-wrap .media-img {
      width: 210px;
      height: 134px;
      object-fit: cover;
      border-radius: 4px; }
    .video-tutorial-card .media-wrap .info-wrap {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.8);
      padding: 9px 16px;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center; }
      .video-tutorial-card .media-wrap .info-wrap p {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.3px;
        color: #ffffff;
        flex-grow: 1;
        flex-basis: 100%; }
        .video-tutorial-card .media-wrap .info-wrap p:last-child {
          text-align: right; }
  .video-tutorial-card .content-wrap > .text-one {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #262626;
    margin-top: 3px; }
  .video-tutorial-card .content-wrap .details-wrap {
    display: flex;
    align-items: center; }
    .video-tutorial-card .content-wrap .details-wrap .img-wrap {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      margin-right: 12px;
      background: #ccc; }
    .video-tutorial-card .content-wrap .details-wrap .text-wrap .text-one {
      font-size: 10px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.25px;
      color: #2f3a4f; }
    .video-tutorial-card .content-wrap .details-wrap .text-wrap .text-two {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.35px;
      color: #262626;
      margin-bottom: 2px; }
    .video-tutorial-card .content-wrap .details-wrap .text-wrap .text-three {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.3px;
      color: #2f3a4f; }
  .video-tutorial-card .content-wrap .info-wrap p {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #262626;
    margin-top: 3px; }

.collapse-section-curriculam .curr-card .text-one {
  background: #e0e4eb;
  color: #627386; }

.collapse-section-curriculam .curr-card .date-time {
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: normal;
  color: #4139eb;
  cursor: pointer; }

.collapse-section-curriculam .collapse-section .flex p {
  font-size: 10px; }
  .collapse-section-curriculam .collapse-section .flex p span {
    font-size: 10px; }

.collapse-section-curriculam .collapse-section .ant-collapse > .ant-collapse-item > .ant-collapse-header p .title-text {
  font-size: 15px; }

.collapse-section-curriculam .collapse-section .ant-collapse > .ant-collapse-item > .ant-collapse-header p .text {
  font-size: 10px; }

.collapse-section-curriculam .collapse-section .flex p {
  display: inline-block;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #2f3a4f;
  margin-bottom: 12px; }
  .collapse-section-curriculam .collapse-section .flex p span {
    display: block;
    color: #242e41;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #242e41; }

.collapse-section-curriculam .collapse-section .ant-collapse {
  background-color: transparent;
  border: 0;
  border-radius: 0; }
  .collapse-section-curriculam .collapse-section .ant-collapse > .ant-collapse-item {
    border-radius: 8px;
    border: solid 1px #f2f4fa;
    background-color: #ffffff; }
    .collapse-section-curriculam .collapse-section .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding: 12px 14px 20px 14px;
      position: relative; }
      .collapse-section-curriculam .collapse-section .ant-collapse > .ant-collapse-item > .ant-collapse-header .toggle-icon {
        width: 15px;
        height: 15px;
        background-color: #e5e3ff;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center; }
        .collapse-section-curriculam .collapse-section .ant-collapse > .ant-collapse-item > .ant-collapse-header .toggle-icon span.arrow {
          background: url(images/mobile-design/down-arrow.svg) no-repeat;
          width: 100%;
          height: 100%;
          background-position: 3.4px 4px;
          border-radius: 100%;
          background-size: 8px 8px; }
      .collapse-section-curriculam .collapse-section .ant-collapse > .ant-collapse-item > .ant-collapse-header[aria-expanded="true"] span.arrow {
        background: url(images/mobile-design/up-arrow.svg) no-repeat;
        background-position: 3.4px 3px;
        background-size: 8px 8px; }
      .collapse-section-curriculam .collapse-section .ant-collapse > .ant-collapse-item > .ant-collapse-header p .title-text {
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #262626;
        display: block; }
      .collapse-section-curriculam .collapse-section .ant-collapse > .ant-collapse-item > .ant-collapse-header p .text {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #262626; }
  .collapse-section-curriculam .collapse-section .ant-collapse .ant-collapse-content {
    border: 0;
    padding: 0 16px; }
    .collapse-section-curriculam .collapse-section .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
      border-top: solid 1px #f4f7fc;
      padding: 16px 0; }

.traning-header-container {
  display: flex;
  justify-content: space-between; }

.traning-left-header {
  display: flex; }

.back-button {
  margin-right: 12px; }
  .back-button .ant-btn {
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4139eb;
    height: fit-content;
    border-radius: 8px;
    border: solid 1px #d4dbe3;
    background-color: #ffffff;
    padding: 21px 18px; }
  .back-button .shape {
    width: 16px;
    height: 10px;
    object-fit: contain; }
  .back-button .shape-arrow {
    display: none; }

.left-subsection {
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */
  justify-content: space-around; }
  .left-subsection .hedaer-heading {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2f3a4f; }
  .left-subsection .second-heading {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2f3a4f; }

.traning-right-header {
  display: flex;
  margin-right: 20px;
  position: relative; }
  .traning-right-header .star-img {
    position: absolute;
    right: 37px;
    top: 4px; }
  .traning-right-header .traning-call-container {
    align-items: center;
    display: flex;
    height: 49px;
    border-radius: 8px;
    background-color: #eaf5f6;
    margin-right: 10px; }
    .traning-right-header .traning-call-container .training-call-heading {
      padding: 13px 15px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #262626; }
    .traning-right-header .traning-call-container .traning-call-button {
      padding-right: 12px;
      padding-left: 47px; }
  .traning-right-header .dropdown-list-container {
    display: flex;
    border-radius: 8px;
    background-image: linear-gradient(105deg, #fba649 20%, #f2534c 85%);
    padding: 11px 20px; }
  .traning-right-header .dropdown-left-content {
    margin-right: 14px;
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.17);
    padding: 5px 6px; }
  .traning-right-header .dropdown-middile-content {
    margin-right: 20px;
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff; }
  .traning-right-header .dropdown-middile-container {
    display: flex;
    align-items: center; }
  .traning-right-header .dropdown-img {
    display: flex;
    align-items: center;
    color: #fff; }

@media only screen and (max-width: 768px) {
  .spilt-layout {
    flex-direction: column;
    position: relative; }
    .spilt-layout .conference-local-video-layout {
      height: 120px;
      width: 120px;
      position: absolute;
      border-radius: 8px;
      right: 8px;
      bottom: 8px;
      z-index: 9;
      overflow: hidden;
      display: flex; }
      .spilt-layout .conference-local-video-layout .local-video-container {
        max-width: 100%;
        height: 100%; }
        .spilt-layout .conference-local-video-layout .local-video-container video {
          border-radius: 8px; }
  .pop {
    display: flex; }
    .pop .ant-btn {
      background: url("./images/modal@3x.png") no-repeat;
      background-size: cover;
      width: 42px;
      height: 32px;
      border-radius: 5px;
      margin: 0 10px; }
    .pop .popmodal {
      display: none;
      width: 39px;
      height: 28px;
      object-fit: contain; }
  .video-container {
    width: 100%;
    margin: auto;
    padding: 0;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background: #2a2934;
    max-height: 100vh;
    overflow: hidden; }
    .video-container .tab-section {
      background-color: rgba(0, 0, 0, 0.7);
      display: flex;
      position: absolute;
      height: 100vh;
      right: 0px;
      min-width: 415px;
      width: 0px; }
      .video-container .tab-section > div {
        margin-top: 10rem;
        height: 100vh;
        width: 100%;
        overflow: scroll; }
      .video-container .tab-section .ant-tabs {
        width: 100%;
        background-color: #fff; }
    .video-container .project-container {
      display: none; }
    .video-container .grid-layout.local-screen {
      flex-direction: column; }
      .video-container .grid-layout.local-screen > div {
        flex-grow: 1;
        flex-basis: initial;
        min-width: 100%;
        max-width: 100%; }
    .video-container .small-videos > div {
      width: 114px;
      height: 82px; }
      .video-container .small-videos > div video {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .video-container .video-flex {
      width: 100%; }
      .video-container .video-flex .video-section {
        width: 100%;
        max-width: 100%;
        position: relative; }
    .video-container .video-box {
      width: 100%;
      background-color: #2a2934;
      margin: 0 auto auto; }
    .video-container .video-footer-container {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      display: flex;
      justify-content: space-between;
      margin: 0;
      height: auto;
      padding: 1.2rem 1rem;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      z-index: 9999;
      padding: 1.8rem 1rem; }
      .video-container .video-footer-container .video-bottom .leave {
        position: relative; }
      .video-container .video-footer-container .video-bottom > div {
        text-align: center; }
      .video-container .video-footer-container > div {
        flex-grow: 1; }
      .video-container .video-footer-container .video-footer-right {
        visibility: hidden;
        margin: 0; }
        .video-container .video-footer-container .video-footer-right .ant-btn {
          font-size: 9px;
          border-radius: 2px;
          padding: 8px 16px;
          height: fit-content;
          border-radius: 4px;
          border: solid 1px #bfbfc0;
          background-color: #e4e4ea; }
      .video-container .video-footer-container .video-footer-right-mobile {
        display: flex;
        align-items: center;
        visibility: visible; }
        .video-container .video-footer-container .video-footer-right-mobile .ant-btn {
          margin-left: -5rem;
          visibility: visible;
          display: flex;
          border-radius: 2px;
          padding: 8px 10px;
          height: fit-content;
          border-radius: 4px;
          font-size: 9px; }
    .video-container .feedback-container {
      display: flex;
      margin-top: 17px; }
      .video-container .feedback-container .feedback-right-section {
        display: flex; }
        .video-container .feedback-container .feedback-right-section .feedback-right-heading {
          display: flex;
          align-items: center;
          font-size: 9px;
          margin-right: 9px;
          margin-bottom: 15px; }
        .video-container .feedback-container .feedback-right-section .feedback-like-button .ant-btn {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          padding: 5px 10px;
          height: fit-content; }
        .video-container .feedback-container .feedback-right-section .feedback-like-button .like {
          width: 12px;
          height: 11px;
          object-fit: contain;
          margin-left: -2px; }
        .video-container .feedback-container .feedback-right-section .feedback-dislike-button .ant-btn {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          padding: 5px 10px;
          height: fit-content; }
        .video-container .feedback-container .feedback-right-section .feedback-dislike-button .dislike {
          width: 12px;
          height: 11px;
          object-fit: contain;
          margin-left: -5px; }
      .video-container .feedback-container .feedback-button {
        visibility: hidden; }
      .video-container .feedback-container .feedback-button-mobile {
        display: flex;
        justify-content: space-between;
        margin-left: -400px; }
        .video-container .feedback-container .feedback-button-mobile .ant-btn {
          visibility: visible;
          padding: 8px 10px;
          border-radius: 4px;
          font-size: 9px;
          height: fit-content;
          margin-left: 10px; }
    .video-container .video-bottom {
      display: flex;
      align-items: center;
      padding: 0;
      position: relative;
      width: 100%; }
      .video-container .video-bottom .leave {
        position: absolute;
        right: 0; }
      .video-container .video-bottom > div {
        flex-grow: 1;
        flex-basis: 100%; }
    .video-container .event-img {
      padding: 0;
      cursor: pointer; }
      .video-container .event-img img {
        width: 15px;
        height: 15px; }
    .video-container .heading-event {
      padding: 0;
      margin-top: 7px;
      font-size: 9px; }
    .video-container .left-subsection {
      display: none; }
    .video-container .video-flex-two {
      display: none; }
    .video-container .traning-right-header .student .contact {
      height: 50%;
      border-radius: 5px;
      background-color: #eaf5f6;
      color: #262626;
      margin-top: 15px;
      padding: 0 10px; }
      .video-container .traning-right-header .student .contact p {
        font-size: 9px; }
      .video-container .traning-right-header .student .contact img {
        display: none; }
    .video-container .traning-right-header .student .payment-link {
      display: none; }
    .video-container .traning-right-header .student .pop {
      display: flex;
      margin-top: 16px; }
      .video-container .traning-right-header .student .pop Button {
        width: 39px;
        height: 28px;
        text-align: center;
        color: #ffff;
        background: #2a2934;
        border: #ffff 1px solid;
        border-radius: 5px; }
    .video-container .traning-header-container {
      background-image: linear-gradient(to bottom, #000000 38%, rgba(0, 0, 0, 0));
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      z-index: 999; }
    .video-container .back-button {
      margin: 20px 24px; }
      .video-container .back-button .ant-btn {
        margin: 0;
        border-radius: 5.6px;
        background-image: linear-gradient(to bottom, #000000 38%, rgba(0, 0, 0, 0));
        width: 35px;
        height: 35px;
        padding: 0;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center; }
      .video-container .back-button .shape-arrow {
        width: 13px;
        height: 9px;
        object-fit: contain;
        display: flex; }
      .video-container .back-button .shape {
        display: none; }
    .video-container .header {
      display: none; } }

.student-view {
  display: flex;
  padding-top: 7.5rem;
  width: 100%; }
  .student-view.white {
    background: #fff; }
  .student-view .left-panel {
    min-width: 24rem;
    width: 24rem; }
    .student-view .left-panel > div {
      width: 24rem;
      position: fixed;
      border: solid 1px #f2f4fa;
      background-color: #ffffff;
      padding: 2.4rem 2rem;
      min-height: calc(100vh - 10rem); }
    .student-view .left-panel a.list {
      border-radius: 4px;
      display: flex;
      width: 100%;
      font-size: 1.4rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 3.57;
      letter-spacing: normal;
      color: #2f3a4f;
      align-items: center;
      padding: 0 1.5rem; }
      .student-view .left-panel a.list img {
        max-height: 1.8rem;
        max-width: 1.8rem;
        margin-right: 2rem; }
      .student-view .left-panel a.list.active {
        background-color: rgba(227, 226, 248, 0.5);
        color: #4139eb; }
  .student-view .right-panel {
    min-width: 26rem;
    width: 26rem;
    padding: 4rem 2rem 0 2rem; }
    .student-view .right-panel .ringt-image {
      position: absolute;
      top: -60px;
      right: 0; }
      .student-view .right-panel .ringt-image img {
        max-width: 22rem; }
    .student-view .right-panel .card-one {
      border-radius: 8px;
      background-color: #f7eded;
      padding: 0 1.8rem 3rem 1.8rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      min-height: 31rem;
      margin-bottom: 2rem;
      margin-top: 74px;
      position: relative; }
      .student-view .right-panel .card-one .text-one {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.11;
        letter-spacing: normal;
        color: #ef5944;
        margin-bottom: .4rem; }
      .student-view .right-panel .card-one .text-two {
        font-size: 1.2rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #262626;
        margin-bottom: 2rem; }
      .student-view .right-panel .card-one .button {
        display: inline-block;
        width: auto;
        max-width: fit-content; }
    .student-view .right-panel .helper-card {
      border-radius: 8px;
      background-color: #f1effb;
      padding: 2.2rem 2rem 3rem 2rem; }
      .student-view .right-panel .helper-card img {
        width: 5.4rem;
        height: 6.4rem;
        object-fit: contain;
        margin-bottom: 1.8rem; }
      .student-view .right-panel .helper-card .text-one {
        font-size: 1.4rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #6746ff; }
      .student-view .right-panel .helper-card .text-two {
        font-size: 1.2rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #262626; }
  .student-view .content-card .button-one-line {
    display: flex;
    flex-wrap: wrap; }
    .student-view .content-card .button-one-line .link-text {
      border-radius: 2px;
      border: solid 1px #f4f7fc;
      background-color: #ffffff;
      padding: 4px 8px;
      display: block;
      align-items: center;
      font-size: 10px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.92;
      letter-spacing: normal;
      text-align: center;
      margin-bottom: 5px;
      margin-right: 5px;
      cursor: pointer; }
    .student-view .content-card .button-one-line .blue {
      color: #4139eb; }
    .student-view .content-card .button-one-line .yellow {
      color: #e69b0a; }
    .student-view .content-card .button-one-line .red {
      color: red; }
    .student-view .content-card .button-one-line .inactive {
      color: #cccccc;
      cursor: not-allowed; }
  .student-view .center-content {
    flex-grow: 1;
    max-width: 100%; }
    .student-view .center-content .content {
      max-width: 50rem;
      margin: 0 auto; }
    .student-view .center-content .header-text {
      font-size: 2rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.1;
      letter-spacing: normal;
      color: #2f3a4f;
      margin-left: 6px; }
      .student-view .center-content .header-text .img {
        width: 3.6rem;
        height: 3.6rem;
        background-color: #1d80d3;
        border-radius: 100%;
        margin-right: .8rem; }
    .student-view .center-content .title-text {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      color: #000000;
      margin-bottom: .8rem;
      display: flex;
      align-items: center;
      justify-content: flex-start; }
  .student-view .grid-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem; }
    .student-view .grid-wrapper.five {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 1rem; }
    .student-view .grid-wrapper.two {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem; }
  .student-view .section {
    margin-top: 2.6rem; }
  .student-view .header-section {
    position: relative; }
    .student-view .header-section.with-img {
      margin-bottom: 2rem; }
    .student-view .header-section .link-text {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.83;
      letter-spacing: normal;
      text-align: right;
      color: #4139eb;
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer; }
  .student-view .courses-card {
    border-radius: .8rem;
    border: solid 1px #d4dbe3;
    background-color: #ffffff;
    padding: 2rem 2rem 2.6rem 2rem;
    margin-bottom: 1.2rem; }
    .student-view .courses-card .button {
      font-size: 10px; }
    .student-view .courses-card .label {
      border-radius: 2px;
      background-color: #f5a623;
      font-size: 10px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      padding: .4rem 1.2rem;
      display: inline-block; }
    .student-view .courses-card .flex-wrap {
      display: flex;
      align-items: center;
      margin-top: 2rem; }
      .student-view .courses-card .flex-wrap > div {
        flex-grow: 1;
        margin-right: 2rem; }
    .student-view .courses-card .text-one {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #262626; }
    .student-view .courses-card .text-two {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #262626; }
  .student-view .teaching-card {
    border-radius: 8px;
    background-color: #f7e7f0;
    margin-right: 2rem;
    padding: 2.2rem 1.8rem 2rem 1.8rem;
    height: 14.5rem;
    overflow: hidden;
    position: relative; }
    .student-view .teaching-card.pink {
      background-color: #f7e7f0; }
    .student-view .teaching-card.green {
      background-color: #e9f1f0; }
    .student-view .teaching-card.blue {
      background-color: #dbe3f0; }
    .student-view .teaching-card .text-one {
      font-size: 10px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: normal;
      color: #262626; }
    .student-view .teaching-card .text-two {
      font-size: 14px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: #262626;
      margin-bottom: 1.8rem; }
    .student-view .teaching-card img {
      width: 2.3rem;
      height: 2.3rem;
      object-fit: contain;
      cursor: pointer;
      position: absolute;
      bottom: 2rem; }
  .student-view .student-card {
    padding-bottom: 1rem; }
    .student-view .student-card .img-wrap {
      width: 92px;
      height: 80px;
      border-radius: 8px;
      background: #dedede;
      margin-bottom: .8rem; }
    .student-view .student-card .text-one {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #262626; }
    .student-view .student-card .text-two {
      font-size: 10px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: normal;
      color: #262626; }
  .student-view .tutorial-video-card .img-wrap {
    height: 160px;
    margin-bottom: 1.2rem;
    overflow: hidden;
    max-width: 100%;
    cursor: pointer;
    position: relative;
    cursor: pointer; }
    .student-view .tutorial-video-card .img-wrap img.play {
      width: 44px;
      height: 43px;
      object-fit: contain;
      position: absolute;
      left: calc(50% - 22px);
      top: calc(50% - 22px); }
    .student-view .tutorial-video-card .img-wrap img.bg {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .student-view .tutorial-video-card .text-one {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #262626;
    margin-bottom: .6rem; }
  .student-view .tutorial-video-card .text-two {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #262626;
    margin-bottom: 1rem; }
  .student-view .projects-card {
    margin-right: 2rem; }
    .student-view .projects-card .img-wrap {
      height: 10rem;
      border-radius: 3px;
      overflow: hidden;
      position: relative;
      margin-bottom: 1.2rem;
      cursor: pointer; }
      .student-view .projects-card .img-wrap img.bg {
        width: 100%;
        height: 100%;
        object-fit: cover; }
      .student-view .projects-card .img-wrap img.play {
        width: 44px;
        height: 43px;
        object-fit: contain;
        position: absolute;
        left: calc(50% - 22px);
        top: calc(50% - 22px); }
    .student-view .projects-card .text-one {
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #262626;
      margin-bottom: .8rem; }
    .student-view .projects-card .text-two {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #262626;
      margin-bottom: 1rem; }
  .student-view .contact-us-card {
    margin-bottom: 2rem; }

.contact-us-card {
  border-radius: .8rem;
  border: solid 1px #d4dbe3;
  background-color: #ffffff;
  padding: 1rem 2rem;
  display: flex;
  align-items: center; }
  .contact-us-card > div:first-child {
    flex-grow: 1; }
  .contact-us-card .text-one {
    font-size: 1.4rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262626;
    margin-bottom: .3rem; }
  .contact-us-card .text-two {
    font-size: 1.2rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #4139eb; }
  .contact-us-card .circle {
    width: 3.8rem;
    height: 3.8rem;
    border-radius: 100%;
    cursor: pointer; }
    .contact-us-card .circle img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain; }

@media screen and (max-width: 768px) {
  .student-view {
    flex-direction: column;
    margin: 0;
    padding-right: 0; }
    .student-view.nopadding {
      padding: 0; }
    .student-view .courses-card .flex-wrap {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start; }
      .student-view .courses-card .flex-wrap > div {
        margin-bottom: 1rem; }
    .student-view .left-panel {
      display: none; }
    .student-view .right-panel {
      min-width: 100%;
      width: 100%;
      padding: 2rem; }
      .student-view .right-panel .card-one {
        flex-direction: row;
        min-height: auto;
        padding: 15px;
        margin-top: 0;
        align-items: center; }
        .student-view .right-panel .card-one .text-one {
          font-size: 14px; }
        .student-view .right-panel .card-one .text-two {
          font-size: 1rem; }
        .student-view .right-panel .card-one .button {
          font-size: 1rem; }
      .student-view .right-panel .ringt-image {
        position: relative;
        top: 0; }
        .student-view .right-panel .ringt-image img {
          max-width: 140px; }
    .student-view .projects-card,
    .student-view .teaching-card {
      margin-right: 1rem; }
    .student-view .center-content {
      max-width: 100%; }
  .contact-us-card .circle {
    width: 3rem;
    height: 3rem; } }

.qidzania-layout .custom-tab .tab-content {
  margin-top: 10px; }

.qidzania-layout .custom-tab .tabs .tab {
  width: 180px; }

.qidzania-layout .custom-tab .tabs .tab-background {
  width: calc(50% - 86px);
  transition: all 0.5s ease; }
  .qidzania-layout .custom-tab .tabs .tab-background.Juniors {
    left: 0; }
  .qidzania-layout .custom-tab .tabs .tab-background.Explorers {
    left: 180px; }
  .qidzania-layout .custom-tab .tabs .tab-background.Seniors {
    left: 360px; }

.qidzania-layout .banner-wrapper {
  background: url(images/qidzania/banner.png) no-repeat;
  background-size: cover;
  background-position: -10px;
  padding: 50px 0 94px 0; }
  .qidzania-layout .banner-wrapper .powered-by {
    font-size: 2rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #fafafa;
    display: flex;
    align-items: center;
    margin-bottom: 2.6rem;
    position: relative;
    left: -8rem; }
    .qidzania-layout .banner-wrapper .powered-by img {
      max-width: 20rem;
      margin-right: 8px; }
  .qidzania-layout .banner-wrapper .banner-text {
    max-width: 370px;
    margin-bottom: 25px;
    display: block; }
  .qidzania-layout .banner-wrapper .logo-img {
    height: 80px;
    margin-bottom: 18px; }
  .qidzania-layout .banner-wrapper .title-text {
    margin: 23px 43px 7px 11px;
    font-size: 34px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.76;
    letter-spacing: 0.26px;
    color: #ffffff; }
  .qidzania-layout .banner-wrapper .sub-text {
    margin: 0 0 10px 0;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #ffffff; }
  .qidzania-layout .banner-wrapper .tird-text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #ffffff;
    max-width: 380px;
    margin-bottom: 20px; }
  .qidzania-layout .banner-wrapper .button {
    border-radius: 1.4px;
    box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    background-image: none;
    margin-top: 18px;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #063bff; }

.qidzania-layout .header-wrap {
  padding: 15px 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  position: absolute;
  z-index: 9;
  left: 0;
  top: 0;
  right: 0;
  background: #063bff; }
  .qidzania-layout .header-wrap .menu {
    display: none; }
  .qidzania-layout .header-wrap .logo-wrap img {
    max-width: 60px;
    width: 100%; }
  .qidzania-layout .header-wrap .nav-wrap {
    flex-grow: 1;
    text-align: right; }
    .qidzania-layout .header-wrap .nav-wrap a {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      margin-right: 35px;
      position: relative; }
      .qidzania-layout .header-wrap .nav-wrap a.with-divider:before {
        content: '';
        position: absolute;
        left: -16px;
        height: 10px;
        border: solid 1px #ffffff;
        top: 6px; }
    .qidzania-layout .header-wrap .nav-wrap button.bordered {
      border-radius: 2px;
      border: solid 1px #ffffff;
      outline: none;
      padding: 10px 32px;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      background: transparent;
      margin-right: 35px;
      cursor: pointer; }
    .qidzania-layout .header-wrap .nav-wrap button.orange {
      border-radius: 2px;
      background-color: #ff8f00;
      border: 1px solid #ff8f00;
      outline: none;
      padding: 10px 48px;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      cursor: pointer; }

.qidzania-layout .wrapper {
  max-width: 1078px;
  margin: 0 auto; }

.qidzania-layout .section.one {
  padding: 100px 0 60px 0; }
  .qidzania-layout .section.one .row {
    display: flex;
    align-items: center;
    position: relative; }
    .qidzania-layout .section.one .row .col {
      flex-grow: 1; }
      .qidzania-layout .section.one .row .col .logo {
        width: 189px;
        height: 98px;
        object-fit: contain;
        margin-bottom: 34px;
        margin-left: -18px; }
      .qidzania-layout .section.one .row .col .text-one {
        font-size: 24px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.08;
        letter-spacing: 0.18px;
        color: #536dfe;
        margin-bottom: 22px; }
      .qidzania-layout .section.one .row .col .text-two {
        font-size: 22px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.18;
        letter-spacing: normal;
        color: #1e174a;
        margin-bottom: 4px; }
      .qidzania-layout .section.one .row .col .text-three {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #1e174a;
        max-width: 340px; }
      .qidzania-layout .section.one .row .col .helper-text {
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.51;
        letter-spacing: normal;
        color: #1e174a;
        position: absolute;
        bottom: 0;
        cursor: pointer; }
      .qidzania-layout .section.one .row .col:last-child {
        max-width: 655px; }
        .qidzania-layout .section.one .row .col:last-child img {
          max-width: 100%; }

.qidzania-layout .section.two {
  background: #f8f9fa;
  width: 100%;
  padding: 60px 0; }
  .qidzania-layout .section.two .row {
    display: flex; }
    .qidzania-layout .section.two .row .col {
      flex-basis: 100%;
      flex-grow: 1;
      border-radius: 6px;
      box-shadow: 0 0 34px 0 rgba(0, 0, 0, 0.12);
      background-color: #ffffff;
      padding: 30px 30px 50px 25px;
      position: relative; }
      .qidzania-layout .section.two .row .col img {
        width: 191px;
        height: 183px;
        object-fit: contain;
        margin-bottom: 24px; }
      .qidzania-layout .section.two .row .col:after {
        content: '';
        opacity: 0.8;
        background-color: #063bff;
        width: 2px;
        height: 40px;
        position: absolute;
        left: 0;
        top: 30px; }
      .qidzania-layout .section.two .row .col .text-one {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: 0.4px;
        color: #2b2b2b; }
      .qidzania-layout .section.two .row .col .text-two {
        margin-top: 20px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: 0.35px;
        color: #2b2b2b; }
      .qidzania-layout .section.two .row .col .text-three {
        font-size: 12.4px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #2f3a4f;
        text-align: left; }
      .qidzania-layout .section.two .row .col:not(:last-child) {
        margin-right: 23px; }
  .qidzania-layout .section.two .grid-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin-bottom: 40px; }
  .qidzania-layout .section.two .cards-wrapper .card {
    max-width: 56rem;
    margin: 0 auto 20px auto; }
    .qidzania-layout .section.two .cards-wrapper .card .text-two {
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #2b2b2b; }
      .qidzania-layout .section.two .cards-wrapper .card .text-two span {
        display: block;
        font-weight: 500; }
        .qidzania-layout .section.two .cards-wrapper .card .text-two span.inline {
          display: inline-block;
          font-weight: 600; }
  .qidzania-layout .section.two .title-text {
    font-size: 25px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.63px;
    text-align: center;
    color: #20211c;
    margin-bottom: 32px; }
    .qidzania-layout .section.two .title-text span {
      font-weight: bold; }
    .qidzania-layout .section.two .title-text.no-margin {
      margin-bottom: 0; }
    .qidzania-layout .section.two .title-text.blue {
      font-size: 25px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.63px;
      text-align: center;
      color: #536dfe;
      margin: 8px 0 32px 0; }
  .qidzania-layout .section.two .card {
    border-radius: 2px;
    border: solid 0.8px #dee2e6;
    background-color: #ffffff;
    padding: 20px; }
    .qidzania-layout .section.two .card .text-one {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.11;
      letter-spacing: normal;
      color: #536dfe;
      margin-bottom: 8px; }
    .qidzania-layout .section.two .card .text-two {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2b2b2b; }

.qidzania-layout .section.three {
  padding: 30px 0;
  margin-bottom: 36px; }
  .qidzania-layout .section.three .second-title-text {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: center;
    color: #black;
    margin-bottom: 30px; }
  .qidzania-layout .section.three .button {
    padding: 14px 59.7px 12px 74px;
    border-radius: 1.4px;
    background-color: #ff8f00;
    text-align: center;
    border-color: #ff8f00;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff; }
  .qidzania-layout .section.three .button-position {
    display: flex;
    justify-content: center; }
  .qidzania-layout .section.three .grid-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin-bottom: 40px; }
  .qidzania-layout .section.three .cards-wrapper .card {
    max-width: 56rem;
    margin: 0 auto 20px auto; }
    .qidzania-layout .section.three .cards-wrapper .card .text-two {
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #2b2b2b; }
      .qidzania-layout .section.three .cards-wrapper .card .text-two span {
        display: block;
        font-weight: 500; }
        .qidzania-layout .section.three .cards-wrapper .card .text-two span.inline {
          display: inline-block;
          font-weight: 600; }
  .qidzania-layout .section.three .title-text {
    font-size: 40px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    color: black;
    text-transform: uppercase;
    margin-bottom: 10px; }
    .qidzania-layout .section.three .title-text span {
      font-weight: bold; }
    .qidzania-layout .section.three .title-text.no-margin {
      margin-bottom: 0; }
    .qidzania-layout .section.three .title-text.blue {
      font-size: 25px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.63px;
      text-align: center;
      color: #536dfe;
      margin: 8px 0 32px 0; }
  .qidzania-layout .section.three .card {
    border-radius: 2px;
    border: solid 0.8px #dee2e6;
    background-color: #ffffff;
    padding: 20px; }
    .qidzania-layout .section.three .card .text-one {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.11;
      letter-spacing: normal;
      color: #536dfe;
      margin-bottom: 8px; }
    .qidzania-layout .section.three .card .text-two {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2b2b2b; }

.qidzania-layout .section.four {
  padding: 80px 0; }
  .qidzania-layout .section.four .title-text {
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.63px;
    text-align: center;
    color: #20211c;
    margin-bottom: 46px; }
  .qidzania-layout .section.four .card-wrapper {
    display: flex;
    align-items: flex-start; }
    .qidzania-layout .section.four .card-wrapper div {
      flex-grow: 1;
      flex-basis: 100%; }
      .qidzania-layout .section.four .card-wrapper div:not(:last-child) {
        margin-right: 20px; }
  .qidzania-layout .section.four .card {
    border-radius: 2px;
    border: solid 0.8px #dee2e6;
    background-color: #ffffff;
    padding: 38px 20px 20px 23px;
    margin-bottom: 20px; }
    .qidzania-layout .section.four .card .text-one {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      color: #536dfe;
      margin-bottom: 12px; }
    .qidzania-layout .section.four .card .text-two {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2b2b2b; }

.qidzania-layout .social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0 0; }
  .qidzania-layout .social-icons .fb,
  .qidzania-layout .social-icons .insta,
  .qidzania-layout .social-icons .linkedin {
    width: 56px;
    height: 56px;
    margin-right: 16px; }
  .qidzania-layout .social-icons .fb {
    background: url(images/landing/icon-fb-grey.svg) no-repeat;
    background-size: 100% 100%; }
    .qidzania-layout .social-icons .fb:hover {
      background: url(images/landing/icon-fb.svg) no-repeat;
      background-size: 100% 100%; }
  .qidzania-layout .social-icons .insta {
    background: url(images/landing/icon-instagram-grey.svg) no-repeat;
    background-size: 100% 100%; }
    .qidzania-layout .social-icons .insta:hover {
      background: url(images/landing/icon-instagram.png) no-repeat;
      background-size: 100% 100%; }
  .qidzania-layout .social-icons .linkedin {
    background: url(images/landing/icon-linkedin-grey.svg) no-repeat;
    background-size: 100% 100%; }
    .qidzania-layout .social-icons .linkedin:hover {
      background: url(images/landing/icon-linkedin.svg) no-repeat;
      background-size: 100% 100%; }
  .qidzania-layout .social-icons img {
    width: 56px;
    height: 56px;
    object-fit: contain;
    margin-right: 16px; }

.qidzania-layout .footer {
  background-color: #0a1929;
  padding: 82px;
  text-align: center; }
  .qidzania-layout .footer p {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 24px; }
  .qidzania-layout .footer .social-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 62px 0 84px 0; }
    .qidzania-layout .footer .social-icons .fb,
    .qidzania-layout .footer .social-icons .insta,
    .qidzania-layout .footer .social-icons .linkedin {
      width: 56px;
      height: 56px;
      margin-right: 16px; }
    .qidzania-layout .footer .social-icons .fb {
      background: url(images/homepage/icon-fb.png) no-repeat;
      background-size: 100% 100%; }
      .qidzania-layout .footer .social-icons .fb:hover {
        background: url(images/landing/icon-fb-grey.svg) no-repeat;
        background-size: 100% 100%; }
    .qidzania-layout .footer .social-icons .insta {
      background: url(images/homepage/icon-instagram.png) no-repeat;
      background-size: 100% 100%; }
      .qidzania-layout .footer .social-icons .insta:hover {
        background: url(images/landing/icon-instagram.png) no-repeat;
        background-size: 100% 100%; }
    .qidzania-layout .footer .social-icons .linkedin {
      background: url(images/homepage/icon-linkedin.png) no-repeat;
      background-size: 100% 100%; }
      .qidzania-layout .footer .social-icons .linkedin:hover {
        background: url(images/landing/icon-linkedin.svg) no-repeat;
        background-size: 100% 100%; }
    .qidzania-layout .footer .social-icons img {
      width: 56px;
      height: 56px;
      object-fit: contain;
      margin-right: 16px; }

.skill-details-view {
  position: relative;
  top: -60px; }
  .skill-details-view .skill-container {
    margin: 0;
    justify-content: center; }
    .skill-details-view .skill-container .skill {
      padding: 30px;
      max-width: 280px;
      cursor: default; }
      .skill-details-view .skill-container .skill .textTwo {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        height: auto;
        margin: 0; }
      .skill-details-view .skill-container .skill .img-wrap {
        text-align: center;
        margin: 0; }
        .skill-details-view .skill-container .skill .img-wrap img {
          height: 59px; }
  .skill-details-view .dates-view {
    display: flex;
    margin: 25px -40px 52px -40px; }
    .skill-details-view .dates-view .date-card {
      flex-basis: 100%;
      flex-grow: 1;
      border-radius: 4px;
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
      border: solid 1px #dee2e6;
      background-color: #ffffff;
      padding: 0; }
      .skill-details-view .dates-view .date-card:last-child {
        background: #fff url(images/qidzania/trophy.png) no-repeat;
        background-size: 97px 103px;
        background-position: bottom right; }
      .skill-details-view .dates-view .date-card .title-wrap {
        padding: 22px 22px 13px 22px;
        border-bottom: solid 1px #f4f6f7; }
      .skill-details-view .dates-view .date-card .list-wrap {
        padding: 0 22px;
        margin: 10px 0; }
      .skill-details-view .dates-view .date-card .line {
        margin: 13px 0 10px; }
      .skill-details-view .dates-view .date-card:not(:last-child) {
        margin-right: 10px; }
      .skill-details-view .dates-view .date-card .text-one {
        font-size: 16px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ff6237; }
      .skill-details-view .dates-view .date-card .text-two {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #2b2b2b;
        background: url(images/qidzania/calendar-icon.png) no-repeat;
        background-size: 24px 24px;
        background-position: center right; }
      .skill-details-view .dates-view .date-card .list-text {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #2b2b2b;
        margin-bottom: 20px;
        position: relative;
        line-height: 1.5; }
        .skill-details-view .dates-view .date-card .list-text:before {
          content: '';
          position: absolute;
          left: -20px;
          width: 2px;
          height: 25px;
          border-radius: 1px;
          background-color: #063bff;
          top: 50%;
          transform: translateY(-50%); }
  .skill-details-view .button-wrap {
    text-align: center; }
    .skill-details-view .button-wrap button {
      padding: 14px 67px 12px;
      border-radius: 1.4px;
      background-color: #063bff;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      border: 0;
      cursor: pointer; }

@media screen and (max-width: 768px) {
  .skill-details-view {
    top: auto;
    padding: 0; }
    .skill-details-view .dates-view {
      margin: 12px;
      flex-direction: column; }
      .skill-details-view .dates-view .date-card {
        min-height: 200px; }
        .skill-details-view .dates-view .date-card:not(:last-child) {
          margin: 0 0 10px 0; }
  .qidzania-layout .header-wrap .nav-wrap {
    display: none; }
  .qidzania-layout .header-wrap .menu {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 20px;
    cursor: pointer; }
  .qidzania-layout .header-wrap .logo-wrap {
    margin-left: 12px; }
    .qidzania-layout .header-wrap .logo-wrap img {
      max-width: 48px; }
  .qidzania-layout .header-wrap .mobile-nav .img-wrap {
    background: #063bff; }
  .qidzania-layout .banner-wrapper {
    padding: 60px 20px;
    background-position: 70% center; }
    .qidzania-layout .banner-wrapper .powered-by {
      left: 0; }
      .qidzania-layout .banner-wrapper .powered-by img {
        max-width: 16rem; }
    .qidzania-layout .banner-wrapper .banner-text {
      max-width: 280px; }
  .qidzania-layout .custom-tab .tabs .tab-background {
    width: 110px; }
    .qidzania-layout .custom-tab .tabs .tab-background.Juniors {
      left: 0; }
    .qidzania-layout .custom-tab .tabs .tab-background.Explorers {
      left: 110px; }
    .qidzania-layout .custom-tab .tabs .tab-background.Seniors {
      left: 220px; }
  .qidzania-layout .custom-tab .tabs .tab {
    width: 110px;
    font-size: 12px;
    padding: 6px 12px; }
    .qidzania-layout .custom-tab .tabs .tab.active {
      padding: 6px 12px; }
  .qidzania-layout .section .wrapper {
    max-width: 100%;
    padding: 0 16px; }
  .qidzania-layout .section.one {
    padding: 40px 0; }
    .qidzania-layout .section.one .row {
      flex-direction: column; }
      .qidzania-layout .section.one .row .col {
        width: 100%; }
        .qidzania-layout .section.one .row .col .logo {
          width: auto;
          height: 60px;
          margin-left: auto; }
        .qidzania-layout .section.one .row .col .text-one {
          font-size: 18px; }
        .qidzania-layout .section.one .row .col .text-two {
          font-size: 16px; }
        .qidzania-layout .section.one .row .col .text-three {
          font-size: 14px; }
        .qidzania-layout .section.one .row .col .helper-text {
          display: none; }
        .qidzania-layout .section.one .row .col:first-child {
          order: 2;
          text-align: center; }
        .qidzania-layout .section.one .row .col:last-child {
          max-width: 100%; }
          .qidzania-layout .section.one .row .col:last-child img {
            max-width: 70%;
            margin: 0 auto;
            display: inherit; }
  .qidzania-layout .section.two {
    padding: 40px 0; }
    .qidzania-layout .section.two .title-text {
      font-size: 16px; }
    .qidzania-layout .section.two .row {
      flex-direction: column; }
      .qidzania-layout .section.two .row .col {
        margin-bottom: 2rem;
        width: 100%; }
        .qidzania-layout .section.two .row .col:not(:last-child) {
          margin-right: 0; }
  .qidzania-layout .section.three .text-one {
    font-size: 22px; }
  .qidzania-layout .section.three .text-two {
    font-size: 16px; }
  .qidzania-layout .section.four .card-wrapper {
    flex-direction: column; }
    .qidzania-layout .section.four .card-wrapper > div {
      width: 100%; }
      .qidzania-layout .section.four .card-wrapper > div:not(:last-child) {
        margin-right: 0; } }

.join-us {
  font-family: "Heebo", sans-serif; }
  .join-us .banner-wrapper {
    background: url(images/join-us/banner.webp) no-repeat;
    background-size: contain;
    max-width: 1080px;
    margin: 0 auto;
    height: 27rem;
    position: relative;
    display: flex;
    justify-content: center; }
    .join-us .banner-wrapper .text-wrapper {
      width: 80rem;
      border-radius: 10px;
      background-color: #ffffff;
      padding: 50px 40px 0 40px;
      margin-top: 28rem; }
      .join-us .banner-wrapper .text-wrapper .text {
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.56;
        letter-spacing: normal;
        color: #ffffff; }
        .join-us .banner-wrapper .text-wrapper .text span {
          font-size: 36px;
          font-weight: 300;
          display: block; }
  .join-us .sections {
    max-width: 710px;
    margin: -40px auto auto;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    padding: 54px 58px;
    position: relative;
    z-index: 1;
    margin-bottom: 180px; }
    .join-us .sections.no-shadow {
      box-shadow: none; }
    .join-us .sections .stepper-container {
      display: none; }
    .join-us .sections .text-one {
      font-size: 14px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      text-align: center;
      color: #262626;
      text-align: left; }
    .join-us .sections .text-one span {
      font-size: 14px;
      color: #262626; }
    .join-us .sections .text-two {
      font-size: 14px;
      font-weight: 500;
      color: #262626;
      margin-bottom: 16px; }
      .join-us .sections .text-two span {
        font-weight: normal;
        display: block; }
    .join-us .sections .form-wrapper .form-field .label {
      font-size: 12px;
      color: #7f8fa4; }
    .join-us .sections .form-wrapper .row {
      display: flex; }
      .join-us .sections .form-wrapper .row.tnc {
        display: block; }
      .join-us .sections .form-wrapper .row.center {
        justify-content: flex-end; }
      .join-us .sections .form-wrapper .row .col {
        flex-grow: 1;
        flex-basis: 100%;
        margin-bottom: 16px;
        position: relative; }
        .join-us .sections .form-wrapper .row .col .ant-tooltip {
          position: absolute;
          right: 12px;
          top: 12px; }
        .join-us .sections .form-wrapper .row .col:not(:last-child) {
          margin-right: 20px; }
        .join-us .sections .form-wrapper .row .col input,
        .join-us .sections .form-wrapper .row .col textarea {
          border-radius: 2px;
          border: solid 1px #adb5c6;
          background-color: #ffffff;
          opacity: 0.9;
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.19;
          letter-spacing: normal;
          color: rgba(53, 64, 82, 0.9);
          width: 100%;
          outline: none;
          padding: 12px 15px; }
          .join-us .sections .form-wrapper .row .col input.error,
          .join-us .sections .form-wrapper .row .col textarea.error {
            border: solid 1px red; }
          .join-us .sections .form-wrapper .row .col input ::-webkit-input-placeholder,
          .join-us .sections .form-wrapper .row .col textarea ::-webkit-input-placeholder {
            /* Edge */
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.19;
            letter-spacing: normal;
            color: rgba(53, 64, 82, 0.9); }
          .join-us .sections .form-wrapper .row .col input :-ms-input-placeholder,
          .join-us .sections .form-wrapper .row .col textarea :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.19;
            letter-spacing: normal;
            color: rgba(53, 64, 82, 0.9); }
          .join-us .sections .form-wrapper .row .col input ::placeholder,
          .join-us .sections .form-wrapper .row .col textarea ::placeholder {
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.19;
            letter-spacing: normal;
            color: rgba(53, 64, 82, 0.9); }
          .join-us .sections .form-wrapper .row .col input:focus,
          .join-us .sections .form-wrapper .row .col textarea:focus {
            border-radius: 2px;
            border: solid 1px #3228ea;
            background-color: #ffffff; }
        .join-us .sections .form-wrapper .row .col textarea {
          min-height: 115px; }
    .join-us .sections .form-wrapper .button {
      padding: 8px 35px;
      border-radius: 4px;
      background-color: #063bff;
      font-size: 12px;
      font-weight: 500;
      color: #ffffff; }
    .join-us .sections .form-wrapper .right {
      margin-right: 10px;
      background-color: #ffffff;
      color: #063bff; }
    .join-us .sections .form-wrapper .ant-radio-input {
      border: none;
      display: none; }
  .join-us .info-wrapper {
    border-radius: 8px;
    box-shadow: 0 2px 44px 0 rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(106deg, #fba649, #f2534c);
    padding: 30px;
    max-width: 1080px;
    margin: 0 auto;
    position: relative;
    margin-bottom: -80px; }
    .join-us .info-wrapper .row {
      display: flex;
      align-items: flex-start; }
      .join-us .info-wrapper .row .col {
        text-align: center; }
        .join-us .info-wrapper .row .col:not(:last-child) {
          margin-right: 32px; }
        .join-us .info-wrapper .row .col:first-child {
          border-right: solid 2px #bb4039;
          padding: 12px 20px 12px 0;
          min-width: 322px; }
        .join-us .info-wrapper .row .col > div {
          height: 40px;
          margin-bottom: 22px;
          display: flex;
          align-items: center;
          justify-content: center; }
          .join-us .info-wrapper .row .col > div img {
            max-height: 100%; }
        .join-us .info-wrapper .row .col .text-one {
          font-size: 30px;
          font-weight: 300;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.75px;
          color: #ffffff;
          text-align: left; }
        .join-us .info-wrapper .row .col .text-two {
          font-size: 16px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.69;
          letter-spacing: normal;
          color: #ffffff; }
          .join-us .info-wrapper .row .col .text-two span {
            display: block; }
        .join-us .info-wrapper .row .col a {
          color: inherit; }
  .join-us .footer {
    padding-top: 200px; }
  .join-us .tnc .ant-checkbox-wrapper {
    display: flex;
    align-items: flex-start; }
    .join-us .tnc .ant-checkbox-wrapper .ant-checkbox + span {
      line-height: 1.2;
      text-align: left; }
  .join-us .stepper-container {
    display: none; }
  .join-us .finish {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin-top: 120px; }
    .join-us .finish .button-last {
      padding: 8px 25px;
      border-radius: 2px;
      background-color: #4139eb;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.6;
      text-align: center;
      color: #ffffff; }
    .join-us .finish .check-img img {
      object-fit: contain;
      margin-bottom: 10px; }
    .join-us .finish .thank-you {
      font-family: "Heebo", sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.31;
      text-align: center;
      color: #ff6237;
      margin: 10px 0; }
    .join-us .finish .final-text {
      font-family: "Heebo", sans-serif;
      font-size: 14px;
      line-height: 1.5;
      text-align: center;
      color: #000000;
      margin-bottom: 20px; }

@media screen and (max-width: 768px) {
  .static {
    padding: 0; }
  .join-us {
    padding-top: 54px; }
    .join-us .banner-wrapper {
      background: url(images/join-us/group-3.webp) no-repeat;
      background-size: cover;
      position: relative;
      display: flex;
      justify-content: center;
      background-position: center center;
      height: 176px; }
      .join-us .banner-wrapper .text-wrapper {
        border-radius: 10px;
        background-color: #ffffff;
        margin-top: 23rem;
        padding: 0 20px; }
        .join-us .banner-wrapper .text-wrapper .text {
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.56;
          letter-spacing: normal;
          color: #ffffff; }
          .join-us .banner-wrapper .text-wrapper .text span {
            font-size: 36px;
            font-weight: 300;
            display: block; }
    .join-us .sections {
      padding: 20px;
      border-radius: 0;
      margin: 0 auto 80px auto;
      box-shadow: none; }
      .join-us .sections .text-one {
        font-size: 12px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        text-align: center;
        color: #262626;
        text-align: left; }
      .join-us .sections .text-one span {
        font-size: 12px;
        color: #262626; }
      .join-us .sections .text-two {
        font-size: 14px;
        font-weight: 500;
        color: #262626; }
        .join-us .sections .text-two span {
          font-weight: normal;
          display: block; }
      .join-us .sections .form-wrapper .form-field .label {
        font-size: 12px;
        color: #7f8fa4; }
      .join-us .sections .form-wrapper .button {
        width: auto; }
      .join-us .sections .form-wrapper .row {
        display: flex;
        flex-direction: column; }
        .join-us .sections .form-wrapper .row.tnc {
          display: block; }
        .join-us .sections .form-wrapper .row.center {
          justify-content: center;
          flex-direction: row;
          margin-top: 40px; }
        .join-us .sections .form-wrapper .row .col {
          flex-grow: 1;
          flex-basis: 100%;
          margin-bottom: 16px;
          position: relative; }
          .join-us .sections .form-wrapper .row .col .ant-tooltip {
            position: absolute;
            right: 12px;
            top: 12px; }
          .join-us .sections .form-wrapper .row .col:not(:last-child) {
            margin-right: 0; }
          .join-us .sections .form-wrapper .row .col input,
          .join-us .sections .form-wrapper .row .col textarea {
            border-radius: 2px;
            border: solid 1px #adb5c6;
            background-color: #ffffff;
            opacity: 0.9;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.19;
            letter-spacing: normal;
            color: rgba(53, 64, 82, 0.9);
            width: 100%;
            outline: none;
            padding: 12px 15px; }
            .join-us .sections .form-wrapper .row .col input.error,
            .join-us .sections .form-wrapper .row .col textarea.error {
              border: solid 1px red; }
            .join-us .sections .form-wrapper .row .col input ::-webkit-input-placeholder,
            .join-us .sections .form-wrapper .row .col textarea ::-webkit-input-placeholder {
              /* Edge */
              font-size: 16px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: 1.19;
              letter-spacing: normal;
              color: rgba(53, 64, 82, 0.9); }
            .join-us .sections .form-wrapper .row .col input :-ms-input-placeholder,
            .join-us .sections .form-wrapper .row .col textarea :-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              font-size: 16px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: 1.19;
              letter-spacing: normal;
              color: rgba(53, 64, 82, 0.9); }
            .join-us .sections .form-wrapper .row .col input ::placeholder,
            .join-us .sections .form-wrapper .row .col textarea ::placeholder {
              font-size: 16px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: 1.19;
              letter-spacing: normal;
              color: rgba(53, 64, 82, 0.9); }
            .join-us .sections .form-wrapper .row .col input:focus,
            .join-us .sections .form-wrapper .row .col textarea:focus {
              border-radius: 2px;
              border: solid 1px #3228ea;
              background-color: #ffffff; }
          .join-us .sections .form-wrapper .row .col textarea {
            min-height: 115px; }
      .join-us .sections .form-wrapper .button {
        padding: 8px 25px;
        border-radius: 4px;
        background-color: #063bff;
        font-size: 12px;
        font-weight: 500;
        color: #ffffff; }
      .join-us .sections .form-wrapper .right {
        margin-right: 10px;
        background-color: #ffffff;
        color: #063bff; }
      .join-us .sections .form-wrapper .ant-radio-input {
        border: none;
        display: none; }
    .join-us .info-wrapper {
      border-radius: 8px;
      box-shadow: 0 2px 44px 0 rgba(0, 0, 0, 0.5);
      background-image: linear-gradient(106deg, #fba649, #f2534c);
      padding: 30px;
      margin: 0 auto;
      position: relative;
      margin-bottom: -80px; }
      .join-us .info-wrapper .row {
        display: flex;
        align-items: flex-start; }
        .join-us .info-wrapper .row .col {
          text-align: center; }
          .join-us .info-wrapper .row .col:not(:last-child) {
            margin-right: 32px; }
          .join-us .info-wrapper .row .col:first-child {
            border-right: solid 2px #bb4039;
            padding: 12px 20px 12px 0;
            min-width: 322px; }
          .join-us .info-wrapper .row .col > div {
            height: 40px;
            margin-bottom: 22px;
            display: flex;
            align-items: center;
            justify-content: center; }
            .join-us .info-wrapper .row .col > div img {
              max-height: 100%; }
          .join-us .info-wrapper .row .col .text-one {
            font-size: 30px;
            font-weight: 300;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.75px;
            color: #ffffff;
            text-align: left; }
          .join-us .info-wrapper .row .col .text-two {
            font-size: 16px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.69;
            letter-spacing: normal;
            color: #ffffff; }
            .join-us .info-wrapper .row .col .text-two span {
              display: block; }
          .join-us .info-wrapper .row .col a {
            color: inherit; }
    .join-us .footer {
      padding-top: 200px; }
    .join-us .tnc .ant-checkbox-wrapper {
      display: flex;
      align-items: flex-start; }
      .join-us .tnc .ant-checkbox-wrapper .ant-checkbox + span {
        line-height: 1.2;
        text-align: left; }
    .join-us .stepper-container {
      display: none; }
    .join-us .finish {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0; }
      .join-us .finish .button-last {
        padding: 8px 25px;
        border-radius: 2px;
        background-color: #4139eb;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.6;
        text-align: center;
        color: #ffffff; }
      .join-us .finish .check-img img {
        object-fit: contain;
        margin-bottom: 10px; }
      .join-us .finish .thank-you {
        font-family: "Heebo", sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.31;
        text-align: center;
        color: #ff6237;
        margin: 10px 0; }
      .join-us .finish .final-text {
        font-family: "Heebo", sans-serif;
        font-size: 14px;
        line-height: 1.5;
        text-align: center;
        color: #000000;
        margin-bottom: 20px; } }

.days-time-wrapper-regular > div > div {
  background: #ffffff;
  border-radius: 2px;
  margin-bottom: 12px;
  border: none; }

.days-time-wrapper-regular .time-input-wrapper {
  display: flex; }
  .days-time-wrapper-regular .time-input-wrapper div {
    flex-grow: 1;
    flex-basis: 100%; }

.days-time-wrapper-regular .days-wrapper {
  display: flex;
  align-items: center; }
  .days-time-wrapper-regular .days-wrapper .day {
    margin-right: 1rem;
    width: 103px; }
    .days-time-wrapper-regular .days-wrapper .day p {
      border-radius: 2px;
      background-color: white;
      border: solid 1px rgba(190, 190, 190, 0.52) !important;
      padding: 1rem 2.5rem;
      font-size: 1.2rem;
      line-height: 1.29;
      text-align: center;
      color: #2f3a4f;
      display: inline-block;
      width: calc(100% - 0.5rem);
      text-align: center;
      text-transform: capitalize !important;
      cursor: pointer; }
      .days-time-wrapper-regular .days-wrapper .day p.ui-error.input {
        border: 1px solid rgba(235, 62, 85, 0.35) !important; }
      .days-time-wrapper-regular .days-wrapper .day p.selected {
        border: 1px solid #063bff !important;
        background: #eef1ff; }
    .days-time-wrapper-regular .days-wrapper .day:last-child button {
      width: 100%; }
  .days-time-wrapper-regular .days-wrapper .day-slot {
    margin-right: 0;
    margin-bottom: 1.5rem; }
    .days-time-wrapper-regular .days-wrapper .day-slot p {
      border-radius: 2px;
      background-color: white;
      border: solid 1px rgba(190, 190, 190, 0.52) !important;
      padding: 0.8rem 1.5rem;
      font-size: 1.2rem;
      line-height: 1.29;
      text-align: center;
      color: #2f3a4f;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      text-align: center;
      margin: 0;
      text-transform: capitalize !important;
      cursor: pointer; }
      .days-time-wrapper-regular .days-wrapper .day-slot p.ui-error.input {
        border: 1px solid rgba(235, 62, 85, 0.35) !important; }
      .days-time-wrapper-regular .days-wrapper .day-slot p.selected {
        border: 1px solid #063bff !important;
        background: #eef1ff; }
    .days-time-wrapper-regular .days-wrapper .day-slot:last-child button {
      width: 100%; }

.regular-container {
  /* padding: 12px 20px; */ }
  .regular-container .content {
    display: flex;
    align-items: flex-start;
    max-width: none !important; }
  .regular-container .filter {
    display: flex;
    justify-content: flex-end;
    margin: 0 12px 12px 0;
    vertical-align: bottom; }
    .regular-container .filter .button {
      background-color: #4139eb;
      border: 0;
      font-size: 1.2rem;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 0.5px;
      color: inherit;
      padding: 0.9rem 1.2rem;
      cursor: pointer;
      display: inline-block;
      border-radius: 4px;
      color: #fff; }
    .regular-container .filter .disabled {
      background-color: #4139eb;
      border: 0;
      font-size: 1.2rem;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 0.5px;
      color: inherit;
      padding: 0.9rem 1.2rem;
      cursor: pointer;
      display: inline-block;
      border-radius: 4px;
      color: #fff;
      opacity: 0.3; }
  .regular-container .right-panel {
    width: 56rem;
    padding: 4rem 0rem 0;
    background: #f4f7fa;
    min-width: 480px;
    /* width: 583px; */
    margin-left: 20px;
    height: auto; }
    .regular-container .right-panel .back-button {
      margin: 20px 24px; }
      .regular-container .right-panel .back-button .ant-btn {
        display: none;
        align-items: center;
        padding: 15px 10px;
        border-radius: 5.6px;
        border: solid 0.7px #d4dbe3;
        margin: 0;
        width: 35px;
        height: 35px; }
      .regular-container .right-panel .back-button .shape-arrow {
        width: 11px;
        height: 7px;
        object-fit: contain;
        background-color: #2f3a4f; }
    .regular-container .right-panel .count-heading {
      margin: 0px 354px 13px 20px;
      font-family: Heebo;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2f3a4f; }
    .regular-container .right-panel .content-card {
      position: relative;
      max-width: 56rem;
      border: solid 1px #d4dbe3;
      background-color: #ffffff;
      color: #7f8fa4;
      border-radius: 0.8rem;
      margin: 13px 20px 10px 20px;
      padding: 20px 78px 20px 19px; }
      .regular-container .right-panel .content-card.selected {
        border: solid 1px #4139eb; }
      .regular-container .right-panel .content-card.demoTrainer {
        background-color: #2f2b46;
        color: #ffffff; }
      .regular-container .right-panel .content-card .filter {
        display: flex;
        justify-content: flex-end;
        margin: 0 12px 12px 0;
        vertical-align: bottom; }
        .regular-container .right-panel .content-card .filter .book-btn {
          background-color: #4139eb;
          border: 0;
          font-size: 1.2rem;
          font-style: normal;
          font-stretch: normal;
          letter-spacing: 0.5px;
          color: inherit;
          padding: 0.9rem 1.2rem;
          cursor: pointer;
          display: inline-block;
          border-radius: 4px;
          color: #fff; }
        .regular-container .right-panel .content-card .filter .button {
          background-color: #4139eb;
          border: 0;
          font-size: 1.2rem;
          font-style: normal;
          font-stretch: normal;
          letter-spacing: 0.5px;
          color: inherit;
          padding: 0.9rem 1.2rem;
          cursor: pointer;
          display: inline-block;
          border-radius: 4px;
          color: #fff; }
      .regular-container .right-panel .content-card .row {
        display: flex;
        margin-top: 10px;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal; }
        .regular-container .right-panel .content-card .row .col:first-child {
          max-width: 69px;
          width: 100%; }
        .regular-container .right-panel .content-card .row .col:last-child {
          flex-grow: 1; }
          .regular-container .right-panel .content-card .row .col:last-child > div {
            display: inline-block;
            margin: 0 10px 10px 0;
            padding: 4px;
            border-radius: 4px;
            border: solid 1px #ced0da;
            background-color: #ffffff;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #525f78;
            cursor: pointer; }
            .regular-container .right-panel .content-card .row .col:last-child > div.selected {
              border: solid 1px #063bff;
              color: #063bff;
              background: #eef1ff; }
      .regular-container .right-panel .content-card .flex-wrap {
        margin-bottom: 0.8rem;
        display: flex;
        align-items: center; }
      .regular-container .right-panel .content-card .text-one {
        background-color: #f5a623;
        font-size: 1rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #262626;
        border-radius: 2px;
        padding: 5px 10px;
        display: inline-block; }
      .regular-container .right-panel .content-card .date-time {
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #262626;
        flex-grow: 1; }
      .regular-container .right-panel .content-card .bottom-wrap {
        margin-top: 1.8rem;
        align-items: center;
        display: flex; }
        .regular-container .right-panel .content-card .bottom-wrap .trainer-wrap {
          padding: 0;
          flex-grow: 1;
          text-align: right; }
      .regular-container .right-panel .content-card .name-header {
        display: flex;
        margin-bottom: 18px;
        align-items: center; }
        .regular-container .right-panel .content-card .name-header .mmms {
          object-fit: contain; }
        .regular-container .right-panel .content-card .name-header .heading-name {
          display: flex;
          flex-direction: column;
          justify-content: center; }
          .regular-container .right-panel .content-card .name-header .heading-name .header-text {
            margin-left: 6px;
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            margin-bottom: 2px; }
            .regular-container .right-panel .content-card .name-header .heading-name .header-text.demoTrainerHeading {
              color: #ffffff; }
          .regular-container .right-panel .content-card .name-header .heading-name .header-text-two {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            margin-left: 6px; }
          .regular-container .right-panel .content-card .name-header .heading-name .header-text-two-demo {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #ffffff;
            margin-left: 6px; }
  .regular-container .right-panel-mobile {
    display: none;
    z-index: 100;
    padding: 4rem 0rem 0;
    background: #f4f7fa;
    min-width: 370px;
    /* width: 583px; */
    margin-left: 20px;
    height: auto; }
    .regular-container .right-panel-mobile .back-button {
      margin: 20px 24px; }
      .regular-container .right-panel-mobile .back-button .ant-btn {
        display: flex;
        align-items: center;
        padding: 15px 10px;
        border-radius: 5.6px;
        border: solid 0.7px #d4dbe3;
        margin: 0;
        width: 35px;
        height: 35px; }
      .regular-container .right-panel-mobile .back-button .shape-arrow {
        width: 11px;
        height: 7px;
        object-fit: contain;
        background-color: #2f3a4f; }
    .regular-container .right-panel-mobile .count-heading {
      margin: 0px 344px 13px 20px;
      font-family: Heebo;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2f3a4f; }
    .regular-container .right-panel-mobile .content-card {
      display: flex;
      flex-direction: column;
      position: relative;
      border: solid 1px #d4dbe3;
      background-color: #ffffff;
      border-radius: 0.8rem;
      padding: 10px;
      margin: 0px; }
      .regular-container .right-panel-mobile .content-card.selected {
        border: solid 1px #4139eb; }
      .regular-container .right-panel-mobile .content-card.demoTrainer {
        background-color: #2f2b46; }
      .regular-container .right-panel-mobile .content-card .row {
        display: flex;
        margin-top: 0px;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff; }
        .regular-container .right-panel-mobile .content-card .row .col:first-child {
          max-width: 69px;
          width: 100%; }
        .regular-container .right-panel-mobile .content-card .row .col:last-child {
          flex-grow: 1; }
          .regular-container .right-panel-mobile .content-card .row .col:last-child > div {
            display: inline-block;
            margin: 0 5px 7px 0;
            padding: 4px;
            border-radius: 4px;
            border: solid 1px #ced0da;
            background-color: #ffffff;
            font-size: 10px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #525f78;
            cursor: pointer; }
            .regular-container .right-panel-mobile .content-card .row .col:last-child > div.selected {
              border: solid 1px #063bff;
              color: #063bff; }
      .regular-container .right-panel-mobile .content-card .flex-wrap {
        margin-bottom: 0.8rem;
        display: flex;
        align-items: center; }
      .regular-container .right-panel-mobile .content-card .text-one {
        background-color: #f5a623;
        font-size: 1rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        border-radius: 2px;
        padding: 5px 10px;
        display: inline-block; }
      .regular-container .right-panel-mobile .content-card .date-time {
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #262626;
        flex-grow: 1; }
      .regular-container .right-panel-mobile .content-card .bottom-wrap {
        margin-top: 1.8rem;
        align-items: center;
        display: flex; }
        .regular-container .right-panel-mobile .content-card .bottom-wrap .trainer-wrap {
          padding: 0;
          flex-grow: 1;
          text-align: right; }
      .regular-container .right-panel-mobile .content-card .name-header {
        display: flex;
        margin-bottom: 18px;
        align-items: center; }
        .regular-container .right-panel-mobile .content-card .name-header .mmms {
          height: 22px;
          width: 22px;
          margin-top: 17px;
          object-fit: contain; }
        .regular-container .right-panel-mobile .content-card .name-header .heading-name {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-top: 17px; }
          .regular-container .right-panel-mobile .content-card .name-header .heading-name .header-text {
            margin-left: 6px;
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            color: #ffffff;
            margin-bottom: 2px; }
          .regular-container .right-panel-mobile .content-card .name-header .heading-name .header-text-two {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #ffffff;
            margin-left: 6px; }
  .regular-container .form-container {
    margin-left: 83px;
    margin-right: 83px;
    margin-top: 25px; }
    .regular-container .form-container .title-text {
      font-size: 16px;
      font-weight: bold;
      color: #2f3a4f; }
    .regular-container .form-container .text {
      font-size: 12px;
      font-weight: normal;
      line-height: 1.83;
      color: #000000; }
    .regular-container .form-container .form-wrapper {
      margin-top: 16px; }
      .regular-container .form-container .form-wrapper .inline-wrapper {
        margin-bottom: 0rem; }
      .regular-container .form-container .form-wrapper .form-field .ant-select-selection__rendered {
        font-size: 12px; }
      .regular-container .form-container .form-wrapper .form-field .label {
        font-size: 12px;
        font-weight: normal;
        line-height: normal;
        color: #7f8fa4;
        margin-bottom: 6px !important; }
      .regular-container .form-container .form-wrapper .form-field .ant-input {
        font-size: 12px; }
    .regular-container .form-container .ant-radio-button-wrapper {
      font-size: 12px; }

@media screen and (max-width: 768px) {
  .days-time-wrapper-regular > div > div {
    background: #ffffff;
    border-radius: 2px;
    margin-bottom: 12px;
    border: none; }
  .days-time-wrapper-regular .time-input-wrapper {
    display: flex; }
    .days-time-wrapper-regular .time-input-wrapper div {
      flex-grow: 1;
      flex-basis: 100%; }
  .days-time-wrapper-regular .days-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
    .days-time-wrapper-regular .days-wrapper .day {
      margin-right: 1rem;
      margin-bottom: 1.5rem;
      width: 103px; }
      .days-time-wrapper-regular .days-wrapper .day p {
        border-radius: 2px;
        background-color: white;
        border: solid 1px rgba(190, 190, 190, 0.52) !important;
        padding: 1rem 2.5rem;
        font-size: 1.2rem;
        line-height: 1.29;
        text-align: center;
        color: #2f3a4f;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: calc(100% - 0.5rem);
        text-align: center;
        text-transform: capitalize !important;
        cursor: pointer; }
        .days-time-wrapper-regular .days-wrapper .day p.ui-error.input {
          border: 1px solid rgba(235, 62, 85, 0.35) !important; }
        .days-time-wrapper-regular .days-wrapper .day p.selected {
          border: 1px solid #063bff !important;
          background: #eef1ff; }
      .days-time-wrapper-regular .days-wrapper .day:last-child button {
        width: 100%; }
    .days-time-wrapper-regular .days-wrapper .day-slot {
      margin-right: 0;
      margin-bottom: 1.5rem; }
      .days-time-wrapper-regular .days-wrapper .day-slot p {
        border-radius: 2px;
        background-color: white;
        border: solid 1px rgba(190, 190, 190, 0.52) !important;
        padding: 1rem 1.5rem;
        font-size: 1.2rem;
        line-height: 1.29;
        text-align: center;
        color: #2f3a4f;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;
        text-transform: capitalize !important;
        cursor: pointer; }
        .days-time-wrapper-regular .days-wrapper .day-slot p.ui-error.input {
          border: 1px solid rgba(235, 62, 85, 0.35) !important; }
        .days-time-wrapper-regular .days-wrapper .day-slot p.selected {
          border: 1px solid #063bff !important; }
      .days-time-wrapper-regular .days-wrapper .day-slot:last-child button {
        width: 100%; }
  .regular-container {
    /* padding: 12px 20px; */ }
    .regular-container .content {
      display: flex;
      align-items: flex-start;
      max-width: none !important; }
    .regular-container .filter {
      display: flex;
      justify-content: center;
      margin: 0;
      vertical-align: bottom; }
      .regular-container .filter .book-btn {
        border: 0;
        background-color: #4139eb;
        font-size: 1.2rem;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: 0.5px;
        color: inherit;
        padding: 0.7rem 11rem;
        cursor: pointer;
        display: inline-block;
        border-radius: 2px;
        color: #fff; }
      .regular-container .filter .button {
        border: 0;
        background-color: #4139eb;
        font-size: 1.2rem;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: 0.5px;
        color: inherit;
        padding: 0.7rem 2rem;
        cursor: pointer;
        display: inline-block;
        border-radius: 2px;
        color: #fff; }
      .regular-container .filter .disabled {
        background-color: #4139eb;
        border: 0;
        font-size: 1.2rem;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: 0.5px;
        color: inherit;
        padding: 0.9rem 1.6rem;
        cursor: pointer;
        display: inline-block;
        border-radius: 4px;
        color: #fff;
        opacity: 0.3; }
    .regular-container .right-panel {
      display: none;
      z-index: 100;
      padding: 4rem 0rem 0;
      background: #f4f7fa;
      min-width: 480px;
      /* width: 583px; */
      margin-left: 20px;
      height: auto; }
      .regular-container .right-panel .back-button {
        margin: 20px 24px; }
        .regular-container .right-panel .back-button .ant-btn {
          display: flex;
          align-items: center;
          padding: 15px 10px;
          border-radius: 5.6px;
          border: solid 0.7px #d4dbe3;
          margin: 0;
          width: 35px;
          height: 35px; }
        .regular-container .right-panel .back-button .shape-arrow {
          width: 11px;
          height: 7px;
          object-fit: contain;
          background-color: #2f3a4f; }
      .regular-container .right-panel .count-heading {
        margin: 0px 354px 13px 20px;
        font-family: Heebo;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #2f3a4f; }
      .regular-container .right-panel .content-card {
        display: flex;
        flex-direction: column;
        position: relative;
        border: solid 1px #d4dbe3;
        background-color: #ffffff;
        border-radius: 0.8rem;
        padding: 10px;
        margin: 0px; }
        .regular-container .right-panel .content-card.selected {
          border: solid 1px #4139eb; }
        .regular-container .right-panel .content-card.demoTrainer {
          background-color: #2f2b46; }
        .regular-container .right-panel .content-card .row {
          display: flex;
          margin-top: 0px;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #ffffff; }
          .regular-container .right-panel .content-card .row .col:first-child {
            max-width: 69px;
            width: 100%; }
          .regular-container .right-panel .content-card .row .col:last-child {
            flex-grow: 1; }
            .regular-container .right-panel .content-card .row .col:last-child > div {
              display: inline-block;
              margin: 0 5px 7px 0;
              padding: 4px;
              border-radius: 4px;
              border: solid 1px #ced0da;
              background-color: #f8f9fa;
              font-size: 10px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #525f78;
              cursor: pointer; }
              .regular-container .right-panel .content-card .row .col:last-child > div.selected {
                border: solid 1px #063bff;
                color: #063bff; }
        .regular-container .right-panel .content-card .flex-wrap {
          margin-bottom: 0.8rem;
          display: flex;
          align-items: center; }
        .regular-container .right-panel .content-card .text-one {
          background-color: #f5a623;
          font-size: 1rem;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #ffffff;
          border-radius: 2px;
          padding: 5px 10px;
          display: inline-block; }
        .regular-container .right-panel .content-card .date-time {
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #262626;
          flex-grow: 1; }
        .regular-container .right-panel .content-card .bottom-wrap {
          margin-top: 1.8rem;
          align-items: center;
          display: flex; }
          .regular-container .right-panel .content-card .bottom-wrap .trainer-wrap {
            padding: 0;
            flex-grow: 1;
            text-align: right; }
        .regular-container .right-panel .content-card .name-header {
          display: flex;
          margin-bottom: 18px;
          align-items: center; }
          .regular-container .right-panel .content-card .name-header .mmms {
            height: 22px;
            width: 22px;
            margin-top: 17px;
            object-fit: contain; }
          .regular-container .right-panel .content-card .name-header .heading-name {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 17px; }
            .regular-container .right-panel .content-card .name-header .heading-name .header-text {
              margin-left: 6px;
              font-size: 12px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.33;
              letter-spacing: normal;
              color: #ffffff;
              margin-bottom: 2px; }
            .regular-container .right-panel .content-card .name-header .heading-name .header-text-two {
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #ffffff;
              margin-left: 6px; }
    .regular-container .right-panel-mobile {
      display: flex;
      flex-direction: column;
      position: absolute;
      z-index: 100;
      padding: 2rem 2rem 6rem 2rem;
      background: #ffffff;
      min-width: 370px;
      /* width: 583px; */
      margin-left: 0;
      height: 80rem; }
      .regular-container .right-panel-mobile .mobile-header {
        display: flex;
        flex-direction: row;
        align-items: center; }
        .regular-container .right-panel-mobile .mobile-header .back-button {
          margin: 20px 10px 20px 0; }
          .regular-container .right-panel-mobile .mobile-header .back-button .ant-btn {
            display: flex;
            align-items: center;
            padding: 15px 10px;
            border-radius: 5.6px;
            border: solid 0.7px #d4dbe3;
            margin: 0;
            width: 35px;
            height: 35px; }
          .regular-container .right-panel-mobile .mobile-header .back-button .shape-arrow {
            width: 11px;
            height: 7px;
            object-fit: contain;
            background-color: #2f3a4f; }
        .regular-container .right-panel-mobile .mobile-header .count-heading {
          margin: 0;
          font-family: Heebo;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #2f3a4f; }
      .regular-container .right-panel-mobile .content-card {
        display: flex;
        flex-direction: column;
        position: relative;
        border: solid 1px #d4dbe3;
        background-color: #ffffff;
        border-radius: 0.8rem;
        padding: 10px;
        margin-bottom: 10px; }
        .regular-container .right-panel-mobile .content-card.selected {
          border: solid 1px #4139eb; }
        .regular-container .right-panel-mobile .content-card.demoTrainer {
          background-color: #2f2b46; }
        .regular-container .right-panel-mobile .content-card .row {
          display: flex;
          margin-top: 0px;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #7f8fa4; }
          .regular-container .right-panel-mobile .content-card .row .col:first-child {
            max-width: 69px;
            width: 100%; }
          .regular-container .right-panel-mobile .content-card .row .col:last-child {
            flex-grow: 1; }
            .regular-container .right-panel-mobile .content-card .row .col:last-child > div {
              display: inline-block;
              margin: 0 5px 7px 0;
              padding: 4px;
              border-radius: 4px;
              border: solid 1px #ced0da;
              background-color: #ffffff;
              font-size: 10px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #7f8fa4;
              cursor: pointer; }
              .regular-container .right-panel-mobile .content-card .row .col:last-child > div.selected {
                border: solid 1px #063bff;
                color: #063bff;
                background-color: #eef1ff; }
        .regular-container .right-panel-mobile .content-card .flex-wrap {
          margin-bottom: 0.8rem;
          display: flex;
          align-items: center; }
        .regular-container .right-panel-mobile .content-card .text-one {
          background-color: #f5a623;
          font-size: 1rem;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #ffffff;
          border-radius: 2px;
          padding: 5px 10px;
          display: inline-block; }
        .regular-container .right-panel-mobile .content-card .date-time {
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #262626;
          flex-grow: 1; }
        .regular-container .right-panel-mobile .content-card .bottom-wrap {
          margin-top: 1.8rem;
          align-items: center;
          display: flex; }
          .regular-container .right-panel-mobile .content-card .bottom-wrap .trainer-wrap {
            padding: 0;
            flex-grow: 1;
            text-align: right; }
        .regular-container .right-panel-mobile .content-card .name-header {
          display: flex;
          margin-bottom: 18px;
          align-items: center; }
          .regular-container .right-panel-mobile .content-card .name-header .mmms {
            height: 22px;
            width: 22px;
            margin-top: 17px;
            object-fit: contain; }
          .regular-container .right-panel-mobile .content-card .name-header .heading-name {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 17px; }
            .regular-container .right-panel-mobile .content-card .name-header .heading-name .header-text {
              margin-left: 6px;
              font-size: 12px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.33;
              letter-spacing: normal;
              color: #262626;
              margin-bottom: 2px; }
              .regular-container .right-panel-mobile .content-card .name-header .heading-name .header-text.demoTrainerHeading {
                color: #ffffff; }
            .regular-container .right-panel-mobile .content-card .name-header .heading-name .header-text-two {
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #7f8fa4;
              margin-left: 6px; }
            .regular-container .right-panel-mobile .content-card .name-header .heading-name .header-text-two-demo {
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #ffffff;
              margin-left: 6px; }
    .regular-container .form-container {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 2rem;
      padding-bottom: 8rem; }
      .regular-container .form-container .title-text {
        font-size: 16px;
        font-weight: bold;
        color: #2f3a4f;
        margin: 0; }
      .regular-container .form-container .text {
        font-size: 12px;
        font-weight: normal;
        line-height: 1.83;
        color: #000000; }
      .regular-container .form-container .form-wrapper {
        margin-top: 16px; }
        .regular-container .form-container .form-wrapper .form-field {
          margin-bottom: 15px; }
          .regular-container .form-container .form-wrapper .form-field .ant-select-selection__rendered {
            font-size: 12px; }
          .regular-container .form-container .form-wrapper .form-field .label {
            font-size: 12px;
            font-weight: normal;
            line-height: normal;
            color: #7f8fa4;
            margin-bottom: 6px !important; }
      .regular-container .form-container .ant-radio-button-wrapper {
        font-size: 12px;
        width: 71px;
        text-align: center; } }

.container-form {
  max-width: 100%;
  background: url(images/lead-form/banner.png) no-repeat;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  width: 100%;
  background-attachment: fixed;
  background-size: auto 80vh; }

.header {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  background-color: none;
  margin: 0;
  height: 100px; }
  .header img {
    height: 50px; }
  .header .heading {
    display: none;
    flex-direction: column;
    justify-content: center;
    padding: 0 0.5rem; }
    .header .heading .bold {
      font-size: 13px;
      font-weight: 900;
      line-height: 1.92;
      color: #2f3a4f; }
    .header .heading p {
      font-size: 10px;
      line-height: 1.2;
      color: #2f3a4f; }

.layout {
  display: flex;
  flex-direction: row;
  padding: 5rem 10rem 10vh 10rem;
  align-items: center;
  justify-content: space-around;
  align-items: flex-end;
  min-height: calc(100vh - 100px);
  /****************
*******Thank you ***
*****************/
  /***************selext date section***********************/
  /***************selext time section***********************/
  /***************bottom button section***********************/
  /******************/ }
  .layout .container-padding,
  .layout .sucContainer {
    background-color: #ffffff;
    padding: 30px;
    width: 420px;
    border-radius: 10px;
    box-shadow: 2px 18px 10px 0px #caccd0;
    min-height: 500px; }
  .layout .sucContainer {
    display: flex;
    align-items: center; }
  .layout .otpcontainer .otpheder {
    display: flex;
    align-items: center;
    width: 100%; }
  .layout .otpcontainer .otpheder p {
    font-size: 14px;
    font-weight: bolder;
    margin-left: 15px; }
  .layout .otpcontainer .iconcontainer {
    padding: 14px 14px;
    border-radius: 8px;
    border: 1px solid #cdcdcd; }
  .layout .otpcontainer .otpsection {
    margin-top: 70px; }
  .layout .otpcontainer .para {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px; }
  .layout .otpcontainer .blue {
    color: blue; }
  .layout .otpcontainer .otpinputsection {
    margin-top: 20px; }
  .layout .otpcontainer .otpinputsection input {
    margin: 0 3px !important;
    width: 40px !important;
    border: 1px solid #d4dbe3;
    border-radius: 6px;
    height: 40px !important;
    font-size: 14px; }
  .layout .otpcontainer .resend {
    text-align: center;
    color: #cdcdcd;
    font-size: 12px;
    margin: 15px 0;
    display: block; }
  .layout .otp-resend-root {
    display: block !important; }
  .layout .otpcontainer .bottomsection {
    margin-top: 90px; }
  .layout .otpcontainer .sendButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px auto 0 auto; }
  .layout .otpcontainer .submit {
    width: 94%;
    margin: 10px auto 25px auto;
    display: flex;
    justify-content: center;
    height: auto;
    padding: 10px 22px;
    font-size: 16px;
    background-color: blue;
    color: #fff; }
  .layout .sucWrapper {
    width: 100%;
    text-align: center; }
  .layout .sucWrapper p {
    font-size: 14px;
    font-weight: 600;
    color: #000;
    text-align: center;
    padding: 0 25px;
    line-height: 20px; }
  .layout .blue {
    color: #4139eb !important;
    font-weight: 600 !important; }
  .layout .sucWrapper .imageContainer {
    display: flex;
    justify-content: center;
    margin: 0 auto 25px auto; }
  .layout .slotWrapper.slotWrapper .heder {
    display: flex;
    align-items: center; }
  .layout .slotWrapper.slotWrapper .heder p {
    font-size: 14px;
    font-weight: bolder;
    margin-left: 15px; }
  .layout .slotWrapper.slotWrapper .iconcontainer {
    padding: 14px 14px;
    border-radius: 8px;
    border: 1px solid #cdcdcd; }
  .layout .slotWrapper .datepickheader {
    font-size: 12px;
    font-weight: bolder;
    margin-top: 50px;
    margin-bottom: 15px; }
  .layout .slotWrapper .form-field .available-slots-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap; }
    .layout .slotWrapper .form-field .available-slots-wrapper .time-slot {
      border-radius: 8px;
      border: 1px solid #adb5c6;
      margin: 5px;
      background-color: #fff;
      font-size: 10px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      padding: 10px 8px;
      cursor: pointer;
      color: #262626; }
      .layout .slotWrapper .form-field .available-slots-wrapper .time-slot.selected {
        background-color: #4139eb;
        color: white; }
  .layout .slotWrapper .datepick {
    display: flex;
    justify-content: flex-start;
    align-items: center; }
    .layout .slotWrapper .datepick .time-slot {
      text-align: center;
      border-radius: 8px;
      border: solid 1px #adb5c6;
      background-color: #fff;
      font-size: 10px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #262626;
      padding: 8px 10px;
      width: 55px;
      cursor: pointer;
      margin-right: 10px; }
      .layout .slotWrapper .datepick .time-slot.selected {
        background-color: #4139eb;
        color: white; }
  .layout .slotWrapper .dates {
    border: 1px solid #d4dbe3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 10px;
    width: 55px;
    border-radius: 8px;
    margin-right: 10px; }
  .layout .slotWrapper .active {
    background-color: #4139eb;
    color: #fff; }
  .layout .slotWrapper .dates p {
    font-size: 10px;
    line-height: 1.3; }
  .layout .slotWrapper .date {
    font-size: 15px !important; }
  .layout .slotWrapper .timeheader {
    font-size: 12px;
    font-weight: bolder;
    margin-top: 30px;
    margin-bottom: 15px; }
    .layout .slotWrapper .timeheader .info-type {
      font-size: 12px;
      font-weight: bold;
      line-height: 16px; }
  .layout .slotWrapper .timeSots {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    font-size: 10px;
    letter-spacing: 1; }
  .layout .slotWrapper .slot {
    border: 1px solid #d4dbe3;
    border-radius: 8px;
    display: inline-block;
    padding: 15px 5px;
    margin: 0px 3px 11px 0;
    letter-spacing: 0.1rem; }
  .layout .slotWrapper .blue {
    color: #4139eb !important; }
  .layout .slotWrapper .timeactive {
    background-color: #4139eb;
    color: white; }
  .layout .slotWrapper .timeInactive {
    background-color: #f1f2f6;
    color: #d4dbe3;
    cursor: not-allowed; }
  .layout .slotWrapper .footesection {
    margin-top: 50px;
    margin-bottom: 20px; }
  .layout .slotWrapper .footesection p {
    font-size: 14px;
    color: #4139eb;
    text-align: center;
    font-weight: 600;
    margin-bottom: 12px; }
  .layout .slotWrapper .confirm {
    color: #fff;
    width: 100%;
    padding: 12px 22px;
    height: auto;
    font-size: 16px;
    background-color: #4139eb; }
  .layout .sucWrapper p {
    font-size: 14px;
    font-weight: 600;
    color: #000;
    text-align: center;
    padding: 0 25px; }
  .layout .sucWrapper .imageContainer {
    display: flex;
    justify-content: center;
    margin: 0 auto 25px auto; }
  .layout .left-content {
    display: flex;
    flex-direction: column;
    padding: 6rem 9rem 0 0; }
    .layout .left-content .lingo-text {
      display: flex;
      flex-direction: column; }
      .layout .left-content .lingo-text .text-one {
        font-size: 60px;
        font-weight: 900;
        line-height: 0.83;
        color: #ffffff;
        margin-bottom: 0.5rem; }
        .layout .left-content .lingo-text .text-one span {
          background-color: #ff6237;
          line-height: 1.3; }
      .layout .left-content .lingo-text .text-two {
        font-size: 18px;
        font-weight: 500;
        line-height: 1.33;
        color: #ffffff; }
        .layout .left-content .lingo-text .text-two span {
          background-color: #2f2b46;
          line-height: 1.3; }
    .layout .left-content .carousel-wrapper .slick-track {
      display: flex !important;
      width: 100%; }
    .layout .left-content .carousel-wrapper button.slick-arrow {
      right: 0;
      top: 180px;
      bottom: auto;
      left: auto;
      z-index: 9;
      bottom: auto; }
      .layout .left-content .carousel-wrapper button.slick-arrow:before {
        display: none; }
    .layout .left-content .carousel-wrapper button.slick-prev {
      left: 0;
      right: auto; }
    .layout .left-content .slick-slider {
      position: relative;
      display: block; }
    .layout .left-content .lead-card {
      display: flex !important;
      flex-direction: row;
      margin-top: 1rem;
      padding: 2rem 0;
      height: 145px; }
      .layout .left-content .lead-card .one {
        max-width: 100%;
        border: black;
        border-radius: 10px;
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
        background-color: #ffffff;
        margin-right: 1rem;
        padding: 1.5rem; }
      .layout .left-content .lead-card .text-one {
        font-size: 14px;
        font-weight: bold;
        color: #393939;
        margin-bottom: 1rem;
        letter-spacing: 0.35px;
        line-height: normal; }
      .layout .left-content .lead-card .text-two {
        font-size: 14px;
        letter-spacing: 0.35px;
        line-height: normal;
        color: #393939; }
  .layout .lead-form {
    background-color: #ffffff;
    padding: 30px;
    max-width: 420px;
    border-radius: 10px;
    box-shadow: 2px 18px 10px 0px #caccd0;
    min-height: 500px; }
    .layout .lead-form .county-phone {
      display: flex; }
      .layout .lead-form .county-phone select {
        width: 136px !important;
        margin-right: 2%; }
      .layout .lead-form .county-phone .with-dial-code {
        width: 81% !important;
        padding-left: 0.5rem !important;
        border-radius: 0 4px 4px 0 !important; }
    .layout .lead-form .inline-wrapper {
      display: flex;
      flex-direction: column; }
      .layout .lead-form .inline-wrapper #dob {
        margin-top: 1rem; }
      .layout .lead-form .inline-wrapper .form-field {
        margin-bottom: 1rem;
        position: relative; }
        .layout .lead-form .inline-wrapper .form-field .inline-error {
          position: relative;
          top: 0.8rem;
          left: 0;
          margin-bottom: 0.8rem;
          color: #f51e1e;
          display: block; }
        .layout .lead-form .inline-wrapper .form-field .label {
          font-size: 14px;
          color: #262626; }
        .layout .lead-form .inline-wrapper .form-field input {
          border-radius: 4px;
          font-size: 12px;
          font-weight: 400;
          line-height: 1.36;
          letter-spacing: normal;
          color: #7f8fa4;
          padding: 1.5rem 1.5rem 1.5rem 4rem;
          width: 100%;
          outline: none;
          height: auto;
          background-color: #f0f1f7;
          border: 0; }
        .layout .lead-form .inline-wrapper .form-field .email {
          background-color: #f0f1f7;
          border-radius: 4px; }
        .layout .lead-form .inline-wrapper .form-field #inputId:-webkit-autofill {
          background: white !important; }
        .layout .lead-form .inline-wrapper .form-field .dial-code-lead {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100px;
          height: 49px;
          background-color: #f0f1f7;
          border: solid 1px #adb5c6;
          border-radius: 4px 0 0 4px;
          border: 0;
          padding: 1rem;
          font-size: 12px;
          line-height: 1.19;
          color: #7f8fa4; }
        .layout .lead-form .inline-wrapper .form-field .no-dialing-code {
          padding-left: 1.5rem !important; }
        .layout .lead-form .inline-wrapper .form-field select {
          border-radius: 4px;
          font-size: 12px;
          font-weight: 400;
          line-height: 1.36;
          letter-spacing: normal;
          color: #7f8fa4;
          padding: 1.5rem 1.2rem;
          width: 100%;
          outline: none;
          height: auto;
          background-color: #f0f1f7;
          border: 0; }
          .layout .lead-form .inline-wrapper .form-field select option {
            color: #525f78; }
        .layout .lead-form .inline-wrapper .form-field .custom-tab {
          margin-top: 1rem; }
          .layout .lead-form .inline-wrapper .form-field .custom-tab .tab-content {
            margin-top: 70px; }
          .layout .lead-form .inline-wrapper .form-field .custom-tab .tabs {
            border-radius: 2px;
            /* background-color: #eff1f3; */
            padding: 0px;
            display: flex;
            position: relative;
            background-color: #ffffff;
            border: solid 0.9px #d1d8e1;
            border-radius: 3.6px; }
            .layout .lead-form .inline-wrapper .form-field .custom-tab .tabs .tab {
              font-size: 12px;
              padding: 1rem 0.5rem;
              background-color: #ffffff;
              color: #2f3a4f;
              width: 100%;
              border: solid 0.9px #fff;
              border-radius: 3.6px; }
              .layout .lead-form .inline-wrapper .form-field .custom-tab .tabs .tab div {
                width: 100%;
                text-align: center; }
            .layout .lead-form .inline-wrapper .form-field .custom-tab .tabs .tab:after {
              content: "";
              position: absolute;
              right: 0;
              width: 1px;
              background: #ddd;
              height: 10px; }
            .layout .lead-form .inline-wrapper .form-field .custom-tab .tabs .tab:last-child::after {
              content: "";
              position: absolute;
              right: 0; }
            .layout .lead-form .inline-wrapper .form-field .custom-tab .tabs .tab.active {
              border: solid 0.9px #063bff;
              background-color: #eef1ff;
              color: #063bff; }
            .layout .lead-form .inline-wrapper .form-field .custom-tab .tabs .tab-background {
              transition: all 0.5s ease;
              box-shadow: none; }
              .layout .lead-form .inline-wrapper .form-field .custom-tab .tabs .tab-background.Toddler {
                left: 0; }
              .layout .lead-form .inline-wrapper .form-field .custom-tab .tabs .tab-background.Junior {
                left: 180; }
              .layout .lead-form .inline-wrapper .form-field .custom-tab .tabs .tab-background.Junior + {
                left: 360; }
              .layout .lead-form .inline-wrapper .form-field .custom-tab .tabs .tab-background.Explorer {
                left: 480px; }
    .layout .lead-form .tnc {
      margin-top: 1rem; }
      .layout .lead-form .tnc .ant-checkbox-wrapper .ant-checkbox + span {
        font-size: 12px;
        margin-bottom: 1rem; }
    .layout .lead-form .btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 2rem; }
      .layout .lead-form .btn .blue {
        padding: 1.4rem 4.6rem;
        border-radius: 2px;
        background-color: #063bff;
        font-size: 16px;
        font-weight: 500;
        color: #ffffff !important;
        width: 100%; }
      .layout .lead-form .btn p {
        margin-top: 1rem;
        font-size: 12px;
        color: #2f3a4f; }

/******** slider button by soutik*******/
.left-content .carousel-wrapper
.slick-arrow {
  background-color: #fff;
  z-index: 99 !important;
  top: 55% !important;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 5px;
  box-shadow: 0px 1px 7px -3px #000; }
  .left-content .carousel-wrapper
.slick-arrow.slick-prev {
    background-image: url(images/lead-form/left-arrow.png);
    left: -34px !important; }
  .left-content .carousel-wrapper
.slick-arrow.slick-next {
    background-image: url(images/lead-form/right-arrow.png);
    right: -34px !important; }

/********slider button by soutik*******/
@media screen and (max-width: 768px) {
  .container-form {
    max-width: 768px;
    margin: 0 auto;
    height: 100%;
    background: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
    flex-direction: column;
    width: 100%; }
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    background-color: #ccc5e3;
    margin: 0; }
    .header img {
      height: 50px; }
    .header .heading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 0.5rem; }
      .header .heading .bold {
        font-size: 13px;
        font-weight: 900;
        line-height: 1.92;
        color: #2f3a4f; }
      .header .heading p {
        font-size: 10px;
        line-height: 1.2;
        color: #2f3a4f; }
  .layout {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px;
    justify-content: flex-start;
    /****************
*******Thank you ***
*****************/
    /***************selext date section***********************/
    /***************selext time section***********************/
    /***************bottom button section***********************/
    /*******************/
    /******************/ }
    .layout .container-padding {
      width: 100%;
      margin: 0 auto;
      padding: 1.5rem;
      box-shadow: none; }
    .layout .otpcontainer .otpheder {
      display: flex;
      align-items: center;
      width: 100%; }
    .layout .otpcontainer .otpheder p {
      font-size: 14px;
      font-weight: bolder;
      margin-left: 15px; }
    .layout .otpcontainer .iconcontainer {
      padding: 14px 14px;
      border-radius: 8px;
      border: 1px solid #cdcdcd; }
    .layout .otpcontainer .otpsection {
      margin-top: 70px; }
    .layout .otpcontainer .para {
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px; }
    .layout .otpcontainer .blue {
      color: blue; }
    .layout .otpcontainer .otpinputsection {
      margin-top: 20px; }
    .layout .otpcontainer .otpinputsection input {
      margin: 0 3px !important;
      width: 40px !important;
      border: 1px solid #d4dbe3;
      border-radius: 6px;
      height: 40px !important;
      font-size: 14px; }
    .layout .otpcontainer .resend {
      text-align: center;
      color: #cdcdcd;
      font-size: 12px;
      margin: 15px 0;
      display: block; }
    .layout .otp-resend-root {
      display: block !important; }
    .layout .otpcontainer .sendButton {
      margin-top: 90px; }
    .layout .otpcontainer .sendButton {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 25px auto 0 auto; }
    .layout .otpcontainer .submit {
      width: 94%;
      margin: 10px auto 25px auto;
      display: flex;
      justify-content: center;
      height: auto;
      padding: 10px 22px;
      font-size: 16px;
      background-color: blue;
      color: white; }
    .layout .sucWrapper p {
      font-size: 14px;
      font-weight: 600;
      color: #000;
      text-align: center;
      padding: 0 25px;
      line-height: 20px; }
    .layout .blue {
      color: #4139eb !important;
      font-weight: 600 !important; }
    .layout .sucWrapper .imageContainer {
      display: flex;
      justify-content: center;
      margin: 0 auto 25px auto; }
    .layout .slotWrapper.slotWrapper .heder {
      display: flex;
      align-items: center; }
    .layout .slotWrapper.slotWrapper .heder p {
      font-size: 14px;
      font-weight: bolder;
      margin-left: 15px; }
    .layout .slotWrapper.slotWrapper .iconcontainer {
      padding: 14px 14px;
      border-radius: 8px;
      border: 1px solid #cdcdcd; }
    .layout .slotWrapper .datepickheader {
      font-size: 12px;
      font-weight: bolder;
      margin-top: 50px;
      margin-bottom: 15px; }
    .layout .slotWrapper .form-field .available-slots-wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-start; }
      .layout .slotWrapper .form-field .available-slots-wrapper .time-slot {
        border-radius: 7px;
        border: 1px solid #adb5c6;
        margin: 5px 5px 5px 0;
        font-size: 10px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        padding: 10px 8px;
        cursor: pointer;
        background-color: #fff;
        color: #262626; }
        .layout .slotWrapper .form-field .available-slots-wrapper .time-slot.selected {
          background-color: #4139eb;
          color: white; }
    .layout .slotWrapper .datepick {
      display: flex;
      align-items: center; }
      .layout .slotWrapper .datepick .time-slot {
        border-radius: 8px;
        border: solid 1px #adb5c6;
        background-color: #fff;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #262626;
        padding: 8px 10px;
        width: 55px;
        cursor: pointer;
        margin-right: 10px;
        text-align: center; }
        .layout .slotWrapper .datepick .time-slot.selected {
          background-color: #4139eb;
          color: white; }
    .layout .slotWrapper .dates {
      border: 1px solid #d4dbe3;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 8px 10px;
      width: 55px;
      border-radius: 8px;
      margin-right: 10px; }
    .layout .slotWrapper .active {
      background-color: #4139eb;
      color: #fff; }
    .layout .slotWrapper .dates p {
      font-size: 10px;
      line-height: 1.3; }
    .layout .slotWrapper .date {
      font-size: 15px !important; }
    .layout .slotWrapper .timeheader {
      font-size: 12px;
      font-weight: bolder;
      margin-top: 30px;
      margin-bottom: 15px; }
      .layout .slotWrapper .timeheader .info-type {
        font-size: 12px;
        font-weight: bold;
        line-height: 16px; }
    .layout .slotWrapper .timeSots {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      font-size: 10px;
      letter-spacing: 1; }
    .layout .slotWrapper .slot {
      border: 1px solid #d4dbe3;
      border-radius: 8px;
      display: inline-block;
      padding: 15px 5px;
      margin: 0px 3px 11px 0;
      letter-spacing: 0.1rem; }
    .layout .slotWrapper .blue {
      color: #4139eb !important; }
    .layout .slotWrapper .timeactive {
      background-color: #4139eb;
      color: white; }
    .layout .slotWrapper .timeInactive {
      background-color: #f1f2f6;
      color: #d4dbe3;
      cursor: not-allowed; }
    .layout .slotWrapper .footesection {
      margin-top: 50px;
      margin-bottom: 20px; }
    .layout .slotWrapper .footesection p {
      font-size: 14px;
      color: #4139eb;
      text-align: center;
      font-weight: 600;
      margin-bottom: 12px; }
    .layout .slotWrapper .confirm {
      color: #fff;
      width: 100%;
      padding: 12px 22px;
      height: auto;
      font-size: 16px;
      background-color: #4139eb; }
    .layout .sucContainer {
      width: 100%;
      margin: 0 auto;
      height: 80vh;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: none; }
    .layout .sucWrapper p {
      font-size: 14px;
      font-weight: 600;
      color: #000;
      text-align: center;
      padding: 0 25px; }
    .layout .sucWrapper .imageContainer {
      display: flex;
      justify-content: center;
      margin: 0 auto 25px auto; }
    .layout .left-content {
      display: none; }
    .layout .lead-form {
      background-color: #ffffff;
      padding: 1.5rem;
      border-radius: 10px;
      box-shadow: none;
      width: 100%;
      margin: 0 auto;
      min-height: 200px; }
      .layout .lead-form .county-phone {
        display: flex; }
        .layout .lead-form .county-phone select {
          width: 136px !important;
          margin-right: 2%; }
        .layout .lead-form .county-phone input {
          width: 60% !important;
          padding-left: 1.5rem !important;
          border-radius: 0 4px 4px 0 !important; }
        .layout .lead-form .county-phone .with-dial-code {
          width: 60% !important;
          padding-left: 1.5rem !important;
          border-radius: 0 4px 4px 0 !important; }
      .layout .lead-form .inline-wrapper {
        display: flex;
        flex-direction: column; }
        .layout .lead-form .inline-wrapper #dob {
          margin-top: 1rem; }
        .layout .lead-form .inline-wrapper .form-field {
          margin-bottom: 0.5rem;
          position: relative; }
          .layout .lead-form .inline-wrapper .form-field .inline-error {
            position: relative;
            top: 0.8rem;
            left: 0;
            margin-bottom: 0.8rem;
            color: #f51e1e;
            display: block; }
          .layout .lead-form .inline-wrapper .form-field .label {
            font-size: 12px;
            color: #262626; }
          .layout .lead-form .inline-wrapper .form-field input {
            border-radius: 4px;
            font-size: 10px;
            font-weight: 400;
            line-height: 1.36;
            letter-spacing: normal;
            color: #7f8fa4;
            padding: 1.5rem 1.5rem 1.5rem 4rem;
            width: 100%;
            outline: none;
            height: auto;
            background-color: #f0f1f7;
            border: 0; }
          .layout .lead-form .inline-wrapper .form-field .email {
            background-color: #f0f1f7;
            border-radius: 4px; }
          .layout .lead-form .inline-wrapper .form-field #inputId:-webkit-autofill {
            background: white !important; }
          .layout .lead-form .inline-wrapper .form-field .dial-code-lead {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 46px;
            background-color: #f0f1f7;
            border: solid 1px #adb5c6;
            border-radius: 4px 0 0 4px;
            border: 0;
            padding: 1rem;
            font-size: 10px;
            line-height: 1.19;
            color: #7f8fa4;
            width: 48px; }
          .layout .lead-form .inline-wrapper .form-field .no-dialing-code {
            padding-left: 1.5rem !important; }
          .layout .lead-form .inline-wrapper .form-field select {
            border-radius: 4px;
            font-size: 10px;
            font-weight: 400;
            line-height: 1.36;
            letter-spacing: normal;
            color: #7f8fa4;
            padding: 1.5rem 1.2rem;
            width: 100%;
            outline: none;
            height: auto;
            background-color: #f0f1f7;
            border: 0; }
            .layout .lead-form .inline-wrapper .form-field select option {
              color: #525f78; }
          .layout .lead-form .inline-wrapper .form-field .custom-tab {
            margin-top: 1rem; }
            .layout .lead-form .inline-wrapper .form-field .custom-tab .tab-content {
              margin-top: 70px; }
            .layout .lead-form .inline-wrapper .form-field .custom-tab .tabs {
              border-radius: 2px;
              /* background-color: #eff1f3; */
              padding: 0px;
              display: flex;
              position: relative;
              background-color: #ffffff;
              border: solid 0.9px #d1d8e1;
              border-radius: 3.6px; }
              .layout .lead-form .inline-wrapper .form-field .custom-tab .tabs .tab {
                font-size: 10px;
                padding: 1.4rem 0.5rem;
                background-color: #ffffff;
                color: #2f3a4f;
                width: 100%;
                border: solid 0.9px #fff;
                border-radius: 3.6px; }
                .layout .lead-form .inline-wrapper .form-field .custom-tab .tabs .tab div {
                  width: 100%;
                  text-align: center; }
              .layout .lead-form .inline-wrapper .form-field .custom-tab .tabs .tab:after {
                content: "";
                position: absolute;
                right: 0;
                width: 1px;
                height: 20px;
                background-color: #ddd; }
              .layout .lead-form .inline-wrapper .form-field .custom-tab .tabs .tab:last-child::after {
                content: "";
                display: none; }
              .layout .lead-form .inline-wrapper .form-field .custom-tab .tabs .tab.active {
                border: solid 0.9px #063bff;
                background-color: #eef1ff;
                color: #063bff; }
              .layout .lead-form .inline-wrapper .form-field .custom-tab .tabs .tab-background {
                transition: all 0.5s ease;
                box-shadow: none; }
                .layout .lead-form .inline-wrapper .form-field .custom-tab .tabs .tab-background.Toddler {
                  left: 0; }
                .layout .lead-form .inline-wrapper .form-field .custom-tab .tabs .tab-background.Junior {
                  left: 180; }
                .layout .lead-form .inline-wrapper .form-field .custom-tab .tabs .tab-background.Junior + {
                  left: 360; }
                .layout .lead-form .inline-wrapper .form-field .custom-tab .tabs .tab-background.Explorer {
                  left: 480px; }
      .layout .lead-form .tnc {
        margin-top: 1rem; }
        .layout .lead-form .tnc .ant-checkbox-wrapper .ant-checkbox + span {
          font-size: 12px;
          margin-bottom: 1rem; }
      .layout .lead-form .btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 2rem; }
        .layout .lead-form .btn .blue {
          padding: 1.4rem 4.6rem;
          border-radius: 2px;
          background-color: #063bff;
          font-size: 16px;
          font-weight: 500;
          color: #ffffff !important;
          width: 100%; }
        .layout .lead-form .btn p {
          margin-top: 1rem;
          font-size: 12px;
          color: #2f3a4f; }
    .layout .carousel-wrapper .slick-track {
      display: flex !important;
      width: 100%; }
    .layout .carousel-wrapper button.slick-arrow {
      right: 0;
      top: 180px;
      bottom: auto;
      left: auto;
      z-index: 9;
      bottom: auto; }
      .layout .carousel-wrapper button.slick-arrow:before {
        display: none; }
    .layout .carousel-wrapper button.slick-prev {
      left: 0;
      right: auto; }
    .layout .slick-slider {
      position: relative;
      display: block; }
    .layout .lead-card {
      width: 100% !important;
      margin: 0 0;
      padding: 10px; }
      .layout .lead-card .one {
        width: 100%;
        max-width: 100%;
        border: black;
        border-radius: 10px;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
        background-color: #ffffff;
        margin-right: 0;
        padding: 1.5rem;
        min-height: 110px; }
      .layout .lead-card .text-one {
        font-size: 14px;
        font-weight: bold;
        color: #393939;
        margin-bottom: 1rem;
        letter-spacing: 0.35px;
        line-height: normal; }
      .layout .lead-card .text-two {
        font-size: 14px;
        letter-spacing: 0.35px;
        line-height: normal;
        color: #393939; } }

.schedule-view-student-wrapper {
  max-width: 1220px;
  width: 80%;
  margin: 0 auto; }
  .schedule-view-student-wrapper .delete-schedule {
    display: flex; }
  .schedule-view-student-wrapper .days-wrapper {
    margin-bottom: 10px; }

.lead-page-layout {
  position: relative;
  font-family: "Heebo";
  overflow: hidden; }
  .lead-page-layout .carousel-wrapper .slick-track {
    display: flex !important; }
  .lead-page-layout .carousel-wrapper .slick-slide {
    height: inherit !important; }
  .lead-page-layout .carousel-wrapper button.slick-arrow {
    border-radius: 0;
    border: 0;
    background: url("") no-repeat;
    color: transparent;
    padding: 0;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.16px;
    text-align: center;
    outline: none;
    cursor: pointer;
    position: absolute;
    right: 40%;
    background-size: 99% 99%;
    width: 58px;
    height: 58px;
    object-fit: contain; }
    .lead-page-layout .carousel-wrapper button.slick-arrow:before {
      display: none; }
    .lead-page-layout .carousel-wrapper button.slick-arrow.slick-disabled {
      opacity: 0.2; }
  .lead-page-layout .carousel-wrapper button.slick-prev {
    left: 40%;
    background: url(images/static/arrowleft.svg) no-repeat;
    background-size: 99% 99%;
    width: 58px;
    height: 58px;
    object-fit: contain; }
  .lead-page-layout .carousel-wrapper button.slick-arrow {
    position: absolute;
    top: 110%;
    margin-top: -15px; }
  .lead-page-layout .sticky-element {
    z-index: 998;
    position: fixed;
    width: 100%; }
    .lead-page-layout .sticky-element .home {
      background: transparent; }
      .lead-page-layout .sticky-element .home .nav-wrap a {
        color: #fff;
        display: inline-block; }
      .lead-page-layout .sticky-element .home .nav-wrap .ant-dropdown-link .dark {
        display: none; }
      .lead-page-layout .sticky-element .home .nav-wrap .ant-dropdown-link .light {
        display: inline-block; }
      .lead-page-layout .sticky-element .home .nav-wrap .ant-dropdown-link span {
        display: flex;
        align-items: center; }
        .lead-page-layout .sticky-element .home .nav-wrap .ant-dropdown-link span img {
          margin-left: 4px;
          -webkit-transition-duration: 0.5s;
          -moz-transition-duration: 0.5s;
          -o-transition-duration: 0.5s;
          transition-duration: 0.5s;
          -webkit-transition-property: -webkit-transform;
          -moz-transition-property: -moz-transform;
          -o-transition-property: -o-transform;
          transition-property: transform; }
      .lead-page-layout .sticky-element .home .nav-wrap .ant-dropdown-link.ant-dropdown-open span img {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg); }
    .lead-page-layout .sticky-element.sticky .home {
      border-bottom: solid 1px #efefef;
      background-color: #ffffff; }
      .lead-page-layout .sticky-element.sticky .home .nav-wrap a {
        color: #35353c; }
        .lead-page-layout .sticky-element.sticky .home .nav-wrap a.with-divider:before {
          border: solid 1px #000; }
      .lead-page-layout .sticky-element.sticky .home .nav-wrap .ant-dropdown-link .dark {
        display: inline-block; }
      .lead-page-layout .sticky-element.sticky .home .nav-wrap .ant-dropdown-link .light {
        display: none; }
      .lead-page-layout .sticky-element.sticky .home .white-bordered {
        border: solid 1px #063bff;
        color: #063bff;
        background: transparent; }
  .lead-page-layout .header-wrapper .logo-wrap img {
    max-width: 100%;
    max-height: 50px;
    width: auto; }
  .lead-page-layout .header-wrapper .nav-wrap a.with-divider:before {
    border: solid 1px #fff; }
  .lead-page-layout .banner-wrapper {
    background: url("");
    background-size: cover;
    background-position: top center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100vh; }
    .lead-page-layout .banner-wrapper .scroll-text {
      font-size: 19px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.11;
      letter-spacing: normal;
      color: #ffffff;
      position: absolute;
      left: 0;
      bottom: -150px;
      cursor: pointer; }
    .lead-page-layout .banner-wrapper .container {
      max-width: 1180px;
      margin: 0 auto;
      display: flex;
      position: relative; }
      .lead-page-layout .banner-wrapper .container .slider-tag {
        display: inline-block;
        color: #fff;
        font-size: 14px;
        font-weight: normal;
        background-color: rgba(0, 0, 0, 0.31);
        border-radius: 3px;
        margin: 2px 0;
        padding: 8px; }
      .lead-page-layout .banner-wrapper .container .content-wrap {
        flex-grow: 1;
        margin-right: 647px; }
        .lead-page-layout .banner-wrapper .container .content-wrap .text-one {
          font-size: 38px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.65;
          letter-spacing: normal;
          color: #ffffff; }
        .lead-page-layout .banner-wrapper .container .content-wrap .text-two {
          font-size: 75px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 0.88;
          letter-spacing: normal;
          color: #ffffff;
          margin-bottom: 12px; }
        .lead-page-layout .banner-wrapper .container .content-wrap .text-three {
          font-size: 20px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.06;
          letter-spacing: normal;
          color: #ffffff;
          margin-bottom: 32px; }
          .lead-page-layout .banner-wrapper .container .content-wrap .text-three span {
            font-weight: 500; }
        .lead-page-layout .banner-wrapper .container .content-wrap .orange {
          background: #ff5b13;
          color: #ffffff;
          border: solid 1px #ff5b13;
          margin-bottom: 10px; }
      .lead-page-layout .banner-wrapper .container .video-wrap {
        max-width: 510px;
        min-width: 510px;
        border-radius: 6px;
        background: url(images/homepage/video-thumbnail.webp) no-repeat;
        background-size: cover;
        background-position: center center;
        height: 360px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative; }
        .lead-page-layout .banner-wrapper .container .video-wrap .thumbnail {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          object-fit: cover; }
        .lead-page-layout .banner-wrapper .container .video-wrap img.play {
          width: 76px;
          height: 76px;
          object-fit: contain;
          cursor: pointer;
          position: absolute;
          z-index: 2;
          margin-top: -22px; }

.lead-section-third {
  background: url("");
  background-size: cover;
  background-position: 30%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 2rem 6rem 0rem 6rem;
  background-color: #f2fafe; }
  .lead-section-third .heading-two {
    display: flex;
    justify-content: flex-end;
    padding: 0 22rem;
    font-size: 18px;
    line-height: 1.67;
    color: #4d4d4d; }
    .lead-section-third .heading-two span {
      font-weight: bold; }
  .lead-section-third .r-layout {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-left: 2rem;
    margin-bottom: 5rem;
    padding: 1.5rem 5rem; }
  .lead-section-third .rect {
    width: 153px;
    height: 184px;
    margin: 1rem;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    border-radius: 12px;
    background-color: #ffffff;
    padding: 2rem;
    padding-bottom: 13px; }
    .lead-section-third .rect:hover {
      box-shadow: 9px 30px 64px -25px #9a9797; }
  .lead-section-third .r-text {
    font-size: 16px;
    text-align: center;
    color: #4d4d4d; }
  .lead-section-third .rect-layout {
    width: 133px;
    height: 125px;
    border-radius: 16px;
    position: absolute;
    top: 305px;
    display: flex;
    justify-content: center;
    align-items: center; }
  .lead-section-third .r-one {
    background-color: #f8e8f0; }
  .lead-section-third .r-two {
    background-color: #fff9e7; }
  .lead-section-third .r-three {
    background-color: #d1d8fd; }
  .lead-section-third .heading-two {
    display: flex;
    justify-content: flex-end;
    padding: 0 22rem;
    font-size: 18px;
    line-height: 1.67;
    color: #4d4d4d; }
    .lead-section-third .heading-two span {
      font-weight: bold; }
  .lead-section-third .r-layout {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-left: 2rem;
    margin-bottom: 5rem;
    padding: 1.5rem 5rem; }
  .lead-section-third .rect {
    width: 153px;
    height: 184px;
    margin: 1rem;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    border-radius: 12px;
    background-color: #ffffff;
    padding: 2rem; }
  .lead-section-third .r-text {
    font-size: 16px;
    text-align: center;
    color: #4d4d4d; }
  .lead-section-third .rect-layout {
    width: 133px;
    height: 125px;
    border-radius: 16px;
    position: absolute;
    top: 305px; }
  .lead-section-third .r-one {
    background-color: #f8e8f0; }
  .lead-section-third .r-two {
    background-color: #fff9e7; }
  .lead-section-third .r-three {
    background-color: #d1d8fd; }
  .lead-section-third .box {
    padding: 2rem;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    background-color: #2f2b46;
    display: flex;
    justify-content: space-between;
    align-items: baseline; }
  .lead-section-third .box-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 6rem; }
  .lead-section-third .testimonial-page {
    width: 1080px;
    height: 578px;
    margin: 51px 0.3px 98px 20.4px;
    padding: 0 6rem; }
  .lead-section-third .text-one {
    font-size: 35px;
    font-weight: 900;
    line-height: 0.74;
    text-align: center;
    color: #ffffff;
    margin-bottom: 15px;
    margin-top: 20px; }
  .lead-section-third .text-two {
    font-size: 14px;
    line-height: 1.57;
    text-align: center;
    color: #ffffff; }

.heading-one {
  display: flex;
  justify-content: flex-end;
  padding: 0 25rem 2rem 15rem;
  margin-top: 2rem; }

.s-text-one {
  font-size: 40px;
  font-weight: bold;
  color: #063bff;
  margin-right: 10px; }

.s-text-two {
  font-size: 40px;
  font-weight: 900;
  color: #363636;
  margin-right: 10px; }

.lead-section-four {
  background: url("") no-repeat;
  background-size: cover;
  background-position: bottom;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 4rem; }

.heading {
  display: flex;
  flex-direction: column;
  padding: 4rem 1rem;
  margin-top: 2rem; }

.h-one {
  font-size: 50px;
  font-weight: bold;
  color: #063bff;
  margin-right: 10px; }

.h-two {
  font-size: 50px;
  font-weight: 900;
  color: #363636;
  margin-right: 10px; }

.l-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 2rem 16rem 6rem 0; }

.cards {
  width: 182px;
  height: 118px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  padding: 1rem;
  border-radius: 12px;
  background-color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  font-family: "Heebo", sans-serif;
  line-height: 1.3;
  color: #3a3a3a; }
  .cards.one {
    border: solid 1.2px #eca300; }
  .cards.two {
    border: solid 1.2px #9567e8; }
  .cards.three {
    border: solid 1.2px #27ac83; }
  .cards.four {
    border: solid 1.2px #28bcff; }
  .cards.five {
    border: solid 1.2px #fe7233; }
  .cards.six {
    border: solid 1.2px #77a7ff; }

.testimonial-page {
  width: 1080px;
  height: 578px;
  margin: 51px 0.3px 98px 20.4px;
  padding: 0 6rem;
  border-radius: 20px;
  background: url("") no-repeat;
  background-color: #f7f9fe;
  background-size: 45px 97px;
  background-position: 50px 30px;
  box-shadow: none; }
  .testimonial-page .flex-layout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
  .testimonial-page .user-wrap {
    display: flex;
    flex-direction: column;
    padding: 14rem 0; }
    .testimonial-page .user-wrap .content-wrap .name {
      font-size: 20px;
      font-weight: 500;
      color: #2b2b2b; }
  .testimonial-page .text {
    width: 431px;
    font-size: 18px;
    line-height: 1.44;
    color: #464646;
    margin-bottom: 2rem; }
  .testimonial-page .text-wrap {
    overflow-y: auto; }
    .testimonial-page .text-wrap::-webkit-scrollbar {
      width: 1px; }
  .testimonial-page .dp {
    width: 308px;
    height: 308px;
    margin-right: 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 69px; }
    .testimonial-page .dp img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10%;
      position: relative;
      top: -10px; }
    .testimonial-page .dp .imge {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10%;
      position: relative;
      transform: rotate(-45deg);
      top: -250px; }

.t-text-one {
  font-size: 30px;
  font-weight: bold;
  color: #063bff;
  margin-right: 10px; }

.t-text-two {
  font-size: 30px;
  font-weight: 900;
  color: #363636;
  margin-right: 10px; }

.t-heading {
  display: flex;
  justify-content: center;
  padding: 0 15rem 2rem 15rem;
  margin-left: 2rem;
  margin-top: 2rem; }

.lingoq-testimonial-layout {
  padding-top: 80px;
  font-family: "Heebo";
  max-width: 1080px;
  margin: 0 auto; }
  .lingoq-testimonial-layout .slick-dots {
    bottom: 20px; }
    .lingoq-testimonial-layout .slick-dots li {
      margin: 0;
      width: 15px;
      height: 15px; }
      .lingoq-testimonial-layout .slick-dots li button:before {
        font-size: 10px; }
      .lingoq-testimonial-layout .slick-dots li.slick-active button:before {
        opacity: 0.75;
        color: #063bff; }

.section {
  padding: 48px 0;
  background-color: #f8f9fa; }
  .section .button-wrap {
    text-align: center;
    margin-top: 40px; }
    .section .button-wrap a {
      display: inline-block;
      margin: 12px 0; }
  .section .container {
    max-width: 1078px;
    margin: 0 auto; }
  .section .slider-wrap {
    margin-top: 42px; }
    .section .slider-wrap .slick-list {
      padding: 12px; }
  .section .title-text {
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #20211c;
    margin-bottom: 4px; }
    .section .title-text span {
      color: #063bff;
      font-size: 32px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      margin-bottom: 4px; }
  .section .sub-text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: center;
    color: #20211c; }
  .section .topics-covered {
    padding: 45px 0;
    max-width: 1040px;
    margin: 0 auto; }
    .section .topics-covered .title-text {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #35353c;
      margin-bottom: 20px;
      text-align: left; }
    .section .topics-covered .topics {
      padding: 0;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 20px; }
      .section .topics-covered .topics .topic {
        border-radius: 4px;
        border: solid 0.8px #dee2e6;
        background-color: #ffffff;
        padding: 18px 14px;
        position: relative; }
        .section .topics-covered .topics .topic:before {
          content: "";
          width: 2px;
          height: 35px;
          border-radius: 1px;
          background-color: #063bff;
          position: absolute;
          left: -2px;
          top: 20px; }
        .section .topics-covered .topics .topic .text-one {
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;
          letter-spacing: 0.4px;
          color: #2b2b2b; }
        .section .topics-covered .topics .topic .text-two {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.35px;
          color: #20211c; }
  .section.one .container:first-child {
    margin-bottom: 80px; }
  .section.one .slick-dots {
    bottom: -47px !important;
    width: 0;
    display: flex   !important;
    justify-content: center;
    left: 50%; }
    .section.one .slick-dots li {
      margin: 0; }
      .section.one .slick-dots li button:before {
        font-size: 10px; }
      .section.one .slick-dots li.slick-active button:before {
        opacity: 0.75;
        color: #ff5b13; }
  .section.two .container {
    max-width: 1080px;
    margin: 0 auto; }
  .section.two .flex-wrap {
    display: flex;
    margin-top: 36px; }
    .section.two .flex-wrap > div {
      flex-grow: 1;
      flex-basis: 100%; }
      .section.two .flex-wrap > div:not(:last-child) {
        margin-right: 20px; }
      .section.two .flex-wrap > div .img-wrap {
        height: 172px;
        opacity: 0.8;
        margin-bottom: 16px;
        position: relative;
        cursor: pointer;
        border-radius: 4px; }
        .section.two .flex-wrap > div .img-wrap .play {
          position: absolute;
          width: 31px;
          height: 31px;
          right: 10px;
          bottom: 10px;
          cursor: pointer; }
        .section.two .flex-wrap > div .img-wrap img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 4px; }
      .section.two .flex-wrap > div .content-wrap p {
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.45px;
        color: #20211c; }
      .section.two .flex-wrap > div:not(:last-child) {
        margin-right: 20px; }
  .section.two .experts-wrap {
    margin-top: 64px; }
    .section.two .experts-wrap .text {
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      text-align: center;
      color: #20211c; }
  .section.two .img-list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 36px; }
    .section.two .img-list img {
      max-height: 46px; }
      .section.two .img-list img:not(:last-child) {
        margin-right: 60px; }
  .section.three {
    background: #fff; }
    .section.three .container {
      max-width: 1080px;
      margin: 0 auto; }
    .section.three .flex-wrap {
      display: flex;
      margin-top: 36px; }
      .section.three .flex-wrap > div {
        flex-grow: 1;
        flex-basis: 100%; }
        .section.three .flex-wrap > div:not(:last-child) {
          margin-right: 82px; }
        .section.three .flex-wrap > div .img-wrap {
          opacity: 0.8;
          margin-bottom: 8px; }
          .section.three .flex-wrap > div .img-wrap img {
            height: 135px; }
        .section.three .flex-wrap > div .content-wrap .text-one {
          font-size: 18px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #20211c;
          margin-bottom: 10px; }
        .section.three .flex-wrap > div .content-wrap .text-two {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.45px;
          color: #61625a; }
  .section.four {
    background: #475a65;
    padding: 48px 0 80px 0; }
    .section.four .title-text {
      color: #fff;
      margin-bottom: 48px; }
    .section.four .cards-wrapper .slick-slide {
      padding-right: 20px; }
    .section.four .proj-card {
      background: #fff;
      border-radius: 4px; }
      .section.four .proj-card .img-wrap {
        height: 180px;
        margin-bottom: 16px;
        cursor: pointer; }
        .section.four .proj-card .img-wrap img {
          border-radius: 4px 4px 0 0;
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .section.four .proj-card .text-content {
        padding: 0 15px 20px 15px; }
      .section.four .proj-card .text-one {
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.45px;
        color: #20211c;
        margin-bottom: 9px; }
      .section.four .proj-card .text-two {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        color: #999b90; }
  .section.five {
    background: #f8f9fe; }
    .section.five .slider-wrap .slick-list {
      padding: 0 !important; }
    .section.five .container {
      max-width: 1080px;
      margin: 0 auto; }
    .section.five .title-text {
      margin-bottom: 72px; }
    .section.five .flex-wrap {
      display: flex;
      align-items: flex-start;
      margin-bottom: 70px; }
      .section.five .flex-wrap > div {
        flex-grow: 1;
        flex-basis: 100%;
        text-align: center; }
        .section.five .flex-wrap > div .text-one {
          font-size: 44px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 0.6;
          letter-spacing: normal;
          text-align: center;
          color: #20211c;
          margin-bottom: 16px; }
        .section.five .flex-wrap > div .text-two {
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.22;
          letter-spacing: normal;
          text-align: center;
          color: #61625a; }
    .section.five .testimonial-wrapper .slick-dots {
      bottom: 9px; }
    .section.five .video-wrapper {
      display: flex;
      margin-bottom: 40px;
      height: 280px; }
      .section.five .video-wrapper .video-card {
        flex-grow: 1;
        flex-basis: 100%; }
        .section.five .video-wrapper .video-card:not(:last-child) {
          margin-right: 20px; }
    .section.five .video-card {
      border-radius: 2px;
      background-color: rgba(6, 59, 255, 0.34);
      display: flex;
      align-items: center;
      justify-content: center;
      height: 380px;
      position: relative; }
      .section.five .video-card .play {
        position: absolute;
        width: 100px;
        height: 100px;
        margin: auto;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        cursor: pointer; }
      .section.five .video-card img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
  .section.six {
    background: #fff; }
    .section.six .container {
      max-width: 1080px;
      margin: 0 auto; }
    .section.six .experts-wrap .text {
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      text-align: center;
      color: #20211c; }
    .section.six .register-wrapper-lingoq {
      object-fit: contain;
      box-shadow: 0 2px 44px 0 rgba(0, 0, 0, 0.18);
      padding: 52px;
      max-width: 1080px;
      margin: 0 auto;
      position: relative;
      margin-top: 75px;
      border-radius: 21px;
      background-image: linear-gradient(to bottom, #584ca0, #171234); }
      .section.six .register-wrapper-lingoq .text-one {
        font-size: 45px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.85px;
        color: #ffffff; }
      .section.six .register-wrapper-lingoq .text-two {
        font-size: 20px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        color: #ffffff; }
      .section.six .register-wrapper-lingoq .button {
        background-color: #ff5b13;
        border-radius: 4px;
        font-size: 15px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 1.16px;
        text-align: center;
        color: #ffffff;
        padding: 16px 60px;
        position: absolute;
        right: 50px;
        border: 0;
        top: calc(50% - 26px);
        cursor: pointer; }
    .section.six .img-list {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 36px; }
      .section.six .img-list img {
        max-height: 46px; }
        .section.six .img-list img:not(:last-child) {
          margin-right: 60px; }
  .section.seven .container {
    max-width: 1080px;
    margin: 0 auto; }
  .section.seven .header-wrap {
    display: flex;
    justify-content: center;
    padding: 0 15rem 2rem 15rem;
    margin-left: 2rem; }
    .section.seven .header-wrap .project-text-two {
      font-size: 32px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #151515;
      margin-right: 10px; }
    .section.seven .header-wrap .project-text-one {
      font-size: 32px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #151515;
      margin-right: 10px; }
  .section.seven h2 {
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #151515;
    margin-bottom: 50px; }
  .section.seven .carousel-wrapper .project-card {
    margin-right: 20px; }
  .section.seven .carousel-wrapper .student-tutorial-card {
    margin-right: 10px !important; }
  .section.seven .carousel-wrapper .student-tutorial-card {
    border-radius: 12px;
    box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.05);
    background-color: #d4eeff;
    margin-right: 33px; }
    .section.seven .carousel-wrapper .student-tutorial-card .media-wrap {
      height: 256px;
      position: relative; }
      .section.seven .carousel-wrapper .student-tutorial-card .media-wrap .play {
        position: absolute;
        width: 50px;
        height: 50px;
        right: 20px;
        bottom: 26px;
        cursor: pointer; }
      .section.seven .carousel-wrapper .student-tutorial-card .media-wrap .media-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
        padding: 15px; }
      .section.seven .carousel-wrapper .student-tutorial-card .media-wrap .info-wrap {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.8);
        padding: 9px 16px;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center; }
        .section.seven .carousel-wrapper .student-tutorial-card .media-wrap .info-wrap p {
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.3px;
          color: #ffffff;
          flex-grow: 1;
          flex-basis: 100%; }
          .section.seven .carousel-wrapper .student-tutorial-card .media-wrap .info-wrap p:last-child {
            text-align: right; }
    .section.seven .carousel-wrapper .student-tutorial-card .content-project {
      margin-bottom: 10px; }
      .section.seven .carousel-wrapper .student-tutorial-card .content-project > .text-one {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4d4d4d; }
      .section.seven .carousel-wrapper .student-tutorial-card .content-project .text-two {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #818181;
        text-align: center;
        padding-bottom: 10px; }
      .section.seven .carousel-wrapper .student-tutorial-card .content-project .details-wrap {
        display: flex;
        align-items: center; }
        .section.seven .carousel-wrapper .student-tutorial-card .content-project .details-wrap .img-wrap {
          width: 50px;
          height: 50px;
          border-radius: 100%;
          margin-right: 12px;
          background: #ccc; }
        .section.seven .carousel-wrapper .student-tutorial-card .content-project .details-wrap .text-wrap .text-one {
          font-size: 10px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.25px;
          color: #2f3a4f; }
        .section.seven .carousel-wrapper .student-tutorial-card .content-project .details-wrap .text-wrap .text-two {
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.35px;
          color: #262626;
          margin-bottom: 2px; }
        .section.seven .carousel-wrapper .student-tutorial-card .content-project .details-wrap .text-wrap .text-three {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.3px;
          color: #2f3a4f; }
      .section.seven .carousel-wrapper .student-tutorial-card .content-project .info-wrap p {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        color: #525f78;
        color: #262626; }
    .section.seven .carousel-wrapper .student-tutorial-card.one {
      background-color: #fef0d1; }
    .section.seven .carousel-wrapper .student-tutorial-card.two {
      background-color: #eae3ff; }
    .section.seven .carousel-wrapper .student-tutorial-card.three {
      background-color: #d4eeff; }
    .section.seven .carousel-wrapper .student-tutorial-card.four {
      background-color: #e6ffe7; }
    .section.seven .carousel-wrapper .student-tutorial-card.five {
      background-color: #ffe3ea; }
    .section.seven .carousel-wrapper .student-tutorial-card.six {
      background-color: rgba(82, 95, 120, 0.22); }
  .section.seven .carousel-wrapper .quiz-card {
    margin-right: 20px; }
  .section.seven .carousel-wrapper .testimonial-card {
    margin-right: 20px;
    width: calc(100% - 20px) !important; }
  .section.seven .carousel-wrapper .slick-list {
    position: relative; }
  .section.eight {
    background: #f8f9fa; }

.section-works {
  margin: auto;
  height: 320px;
  overflow: hidden;
  max-width: 1080px; }
  .section-works .row {
    display: flex;
    align-items: center; }
    .section-works .row .col {
      flex-basis: 100%;
      flex-grow: 1; }
      .section-works .row .col .text-one {
        font-size: 50px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #063bff;
        text-align: left;
        margin-bottom: 22px; }
      .section-works .row .col .text-two {
        text-align: left;
        max-width: 480px;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.45px;
        color: #2f3a4f; }
      .section-works .row .col .img-wrap {
        flex-grow: 1;
        flex-basis: 100%;
        margin: 0 19px 6px 0;
        overflow: hidden; }
        .section-works .row .col .img-wrap img {
          height: 300px; }

.vertical-dots {
  list-style: none;
  display: block;
  position: absolute;
  top: 12%;
  margin-top: -23px;
  text-align: right;
  right: 100%; }

.vertical-dots li.slick-active:nth-child(2) {
  background-color: #fde2ea; }

.vertical-dots li.slick-active:nth-child(3) {
  background-color: #e2dcfe; }

.vertical-dots li.slick-active:nth-child(4) {
  background-color: #c0f4ce; }

.vertical-dots li {
  position: relative;
  cursor: pointer;
  margin: 20px 0;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center; }
  .vertical-dots li.slick-active {
    background-color: #d4eeff;
    border: 3px solid #fff;
    width: 43px;
    height: 43px;
    box-shadow: 0px 0px 2px 8px #d2dbe8;
    margin: 30px 0;
    color: #082eb6f1 !important; }
  .vertical-dots li::before {
    content: "";
    width: 2px;
    height: 100%;
    background-color: #d2dbe8;
    position: absolute;
    z-index: 0;
    top: 40px; }
  .vertical-dots li:last-child:before {
    background: none; }

.vertical-dots li button {
  border: 0;
  padding: 0;
  font-size: 22px;
  font-weight: bold;
  color: #979bad; }

.vertical-dots li button:hover:before,
.vertical-dots li button:focus:before {
  opacity: 1; }

.vertical-dots li.slick-active button:before {
  opacity: 0.75;
  color: black; }

.form-lingoq .dial-code {
  border: solid 0.8px #dee2e6; }

.form-lingoq .orange {
  background: #ff5b13;
  color: #ffffff;
  border: solid 1px #ff5b13;
  padding: 13px 65px; }

.form-lingoq .component {
  max-width: 710px;
  margin: 0 auto;
  margin-top: 50px; }
  .form-lingoq .component .error-text {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #f70909;
    text-align: center; }

.form-lingoq .title-text {
  font-size: 32px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  text-align: center;
  color: #20211c; }
  .form-lingoq .title-text span {
    font-weight: bold; }

.form-lingoq .sub-text {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: center;
  color: #20211c; }

.form-lingoq .row {
  display: flex;
  margin-bottom: 15px; }
  .form-lingoq .row.center {
    justify-content: center; }
  .form-lingoq .row.tnc {
    display: block; }
  .form-lingoq .row .col {
    flex-grow: 1;
    flex-basis: 100%;
    display: flex;
    position: relative;
    flex-direction: column; }
    .form-lingoq .row .col .ant-tooltip {
      right: 10px;
      top: calc(50% - 5px); }
    .form-lingoq .row .col:not(:last-child) {
      margin-right: 20px; }
    .form-lingoq .row .col .ant-radio-wrapper {
      white-space: pre-wrap; }
    .form-lingoq .row .col .react-tel-input {
      font-family: "Heebo";
      font-size: 14px;
      border-radius: 2px; }
      .form-lingoq .row .col .react-tel-input.error {
        border: solid 1px red; }
      .form-lingoq .row .col .react-tel-input .search {
        padding: 12px; }
      .form-lingoq .row .col .react-tel-input .search-box {
        background-color: #fff;
        border: solid 0.8px #dee2e6;
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #222;
        font-family: "Heebo"; }
        .form-lingoq .row .col .react-tel-input .search-box::-webkit-input-placeholder {
          /* Edge */
          opacity: 0.8;
          font-size: 14px;
          font-weight: 400;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #222; }
        .form-lingoq .row .col .react-tel-input .search-box:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          opacity: 0.8;
          font-size: 14px;
          font-weight: 400;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #222; }
        .form-lingoq .row .col .react-tel-input .search-box::placeholder {
          opacity: 0.8;
          font-size: 14px;
          font-weight: 400;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #222; }
      .form-lingoq .row .col .react-tel-input .flag-dropdown {
        background-color: #fff;
        border: solid 0.8px #dee2e6; }
      .form-lingoq .row .col .react-tel-input .form-control {
        font-family: "Heebo";
        width: 100%;
        height: auto;
        border-radius: 2px;
        border: solid 0.8px #dee2e6;
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #222;
        width: 100%;
        outline: none;
        padding: 6px 12px 6px 48px; }
        .form-lingoq .row .col .react-tel-input .form-control::-webkit-input-placeholder {
          font-family: "Heebo";
          /* Edge */
          opacity: 0.8;
          font-size: 14px;
          font-weight: 400;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #222; }
        .form-lingoq .row .col .react-tel-input .form-control:-ms-input-placeholder {
          font-family: "Heebo";
          /* Internet Explorer 10-11 */
          opacity: 0.8;
          font-size: 14px;
          font-weight: 400;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #222; }
        .form-lingoq .row .col .react-tel-input .form-control::placeholder {
          font-family: "Heebo";
          opacity: 0.8;
          font-size: 14px;
          font-weight: 400;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #222; }
    .form-lingoq .row .col input[type="text"],
    .form-lingoq .row .col input[type="number"],
    .form-lingoq .row .col textarea {
      background-color: #fff;
      opacity: 0.9;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.19;
      letter-spacing: normal;
      color: rgba(53, 64, 82, 0.9);
      width: 100%;
      outline: none;
      padding: 12px 15px;
      border: solid 0.8px #dee2e6; }
      .form-lingoq .row .col input[type="text"].error,
      .form-lingoq .row .col input[type="number"].error,
      .form-lingoq .row .col textarea.error {
        border: solid 1px red; }
      .form-lingoq .row .col input[type="text"]::-webkit-input-placeholder,
      .form-lingoq .row .col input[type="number"]::-webkit-input-placeholder,
      .form-lingoq .row .col textarea::-webkit-input-placeholder {
        /* Edge */
        opacity: 0.8;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #222; }
      .form-lingoq .row .col input[type="text"]:-ms-input-placeholder,
      .form-lingoq .row .col input[type="number"]:-ms-input-placeholder,
      .form-lingoq .row .col textarea:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        opacity: 0.8;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #222; }
      .form-lingoq .row .col input[type="text"]::placeholder,
      .form-lingoq .row .col input[type="number"]::placeholder,
      .form-lingoq .row .col textarea::placeholder {
        opacity: 0.8;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #222; }
    .form-lingoq .row .col .label {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #525f78;
      display: block;
      margin-bottom: 6px; }
    .form-lingoq .row .col .ant-checkbox-group > label {
      display: block;
      margin-bottom: 12px; }
    .form-lingoq .row .col .select-dropdown {
      border: solid 0.8px #dee2e6; }
      .form-lingoq .row .col .select-dropdown.error {
        border: solid 0.8px #f53636; }
      .form-lingoq .row .col .select-dropdown select {
        padding: 9px 15px; }
    .form-lingoq .row .col textarea {
      min-height: 115px; }

.form-lingoq .label-age {
  color: rgba(53, 64, 82, 0.9);
  font-size: 16px; }

.form-lingoq .custom-tab {
  margin-top: 1rem; }
  .form-lingoq .custom-tab .tab-content {
    margin-top: 70px; }
  .form-lingoq .custom-tab .tabs {
    border-radius: 2px;
    padding: 0px;
    display: flex;
    position: relative;
    background-color: #f8f9fa;
    border-radius: 3.6px;
    flex-wrap: wrap;
    margin-bottom: 10px; }
    .form-lingoq .custom-tab .tabs .tab {
      font-size: 14px;
      padding: 1rem 1rem;
      background-color: #ffffff;
      color: rgba(53, 64, 82, 0.9);
      border: solid 0.9px #d1d8e1;
      border-radius: 3.6px;
      border-radius: 3.6px;
      text-align: center;
      margin-right: 10px;
      margin-bottom: 10px; }
      .form-lingoq .custom-tab .tabs .tab div {
        text-align: center; }
        .form-lingoq .custom-tab .tabs .tab div p {
          margin: 0; }
    .form-lingoq .custom-tab .tabs .tab.active {
      border: solid 0.9px #063bff;
      background-color: #eef1ff;
      color: #063bff; }
    .form-lingoq .custom-tab .tabs .tab.error {
      border: solid 0.9px red; }
    .form-lingoq .custom-tab .tabs .tab-background {
      transition: all 0.5s ease;
      box-shadow: none; }
      .form-lingoq .custom-tab .tabs .tab-background.Toddler {
        left: 0; }
      .form-lingoq .custom-tab .tabs .tab-background.Junior {
        left: 180; }
      .form-lingoq .custom-tab .tabs .tab-background.Junior + {
        left: 360; }
      .form-lingoq .custom-tab .tabs .tab-background.Explorer {
        left: 480px; }

@media screen and (max-width: 768px) {
  .lead-page-layout .carousel-wrapper button.slick-prev {
    left: 25%;
    margin: 0;
    top: 109%; }
  .lead-page-layout .carousel-wrapper button.slick-next {
    right: 25%;
    margin: 0;
    top: 109%; }
  .lead-page-layout .sticky-element .home {
    background: #ffffff; }
    .lead-page-layout .sticky-element .home .nav-wrap {
      justify-content: end; }
      .lead-page-layout .sticky-element .home .nav-wrap a {
        display: none; }
  .lead-page-layout.sticky .home img .m-menu {
    display: inline;
    margin-left: 10px; }
  .lead-page-layout .banner-wrapper {
    background: url("") no-repeat, url("") no-repeat;
    background-size: auto 230px, cover;
    background-position: top center;
    position: relative;
    top: 75px;
    height: auto;
    display: block;
    padding: 20px; }
    .lead-page-layout .banner-wrapper .container {
      margin: 0; }
      .lead-page-layout .banner-wrapper .container .slider-tag {
        font-size: 9px;
        border-radius: 1px;
        opacity: 0.81;
        padding: 5px; }
      .lead-page-layout .banner-wrapper .container .scroll-text {
        display: none; }
      .lead-page-layout .banner-wrapper .container .content-wrap {
        padding-top: 230px;
        margin-right: 0;
        margin-left: 0; }
        .lead-page-layout .banner-wrapper .container .content-wrap .text-one {
          font-size: 16px;
          font-weight: 500;
          line-height: 2.58; }
        .lead-page-layout .banner-wrapper .container .content-wrap .text-two {
          font-size: 36px;
          font-weight: 900;
          margin-bottom: 12px; }
        .lead-page-layout .banner-wrapper .container .content-wrap .text-three {
          font-size: 10px;
          font-weight: 500;
          margin-bottom: 22px; }
          .lead-page-layout .banner-wrapper .container .content-wrap .text-three span {
            font-weight: 500; }
        .lead-page-layout .banner-wrapper .container .content-wrap .orange {
          font-size: 14px;
          font-weight: 500;
          padding: 12px 60px;
          margin-bottom: 10px; }
  .lead-page-layout .company-details .col .text-two {
    font-size: 15.5px; }
  .section {
    padding-top: 10rem;
    background-color: #f8f9fa; }
    .section .title-text {
      font-size: 20px;
      margin-bottom: 4px; }
      .section .title-text span {
        font-size: 20px;
        margin-bottom: 4px;
        padding: 0; }
    .section .sub-text {
      font-size: 12px;
      padding: 0 1.5rem; }
    .section .img-list {
      justify-content: space-evenly !important;
      margin-top: 36px;
      flex-wrap: wrap; }
      .section .img-list img {
        margin: 10px !important;
        max-height: 42px !important; }
    .section.six .register-wrapper-lingoq {
      padding: 20px;
      text-align: center;
      margin-top: 10px; }
      .section.six .register-wrapper-lingoq .text-two {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.3px;
        text-align: center;
        color: #ffffff; }
      .section.six .register-wrapper-lingoq .text-one {
        font-size: 24px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.6px;
        text-align: center;
        color: #ffffff; }
    .section.six .register-wrapper-lingoq .button {
      position: relative;
      margin: 14px auto auto;
      left: auto;
      right: auto;
      font-size: 12px; }
      .section.six .register-wrapper-lingoq .button .slider-wrap {
        margin-top: 15px; }
        .section.six .register-wrapper-lingoq .button .slider-wrap .slick-list {
          padding: 12px; }
  .lead-section-third {
    background-position: 20% 77%;
    background-size: 273%; }
    .lead-section-third .heading {
      padding: 2rem 1rem; }
    .lead-section-third .heading-two {
      display: flex;
      justify-content: flex-start;
      padding: 0 2rem;
      font-size: 12px; }
    .lead-section-third .s-text-one .s-text-two {
      font-size: 24px; }
    .lead-section-third .r-layout {
      justify-content: center;
      align-items: center;
      padding: 2rem;
      margin-left: -10px;
      margin-top: -15px;
      margin-bottom: 20rem; }
    .lead-section-third .rect {
      width: 92px;
      height: 110px;
      margin: 1rem;
      align-items: flex-end;
      justify-content: center;
      border-radius: 12px;
      padding: 1rem; }
    .lead-section-third .r-text {
      font-size: 10px; }
    .lead-section-third .rect-layout {
      width: 80px;
      height: 75px;
      border-radius: 9.6px;
      top: 225px; }
    .lead-section-third .box {
      border-radius: 40px;
      flex-wrap: wrap;
      margin: 1rem 2rem; }
    .lead-section-third .box-layout {
      flex-wrap: wrap;
      padding: 2rem; }
    .lead-section-third .text-one {
      font-size: 24px; }
    .lead-section-third .text-two {
      font-size: 10px; }
  .heading-one {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    padding: 0 2rem; }
  .lead-section-four {
    background-image: url("");
    background-size: cover;
    background-position: bottom;
    padding: 2rem; }
    .lead-section-four .heading {
      margin-top: 0;
      padding: 3rem 1rem; }
    .lead-section-four .h-one {
      font-size: 30px; }
    .lead-section-four .h-two {
      margin-top: 10px;
      font-size: 30px; }
    .lead-section-four .l-cards {
      justify-content: center;
      padding: 1rem;
      padding-bottom: 32rem; }
    .lead-section-four .cards {
      width: 112px;
      height: 76px;
      margin: 5px;
      padding: 1rem;
      border-radius: 7.2px;
      font-size: 11px;
      line-height: 1.3; }
  .section.eight {
    padding: 40px 20px 20px 20px; }
  .section.seven {
    padding: 40px 20px 20px 20px; }
    .section.seven .carousel-wrapper .student-tutorial-card .media-wrap .play {
      width: 30px;
      height: 30px; }
    .section.seven .carousel-wrapper .student-tutorial-card .content-project > .text-one {
      display: block;
      height: 50px;
      padding: 0px 14px;
      line-height: 23px; }
  .section.six {
    padding: 40px 20px 20px 20px; }
  .section.five {
    padding: 40px 20px 20px 20px; }
    .section.five .slider-wrap {
      margin: 0; }
  .form-lingoq .title-text {
    font-size: 20px;
    margin-bottom: 8px; }
  .form-lingoq .orange {
    background: #063bff;
    color: #ffffff;
    border: solid 1px #063bff;
    margin-top: 27px; }
  .form-lingoq .sub-text {
    font-size: 16px;
    margin-bottom: 20px; }
  .form-lingoq .row {
    flex-direction: column;
    margin-top: 0; }
    .form-lingoq .row .col {
      max-width: 100%; }
      .form-lingoq .row .col:not(:last-child) {
        margin: 0;
        margin-bottom: 16px; }
      .form-lingoq .row .col .select-dropdown select {
        font-size: 14px;
        font-weight: 500;
        padding: 12px 15px; }
  .lingoq-testimonial-layout {
    padding: 0; }
    .lingoq-testimonial-layout .slick-dots {
      margin: 20px auto 20px;
      position: relative;
      bottom: 0; }
    .lingoq-testimonial-layout .testimonial-wrapper {
      padding: 40px 20px 0 20px; }
      .lingoq-testimonial-layout .testimonial-wrapper .t-heading {
        padding: 0;
        margin: 0; }
        .lingoq-testimonial-layout .testimonial-wrapper .t-heading .t-text-two,
        .lingoq-testimonial-layout .testimonial-wrapper .t-heading .t-text-one {
          font-size: 18px; }
      .lingoq-testimonial-layout .testimonial-wrapper .slick-slider {
        margin-top: 15px; }
        .lingoq-testimonial-layout .testimonial-wrapper .slick-slider .testimonial {
          padding: 20px; }
          .lingoq-testimonial-layout .testimonial-wrapper .slick-slider .testimonial p {
            font-size: 10px;
            line-height: 18px; }
          .lingoq-testimonial-layout .testimonial-wrapper .slick-slider .testimonial .feedback-person {
            font-size: 12px;
            margin-top: 10px; }
          .lingoq-testimonial-layout .testimonial-wrapper .slick-slider .testimonial .quote {
            position: absolute;
            top: -25px;
            left: -9px;
            width: 20px; }
          .lingoq-testimonial-layout .testimonial-wrapper .slick-slider .testimonial .ant-row {
            flex-wrap: wrap;
            flex-flow: column-reverse; }
            .lingoq-testimonial-layout .testimonial-wrapper .slick-slider .testimonial .ant-row .frame {
              width: 280px;
              height: 280px;
              margin: 0 auto; }
  .courses-layout {
    padding: 0; }
  .section.seven .header-wrap {
    padding: 0;
    margin: 20px auto; }
    .section.seven .header-wrap .project-text-two,
    .section.seven .header-wrap .project-text-one {
      font-size: 18px; }
  .section-works {
    height: auto; }
    .section-works .row {
      flex-wrap: wrap;
      flex-flow: column-reverse; }
      .section-works .row .col .text-one {
        font-size: 30px;
        margin-bottom: 12px; }
      .section-works .row .col .text-two {
        font-size: 12px; }
      .section-works .row .col .img-wrap {
        margin: 0; }
    .section-works img {
      display: block;
      width: 100%;
      max-width: 500px;
      height: auto !important; }
  .vertical-dots {
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    margin: 0;
    top: 0;
    right: 0;
    height: 50px;
    margin: auto;
    width: 300px; }
    .vertical-dots li::before {
      content: "";
      width: 30px;
      height: 2px;
      background-color: #d2dbe8;
      position: absolute;
      z-index: 0;
      top: 21px;
      left: 43px; }
    .vertical-dots li button {
      font-size: 16px; } }

@media screen and (max-width: 320px) {
  .lead-page-layout .carousel-wrapper button.slick-prev {
    left: 20%; }
  .lead-page-layout .carousel-wrapper button.slick-next {
    right: 20%; } }

.class-card-header {
  border-radius: 4px;
  background-color: #ffffff;
  position: relative;
  z-index: 1;
  padding: 36px 20px 15px; }
  .class-card-header img {
    margin-bottom: 12px; }
  .class-card-header .text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #464646;
    margin-bottom: 80px; }
  .class-card-header .user-wrap {
    display: flex; }
    .class-card-header .user-wrap .dp {
      width: 50px;
      height: 50px;
      margin-right: 20px; }
    .class-card-header .user-wrap .img-wrap {
      width: 50px;
      height: 50px;
      margin-right: 12px;
      background: #ddd;
      border-radius: 100%; }
    .class-card-header .user-wrap .content-wrap .text-one {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.45px;
      color: #262626;
      margin-bottom: 2px; }
    .class-card-header .user-wrap .content-wrap .name {
      font-size: 14px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #6741d0; }
    .class-card-header .user-wrap .content-wrap .desc {
      font-size: 30px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #28bcff; }
    .class-card-header .user-wrap .content-wrap .lingoQToddler {
      color: #28bcff; }
    .class-card-header .user-wrap .content-wrap .lingoJunior {
      color: #27ac83; }
    .class-card-header .user-wrap .content-wrap .ligoqSenior {
      color: #9567e8; }
    .class-card-header .user-wrap .content-wrap .lingoqExplorer {
      color: #0fbdce; }
    .class-card-header .user-wrap .content-wrap .text-two {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.3px;
      color: #2f3a4f; }

.new-class-card {
  border-radius: 7px;
  background-color: #ffffff;
  padding: 0;
  max-width: 340px;
  position: relative;
  font-family: "Heebo";
  cursor: pointer;
  transition: transform 0.2s;
  /* Animation */
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.06); }
  .new-class-card:hover {
    transform: scale(1.025); }
  .new-class-card .text-wrap-discription {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #252525;
    margin-left: 2px;
    margin-bottom: 5px; }
  .new-class-card .text-wrap-session {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #4d4d4d;
    margin: 10px 0; }
  .new-class-card .discription-text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #4d4d4d;
    padding: 2px 20px 15px; }
  .new-class-card hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 1rem;
    padding: 0; }
  .new-class-card .sub-text-wrapper {
    height: 100px;
    padding: 2px 20px 15px; }
  .new-class-card .bottom-section {
    margin-top: 20px;
    padding-top: 1rem;
    padding: 20px;
    text-align: center; }
    .new-class-card .bottom-section button {
      width: 100%;
      margin-bottom: 20px;
      background-color: #ff5b13;
      border: solid 1px #ff5b13; }
    .new-class-card .bottom-section p.link {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.57px;
      text-align: center;
      color: #063bff;
      cursor: pointer; }
      .new-class-card .bottom-section p.link a {
        color: #063bff; }
  .new-class-card.one .img-wrap {
    background: #f94267 url(images/homepage/class-card-one.png) no-repeat;
    background-size: 100% 100%; }
  .new-class-card.two .img-wrap {
    background: #5c48fb url(images/homepage/class-card-two.png) no-repeat;
    background-size: 100% 100%; }
  .new-class-card.three .img-wrap {
    background: #524bde url(images/homepage/class-card-three.png) no-repeat;
    background-size: 100% 100%; }
  .new-class-card.four .img-wrap {
    background: #1b9dd1 url(images/homepage/class-card-four.png) no-repeat;
    background-size: 100% 100%; }
  .new-class-card.five .img-wrap {
    background: #e732c4 url(images/homepage/class-card-five.png) no-repeat;
    background-size: 100% 100%; }
  .new-class-card.six .img-wrap {
    background: rgba(82, 95, 120, 0.22) url(images/homepage/class-card-six.png) no-repeat;
    background-size: 100% 100%; }
  .new-class-card .img-wrap {
    border-radius: 2px;
    position: relative;
    width: 100%; }
    .new-class-card .img-wrap > div {
      position: relative;
      z-index: 1;
      padding: 36px 20px 15px 20px; }
    .new-class-card .img-wrap .text-one {
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
      font-size: 22px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.18;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      margin-bottom: 9px; }
    .new-class-card .img-wrap .text-two {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      display: block;
      display: -webkit-box;
      max-width: 100%;
      margin: 0 auto;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 64px;
      max-width: 280px;
      margin: 0 auto; }
    .new-class-card .img-wrap .text-three {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #ffffff;
      display: flex;
      align-items: center; }
      .new-class-card .img-wrap .text-three img {
        max-width: 1.3rem;
        margin-right: 0.6rem; }
    .new-class-card .img-wrap .flex-wrap {
      display: flex;
      align-items: center;
      padding: 0; }
      .new-class-card .img-wrap .flex-wrap > p {
        flex-grow: 1;
        flex-basis: 100%; }
        .new-class-card .img-wrap .flex-wrap > p:first-child {
          justify-content: flex-end;
          padding-right: 15px; }
        .new-class-card .img-wrap .flex-wrap > p:last-child {
          justify-content: flex-start;
          padding-left: 15px; }
    .new-class-card .img-wrap .line {
      height: 1px;
      background-color: rgba(0, 0, 0, 0.12);
      margin: 14px 0; }
    .new-class-card .img-wrap > img {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 0; }
  .new-class-card .content {
    padding: 16px 20px 20px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: normal;
    text-align: center;
    color: #2b2b2b;
    height: 88px;
    overflow: hidden;
    /* Track */
    /* Handle */
    /* Handle on hover */ }
    .new-class-card .content.lingoq {
      height: 88px;
      overflow: hidden;
      display: none; }
    .new-class-card .content::-webkit-scrollbar {
      width: 1px; }
    .new-class-card .content::-webkit-scrollbar-track {
      background: #f1f1f1; }
    .new-class-card .content::-webkit-scrollbar-thumb {
      background: #fafafa; }
    .new-class-card .content::-webkit-scrollbar-thumb:hover {
      background: #fefefe; }
  .new-class-card .icons-wrap {
    padding: 0 20px;
    text-align: center;
    height: 90px;
    overflow: hidden; }
    .new-class-card .icons-wrap .icon {
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      margin: 7.5px 8px; }
      .new-class-card .icons-wrap .icon img {
        max-height: 26px;
        max-width: 100%;
        display: inline-block; }
  .new-class-card .tags-wrap {
    text-align: center;
    padding: 0 18px;
    margin-top: 20px; }
    .new-class-card .tags-wrap .tag {
      display: inline-flex;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #d38e00;
      padding: 8px 12px;
      border-radius: 4px;
      background-color: #fffaec;
      margin: 0 8px 8px 0; }
      .new-class-card .tags-wrap .tag img {
        margin-right: 4px;
        max-height: 13px;
        display: inline-block;
        vertical-align: middle; }
  .new-class-card .text-four {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: right;
    color: #2b2b2b;
    margin-top: 28px;
    display: flex; }
    .new-class-card .text-four span {
      flex-grow: 1;
      flex-basis: 100%; }
      .new-class-card .text-four span:last-child {
        text-align: right; }
  .new-class-card .mt-28 {
    margin-top: 28px; }
  .new-class-card .line {
    height: 1px;
    opacity: 0.2;
    background: #525f78;
    margin: 14px auto 18px auto;
    width: calc(100% - 40px); }
  .new-class-card .pricing-wrap {
    border-radius: 8px;
    border: solid 1px #d5dce4;
    display: flex;
    padding: 8px 0px;
    max-width: calc(100% - 20px);
    margin: 10px auto auto;
    background-color: #eef1ff; }
    .new-class-card .pricing-wrap .pricing {
      flex-basis: 100%;
      flex-grow: 1;
      padding: 0 8px; }
      .new-class-card .pricing-wrap .pricing:not(:last-child) {
        border-right: solid 1px #e3e4ec; }
      .new-class-card .pricing-wrap .pricing .text-one {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        text-align: center;
        color: #2f3a4f; }
        .new-class-card .pricing-wrap .pricing .text-one span {
          border-left: solid 1px #e3e4ec;
          padding-left: 5px; }
      .new-class-card .pricing-wrap .pricing .text-two {
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ff6237;
        text-align: center; }
      .new-class-card .pricing-wrap .pricing .text-session {
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #2f3a4f;
        text-align: center; }
  .new-class-card .flex-wrap {
    display: flex;
    align-items: center;
    padding: 0 20px 20px; }
    .new-class-card .flex-wrap button {
      display: block;
      margin-bottom: 20px;
      width: 100%; }

@media screen and (max-width: 768px) {
  .class-card-header {
    padding: 3rem 0.7rem 0 0.7rem; }
    .class-card-header img {
      width: 36px;
      height: 45px;
      margin-bottom: 12px; }
    .class-card-header .user-wrap .dp {
      width: 40px;
      height: 50px;
      margin-right: 0; }
    .class-card-header .user-wrap .content-wrap .name {
      font-size: 10px; }
    .class-card-header .user-wrap .content-wrap .desc {
      font-size: 18px; }
    .class-card-header .user-wrap .content-wrap .text-two {
      font-size: 12px; }
  .new-class-card {
    margin: 0 11px;
    max-width: 100% !important;
    padding: 0 5px; }
    .new-class-card .text-wrap-discription {
      font-size: 14px;
      margin-left: 2px;
      margin-bottom: 5px; }
    .new-class-card .text-wrap-session {
      font-size: 14px;
      margin: 10px 0; }
    .new-class-card .sub-text-wrapper {
      height: 100px;
      padding: 2px 10px 5px; }
    .new-class-card .discription-text {
      font-size: 14px;
      padding: 2px 10px 15px; }
    .new-class-card hr {
      margin: 3em 1rem 1rem 1rem; }
    .new-class-card .pricing-wrap {
      border-radius: 2.4px;
      border: none;
      display: flex;
      padding: 8px 0px;
      max-width: calc(100% - 20px);
      margin: 0 10px; }
      .new-class-card .pricing-wrap .pricing {
        flex-basis: 100%;
        flex-grow: 1;
        padding: 0 8px; }
        .new-class-card .pricing-wrap .pricing .text-one {
          font-size: 8px; }
        .new-class-card .pricing-wrap .pricing .text-two {
          font-size: 14px; }
        .new-class-card .pricing-wrap .pricing .text-session {
          font-size: 14px;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #2f3a4f;
          text-align: center; }
    .new-class-card .bottom-section {
      margin-top: 0px;
      padding: 20px 10px;
      text-align: center; }
      .new-class-card .bottom-section button {
        width: 100%;
        margin-bottom: 20px;
        padding: 1rem;
        font-size: 14px;
        background-color: #ff5b13;
        border: solid 1px #ff5b13; }
      .new-class-card .bottom-section p.link {
        font-size: 10px; } }

.testimonial {
  background-color: #f7f9fe;
  width: 100%;
  padding: 30px 70px; }
  .testimonial .feedback-person {
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2b2b2b;
    margin-top: 16px;
    display: block; }
  .testimonial .ant-row {
    display: flex;
    align-items: center; }
  .testimonial p {
    font-size: 18px;
    font-weight: normal;
    line-height: 1.44;
    letter-spacing: normal;
    color: #464646; }
    .testimonial p b {
      font-weight: bold; }
  .testimonial .quote {
    width: 50px;
    margin-bottom: 50px; }

.frame {
  width: 350px;
  height: 350px;
  background-size: cover;
  background-position: center; }
  .frame img {
    width: 100%; }

.demo-booking-lingoq {
  width: 100%; }
  .demo-booking-lingoq .form-component .component {
    max-width: 372px;
    margin: 0 auto;
    margin-top: 50px; }
  .demo-booking-lingoq #newgoal {
    width: 100%;
    margin-bottom: 0px; }
  .demo-booking-lingoq .label {
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7f8fa4;
    margin-bottom: 6px;
    display: block;
    text-align: left; }
  .demo-booking-lingoq .custom-tab .tabs {
    border-radius: 2px;
    padding: 0;
    display: flex;
    position: relative;
    background-color: #fff;
    border-radius: 3.6px;
    flex-wrap: wrap;
    margin-bottom: 10px; }
    .demo-booking-lingoq .custom-tab .tabs .tab {
      font-size: 12px;
      padding: 1rem;
      background-color: #fff;
      color: #2f3a4f;
      border: 0.9px solid #d1d8e1;
      border-radius: 3.6px;
      text-align: center;
      margin-right: 10px;
      margin-bottom: 10px; }
      .demo-booking-lingoq .custom-tab .tabs .tab.active {
        border: 0.9px solid #063bff;
        background-color: #eef1ff;
        color: #063bff; }
      .demo-booking-lingoq .custom-tab .tabs .tab.error {
        border: 0.9px solid red; }
